import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Button } from '@/components/Button/Button';
import { Skeleton } from '@/components/Skeleton/Skeleton';
import { ITableColumn, Table } from '@/components/Table/Table';
import { TextArea } from '@/components/TextArea/TextArea';
import { Typography } from '@/components/Typography/Typography';
import { FunctionContent } from '@/components/wrapper/FunctionWrapper/FunctionContent/FunctionContent';
import { FunctionFooter } from '@/components/wrapper/FunctionWrapper/FunctionFooter/FunctionFooter';
import { FunctionWrapper } from '@/components/wrapper/FunctionWrapper/FunctionWrapper';
import { useSelectedPerson } from '@/core/hooks/useSelectedPerson';
import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';
import { organizationalUnitsSelectors } from '@/core/redux/slices/functions/organizationalUnits/selectors';
import { organizationalUnitsActions } from '@/core/redux/slices/functions/organizationalUnits/slice';
import { organizationalUnitsModalsActions } from '@/core/redux/slices/modalsSlice/functions/organizationalUnits/slice';
import { LoadingStatus } from '@/types/loadingStatus';

interface IAdditionalPersonRequirementForm {
  comment?: string;
}

export const AdditionalPersonRequirement: React.FC = () => {
  const { t: additionalPersonRequirementTranslations } = useTranslation(
    'additionalPersonRequirement'
  );

  const [selectedID, setSelectedID] = useState<string | null>(null);

  const additionalPersonRequirements = useAppSelector(
    organizationalUnitsSelectors.additionalPersonRequirement
  );

  const loadingStatus = useAppSelector(
    organizationalUnitsSelectors.additionalPersonRequirementLock
  );

  const { selectedPersonID } = useSelectedPerson();

  const dispatch = useAppDispatch();

  const form = useForm<IAdditionalPersonRequirementForm>();

  const tableColumns: ITableColumn[] = [
    {
      header: additionalPersonRequirementTranslations('table.header.assistance.label'),
      accessor: 'assistance.name',
    },
    {
      header: additionalPersonRequirementTranslations('table.header.responsible.label'),
      accessor: 'responsible.value',
    },
    {
      header: additionalPersonRequirementTranslations('table.header.appliedOn.label'),
      accessor: 'appliedTimestamp',
    },
    {
      header: additionalPersonRequirementTranslations('table.header.approvedFrom.label'),
      accessor: 'approvedFromTimestamp',
    },
    {
      header: additionalPersonRequirementTranslations('table.header.until.label'),
      accessor: 'approvedToTimestamp',
    },
    {
      header: additionalPersonRequirementTranslations('table.header.minutesPerDay.label'),
      accessor: 'minutesPerDay',
    },
  ];

  const handleSelect = (id: string) => {
    if (!additionalPersonRequirements) {
      return;
    }

    const foundItem = additionalPersonRequirements.personRequirements.find(
      (personRequirement) => personRequirement.id === Number(id)
    );

    if (!foundItem) {
      form.reset({
        comment: undefined,
      });
      setSelectedID(null);

      return;
    }

    setSelectedID(id);
    form.reset({
      comment: foundItem.comment ?? '',
    });
  };

  useEffect(() => {
    if (!selectedPersonID) {
      return;
    }

    dispatch(
      organizationalUnitsActions.fetchAdditionalPersonRequirementUnits({
        personID: selectedPersonID,
      })
    );

    return () => {
      dispatch(organizationalUnitsActions.setAdditionalPersonRequirementUnits(null));
    };
  }, []);

  const handleCreate = () => {
    dispatch(
      organizationalUnitsModalsActions.openAdditionalPersonRequirementModal({
        additionalPersonRequirementID: null,
        responsible: additionalPersonRequirements?.responsible,
      })
    );
  };

  const handleEdit = () => {
    dispatch(
      organizationalUnitsModalsActions.openAdditionalPersonRequirementModal({
        additionalPersonRequirementID: Number(selectedID),
        responsible: null,
      })
    );
  };

  const handleOpenTimeListModal = () => {
    const foundRequirement = additionalPersonRequirements?.personRequirements.find(
      (personRequirement) => personRequirement.id === Number(selectedID)
    );

    dispatch(
      organizationalUnitsModalsActions.openAdditionalPersonRequirementTimeListModal({
        additionalPersonRequirementID: Number(selectedID),
        assistanceName: foundRequirement?.assistance.name,
      })
    );
  };

  const handleDelete = () => {
    if (!selectedPersonID) {
      return;
    }

    dispatch(
      organizationalUnitsActions.deleteAdditionalPersonRequirement({
        additionalPersonRequirementID: Number(selectedID),
        personID: selectedPersonID,
      })
    );
  };

  return (
    <FunctionWrapper>
      <FunctionContent withFooter={true}>
        <div className={'h-full flex flex-col gap-3'}>
          <Typography type={'H3'} className={'px-7'}>
            {additionalPersonRequirementTranslations('title.label')}
          </Typography>
          <Skeleton trigger={loadingStatus === LoadingStatus.LOADING}>
            {additionalPersonRequirements?.personRequirements && (
              <Table
                columns={tableColumns}
                data={additionalPersonRequirements.personRequirements}
                onSingleSelectChange={handleSelect}
                singleSelect={true}
              />
            )}
          </Skeleton>
          <FormProvider {...form}>
            <div className={'px-5 flex flex-col gap-2'}>
              <Typography>
                {additionalPersonRequirementTranslations('fields.comment.label')}
              </Typography>
              <TextArea control={form.register('comment')} readOnly={true} />
            </div>
          </FormProvider>
        </div>
      </FunctionContent>
      <FunctionFooter>
        {additionalPersonRequirements?.isNewRequirementAvailable && (
          <Button onClick={handleCreate}>
            {additionalPersonRequirementTranslations('buttons.create.label')}
          </Button>
        )}
        {selectedID && (
          <>
            {additionalPersonRequirements?.isEditable && (
              <Button onClick={handleEdit}>
                {additionalPersonRequirementTranslations('buttons.update.label')}
              </Button>
            )}
            <Button onClick={handleDelete}>
              {additionalPersonRequirementTranslations('buttons.delete.label')}
            </Button>
            <Button onClick={handleOpenTimeListModal}>
              {additionalPersonRequirementTranslations('buttons.time.label')}
            </Button>
          </>
        )}
      </FunctionFooter>
    </FunctionWrapper>
  );
};
