import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Typography } from '@/components/Typography/Typography';
import { MeasureCompleteValidationStatusEnum } from '@/core/enums/functions/vocationalTrainingArea/measureCompleteValidationStatusEnum';
import { useDialog } from '@/core/hooks/useDialog';
import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';
import { vocationalTrainingAreaSelectors } from '@/core/redux/slices/functions/vocationalTrainingArea/selector';
import {
  IMeasureItem,
  vocationalTrainingAreaActions,
} from '@/core/redux/slices/functions/vocationalTrainingArea/slice';
import { vocationalTrainingAreaModalActions } from '@/core/redux/slices/modalsSlice/functions/vocationalTrainingArea/slice';

interface IMeasureCompleteErrorDialog {
  description: string | React.ReactNode;
  onConfirm: () => void;
  onCancel?: () => void;
}

const buildErrorDescription = (
  description: string,
  warningMessage: string,
  measuresList?: IMeasureItem[]
): React.ReactNode => {
  return (
    <div className={'flex flex-col gap-1'}>
      <Typography>{description}</Typography>
      <div className={'my-2 flex flex-col gap-1'}>
        {measuresList &&
          measuresList.map((measure) => <Typography key={measure.id}>{measure.name}</Typography>)}
      </div>
      <Typography>{warningMessage}</Typography>
    </div>
  );
};

export function useVocationalTrainingAreaErrors() {
  const { t: measureCompleteErrorsTranslations } = useTranslation('vocationalTrainingArea', {
    keyPrefix: 'errors',
  });

  const [activeError, setActiveError] = useState<MeasureCompleteValidationStatusEnum | null>(null);

  const measureCompleteErrors = useAppSelector(
    vocationalTrainingAreaSelectors.measureCompleteErrors
  );
  const completeMeasureState = useAppSelector(vocationalTrainingAreaSelectors.completeMeasureState);
  const availableIDs = completeMeasureState?.validAppointments.map((measure) => measure.id) ?? [];
  const withoutAppointmentsIDs =
    completeMeasureState?.withoutAppointments.map((measure) => measure.id) ?? [];

  const dispatch = useAppDispatch();

  const measureCompleteDialogs: Partial<
    Record<MeasureCompleteValidationStatusEnum, IMeasureCompleteErrorDialog>
  > = {
    [MeasureCompleteValidationStatusEnum.SINGLE_MEASURE_COMPLETED]: {
      description: measureCompleteErrorsTranslations('singleMeasureComplete.label'),
      onConfirm: () => {
        dispatch(
          vocationalTrainingAreaActions.setCompleteMeasuresErrors({
            [MeasureCompleteValidationStatusEnum.SINGLE_MEASURE_COMPLETED]: false,
          })
        );
      },
    },
    [MeasureCompleteValidationStatusEnum.SINGLE_MEASURE_WITHOUT_APPOINTMENTS]: {
      description: measureCompleteErrorsTranslations('singleMeasureWithoutAppointments.label'),
      onConfirm: () => {
        dispatch(
          vocationalTrainingAreaModalActions.removeMeasureFromPerson(withoutAppointmentsIDs)
        );
        dispatch(
          vocationalTrainingAreaActions.setCompleteMeasuresErrors({
            [MeasureCompleteValidationStatusEnum.SINGLE_MEASURE_WITHOUT_APPOINTMENTS]: false,
          })
        );
      },
      onCancel: () => {
        if (availableIDs.length < 1) {
          return;
        }

        dispatch(vocationalTrainingAreaModalActions.openCompleteMeasureModal(availableIDs));
      },
    },
    [MeasureCompleteValidationStatusEnum.SINGLE_MEASURE_HAS_OPEN_APPOINTMENTS]: {
      description: measureCompleteErrorsTranslations('singleMeasureHasOpenAppointments.label'),
      onConfirm: () => {
        dispatch(
          vocationalTrainingAreaActions.setCompleteMeasuresErrors({
            [MeasureCompleteValidationStatusEnum.SINGLE_MEASURE_HAS_OPEN_APPOINTMENTS]: false,
          })
        );
      },
    },
    [MeasureCompleteValidationStatusEnum.MULTIPLE_HAS_OPEN_APPOINTMENTS]: {
      description: buildErrorDescription(
        measureCompleteErrorsTranslations('multipleMeasureHasOpenAppointmentsD.description.label'),
        measureCompleteErrorsTranslations('multipleMeasureHasOpenAppointmentsD.warning.label'),
        completeMeasureState?.hasOpenAppointments
      ),
      onConfirm: () => {
        dispatch(
          vocationalTrainingAreaActions.setCompleteMeasuresErrors({
            [MeasureCompleteValidationStatusEnum.MULTIPLE_HAS_OPEN_APPOINTMENTS]: false,
          })
        );

        if (!measureCompleteErrors.MULTIPLE_MEASURE_WITHOUT_APPOINTMENTS) {
          dispatch(vocationalTrainingAreaModalActions.openCompleteMeasureModal(availableIDs));
        }
      },
    },
    [MeasureCompleteValidationStatusEnum.MULTIPLE_MEASURE_WITHOUT_APPOINTMENTS]: {
      description: buildErrorDescription(
        measureCompleteErrorsTranslations('multipleWithoutAppointments.description.label'),
        measureCompleteErrorsTranslations('multipleWithoutAppointments.warning.label'),
        completeMeasureState?.withoutAppointments
      ),
      onConfirm: () => {
        const filteredIDs = availableIDs.filter((id) => !withoutAppointmentsIDs.includes(id));
        dispatch(
          vocationalTrainingAreaModalActions.removeMeasureFromPerson(withoutAppointmentsIDs)
        );
        dispatch(
          vocationalTrainingAreaActions.setCompleteMeasuresErrors({
            [MeasureCompleteValidationStatusEnum.MULTIPLE_MEASURE_WITHOUT_APPOINTMENTS]: false,
          })
        );
        dispatch(vocationalTrainingAreaModalActions.openCompleteMeasureModal(filteredIDs));
      },
      onCancel: () => {
        dispatch(vocationalTrainingAreaModalActions.openCompleteMeasureModal(availableIDs));
      },
    },
  };

  const { renderDialogContainer, openDialog } = useDialog({
    description: activeError ? measureCompleteDialogs[activeError]?.description : null,
    onConfirm: activeError ? measureCompleteDialogs[activeError]?.onConfirm : undefined,
    onCancel: activeError ? measureCompleteDialogs[activeError]?.onCancel : undefined,
    showCancelButton: activeError ? Boolean(measureCompleteDialogs[activeError]?.onCancel) : false,
  });

  useEffect(() => {
    const activeErrorKey = Object.entries(measureCompleteErrors).find(([_, status]) => status)?.[0];

    if (activeErrorKey) {
      setActiveError(activeErrorKey as MeasureCompleteValidationStatusEnum);
      openDialog();
    }
  }, [measureCompleteErrors]);

  return { renderDialogContainer };
}
