import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { DatePicker } from '@/components/Datepicker/DatePicker';
import { IMobilityForm } from '@/components/Modals/Modals/functions/workingTimeMobilityModals/EditMobilityModal/hooks/useMobilityForm';
import { SelectForm } from '@/components/Select/form/SelectForm';
import { ISelectItem } from '@/components/Select/SelectItem/SelectItem';
import { TextArea } from '@/components/TextArea/TextArea';
import { Typography } from '@/components/Typography/Typography';
import { useAppSelector } from '@/core/redux/hooks';
import { mobilityModalsSelectors } from '@/core/redux/slices/modalsSlice/functions/mobility/selectors';

export const CarServiceForm: React.FC = () => {
  const { t: mobilityCarServiceTranslations } = useTranslation('mobility', {
    keyPrefix: 'forms.carService',
  });

  const formContext = useFormContext<IMobilityForm>();

  const { mobilityDetails } = useAppSelector(mobilityModalsSelectors.editMobilityModal);
  const availableTransportTypes = mobilityDetails?.availableTransportServices ?? [];

  const selectOptions: ISelectItem[] = availableTransportTypes.map<ISelectItem>(
    (transportType) => ({
      id: transportType.type?.id?.toString() || '1',
      component: transportType.type?.name,
    })
  );

  const handleSelect = (id: string) => {
    const foundElement = mobilityDetails?.availableTransportServices.find(
      (service) => service.type?.id === Number(id)
    );

    if (!foundElement) {
      return;
    }

    formContext.setValue('carService', foundElement);
  };

  return (
    <>
      <div className={'flex flex-col gap-6 w-full'}>
        <div className={'flex flex-col gap-3'}>
          <div className={'flex flex-row gap-3'}>
            <Typography>{mobilityCarServiceTranslations('carServiceName.label')}</Typography>
          </div>
          <SelectForm
            control={formContext.register('carService.type.id')}
            onChange={handleSelect}
            values={selectOptions}
            isFlexible={true}
          />
        </div>
        <div className={'flex flex-col gap-3'}>
          <Typography>{mobilityCarServiceTranslations('contacts.label')}</Typography>
          <TextArea readOnly={true} control={formContext.register('carService.contacts')} />
        </div>
        <div className={'flex flex-col gap-3'}>
          <Typography>{mobilityCarServiceTranslations('appliesUntil.label')}</Typography>
          <DatePicker control={formContext.register('carService.date')} />
        </div>
      </div>
    </>
  );
};
