import { useAppSelector } from '@/core/redux/hooks';
import { developmentReportSelectors } from '@/core/redux/slices/functions/developmentReport/selectors';

export function useDevelopmentReport() {
  const developmentReport = useAppSelector(developmentReportSelectors.developmentReport);

  const developmentReportID = developmentReport?.id || null;

  return { developmentReportID };
}
