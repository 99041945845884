import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Checkbox } from '@/components/Checkbox/Checkbox';
import { IAddParticipantsModalForm } from '@/components/Modals/Modals/functions/activityPlaningModals/participantsListModals/AddParticipantsModal/AddParticipantsModal';
import { Typography } from '@/components/Typography/Typography';
import { useDialog } from '@/core/hooks/useDialog';
import { IActivityPlanningParticipant } from '@/core/redux/slices/modalsSlice/functions/activityPlanning/activityPlanningParticipants/slice';
import { generateName } from '@/core/utils/commonUtils';
import { twMerge } from '@/core/utils/tailwindUtil';
import { ColorTypes, colorTypes } from '@/styles/types';

import styles from './styles.module.scss';

interface IAddParticipantsListItem extends IActivityPlanningParticipant {
  bgColor?: string;
  hasPlaces: boolean;
}

export const AddParticipantsListItem: React.FC<IAddParticipantsListItem> = ({
  id,
  name,
  surname,
  department,
  appointmentDate,
  inMeasure,
  bbbInInterruption,
  bgColor,
  hasPlaces,
}) => {
  const { t: addParticipantsModalTranslations } = useTranslation('addParticipantsModal');

  const { getValues } = useFormContext<IAddParticipantsModalForm>();

  const { renderDialogContainer, openDialog } = useDialog({
    description: addParticipantsModalTranslations(
      'dialogs.personInBBBInterruption.description.label'
    ),
    showCancelButton: false,
  });

  const isSelected = getValues(`participants.participant_id=${id}`);

  const getColor = (): ColorTypes => {
    if (!isSelected && !hasPlaces) {
      return 'Disabled';
    }

    if (inMeasure) {
      return 'Primary';
    }

    return 'Orange';
  };

  return (
    <div
      className={twMerge(
        styles.item,
        'grid grid-cols-12 items-center gap-3 p-2 border-l-2',
        isSelected
          ? twMerge(`border-${colorTypes.Blue}`, styles.item_active)
          : 'border-transparent',
        !isSelected && !hasPlaces ? `bg-${colorTypes.Fields}` : ''
      )}
      style={{ backgroundColor: bgColor }}
    >
      <Checkbox
        name={`participants.participant_id=${id}`}
        preventDefault={bbbInInterruption}
        onClick={bbbInInterruption ? openDialog : undefined}
        disabled={!isSelected && !hasPlaces}
      />
      <Typography className={'col-span-4'} color={getColor()}>
        {generateName({ name, surname })}
      </Typography>
      <Typography className={'col-span-4'} color={getColor()}>
        {department}
      </Typography>
      <Typography className={'col-span-3'} color={getColor()}>
        {appointmentDate ?? ''}
      </Typography>
      {renderDialogContainer()}
    </div>
  );
};
