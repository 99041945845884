import { useEffect, useMemo, useState } from 'react';

import { Button } from '@/components/Button/Button';
import { FunctionContent } from '@/components/wrapper/FunctionWrapper/FunctionContent/FunctionContent';
import { FunctionWrapper } from '@/components/wrapper/FunctionWrapper/FunctionWrapper';
import { useSelectedPerson } from '@/core/hooks/useSelectedPerson';
import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';
import { checklistActions } from '@/core/redux/slices/functions/checklist/checklistSlice';
import { checklistSelectors } from '@/core/redux/slices/functions/checklist/selectros';
import { colorTypes } from '@/styles/types';

import { CheckList } from './CheckList/CheckList';

interface View {
  id: number;
  view: JSX.Element;
  name: string;
}

export const CheckLists: React.FC = () => {
  const dispatch = useAppDispatch();

  const checklistGroups = useAppSelector(checklistSelectors.checklistGroups);

  useEffect(() => {
    dispatch(checklistActions.fetchGroups());
  }, []);

  const [selectedView, setSelectedView] = useState<number>();

  const handleTabClick = (view: number) => {
    setSelectedView(view);
  };

  const checkGroupTabs = useMemo(() => {
    const tabs: Record<string, View> = {};

    checklistGroups.forEach((group) => {
      tabs[group.id] = {
        id: group.id,
        name: group.name,
        view: <CheckList id={group.id} />,
      };
    });

    return tabs;
  }, [checklistGroups]);

  useEffect(() => {
    if (checklistGroups.length && !selectedView) {
      const values = Object.values(checklistGroups);
      setSelectedView(values[0].id);
    }
  }, [checklistGroups]);

  return (
    <FunctionWrapper>
      <FunctionContent className={'flex flex-col'}>
        {checkGroupTabs && (
          <>
            <div className={`flex flex-row gap-3 border-b border-b-${colorTypes.Stroke} px-7`}>
              {Object.values(checkGroupTabs).map((view) => (
                <Button
                  onClick={() => handleTabClick(view.id)}
                  buttonVariant={selectedView === view.id ? 'Default' : 'Secondary'}
                  key={view.id}
                  className={'rounded-b-none px-4 pt-2 pb-[7px]'}
                >
                  {view.name}
                </Button>
              ))}
            </div>
            {selectedView && checkGroupTabs[selectedView].view}
          </>
        )}
      </FunctionContent>
    </FunctionWrapper>
  );
};
