import { useTranslation } from 'react-i18next';

import { Button } from '@/components/Button/Button';
import { FundingCycleSection } from '@/components/functions/FundingCycle/FundingCycleSection/FundingCycleSection';
import { FundingCycleSectionHeader } from '@/components/functions/FundingCycle/FundingCycleSectionHeader/FundingCycleSectionHeader';
import { useSelectedPerson } from '@/core/hooks/useSelectedPerson';
import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';
import { smartGoalsSelectors } from '@/core/redux/slices/functions/fundingCycle/smartGoals/selectors';
import { efficacyAssessmentModalActions } from '@/core/redux/slices/modalsSlice/efficacyAssessment/efficacyAssessmentModalSlice';

import { GoalsSection } from '../SmartGoals/GoalsSection/GoalsSection';
import { MeasuresSection } from '../SmartGoals/MeasuresSection/MeasuresSection';

export const MeasuresCarrying: React.FC = () => {
  const { t: measuresCarryingTranslations } = useTranslation('measuresCarrying');
  const { t: smartGoalsTranslations } = useTranslation('smartGoals');

  const dispatch = useAppDispatch();
  const { selectedPersonID } = useSelectedPerson();

  const selectedSmartGoalID = useAppSelector(smartGoalsSelectors.selectedSmartGoal);
  const selectedMeasureID = useAppSelector(smartGoalsSelectors.selectedMeasure);

  const handleCloseMeasure = () => {};

  const handleOpenEfficacyAssessmentModal = () => {
    if (!selectedPersonID || !selectedMeasureID) {
      return;
    }

    dispatch(
      efficacyAssessmentModalActions.openEfficacyAssessmentModal({
        personID: selectedPersonID,
        measureID: selectedMeasureID,
      })
    );
  };

  const measureControls = selectedMeasureID ? (
    <div className={'flex flex-row gap-2'}>
      <Button onClick={handleCloseMeasure}>
        {measuresCarryingTranslations('buttons.closeMeasure.label')}
      </Button>
      <Button onClick={handleOpenEfficacyAssessmentModal}>
        {measuresCarryingTranslations('buttons.openEfficacyAssessment.label')}
      </Button>
    </div>
  ) : null;

  return (
    <div className='h-full flex flex-col'>
      <FundingCycleSection
        className={'max-h-[50%]'}
        header={<FundingCycleSectionHeader title={smartGoalsTranslations('smartGoals.label')} />}
      >
        <GoalsSection />
      </FundingCycleSection>
      <FundingCycleSection
        header={
          <FundingCycleSectionHeader
            title={smartGoalsTranslations('measures.label')}
            controls={measureControls}
          />
        }
      >
        {selectedSmartGoalID && <MeasuresSection />}
      </FundingCycleSection>
    </div>
  );
};
