import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { LoadingStatus } from '@/types/loadingStatus';

// interfaces

export interface ISmartGoal {
  id: number;
  agreedBy: string | null;
  agreedOnDate: string;
  nextReviewDate: string;
  name?: string | null;
}

export interface ISmartGoalDetails {
  id: number;
  agreedOnDate: string;
  firstReviewDate: string;
  name?: string | null;
  repetitionMonthsNumber?: number | null;
}

export interface IMeasure {
  nearestActivityTimestamp: string | null;
  lastActivityTimestamp: string | null;
  type?: 'G' | 'P' | 'S' | null;
  id: number;
  name?: string | null;
  hasDocuments: boolean;
  canBeDeleted: boolean;
}

export interface ISmartGoalData {
  name: string;
  agreedOnDate: string;
  firstReviewDate: string;
}

// payloads

export interface ISmartGoalsFetchPayload {
  personID: number;
}

export interface ISmartGoalDetailsFetchPayload {
  smartGoalID: number;
}

export interface IMeasuresFetchPayload {
  smartGoalID: number;
}

export interface ISmartGoalCreatePayload {
  personID: number;
  smartGoalData: ISmartGoalData;
}

export interface ISmartGoalUpdatePayload {
  personID: number;
  smartGoalID: number;
  smartGoalData: ISmartGoalData;
}

export interface ISmartGoalDeletePayload {
  personID: number;
  smartGoalID: number;
}

export interface IMeasureDeletePayload {
  measureID: number;
  smartGoalID: number;
}

// state

export interface ISmartGoalsState {
  smartGoals: ISmartGoal[];
  smartGoalDetails: ISmartGoalDetails | null;
  measures: IMeasure[];

  selectedSmartGoal: number | undefined;
  selectedMeasure: number | undefined;

  smartGoalDetailsLock: LoadingStatus;
  createSmartGoalLock: LoadingStatus;
  updateSmartGoalLock: LoadingStatus;
}

const initialState: ISmartGoalsState = {
  smartGoals: [],
  smartGoalDetails: null,
  measures: [],
  // selected
  selectedSmartGoal: undefined,
  selectedMeasure: undefined,
  // locks
  smartGoalDetailsLock: LoadingStatus.NEVER,
  createSmartGoalLock: LoadingStatus.NEVER,
  updateSmartGoalLock: LoadingStatus.NEVER,
};

export const smartGoalsSlice = createSlice({
  name: 'smartGoals',
  initialState,
  reducers: {
    // smart goals
    fetchSmartGoals: (state, action: PayloadAction<ISmartGoalsFetchPayload>) => ({
      ...state,
      payload: action.payload,
    }),
    setSmartGoals: (state, action: PayloadAction<ISmartGoal[]>) => ({
      ...state,
      smartGoals: action.payload,
    }),
    setSelectedSmartGoal: (state, action: PayloadAction<number>) => ({
      ...state,
      selectedSmartGoal: action.payload,
    }),
    // create
    createSmartGoal: (state, action: PayloadAction<ISmartGoalCreatePayload>) => ({
      ...state,
      payload: action.payload,
    }),
    setCreateSmartGoalLock: (state, action: PayloadAction<LoadingStatus>) => ({
      ...state,
      createSmartGoalLock: action.payload,
    }),
    // update
    updateSmartGoal: (state, action: PayloadAction<ISmartGoalUpdatePayload>) => ({
      ...state,
      payload: action.payload,
    }),
    setUpdateSmartGoalLock: (state, action: PayloadAction<LoadingStatus>) => ({
      ...state,
      updateSmartGoalLock: action.payload,
    }),
    // delete
    deleteSmartGoal: (state, action: PayloadAction<ISmartGoalDeletePayload>) => ({
      ...state,
      payload: action.payload,
    }),
    // details
    fetchSmartGoalDetails: (state, action: PayloadAction<ISmartGoalDetailsFetchPayload>) => ({
      ...state,
      payload: action.payload,
    }),
    setSmartGoalDetails: (state, action: PayloadAction<ISmartGoalDetails | null>) => ({
      ...state,
      smartGoalDetails: action.payload,
    }),
    setSmartGoalDetailsLock: (state, action: PayloadAction<LoadingStatus>) => ({
      ...state,
      smartGoalDetailsLock: action.payload,
    }),
    // measures
    fetchMeasures: (state, action: PayloadAction<IMeasuresFetchPayload>) => ({
      ...state,
      payload: action.payload,
    }),
    setMeasures: (state, action: PayloadAction<IMeasure[]>) => ({
      ...state,
      measures: action.payload,
    }),
    setSelectedMeasure: (state, action: PayloadAction<number | undefined>) => ({
      ...state,
      selectedMeasure: action.payload,
    }),
    // delete
    deleteMeasure: (state, action: PayloadAction<IMeasureDeletePayload>) => ({
      ...state,
      payload: action.payload,
    }),
  },
});

export const smartGoalsReducer = smartGoalsSlice.reducer;
export const smartGoalsActions = smartGoalsSlice.actions;
