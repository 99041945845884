import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useActivityPlaningSelector } from '@/components/functions/ActivityPlanning/FullFunction/ActivityPlanningContext/ActivityPlanningContext';
import { ImplementationAidTableItem } from '@/components/functions/ActivityPlanning/WizardFunction/MeasuresAppointmentsList/ImplementationAidTableItem/ImplementationAidTableItem';
import { CheckedIcon } from '@/components/Icons/CheckedIcon';
import { Skeleton } from '@/components/Skeleton/Skeleton';
import { ITableColumn, Table } from '@/components/Table/Table';
import { Typography } from '@/components/Typography/Typography';
import { useSelectedPerson } from '@/core/hooks/useSelectedPerson';
import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';
import { activityPlaningMeasuresAppointmentsListSelectors } from '@/core/redux/slices/functions/activityPlanning/measuresAppointmentsList/selectors';
import { activityPlanningAppointmentsMeasuresListActions } from '@/core/redux/slices/functions/activityPlanning/measuresAppointmentsList/slice';
import { LoadingStatus } from '@/types/loadingStatus';

export const MeasuresAppointmentsList: React.FC = () => {
  const { t: measuresAppointmentsListTranslations } = useTranslation('activityPlanning', {
    keyPrefix: 'measuresAppointmentsList',
  });

  const [selectedTableID, setSelectedTableID] = useState<number | null>(null);

  const [selectedMeasureID] = useActivityPlaningSelector((store) => store.selectedMeasureID);
  const [, setSelectedAppointmentID] = useActivityPlaningSelector(
    (store) => store.selectedAppointmentID
  );
  const [isActionsBlocked] = useActivityPlaningSelector((store) => store.isActionsBlocked);

  const measuresAppointmentsList = useAppSelector(
    activityPlaningMeasuresAppointmentsListSelectors.measuresAppointmentsList
  );
  const loadingStatus = useAppSelector(
    activityPlaningMeasuresAppointmentsListSelectors.measuresAppointmentsListLock
  );
  const { selectedPersonID } = useSelectedPerson();

  const dispatch = useAppDispatch();

  const columns: ITableColumn[] = [
    {
      accessor: 'isRace',
      header: '',
    },
    {
      accessor: 'isCompleted',
      header: '',
      template: (value) => (value ? <CheckedIcon /> : null),
    },
    {
      accessor: 'freePlaces',
      header: measuresAppointmentsListTranslations('list.tableHeader.freePlaces.label'),
    },
    {
      accessor: 'day',
      header: measuresAppointmentsListTranslations('list.tableHeader.day.label'),
    },
    {
      accessor: 'appointmentStartDate',
      header: measuresAppointmentsListTranslations('list.tableHeader.startDate.label'),
    },
    {
      accessor: 'appointmentStartTime',
      header: measuresAppointmentsListTranslations('list.tableHeader.fromTimestamp.label'),
    },
    {
      accessor: 'appointmentEndTime',
      header: measuresAppointmentsListTranslations('list.tableHeader.toTimestamp.label'),
    },
    {
      accessor: 'appointmentEndDate',
      header: measuresAppointmentsListTranslations('list.tableHeader.endDate.label'),
    },
    {
      accessor: 'hours',
      header: measuresAppointmentsListTranslations('list.tableHeader.hours.label'),
    },
    {
      accessor: 'location',
      header: measuresAppointmentsListTranslations('list.tableHeader.location.label'),
    },
    {
      accessor: 'responsibleUser',
      header: measuresAppointmentsListTranslations('list.tableHeader.responsible.label'),
    },
    {
      accessor: 'implementationAid',
      header: measuresAppointmentsListTranslations('list.tableHeader.assistance.label'),
      template: (aidString, rowData) => {
        return aidString ? (
          <ImplementationAidTableItem aidString={aidString} itemID={rowData.id} />
        ) : (
          aidString
        );
      },
    },
  ];

  const handleSelect = (id: number) => {
    const foundElement = measuresAppointmentsList.find((appointment) => appointment.id === id);

    if (!foundElement || isActionsBlocked) {
      return;
    }

    setSelectedTableID(id);
    setSelectedAppointmentID({ selectedAppointmentID: foundElement.appointmentID });
  };

  useEffect(() => {
    if (!selectedPersonID || !selectedMeasureID) {
      return;
    }

    dispatch(
      activityPlanningAppointmentsMeasuresListActions.fetchMeasuresAppointmentsList({
        personID: selectedPersonID,
        measureID: selectedMeasureID,
      })
    );
  }, [selectedMeasureID]);

  useEffect(() => {
    if (!measuresAppointmentsList || !measuresAppointmentsList[0]) {
      setSelectedAppointmentID({ selectedAppointmentID: null });
      return;
    }

    setSelectedAppointmentID({ selectedAppointmentID: measuresAppointmentsList[0].appointmentID });
    setSelectedTableID(measuresAppointmentsList[0].id);
  }, [measuresAppointmentsList]);

  return (
    <div className={'border flex flex-col gap-2 p-2'}>
      <Typography type={'16-Sb'}>{measuresAppointmentsListTranslations('title.label')}</Typography>
      <Skeleton trigger={loadingStatus === LoadingStatus.LOADING}>
        <Table
          preventDefault={isActionsBlocked}
          isDeselectAllowed={false}
          columns={columns}
          data={measuresAppointmentsList}
          initialSelectedState={[selectedTableID]}
          singleSelect={true}
          onSingleSelectChange={handleSelect}
        />
      </Skeleton>
    </div>
  );
};
