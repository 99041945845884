import React from 'react';
import { useTranslation } from 'react-i18next';

import { Typography } from '@/components/Typography/Typography';

export const PersonRequirementTimeListHeader: React.FC = () => {
  const { t: personRequirementTimeListModalTranslations } = useTranslation(
    'personRequirementTimeListModal',
    { keyPrefix: 'table.header' }
  );

  return (
    <div className={`grid grid-cols-12 items-center gap-x-5 mt-3`}>
      <Typography className={'col-span-4'}>
        {personRequirementTimeListModalTranslations('assistance.label')}
      </Typography>
      <Typography className={'col-span-1'}>
        {personRequirementTimeListModalTranslations('value.label')}
      </Typography>
      <Typography className={'col-span-1'}>
        {personRequirementTimeListModalTranslations('isInGroup.label')}
      </Typography>
      <Typography className={'col-span-2'}>
        {personRequirementTimeListModalTranslations('cycle.label')}
      </Typography>
      <Typography className={'col-span-1'}>
        {personRequirementTimeListModalTranslations('minutesPerDay.label')}
      </Typography>
      <Typography className={'col-span-3'}>
        {personRequirementTimeListModalTranslations('comment.label')}
      </Typography>
    </div>
  );
};
