import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { DatePicker } from '@/components/Datepicker/DatePicker';
import { ICreateOrganizationalUnitForm } from '@/components/Modals/Modals/functions/organizationalUnitsModals/CreateOrganizationalUnitModal/CreateOrganizationalUnitModalContent/CreateOrganizationalUnitModalContent';
import { TextArea } from '@/components/TextArea/TextArea';

export interface IOrganizationalUnitBBBInterruptionForm {
  fromTimestamp: string;
  toTimestamp?: string | null;
  note?: string | null;
}

export const OrganizationalUnitBBBInterruption: React.FC = () => {
  const { t: bbbInterruptionTranslations } = useTranslation('organizationalUnitsForms', {
    keyPrefix: 'bbbInterruption',
  });

  const { register } = useFormContext<ICreateOrganizationalUnitForm>();

  return (
    <div className={'flex flex-col gap-2'}>
      <DatePicker
        control={register('bbbInterruption.fromTimestamp')}
        label={bbbInterruptionTranslations('fields.dateFrom.label')}
      />
      <DatePicker
        control={register('bbbInterruption.toTimestamp')}
        label={bbbInterruptionTranslations('fields.dateTo.label')}
      />
      <TextArea
        control={register('bbbInterruption.note')}
        label={bbbInterruptionTranslations('fields.comment.label')}
      />
    </div>
  );
};
