import { IDepartment } from '@/types/department';

export const getAllIds = (departments: IDepartment[]): string[] => {
  const ids: string[] = [];

  const extractIds = (deptArray: IDepartment[]) => {
    deptArray?.forEach((department) => {
      const isExpanded = department.childGroups || department.departments;
      const depID = isExpanded ? String(department.id) : `location_${department.id}`;

      ids.push(depID);

      if (department.departments && department.departments.length) {
        extractIds(department.departments);
      }

      if (department.childGroups && department.childGroups.length) {
        extractIds(department.childGroups);
      }
    });
  };

  extractIds(departments);
  return ids;
};
