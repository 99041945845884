import { createSelector } from '@reduxjs/toolkit';

import { IRootState } from '@/core/redux/types';

const selector = (state: IRootState) => state.modals.functions.fundingCycleModals.participationPlanModals;

const displayParticipationPlanModal = createSelector([selector], (state) => state.displayParticipationPlanModal);
const goalsWishesModal = createSelector([selector], (state) => state.goalsWishesModal);

export const participationPlanModalsSelectors = {
    displayParticipationPlanModal,
    goalsWishesModal
};
