import React from 'react';

import { Button } from '@/components/Button/Button';
import { DownloadIcon } from '@/components/Icons/DownloadIcon';
import { SpinnerIcon } from '@/components/Icons/SpinnerIcon';
import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';
import { vocationalTrainingAreaModalSelectors } from '@/core/redux/slices/modalsSlice/functions/vocationalTrainingArea/selectors';
import { vocationalTrainingAreaModalActions } from '@/core/redux/slices/modalsSlice/functions/vocationalTrainingArea/slice';
import { LoadingStatus } from '@/types/loadingStatus';

interface IVocationalTrainingAreaDocumentDownloadButton {
  documentID: number;
}

export const VocationalTrainingAreaDocumentDownloadButton: React.FC<IVocationalTrainingAreaDocumentDownloadButton> =
  ({ documentID }) => {
    const { documentDownloadLock } = useAppSelector(
      vocationalTrainingAreaModalSelectors.vocationalTrainingAreaDocumentsModal
    );

    const loadingStatus = documentDownloadLock[documentID];

    const dispatch = useAppDispatch();

    const handleDownload = () => {
      dispatch(
        vocationalTrainingAreaModalActions.downloadVocationalTrainingAreaDocument({
          documentID: documentID,
        })
      );
    };

    return documentID ? (
      <Button onClick={handleDownload} buttonStyle={'Icon'} buttonVariant={'Icon'}>
        {loadingStatus === LoadingStatus.LOADING ? (
          <SpinnerIcon className={'w-[24px] h-[24px]'} />
        ) : (
          <DownloadIcon />
        )}
      </Button>
    ) : null;
  };
