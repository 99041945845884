import { UseFormReturn } from 'react-hook-form';
import fp from 'lodash/fp';

import { ICreateOrganizationalUnitForm } from '@/components/Modals/Modals/functions/organizationalUnitsModals/CreateOrganizationalUnitModal/CreateOrganizationalUnitModalContent/CreateOrganizationalUnitModalContent';
import { OrganizationalUnitsTypes } from '@/core/enums/functions/organizationalUnits/organizationalUnitsTypesEnum';
import { useAppDispatch } from '@/core/redux/hooks';
import { organizationalUnitsModalsActions } from '@/core/redux/slices/modalsSlice/functions/organizationalUnits/slice';

export function useBBBInterruptionUpdate(
  form: UseFormReturn<ICreateOrganizationalUnitForm, any, undefined>,
  personID: number,
  type?: OrganizationalUnitsTypes | null
) {
  const dispatch = useAppDispatch();

  const handleUpdateBBBInterruption = () => {
    form.trigger('bbbInterruption').then((isValid) => {
      console.log(isValid);
      if (!isValid) {
        return;
      }

      const bbbFormValues = fp.cloneDeep(form?.watch('bbbInterruption'));

      if (!bbbFormValues || !type) {
        return;
      }

      dispatch(
        organizationalUnitsModalsActions.setOrganizationalUnitBBBInterruptionFormValues(
          bbbFormValues
        )
      );

      dispatch(
        organizationalUnitsModalsActions.createOrganizationalUnit({
          personID: personID,
          type: type,
          fromTimestamp: bbbFormValues.fromTimestamp,
          toTimestamp: bbbFormValues.toTimestamp,
          note: bbbFormValues?.note,
        })
      );
    });
  };

  return { handleUpdateBBBInterruption };
}
