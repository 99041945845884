import React from 'react';
import { useTranslation } from 'react-i18next';

import { Typography } from '@/components/Typography/Typography';

export const WorkingTimePlannerContainerHeader: React.FC = () => {
  const { t: workingTimePlannerTranslations } = useTranslation('workingTimePlanner', {
    keyPrefix: 'daysList.header',
  });

  return (
    <>
      <Typography className={'col-span-5 justify-self-start'}>
        {workingTimePlannerTranslations('weekDay.label')}
      </Typography>
      <Typography className={'col-span-2'}>
        {workingTimePlannerTranslations('fromTimestamp.label')}
      </Typography>
      <Typography className={'col-span-2'}>
        {workingTimePlannerTranslations('toTimestamp.label')}
      </Typography>
      <Typography className={'col-span-2'}>
        {workingTimePlannerTranslations('pauseDuration.label')}
      </Typography>
      <div />
    </>
  );
};
