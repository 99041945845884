import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@/components/Button/Button';
import { Dialog } from '@/components/Dialog/Dialog';
import { DialogContent } from '@/components/Dialog/DialogContent/DialogContent';
import { Typography } from '@/components/Typography/Typography';

interface IUseDialog {
  onConfirm?: () => void;
  onCancel?: () => void;
  title?: string | React.ReactNode;
  description?: string | React.ReactNode;
  confirmButton?: React.ReactNode;
  cancelButton?: React.ReactNode;
  showConfirmButton?: boolean;
  showCancelButton?: boolean;
  isOpened?: boolean;
}

export function useDialog({
  onConfirm,
  onCancel,
  cancelButton,
  confirmButton,
  description,
  title,
  showConfirmButton = true,
  showCancelButton = true,
  isOpened = false,
}: IUseDialog) {
  const { t: dialogTranslations } = useTranslation('dialog');

  const [isOpen, setIsOpen] = useState<boolean>(isOpened);

  const openDialog = () => setIsOpen(true);
  const closeDialog = () => setIsOpen(false);

  const handleConfirm = () => {
    onConfirm?.();
    closeDialog();
  };

  const handleCancel = () => {
    onCancel?.();
    closeDialog();
  };

  const renderDialogContainer = () => (
    <Dialog open={isOpen}>
      <DialogContent>
        <div className='flex flex-col gap-5'>
          {title && typeof title === 'string' ? <Typography type='H3'>{title}</Typography> : title}
          {description && typeof description === 'string' ? (
            <Typography>{description}</Typography>
          ) : (
            description
          )}
          <div className='flex flex-row gap-2 justify-center'>
            {showConfirmButton &&
              (confirmButton ? (
                confirmButton
              ) : (
                <Button onClick={handleConfirm}>
                  {dialogTranslations('buttons.confirm.label')}
                </Button>
              ))}
            {showCancelButton &&
              (cancelButton ? (
                cancelButton
              ) : (
                <Button onClick={handleCancel}>{dialogTranslations('buttons.cancel.label')}</Button>
              ))}
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );

  return { renderDialogContainer, closeDialog, openDialog };
}
