import React, { useEffect, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import { ObjectSchema } from 'yup';

import { Button } from '@/components/Button/Button';
import { Sidebar } from '@/components/Overlays/Sidebar/Sidebar';
import { Typography } from '@/components/Typography/Typography';
import useRequiredFieldsWatcher from '@/core/hooks/useRequiredFieldsWatcher';
import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';
import {
  addressesActions,
  IAddressInfo,
} from '@/core/redux/slices/functions/personGeneralData/addresses/addressesSlice';
import { addressesSelectors } from '@/core/redux/slices/functions/personGeneralData/addresses/selectors';
import { personGeneralDataModalsActions } from '@/core/redux/slices/modalsSlice/functions/personGeneralData/personGeneralDataModalsSlice';
import { personGeneralDataModalsSelectors } from '@/core/redux/slices/modalsSlice/functions/personGeneralData/selector';
import { notificationsActions } from '@/core/redux/slices/notifications/notificationsSlice';
import { yup } from '@/core/utils/commonUtils';
import { LoadingStatus } from '@/types/loadingStatus';

import { AddressForm } from './AddressForm/AddressForm';
import { AddressSelectType } from './AddressSelectType/AddressSelectType';

interface IAddressForm {
  address: string | null;
  cellPhoneNumber?: string | null;
  email?: string | null;
  isLegalGuardian?: boolean | null;
  name: string | null;
  telephoneNumber?: string | null;
  location?: string | null;
  surname: string | null;
  notes?: string | null;
  addresseeTypeId?: string | null;
  plz: string | null;
  fax?: string | null;
  salutationId: number;
}

const schema: ObjectSchema<IAddressForm> = yup.object().shape({
  salutationId: yup.number().required(),
  name: yup.string().required(),
  surname: yup.string().required(),
  address: yup.string().required(),
  plz: yup.string().required().min(5).max(5),
  cellPhoneNumber: yup.string().nullable(),
  email: yup.string().nullable(),
  isLegalGuardian: yup.boolean().nullable(),
  telephoneNumber: yup.string().nullable(),
  location: yup.string().nullable(),
  notes: yup.string().nullable(),
  addresseeTypeId: yup.string().required(),
  fax: yup.string().nullable(),
});

const ModalContent: React.FC = () => {
  const { t: displayAddressModalTranslations } = useTranslation('displayAddressModal');

  const form = useForm<IAddressForm>({
    resolver: yupResolver(schema),
  });

  const currentValues = form.watch();
  const isAllRequiredFieldsFilled = useRequiredFieldsWatcher(schema, currentValues);

  const dispatch = useAppDispatch();

  console.log(currentValues);

  // address data
  const addressInfo = useAppSelector(addressesSelectors.addressInfo);
  const addressInfoLock = useAppSelector(addressesSelectors.addressInfoLock);
  const addressTypes = useAppSelector(addressesSelectors.addressTypes);

  // action locks
  const updateAddressLock = useAppSelector(addressesSelectors.updateAddressLock);
  const createAddressLock = useAppSelector(addressesSelectors.createAddressLock);

  // payload data
  const { payload } = useAppSelector(personGeneralDataModalsSelectors.displayAddressModal);
  const personID = payload?.personID;
  const addressID = payload?.addressID;

  const currentAddressType = useMemo(
    () => addressTypes.find((addressType) => Boolean(addressType.is_checked)),
    [addressTypes]
  );

  const handleExit = () => {
    dispatch(personGeneralDataModalsActions.closeDisplayAddressModal());
  };

  const onSubmit = (data: IAddressInfo) => {
    if (!personID || addressID === undefined) {
      return;
    }

    if (addressID === 0) {
      dispatch(
        addressesActions.createAddress({
          personID: personID,
          addressInfo: data,
        })
      );
    } else {
      dispatch(
        addressesActions.updateAddress({
          personID: personID,
          addressID: addressID,
          addressInfo: data,
        })
      );
    }
  };

  useEffect(() => {
    if (!personID || addressID === undefined) {
      return;
    }

    dispatch(
      addressesActions.fetchAddressInfo({
        personID: personID,
        addressID: addressID,
      })
    );
  }, [personID, addressID]);

  useEffect(() => {
    if (!addressInfo || addressInfoLock !== LoadingStatus.LOADED) {
      return;
    }

    form.reset({
      ...addressInfo,
      location: addressInfo.location ?? '',
      address: addressInfo.address ?? '',
      plz: addressInfo.plz ?? '',
      name: addressInfo.name ?? '',
      surname: addressInfo.surname ?? '',
    });
  }, [addressInfo, addressInfoLock]);

  // handle update submit

  useEffect(() => {
    if (updateAddressLock === LoadingStatus.LOADED) {
      dispatch(
        notificationsActions.showNotification({
          notification: {
            type: 'success',
            title: displayAddressModalTranslations('notification.updated.title.label'),
            description: displayAddressModalTranslations('notification.updated.description.label'),
          },
        })
      );

      dispatch(addressesActions.setUpdateAddressLock(LoadingStatus.NEVER));

      handleExit();
    }
  }, [updateAddressLock]);

  // handle create submit

  useEffect(() => {
    if (createAddressLock === LoadingStatus.LOADED) {
      dispatch(
        notificationsActions.showNotification({
          notification: {
            type: 'success',
            title: displayAddressModalTranslations('notification.created.title.label'),
            description: displayAddressModalTranslations('notification.created.description.label'),
          },
        })
      );

      dispatch(addressesActions.setCreateAddressLock(LoadingStatus.NEVER));

      handleExit();
    }
  }, [createAddressLock]);

  useEffect(() => {
    return () => {
      dispatch(addressesActions.setAddressInfo(null));
      dispatch(addressesActions.setAddressInfoLock(LoadingStatus.NEVER));
      dispatch(addressesActions.setAddressTypes([]));
    };
  }, []);

  return (
    <Sidebar onExit={handleExit} position='right' className='p-0 gap-0'>
      <div className={'flex flex-col gap-3 px-8 pt-8 h-full'}>
        <Typography type={'H2'}>
          {addressID === 0
            ? displayAddressModalTranslations('title.label')
            : currentAddressType?.name}
        </Typography>
        <FormProvider {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className='h-full'>
            <div className='flex flex-col gap-10 max-h-[calc(100vh_-_155px)] overflow-y-auto'>
              {addressTypes && <AddressSelectType addressTypes={addressTypes} />}
              {form.watch('addresseeTypeId') && <AddressForm onExit={handleExit} />}
              <div className='flex gap-4 justify-end'></div>
            </div>
          </form>
        </FormProvider>
      </div>
      <div className={'flex flex-row gap-3 shadow-footer p-3'}>
        <Button onClick={() => form.handleSubmit(onSubmit)()} disabled={!isAllRequiredFieldsFilled}>
          {displayAddressModalTranslations('buttons.submit.label')}
        </Button>
        <Button type='button' onClick={handleExit} buttonVariant={'Secondary'}>
          {displayAddressModalTranslations('buttons.close.label')}
        </Button>
      </div>
    </Sidebar>
  );
};

export const DisplayAddressModal: React.FC = () => {
  const { isRender: isOpened } = useAppSelector(
    personGeneralDataModalsSelectors.displayAddressModal
  );

  if (isOpened) {
    return <ModalContent />;
  }

  return null;
};
