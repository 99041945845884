import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';

import { Button } from '@/components/Button/Button';
import { CircleFilledIcon } from '@/components/Icons/CircleFilledIcon';
import { CircleOutlineIcon } from '@/components/Icons/CircleOutlineIcon';
import { CompleteMeasureAppointmentListItem } from '@/components/Modals/Modals/functions/vocationalTrainingAreaModals/CompleteMeasureModal/CompleteMeasureModalContent/CompleteMeasureAppointmentList/CompleteMeasureAppointmentListItem';
import { Typography } from '@/components/Typography/Typography';
import { IAppointment } from '@/core/redux/slices/modalsSlice/functions/vocationalTrainingArea/slice';
import { twMerge } from '@/core/utils/tailwindUtil';
import { colorTypes } from '@/styles/types';

interface ICompleteMeasureAppointmentListGroup {
  appointment: IAppointment;
}

export const CompleteMeasureAppointmentListGroup: React.FC<ICompleteMeasureAppointmentListGroup> =
  ({ appointment }) => {
    const { t: completeMeasureModalTranslations } = useTranslation('completeMeasureModal', {
      keyPrefix: 'appointmentTable',
    });

    const [expanded, setExpanded] = useState<boolean>(true);

    const handleExpand = () => {
      setExpanded((prev) => !prev);
    };

    return (
      <div className={'col-span-12 grid grid-cols-12 justify-center items-center'}>
        <Button
          buttonVariant={'Transparent'}
          buttonStyle={'Text'}
          onClick={handleExpand}
          className={`col-span-12 hover:bg-${colorTypes.Hover}`}
        >
          <Typography type={'16-M'} className={'flex flex-row items-center gap-2 py-1'}>
            {expanded ? (
              <CircleFilledIcon className={`text-${colorTypes.Tiffany} w-[10px]`} />
            ) : (
              <CircleOutlineIcon className={`text-${colorTypes.Tiffany} w-[10px]`} />
            )}
            {completeMeasureModalTranslations('fields.date.label', {
              date: appointment.appointmentDate,
            })}
          </Typography>
        </Button>
        <motion.div
          className={twMerge(`col-span-12 grid grid-cols-12 w-full overflow-hidden`)}
          initial={expanded ? { height: 'auto', opacity: 100 } : { height: 0, opacity: 0 }}
          animate={expanded ? { height: 'auto', opacity: 100 } : { height: 0, opacity: 0 }}
        >
          <div className={'col-span-12 grid grid-cols-12'}>
            <CompleteMeasureAppointmentListItem {...appointment} />
          </div>
        </motion.div>
      </div>
    );
  };
