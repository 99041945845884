import { PropsWithChildren } from 'react';
import { twMerge } from 'tailwind-merge';

interface IFunctionWrapper {
  className?: string;
}

export const FunctionWrapper: React.FC<PropsWithChildren<IFunctionWrapper>> = ({
  children,
  className,
}) => {
  return <div className={twMerge('flex flex-col pt-default h-full overflow-hidden', className)}>{children}</div>;
};
