import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import fp from 'lodash/fp';

import FormRender, { FormGroup, FormItem } from '@/components/FormRender/FormRender';
import { visionAndGoalsDefaultValues } from '@/components/functions/DevelopmentReport/DevelopmentReportForms/VisionAndGoals/helper/visionAndGoalsForm';
import { useDevelopmentReport } from '@/components/functions/DevelopmentReport/hooks/useDevelopmentReport';
import { IDevelopmentReportForm } from '@/components/functions/DevelopmentReport/hooks/useDevelopmentReportForm';
import { DevelopmentReportForms } from '@/core/enums/functions/developmentReport/developmentReportFormsEnum';
import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';
import { developmentReportVisionAndGoalsSelectors } from '@/core/redux/slices/functions/developmentReport/developmentReportVisionAndGoalsSlice/selectors';
import { developmentReportVisionAndGoalsActions } from '@/core/redux/slices/functions/developmentReport/developmentReportVisionAndGoalsSlice/slice';

export const DevelopmentReportVisionAndGoals: React.FC = () => {
  const { t: visionAndGoalsTranslations } = useTranslation('developmentReportVisionAndGoals');

  const { developmentReportID } = useDevelopmentReport();

  const { reset, watch } = useFormContext<IDevelopmentReportForm>();
  const dispatch = useAppDispatch();

  const developmentReportVisionAndGoals = useAppSelector(
    developmentReportVisionAndGoalsSelectors.developmentReportVisionAndGoals
  );

  useEffect(() => {
    if (!developmentReportID) {
      return;
    }

    dispatch(
      developmentReportVisionAndGoalsActions.fetchDevelopmentReportVisionAndGoals({
        developmentReportID: developmentReportID,
      })
    );

    return () => {
      const currentValues = fp.cloneDeep(watch());
      reset({
        ...currentValues,
        [DevelopmentReportForms.VisionAndGoals]: visionAndGoalsDefaultValues,
      });
    };
  }, [developmentReportID]);

  const developmentReportGoalsAndActivityForm: (
    | FormItem<IDevelopmentReportForm>
    | FormGroup<IDevelopmentReportForm>
  )[] = developmentReportVisionAndGoals
    ? developmentReportVisionAndGoals.visionAndGoals.map<
        FormItem<IDevelopmentReportForm> | FormGroup<IDevelopmentReportForm>
      >((visionAndGoal, index) => ({
        fieldsContainerClassName: 'grid grid-cols-2 gap-5',
        fields: [
          {
            fieldsContainerClassName: 'flex flex-col gap-2',
            fields: [
              {
                fields: [
                  {
                    name: `${DevelopmentReportForms.VisionAndGoals}.visionAndGoals.${index}.isOwn`,
                    type: 'checkbox',
                    label: 'fields.ownExclusion.label',
                    className: 'flex flex-row gap-2',
                  },
                  {
                    name: `${DevelopmentReportForms.VisionAndGoals}.visionAndGoals.${index}.isRepresentative`,
                    type: 'checkbox',
                    label: 'fields.deputyExclusion.label',
                    className: 'flex flex-row gap-2',
                  },
                ],
              },
              {
                name: `${DevelopmentReportForms.VisionAndGoals}.visionAndGoals.${index}.ownView`,
                type: 'textarea',
              },
            ],
          },
          {
            name: `${DevelopmentReportForms.VisionAndGoals}.visionAndGoals.${index}.representativeView`,
            type: 'textarea',
            label: 'fields.professionalView.label',
          },
        ],
      }))
    : [];

  return (
    <>
      <FormRender<IDevelopmentReportForm>
        list={developmentReportGoalsAndActivityForm}
        translationContext={visionAndGoalsTranslations}
        className={'py-4 flex flex-col gap-2'}
      />
    </>
  );
};
