import React from 'react';

import { CompleteMeasureModalContent } from '@/components/Modals/Modals/functions/vocationalTrainingAreaModals/CompleteMeasureModal/CompleteMeasureModalContent/CompleteMeasureModalContent';
import { useAppSelector } from '@/core/redux/hooks';
import { vocationalTrainingAreaModalSelectors } from '@/core/redux/slices/modalsSlice/functions/vocationalTrainingArea/selectors';

export const CompleteMeasureModal: React.FC = () => {
  const { isRender: isOpened } = useAppSelector(
    vocationalTrainingAreaModalSelectors.completeMeasureModal
  );
  return isOpened ? <CompleteMeasureModalContent /> : null;
};
