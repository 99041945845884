import { createSelector } from '@reduxjs/toolkit';

import { IRootState } from '@/core/redux/types';

const selector = (state: IRootState) =>
  state.functions.developmentReport.developmentReportVisionAndGoals;

const developmentReportVisionAndGoals = createSelector(
  [selector],
  (state) => state.developmentReportVisionAndGoals
);

export const developmentReportVisionAndGoalsSelectors = {
  developmentReportVisionAndGoals,
};
