import React from 'react';
import { useFormContext } from 'react-hook-form';

import { CompleteMeasureRootGroup } from '@/components/Modals/Modals/functions/vocationalTrainingAreaModals/CompleteMeasureModal/CompleteMeasureModalContent/CompleteMeasureList/CompleteMeasureRootGroup';
import { ICompleteMeasureForm } from '@/components/Modals/Modals/functions/vocationalTrainingAreaModals/CompleteMeasureModal/CompleteMeasureModalContent/hooks/useCompleteMeasureModalForm';
import { useAppSelector } from '@/core/redux/hooks';
import { vocationalTrainingAreaModalSelectors } from '@/core/redux/slices/modalsSlice/functions/vocationalTrainingArea/selectors';

export const CompleteMeasureRootGroupList: React.FC = () => {
  const { measureDetails } = useAppSelector(
    vocationalTrainingAreaModalSelectors.completeMeasureModal
  );

  const { watch } = useFormContext();
  const currentValues: ICompleteMeasureForm = watch();

  return (
    <div>
      {measureDetails &&
        measureDetails.map((measure) => {
          const foundIndex = currentValues?.rootGroups
            ? currentValues.rootGroups.findIndex((rootGroup) => rootGroup.id === measure.id)
            : -1;

          return (
            <CompleteMeasureRootGroup
              formPath={`rootGroups.${foundIndex}`}
              name={measure.name}
              surname={measure.surname}
              groups={measure.groups}
              id={measure.id}
              key={measure.id}
              layer={0}
            />
          );
        })}
    </div>
  );
};
