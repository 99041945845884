export interface IDevelopmentReportVisionAndGoal {
  lfdNr: number;
  isOwn: boolean;
  isRepresentative: boolean;
  ownView?: string | null;
  representativeView?: string | null;
}

export interface IDevelopmentReportVisionAndGoalsForm {
  visionAndGoals: IDevelopmentReportVisionAndGoal[];
}

export const visionAndGoalsDefaultValues: IDevelopmentReportVisionAndGoalsForm = {
  visionAndGoals: [],
};
