import React from 'react';

import { IIcon } from '@/types/icon';

export const InfoIcon: React.FC<IIcon> = ({ className }) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    stroke='currentColor'
    strokeWidth='2'
    strokeLinecap='round'
    strokeLinejoin='round'
    className={className}
  >
    <circle cx='12' cy='12' r='10' />
    <path d='M12 7h.01' />
    <path d='M10 11h2v5' />
    <path d='M10 16h4' />
  </svg>
);
