import { PayloadAction } from '@reduxjs/toolkit';
import { type NormalizeOAS, type OASOutput, OASRequestParams } from 'fets';
import { takeLatest } from 'redux-saga/effects';

import {
  developmentReportReviewSupportActions,
  IDevelopmentReportReviewSupportFetchPayload,
  IDevelopmentReportReviewSupportUpdatePayload,
} from '@/core/redux/slices/functions/developmentReport/developmentReportReviewSupportSlice/slice';
import type oas from '@/services/rest/base/openapi';

type FetchDevelopmentReportPersonDetailsRequest = OASRequestParams<
  NormalizeOAS<typeof oas>,
  '/development_report/personal_details',
  'get'
>;


type DevelopmentReportPersonDetailsResponse = OASOutput<
  NormalizeOAS<typeof oas>,
  '/development_report/personal_details',
  'get',
  '200'
>;

type UpdateDevelopmentReportPersonDetailsRequest = OASRequestParams<
  NormalizeOAS<typeof oas>,
  '/development_report/personal_details',
  'post'
>;

function* fetchDevelopmentReportReviewSupport(action: PayloadAction<IDevelopmentReportReviewSupportFetchPayload>): Generator<any, void, DevelopmentReportPersonDetailsResponse> {
  const { developmentReportID } = action.payload;

  try {
    // const request: FetchDevelopmentReportReviewSupportRequest = {
    //   query: {
    //     development_report_id: developmentReportID
    //   },
    //   ...authAdd(),
    // };
    //
    // const response = yield call(restCall, '/development_report/personal_details', 'get', request);
    // const mappedResponse = toCamelCase(response.personal_details)
    //
    // yield put(developmentReportPersonDetailsActions.setDevelopmentReportPersonDetails(mappedResponse));
  } catch (error) {
    console.log('Error on development report review support fetching', error);
  }
}

function* updateDevelopmentReportReviewSupport(action: PayloadAction<IDevelopmentReportReviewSupportUpdatePayload>): Generator<any, void, DevelopmentReportPersonDetailsResponse> {
  const { developmentReportID, personID, formValues } = action.payload;

  try {
   //  const request: UpdateDevelopmentReportPersonDetailsRequest = {
   //    json: {
   //    },
   //    ...authAdd(),
   //  };
   //
   // yield call(restCall, '/development_report/personal_details', 'post', request);
  } catch (error) {
    console.log('Error on development report review support updating', error);
  }
}

export const developmentReportReviewSupportSagas = [
  takeLatest(developmentReportReviewSupportActions.fetchDevelopmentReportReviewSupport, fetchDevelopmentReportReviewSupport),
  takeLatest(developmentReportReviewSupportActions.updateDevelopmentReportReviewSupport, updateDevelopmentReportReviewSupport)
];
