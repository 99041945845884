import { TFunction } from 'i18next';
import { ObjectSchema } from 'yup';

import { IOrganizationalUnitBBBForm } from '@/components/Modals/Modals/functions/organizationalUnitsModals/OrganizationalUnitForms/BBB/OrganizationalUnitBBB';
import { OrganizationUnitBBBActionTypes } from '@/core/enums/functions/organizationalUnits/organizationUnitBBBActionTypesEnum';
import { yup } from '@/core/utils/commonUtils';

export const bbbValidator = (
  translations: TFunction<'organizationalUnitsForms', undefined>
): ObjectSchema<IOrganizationalUnitBBBForm> => {
  return yup.object({
    startDate: yup.string().required(),
    comment: yup.string().optional(),
    actionID: yup.number().optional(),
    actionType: yup
      .mixed<OrganizationUnitBBBActionTypes>()
      .oneOf(Object.values(OrganizationUnitBBBActionTypes))
      .optional(),
    actionValue: yup.number().optional(),
    isWithoutAdaptation: yup.boolean().optional(),
    adaptationPeriod: yup.number().optional(),
  });
};
