import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@/components/Button/Button';
import { EditTextIcon } from '@/components/Icons/EditTextIcon';
import { TrashIcon } from '@/components/Icons/TrashIcon';
import { ITableColumn, Table } from '@/components/Table/Table';
import { useSelectedPerson } from '@/core/hooks/useSelectedPerson';
import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';
import { smartGoalsSelectors } from '@/core/redux/slices/functions/fundingCycle/smartGoals/selectors';
import { smartGoalsActions } from '@/core/redux/slices/functions/fundingCycle/smartGoals/smartGoalsSlice';
import { smartGoalsModalActions } from '@/core/redux/slices/modalsSlice/functions/fundingCycle/smartGoals/smartGoalsModalSlice';
import { colorTypes } from '@/styles/types';

export const GoalsSection: React.FC = () => {
  const { t: smartGoalsTranslations } = useTranslation('smartGoals');

  const dispatch = useAppDispatch();

  const data = useAppSelector(smartGoalsSelectors.smartGoals);
  const selectedSmartGoalID = useAppSelector(smartGoalsSelectors.selectedSmartGoal);

  const { selectedPersonID } = useSelectedPerson();

  const openDisplaySmartGoalsModal = (id: number) => {
    if (!selectedPersonID) {
      return;
    }

    dispatch(
      smartGoalsModalActions.openDisplaySmartGoalModal({
        smartGoalID: id,
        personID: selectedPersonID,
      })
    );
  };

  const handleDeleteSmartGoal = (id: number) => {
    if (!selectedPersonID) {
      return;
    }

    dispatch(
      smartGoalsActions.deleteSmartGoal({
        personID: selectedPersonID,
        smartGoalID: id,
      })
    );
  };

  useEffect(() => {
    if (!selectedPersonID) {
      return;
    }

    dispatch(
      smartGoalsActions.fetchSmartGoals({
        personID: selectedPersonID,
      })
    );
  }, []);

  const columns: ITableColumn[] = [
    {
      header: smartGoalsTranslations('columns.name.label'),
      accessor: 'name',
    },
    {
      header: smartGoalsTranslations('columns.agreedOnTimestamp.label'),
      accessor: 'agreedOnDate',
    },
    {
      header: smartGoalsTranslations('columns.agreedBy.label'),
      accessor: 'agreedBy',
    },
    {
      header: smartGoalsTranslations('columns.nextReviewTimestamp.label'),
      accessor: 'nextReviewDate',
    },
    {
      header: '',
      accessor: '',
      isSortable: false,
      template: (_, row) => {
        return (
          <div className={'flex flex-row gap-[20px] items-center'}>
            <Button
              buttonVariant={'Icon'}
              buttonStyle={'Icon'}
              className={`text-${colorTypes.Grey}`}
              onClick={(e) => {
                e.stopPropagation();
                openDisplaySmartGoalsModal(row.id);
              }}
            >
              <EditTextIcon className={'w-[24px] h-[24px]'} />
            </Button>
            <Button
              buttonVariant={'Icon'}
              buttonStyle={'Icon'}
              className={`text-${colorTypes.Grey}`}
              onClick={(e) => {
                e.stopPropagation();
                handleDeleteSmartGoal(row.id);
              }}
            >
              <TrashIcon className={'w-[24px] h-[24px]'} />
            </Button>
          </div>
        );
      },
    },
  ];

  return (
    <Table
      data={data}
      columns={columns}
      singleSelect
      initialSelectedState={[selectedSmartGoalID]}
      onSingleSelectChange={(selected) =>
        dispatch(smartGoalsActions.setSelectedSmartGoal(selected))
      }
    />
  );
};
