import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Input } from '@/components/Input/Input';
import {
  IRadioButtonGroupItem,
  RadioButtonGroup,
} from '@/components/RadioButtonGroup/RadioButtonGroup';
import { Typography } from '@/components/Typography/Typography';
import { MobilityRefundVariant } from '@/core/enums/functions/workingTimeMobility/mobilityRefundVariantsEnum';

export const SelfDriverForm: React.FC = () => {
  const { t: mobilitySelfDriverFormTranslations } = useTranslation('mobility', {
    keyPrefix: 'forms.selfDriver',
  });

  const formContext = useFormContext();

  const options: IRadioButtonGroupItem[] = [
    {
      value: MobilityRefundVariant.NonRefundable,
      element: <Typography>{mobilitySelfDriverFormTranslations('noRefund.label')}</Typography>,
    },
    {
      value: MobilityRefundVariant.TicketPrice,
      element: (
        <Typography>{mobilitySelfDriverFormTranslations('refundTicketPrice.label')}</Typography>
      ),
    },
    {
      value: MobilityRefundVariant.ByMileage,
      element: (
        <Typography>{mobilitySelfDriverFormTranslations('refundByMileage.label')}</Typography>
      ),
    },
  ];

  return (
    <>
      <RadioButtonGroup
        options={options}
        name={'selfDriver.refundVariant'}
        itemClassName={'flex flex-row gap-3 items-start'}
      />
      <div className={'flex flex-col items-start gap-3 mt-3'}>
        <Typography>{'kilometer'}</Typography>
        <Input
          control={formContext.register('selfDriver.kilometer')}
          className={'max-w-[200px]'}
          type={'number'}
        />
      </div>
    </>
  );
};
