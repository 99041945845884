import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { twMerge } from 'tailwind-merge';

import { DatePicker } from '@/components/Datepicker/DatePicker';
import { TextArea } from '@/components/TextArea/TextArea';

export interface ISmartGoalFormFields {
  name: string;
  agreedOnDate: string;
  firstReviewDate: string;
}

interface ISmartGoalForm {
  className?: string;
}

export const SmartGoalForm: React.FC<ISmartGoalForm> = ({ className }) => {
  const { t: smartGoalModalTranslations } = useTranslation('displaySmartGoalModal');

  const form = useFormContext<ISmartGoalFormFields>();
  const { control } = form;

  return (
    <form className={twMerge('flex flex-col gap-4', className)}>
      <TextArea
        control={control.register('name')}
        label={smartGoalModalTranslations('fields.name.label')}
      />
      <DatePicker
        control={control.register('agreedOnDate')}
        label={smartGoalModalTranslations('fields.agreedOnDate.label')}
      />
      <DatePicker
        control={control.register('firstReviewDate')}
        label={smartGoalModalTranslations('fields.firstReviewDate.label')}
      />
    </form>
  );
};
