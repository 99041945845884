import { TFunction } from 'i18next';
import { ObjectSchema } from 'yup';

import { IOrganizationalUnitBBBInterruptionForm } from '@/components/Modals/Modals/functions/organizationalUnitsModals/OrganizationalUnitForms/BBBInterruption/OrganizationalUnitBBBInterruption';
import { yup } from '@/core/utils/commonUtils';

export const bbbInterruptionValidatorBuilder = (
  translations: TFunction<'organizationalUnitsForms', undefined>
): ObjectSchema<IOrganizationalUnitBBBInterruptionForm> => {
  return yup.object({
    fromTimestamp: yup.string().required(),
    toTimestamp: yup.string().optional(),
    note: yup.string().optional(),
  });
};
