import { createSelector } from '@reduxjs/toolkit';

import { IRootState } from '@/core/redux/types';

const selector = (state: IRootState) => state.functions.fundingCycle.evaluateGoals;

const goalChecks = createSelector([selector], (state) => state.goalChecks);
const selectedGoalCheck = createSelector([selector], (state) => state.selectedGoalCheck);
const goalCheckDetails = createSelector([selector], (state) => state.goalCheckDetails);
const nextExpectedCheckDate = createSelector([selector], (state) => state.nextExpectedCheckDate);

const goalCheckDetailsLock = createSelector([selector], (state) => state.goalCheckDetailsLock);
const nextExpectedDateLock = createSelector([selector], (state) => state.nextExpectedDateLock);
const createGoalCheckLock = createSelector([selector], (state) => state.createGoalCheckLock);
const updateGoalCheckLock = createSelector([selector], (state) => state.updateGoalCheckLock);

const goalCheckNotification = createSelector([selector], (state) => state.goalCheckNotification);

export const evaluateGoalsSelectors = {
  goalChecks,
  selectedGoalCheck,
  goalCheckDetails,

  goalCheckDetailsLock,
  createGoalCheckLock,
  updateGoalCheckLock,

  nextExpectedCheckDate,
  nextExpectedDateLock,
  goalCheckNotification
};
