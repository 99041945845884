import { createSelector } from '@reduxjs/toolkit';

import { IRootState } from '@/core/redux/types';

const selector = (state: IRootState) => state.modals.functions.mobilityModals;

const editMobilityModal = createSelector([selector], (state) => state.editMobilityModal);

export const mobilityModalsSelectors = {
  editMobilityModal,
};
