import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ILocationsChecksState } from '@/components/views/person/PersonPage/Context/PersonPageStoreContext';
import { ICheckedState } from '@/components/views/person/PersonPage/DepartmentView/DepartmentList/DepartmentsTreeSelect/DepartmentsTreeSelect';
import { Language } from '@/core/enums/languageEnum';

export interface IUpdateFavoritePersonsPayload {
  favoritePersons: number[];
}

export interface IUserSettingState {
  favoritePersons: number[];
  languageCode: Language;
  selectedDepartments: string | null | undefined;
}

const initialState: IUserSettingState = {
  favoritePersons: [],
  languageCode: Language.DEUTSCH,
  selectedDepartments: null,
};

const userSettingSlice = createSlice({
  name: 'userSettings',
  initialState,
  reducers: {
    fetchUserSettings: () => {},
    setUserSettingsLanguage: (state, action: PayloadAction<Language>) => ({
      ...state,
      languageCode: action.payload,
    }),
    setUserSelectedDepartments: (state, action: PayloadAction<string | null | undefined>) => ({
      ...state,
      selectedDepartments: action.payload,
    }),
    updateUserSettingsLanguage: (state, action: PayloadAction<Language>) => ({
      ...state,
      payload: action.payload,
    }),
    updateUserSelectedDepartments: (state, action: PayloadAction<ICheckedState>) => ({
      ...state,
      payload: action.payload,
    }),
    setFavoritePersons: (state, action: PayloadAction<number[]>) => ({
      ...state,
      favoritePersons: action.payload,
    }),
    updateFavoritePersons: (state, action: PayloadAction<IUpdateFavoritePersonsPayload>) => ({
      ...state,
      payload: action.payload,
    }),
  },
});

export const userSettingsReducers = userSettingSlice.reducer;
export const userSettingsActions = userSettingSlice.actions;
