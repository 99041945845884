import { createSelector } from '@reduxjs/toolkit';

import { IRootState } from '@/core/redux/types';

const selector = (state: IRootState) =>
  state.functions.developmentReport.developmentReportGoalsAndActivity;

const developmentReportGoalsAndActivity = createSelector(
  [selector],
  (state) => state.developmentReportGoalsAndActivity
);

export const developmentReportGoalsAndActivitySelectors = {
  developmentReportGoalsAndActivity,
};
