import { useEffect } from 'react';
import { useForm } from 'react-hook-form';

import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';
import { mobilityModalsSelectors } from '@/core/redux/slices/modalsSlice/functions/mobility/selectors';
import {
  IMobilityFormType,
  ISelfDrive,
  ITransportTypeService,
  mobilityModalsActions,
} from '@/core/redux/slices/modalsSlice/functions/mobility/slice';

export interface IMobilityForm {
  mobilityType: IMobilityFormType;
  carService?: ITransportTypeService | null;
  selfDriver?: ISelfDrive | null;
  appliedDays?: number[] | null;
}

export function useMobilityForm() {
  const { mobilityDetails, payload } = useAppSelector(mobilityModalsSelectors.editMobilityModal);

  const form = useForm<IMobilityForm>();
  const selectedMobility = form.watch('mobilityType');

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!mobilityDetails) {
      return;
    }

    const newFormState: IMobilityForm = {
      appliedDays: payload?.mobilityDay?.id ? [payload.mobilityDay.id] : [],
      carService: mobilityDetails.transportService,
      selfDriver: mobilityDetails.selfDrive,
      mobilityType: mobilityDetails.mobilityType,
    };

    form.reset(newFormState);
  }, [mobilityDetails]);

  const handleSubmit = (data: IMobilityForm) => {
    if (!payload) {
      return;
    }

    dispatch(
      mobilityModalsActions.updateMobility({
        context: payload.context,
        formValues: data,
      })
    );
  };

  return { form, selectedMobility, handleSubmit };
}
