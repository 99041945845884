import { createSelector } from '@reduxjs/toolkit';

import { IRootState } from '@/core/redux/types';

const selector = (state: IRootState) =>
  state.functions.developmentReport.developmentReportPersonDetails;

const developmentReportPersonDetails = createSelector(
  [selector],
  (state) => state.developmentReportPersonDetails
);

export const developmentReportPersonDetailsSelectors = {
  developmentReportPersonDetails,
};
