import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@/components/Button/Button';
import { useActivityPlaningWizardSelector } from '@/components/functions/ActivityPlanning/WizardFunction/ActivityPlanningWizardContext/ActivityPlanningContext';
import { ImplementationAidTableItem } from '@/components/functions/ActivityPlanning/WizardFunction/MeasuresAppointmentsList/ImplementationAidTableItem/ImplementationAidTableItem';
import { CheckedIcon } from '@/components/Icons/CheckedIcon';
import { Skeleton } from '@/components/Skeleton/Skeleton';
import { ITableColumn, Table } from '@/components/Table/Table';
import { Typography } from '@/components/Typography/Typography';
import { useSelectedPerson } from '@/core/hooks/useSelectedPerson';
import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';
import { activityPlaningMeasuresAppointmentsListSelectors } from '@/core/redux/slices/functions/activityPlanning/measuresAppointmentsList/selectors';
import { activityPlanningAppointmentsMeasuresListActions } from '@/core/redux/slices/functions/activityPlanning/measuresAppointmentsList/slice';
import { activityPlaningAppointmentDetailsModalsActions } from '@/core/redux/slices/modalsSlice/functions/activityPlanning/activityPlaningAppointmentDetails/slice';
import { LoadingStatus } from '@/types/loadingStatus';

export const MeasuresAppointmentsList: React.FC = () => {
  const { t: measuresAppointmentsListTranslations } = useTranslation('activityPlanning', {
    keyPrefix: 'measuresAppointmentsList',
  });

  const [selectedTableID, setSelectedTableID] = useState<number | null>(null);

  const [selectedMeasureID] = useActivityPlaningWizardSelector((store) => store.selectedMeasureID);
  const [selectedAppointmentID, setSelectedAppointmentID] = useActivityPlaningWizardSelector(
    (store) => store.selectedAppointmentID
  );
  const [selectedPersonLocation] = useActivityPlaningWizardSelector(
    (store) => store.personLocation
  );
  const [selectedMeasureName] = useActivityPlaningWizardSelector(
    (store) => store.selectedMeasureName
  );

  const measuresAppointmentsList = useAppSelector(
    activityPlaningMeasuresAppointmentsListSelectors.measuresAppointmentsList
  );
  const loadingStatus = useAppSelector(
    activityPlaningMeasuresAppointmentsListSelectors.measuresAppointmentsListLock
  );
  const { selectedPersonID } = useSelectedPerson();

  const dispatch = useAppDispatch();

  const columns: ITableColumn[] = [
    {
      accessor: 'isRace',
      header: '',
    },
    {
      accessor: 'isCompleted',
      header: '',
      template: (value) => (value ? <CheckedIcon /> : null),
    },
    {
      accessor: 'freePlaces',
      header: measuresAppointmentsListTranslations('list.tableHeader.freePlaces.label'),
    },
    {
      accessor: 'day',
      header: measuresAppointmentsListTranslations('list.tableHeader.day.label'),
    },
    {
      accessor: 'appointmentStartDate',
      header: measuresAppointmentsListTranslations('list.tableHeader.startDate.label'),
    },
    {
      accessor: 'appointmentStartTime',
      header: measuresAppointmentsListTranslations('list.tableHeader.fromTimestamp.label'),
    },
    {
      accessor: 'appointmentEndTime',
      header: measuresAppointmentsListTranslations('list.tableHeader.toTimestamp.label'),
    },
    {
      accessor: 'appointmentEndDate',
      header: measuresAppointmentsListTranslations('list.tableHeader.endDate.label'),
    },
    {
      accessor: 'hours',
      header: measuresAppointmentsListTranslations('list.tableHeader.hours.label'),
    },
    {
      accessor: 'location',
      header: measuresAppointmentsListTranslations('list.tableHeader.location.label'),
    },
    {
      accessor: 'responsibleUser',
      header: measuresAppointmentsListTranslations('list.tableHeader.responsible.label'),
    },
    {
      accessor: 'implementationAid',
      header: measuresAppointmentsListTranslations('list.tableHeader.assistance.label'),
      template: (aidString, rowData) => {
        return aidString ? (
          <ImplementationAidTableItem aidString={aidString} itemID={rowData.id} />
        ) : (
          aidString
        );
      },
    },
  ];

  const handleSelect = (id: number) => {
    const foundElement = measuresAppointmentsList.find((appointment) => appointment.id === id);

    if (!foundElement) {
      return;
    }

    setSelectedTableID(id);
    setSelectedAppointmentID({ selectedAppointmentID: foundElement.appointmentID });
  };

  useEffect(() => {
    if (!selectedPersonID || !selectedMeasureID) {
      return;
    }

    dispatch(
      activityPlanningAppointmentsMeasuresListActions.fetchMeasuresAppointmentsList({
        personID: selectedPersonID,
        measureID: selectedMeasureID,
      })
    );
  }, [selectedMeasureID]);

  useEffect(() => {
    if (!measuresAppointmentsList?.length) {
      setSelectedAppointmentID({ selectedAppointmentID: null });
      return;
    }

    const defaultAppointment = measuresAppointmentsList[0];
    const foundAppointment =
      measuresAppointmentsList.find(
        ({ appointmentID }) => appointmentID === selectedAppointmentID
      ) || defaultAppointment;

    if (!selectedAppointmentID) {
      setSelectedAppointmentID({ selectedAppointmentID: defaultAppointment.appointmentID });
    }

    setSelectedTableID(foundAppointment.id);
  }, [measuresAppointmentsList, selectedAppointmentID]);

  const handleShowDetails = () => {
    if (!selectedAppointmentID || !selectedMeasureID || !selectedPersonLocation) {
      return;
    }

    dispatch(
      activityPlaningAppointmentDetailsModalsActions.openAppointmentDetailsModal({
        appointmentID: selectedAppointmentID,
        measureID: selectedMeasureID,
        personLocation: selectedPersonLocation,
        selectedMeasureName: selectedMeasureName,
        appointmentDetailsContext: 'edit',
      })
    );
  };

  const handleCreateAppointment = () => {
    if (!selectedMeasureID || !selectedPersonLocation) {
      return;
    }

    dispatch(
      activityPlaningAppointmentDetailsModalsActions.openAppointmentDetailsModal({
        measureID: selectedMeasureID,
        personLocation: selectedPersonLocation,
        selectedMeasureName: selectedMeasureName,
        appointmentDetailsContext: 'create',
      })
    );
  };

  return (
    <div className={'h-full flex flex-col justify-between'}>
      <div className={'max-h-[calc(100vh_-_440px)] flex flex-col gap-2'}>
        <Typography type={'16-Sb'}>
          {measuresAppointmentsListTranslations('title.label')}
        </Typography>
        <Skeleton trigger={loadingStatus === LoadingStatus.LOADING}>
          <Table
            isDeselectAllowed={false}
            columns={columns}
            data={measuresAppointmentsList}
            initialSelectedState={[selectedTableID]}
            singleSelect={true}
            onSingleSelectChange={handleSelect}
          />
        </Skeleton>
      </div>
      <div className={'flex flex-row gap-3 pb-3'}>
        <Button onClick={handleCreateAppointment}>
          {measuresAppointmentsListTranslations('footer.buttons.newAppointment.label')}
        </Button>
        {selectedAppointmentID && (
          <Button onClick={handleShowDetails}>
            {measuresAppointmentsListTranslations('footer.buttons.appointmentDetails.label')}
          </Button>
        )}
      </div>
    </div>
  );
};
