import { useEffect, useMemo } from 'react';
import fp from 'lodash/fp';

import { usePersonPageStoreSelector } from '@/components/views/person/PersonPage/Context/PersonPageStoreContext';
import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';
import { personPageSelectors } from '@/core/redux/slices/personPage/personPageSelectors';
import { personPageActions } from '@/core/redux/slices/personPage/personPageSlice';
import { resolveProgress } from '@/core/utils/commonUtils';
import { filterDepartments, sortByName, sortDepartments } from '@/core/utils/departmentUtils';
import { IDepartment } from '@/types/department';
import { SortingBy } from '@/types/sortingBy';

export default function useFetchedDepartments() {
  const dispatch = useAppDispatch();
  const fetchedDepartments = useAppSelector(personPageSelectors.departments);
  const departmentsStatus = useAppSelector(personPageSelectors.departmentsStatus);

  const [sortBy] = usePersonPageStoreSelector((store) => store.departmentsSortedBy);
  const [searchValue] = usePersonPageStoreSelector((store) => store.departmentSearchValue);

  useEffect(() => {
    if (fp.isEmpty(fetchedDepartments)) {
      dispatch(
        personPageActions.fetchDynamicDepartments()
      );
    }
  }, [dispatch, fetchedDepartments]);

  const departments = useMemo(() => {
    if (fp.isEmpty(fetchedDepartments) || fp.isEmpty(sortBy) || searchValue === undefined) {
      return null;
    }

    const filteredDepartments = filterDepartments(fetchedDepartments, searchValue);
    const sortedDepartments = sortDepartments(filteredDepartments, sortBy)

    return sortedDepartments;
  }, [fetchedDepartments, sortBy, searchValue]);

  const isDepartmentsInProgress = useMemo(() => {
    return resolveProgress(departmentsStatus);
  }, [departmentsStatus]);

  return { departments, isDepartmentsInProgress };
}
