import React, { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';

import { Button } from '@/components/Button/Button';
import { GoalCheckForm, IGoalCheckFormFields } from '@/components/forms/GoalCheck/GoalCheck';
import { SpinnerIcon } from '@/components/Icons/SpinnerIcon';
import { Sidebar } from '@/components/Overlays/Sidebar/Sidebar';
import { SchemaProvider } from '@/components/SchemaContext/SchemaContext';
import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';
import { evaluateGoalsActions } from '@/core/redux/slices/functions/fundingCycle/evaluateGoals/evaluateGoalsSlice';
import { evaluateGoalsSelectors } from '@/core/redux/slices/functions/fundingCycle/evaluateGoals/selectors';
import { evaluateGoalsModalActions } from '@/core/redux/slices/modalsSlice/functions/fundingCycle/evaluateGoals/evaluateGoalsModalSlice';
import { evaluateGoalsModalsSelectors } from '@/core/redux/slices/modalsSlice/functions/fundingCycle/evaluateGoals/selectors';
import { notificationsActions } from '@/core/redux/slices/notifications/notificationsSlice';
import { colorTypes } from '@/styles/types';
import { LoadingStatus } from '@/types/loadingStatus';

import { GoalCheckFormResolver } from './helpers/resolver';

const ModalContent: React.FC = () => {
  const form = useForm<IGoalCheckFormFields>({
    resolver: yupResolver(GoalCheckFormResolver),
  });
  const dispatch = useAppDispatch();
  const { t: goalCheckModalTranslations } = useTranslation('displayGoalCheckModal');

  // selectors
  const { payload } = useAppSelector(evaluateGoalsModalsSelectors.displayGoalCheckModal);
  const goalCheckDetails = useAppSelector(evaluateGoalsSelectors.goalCheckDetails);
  // locks
  const createGoalCheckLock = useAppSelector(evaluateGoalsSelectors.createGoalCheckLock);
  const updateGoalCheckLock = useAppSelector(evaluateGoalsSelectors.updateGoalCheckLock);
  const goalCheckDetailsLock = useAppSelector(evaluateGoalsSelectors.goalCheckDetailsLock);
  //notification
  const goalCheckNotification = useAppSelector(evaluateGoalsSelectors.goalCheckNotification);

  // loading state
  const isLoading =
    createGoalCheckLock === LoadingStatus.LOADING || updateGoalCheckLock === LoadingStatus.LOADING;
  const isLoaded =
    createGoalCheckLock === LoadingStatus.LOADED || updateGoalCheckLock === LoadingStatus.LOADED;
  const isError =
    createGoalCheckLock === LoadingStatus.ERROR || updateGoalCheckLock === LoadingStatus.ERROR;

  // payload data
  const smartGoalID = payload?.smartGoalID;
  const goalCheckID = payload?.goalCheckID;

  // handle exit

  const handleExit = () => {
    dispatch(evaluateGoalsModalActions.closeDisplayGoalCheckModal());
  };

  // handle submit

  const onSubmit = (data: IGoalCheckFormFields) => {
    if (!smartGoalID || goalCheckID === undefined || isLoading) {
      return;
    }

    if (goalCheckID === 0) {
      dispatch(
        evaluateGoalsActions.createGoalCheck({
          smartGoalID: smartGoalID,
          goalCheckData: {
            ...data,
            achievementLevel: Number(data.achievementLevel),
            nextCheckDate: data.achievementLevel === '1' ? data.nextCheckDate : undefined,
          },
        })
      );
    } else {
      dispatch(
        evaluateGoalsActions.updateGoalCheck({
          smartGoalID: smartGoalID,
          goalCheckID: goalCheckID,
          goalCheckData: {
            ...data,
            achievementLevel: Number(data.achievementLevel),
            nextCheckDate: data.achievementLevel === '1' ? data.nextCheckDate : undefined,
          },
        })
      );
    }
  };

  // fetch details

  useEffect(() => {
    if (goalCheckID === undefined || goalCheckID === 0) {
      return;
    }

    dispatch(
      evaluateGoalsActions.fetchGoalCheckDetails({
        goalCheckID: goalCheckID,
      })
    );
  }, [goalCheckID]);

  // reset form values on details loaded

  useEffect(() => {
    if (!goalCheckDetails || goalCheckDetailsLock !== LoadingStatus.LOADED) {
      return;
    }

    form.reset({
      ...goalCheckDetails,
      achievementLevel: String(goalCheckDetails.achievementLevel.id),
      nextAction: goalCheckDetails.nextAction.id,
    });
  }, [goalCheckDetails, goalCheckDetailsLock]);

  // exit on update

  useEffect(() => {
    if (isLoaded) {
      handleExit();
    }
  }, [isLoaded]);

  // show notification on error

  useEffect(() => {
    if (isError && goalCheckNotification) {
      dispatch(
        notificationsActions.showNotification({
          notification: {
            type: 'error',
            title: 'Error',
            description: goalCheckNotification,
          },
        })
      );
    }
  }, [isError]);

  // reset state on unmount

  useEffect(() => {
    return () => {
      dispatch(evaluateGoalsActions.setGoalCheckDetails(null));
      dispatch(evaluateGoalsActions.setGoalCheckDetailsLock(LoadingStatus.NEVER));
      dispatch(evaluateGoalsActions.setUpdateGoalCheckLock(LoadingStatus.NEVER));
      dispatch(evaluateGoalsActions.setCreateGoalCheckLock(LoadingStatus.NEVER));
    };
  }, []);

  const renderFooter = (
    <div className='flex gap-4'>
      <Button
        type='submit'
        onClick={form.handleSubmit(onSubmit)}
        buttonVariant={form.formState.isValid ? 'Default' : 'Disabled'}
      >
        {goalCheckModalTranslations('buttons.submit.label')}
      </Button>
      <Button type='button' buttonVariant='Secondary' onClick={handleExit}>
        {goalCheckModalTranslations('buttons.close.label')}
      </Button>
    </div>
  );

  return (
    <Sidebar onExit={handleExit} className='w-[800px]' footer={renderFooter} position={'right'}>
      <SchemaProvider schema={GoalCheckFormResolver}>
        <FormProvider {...form}>
          <div className={'relative p-7'}>
            <GoalCheckForm smartGoalID={smartGoalID} className={isLoading ? 'opacity-50' : ''} />
            {isLoading && (
              <div className={'absolute inset-0 flex items-center justify-center'}>
                <SpinnerIcon className={`text-${colorTypes.Blue} w-fit`} />
              </div>
            )}
          </div>
        </FormProvider>
      </SchemaProvider>
    </Sidebar>
  );
};

export const DisplayGoalCheckModal: React.FC = () => {
  const { isRender: isOpened } = useAppSelector(evaluateGoalsModalsSelectors.displayGoalCheckModal);

  if (isOpened) {
    return <ModalContent />;
  }

  return null;
};
