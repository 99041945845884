import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IDevelopmentReportForm } from '@/components/functions/DevelopmentReport/hooks/useDevelopmentReportForm';
import { DevelopmentReportForms } from '@/core/enums/functions/developmentReport/developmentReportFormsEnum';

export interface IDevelopmentReportIDFetchPayload {
  personID: number;
}

export interface IUpdateDevelopmentReportFormPayload {
  type: DevelopmentReportForms;
  form: IDevelopmentReportForm;
  personID: number;
  developmentReportID: number;
}

export interface IDevelopmentReport {
  id: number;
  printedTimestamp?: string | null;
}

export interface IDevelopmentReportState {
  developmentReport: IDevelopmentReport | null;
  fetchDevelopmentReportIDPayload: IDevelopmentReportIDFetchPayload | null;
  updateDevelopmentReportFormPayload: IUpdateDevelopmentReportFormPayload | null;
}

const initialState: IDevelopmentReportState = {
  developmentReport: null,
  fetchDevelopmentReportIDPayload: null,
  updateDevelopmentReportFormPayload: null,
};

export const developmentReportSlice = createSlice({
  name: 'developmentReport',
  initialState,
  reducers: {
    fetchDevelopmentReport: (state, action: PayloadAction<IDevelopmentReportIDFetchPayload>) => ({
      ...state,
      fetchDevelopmentReportIDPayload: action.payload,
    }),
    setDevelopmentReport: (state, action: PayloadAction<IDevelopmentReport | null>) => ({
      ...state,
      developmentReport: action.payload,
    }),
    updateDevelopmentReportForm: (
      state,
      action: PayloadAction<IUpdateDevelopmentReportFormPayload | null>
    ) => ({
      ...state,
      updateDevelopmentReportFormPayload: action.payload,
    }),
  },
});

export const developmentReportReducer = developmentReportSlice.reducer;
export const developmentReportActions = developmentReportSlice.actions;
