import React from 'react';
import { useTranslation } from 'react-i18next';

import { Typography } from '@/components/Typography/Typography';
import { colorTypes } from '@/styles/types';

export const TableHeader: React.FC = () => {
  const { t: checklistTranslations } = useTranslation('checklist', {
    keyPrefix: 'table.header',
  });

  return (
    <div
      className={`grid grid-cols-12 items-center border-y border-${colorTypes.Stroke} px-[30px] py-3`}
    >
      <Typography color={'Secondary'} className={'col-span-5'}>
        {checklistTranslations('name.label')}
      </Typography>
      <Typography color={'Secondary'} className={'col-span-2'}>
        {checklistTranslations('completeDate.label')}
      </Typography>
      <Typography color={'Secondary'} className={'col-span-3'}>
        {checklistTranslations('isCompleted.label')}
      </Typography>
    </div>
  );
};
