export interface IDevelopmentReportGoalAndActivity {
  lfdNr: number;
  goal?: string | null;
  requiredActions?: string | null;
  until?: string | null;
  executor?: string | null;
  executionPlace?: string | null;
  smartGoalId?: number | null;
}

export interface IDevelopmentReportGoalsAndActivityForm {
  goalsAndActivities: IDevelopmentReportGoalAndActivity[];
}

export const goalsAndActivityDefaultValues: IDevelopmentReportGoalsAndActivityForm = {
  goalsAndActivities: [],
};
