import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IDevelopmentReportVisionAndGoalsForm } from '@/components/functions/DevelopmentReport/DevelopmentReportForms/VisionAndGoals/helper/visionAndGoalsForm';

export interface IDevelopmentReportVisionAndGoalsFetchPayload {
  developmentReportID: number;
}

export interface IDevelopmentReportVisionAndGoalsUpdatePayload {
  developmentReportID: number;
  personID: number;
  formValues: IDevelopmentReportVisionAndGoalsForm;
}

export interface IDevelopmentReportVisionAndGoalsState {
  developmentReportVisionAndGoals: IDevelopmentReportVisionAndGoalsForm | null;
  fetchPayload: IDevelopmentReportVisionAndGoalsFetchPayload | null;
  updatePayload: IDevelopmentReportVisionAndGoalsUpdatePayload | null;
}

const initialState: IDevelopmentReportVisionAndGoalsState = {
  developmentReportVisionAndGoals: null,
  fetchPayload: null,
  updatePayload: null,
};

const developmentReportVisionAndGoalsSlice = createSlice({
  name: 'developmentReportVisionAndGoals',
  initialState,
  reducers: {
    fetchDevelopmentReportVisionAndGoals: (
      state,
      action: PayloadAction<IDevelopmentReportVisionAndGoalsFetchPayload>
    ) => ({
      ...state,
      fetchPayload: action.payload,
    }),
    setDevelopmentReportVisionAndGoals: (
      state,
      action: PayloadAction<IDevelopmentReportVisionAndGoalsForm | null>
    ) => ({
      ...state,
      developmentReportVisionAndGoals: action.payload,
    }),
    updateDevelopmentReportVisionAndGoals: (
      state,
      action: PayloadAction<IDevelopmentReportVisionAndGoalsUpdatePayload>
    ) => ({
      ...state,
      updatePayload: action.payload,
    }),
  },
});

export const developmentReportVisionAndGoalsReducer = developmentReportVisionAndGoalsSlice.reducer;
export const developmentReportVisionAndGoalsActions = developmentReportVisionAndGoalsSlice.actions;
