import { createSelector } from '@reduxjs/toolkit';

import { IRootState } from '@/core/redux/types';

const selector = (state: IRootState) => state.modals.functions.fundingCycleModals.smartGoalsModals;

const displaySmartGoalModal = createSelector([selector], (state) => state.displaySmartGoalModal);

export const smartGoalsModalsSelectors = {
    displaySmartGoalModal
};
