import React from 'react';

import { IIcon } from '@/types/icon';

export const TrashIcon: React.FC<IIcon> = ({ className }) => (
  <svg
    width='18'
    height='20'
    viewBox='0 0 18 20'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    className={className}
  >
    <path
      d='M1 4.55469H2.77778H17'
      stroke='currentColor'
      strokeWidth='1.7'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M15.2257 4.55556V17C15.2257 17.4715 15.0384 17.9237 14.705 18.2571C14.3716 18.5905 13.9194 18.7778 13.4479 18.7778H4.55903C4.08753 18.7778 3.63535 18.5905 3.30195 18.2571C2.96855 17.9237 2.78125 17.4715 2.78125 17V4.55556M5.44792 4.55556V2.77778C5.44792 2.30628 5.63522 1.8541 5.96862 1.5207C6.30201 1.1873 6.7542 1 7.22569 1H10.7813C11.2527 1 11.7049 1.1873 12.0383 1.5207C12.3717 1.8541 12.559 2.30628 12.559 2.77778V4.55556'
      stroke='currentColor'
      strokeWidth='1.7'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);
