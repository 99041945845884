import React from 'react';

import { Button } from '@/components/Button/Button';
import { IDevelopmentReportTab } from '@/components/functions/DevelopmentReport/DevelopmentReport';
import { DevelopmentReportForms } from '@/core/enums/functions/developmentReport/developmentReportFormsEnum';
import { colorTypes } from '@/styles/types';

export interface IDevelopmentReportNavigation {
  developmentReportTabs: Record<DevelopmentReportForms, IDevelopmentReportTab>;
  onTabSwitch: (selectedTab: DevelopmentReportForms) => void;
  activeTab: DevelopmentReportForms;
}

export const DevelopmentReportNavigation: React.FC<IDevelopmentReportNavigation> = ({
  developmentReportTabs,
  onTabSwitch,
  activeTab,
}) => {
  return (
    <div className={`flex flex-row gap-2 border-b border-b-${colorTypes.HoverLight}`}>
      {Object.values(developmentReportTabs).map((tab) => {
        const isActive = activeTab === tab.type;

        return (
          <Button
            onClick={() => onTabSwitch(tab.type)}
            className={'rounded-b-none px-4 pt-2 pb-[7px] justify-start text-sm overflow-hidden '}
            buttonVariant={isActive ? 'Default' : 'Secondary'}
            key={tab.type}
            type={'submit'}
          >
            {tab.title}
          </Button>
        );
      })}
    </div>
  );
};
