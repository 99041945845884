import { TFunction } from 'i18next';
import { ObjectSchema } from 'yup';

import { ICreateOrganizationalUnitForm } from '@/components/Modals/Modals/functions/organizationalUnitsModals/CreateOrganizationalUnitModal/CreateOrganizationalUnitModalContent/CreateOrganizationalUnitModalContent';
import { bbbValidator } from '@/components/Modals/Modals/functions/organizationalUnitsModals/OrganizationalUnitForms/BBB/helper/bbbValidator';
import { bbbInterruptionValidatorBuilder } from '@/components/Modals/Modals/functions/organizationalUnitsModals/OrganizationalUnitForms/BBBInterruption/helper/bbbInterruptionValidator';
import { yup } from '@/core/utils/commonUtils';

export const createOrganizationalUnitValidatorBuilder = (
  translations: TFunction<'organizationalUnitsForms', undefined>
): ObjectSchema<ICreateOrganizationalUnitForm> =>
  yup.object({
    bbb: bbbValidator(translations).optional(),
    bbbInterruption: bbbInterruptionValidatorBuilder(translations).optional(),
  });
