import React, { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { motion } from 'framer-motion';

import { Button } from '@/components/Button/Button';
import { CircleFilledIcon } from '@/components/Icons/CircleFilledIcon';
import { CircleOutlineIcon } from '@/components/Icons/CircleOutlineIcon';
import { CompleteAppointmentGroupedListItem } from '@/components/Modals/Modals/functions/activityPlaningModals/appointmentDetailsModals/CompleteAppointmentModal/CompleteAppointmentGroupedList/CompleteAppointmentGroupedListItem';
import { ICompleteAppointmentFormGroup } from '@/components/Modals/Modals/functions/activityPlaningModals/appointmentDetailsModals/CompleteAppointmentModal/hooks/useCompleteAppointmentModalForm';
import { Typography } from '@/components/Typography/Typography';
import { useAppSelector } from '@/core/redux/hooks';
import { IAppointmentQuestionnaire } from '@/core/redux/slices/modalsSlice/functions/activityPlanning/activityPlaningAppointmentDetails/slice';
import { vocationalTrainingAreaModalSelectors } from '@/core/redux/slices/modalsSlice/functions/vocationalTrainingArea/selectors';
import { parametersSelectors } from '@/core/redux/slices/parameters/selectors';
import { twMerge } from '@/core/utils/tailwindUtil';
import { colorTypes } from '@/styles/types';

interface ICompleteAppointmentAreaGroupedListGroup extends IAppointmentQuestionnaire {
  layer: number;
  formPath?: string;
}

export const DEFAULT_LAYER_PADDING = 40;

export const CompleteAppointmentGroupedListGroup: React.FC<ICompleteAppointmentAreaGroupedListGroup> =
  ({ items, childGroups, layer, name, id, formPath }) => {
    const [expanded, setExpanded] = useState<boolean>(true);

    const { measureDetails } = useAppSelector(
      vocationalTrainingAreaModalSelectors.completeMeasureModal
    );

    const oddColor = useAppSelector(parametersSelectors.selfAssessmentOddBackgroundColor);
    const evenColor = useAppSelector(parametersSelectors.selfAssessmentEvenBackgroundColor);

    const handleExpand = () => {
      setExpanded((prev) => !prev);
    };

    const { getValues } = useFormContext();
    const currentValues: ICompleteAppointmentFormGroup = formPath
      ? getValues(formPath)
      : getValues();

    const foundGroupIndex = currentValues.childGroup
      ? currentValues.childGroup.findIndex((group) => group.id === id)
      : -1;
    const nestedFormPath = `${formPath ? `${formPath}.` : ''}childGroup.${foundGroupIndex}`;

    return (
      <div className={'col-span-12 grid grid-cols-12'}>
        <Button
          buttonVariant={'Transparent'}
          buttonStyle={'Text'}
          onClick={handleExpand}
          className={`col-span-12 hover:bg-${colorTypes.Hover}`}
        >
          <Typography
            style={{ paddingLeft: DEFAULT_LAYER_PADDING * layer }}
            type={'16-M'}
            className={'flex flex-row items-center gap-2 py-1'}
          >
            {expanded ? (
              <CircleFilledIcon className={`text-${colorTypes.Tiffany} w-[10px]`} />
            ) : (
              <CircleOutlineIcon className={`text-${colorTypes.Tiffany} w-[10px]`} />
            )}
            {name}
          </Typography>
        </Button>
        <motion.div
          className={twMerge(`col-span-12 grid grid-cols-12 w-full overflow-hidden`)}
          initial={expanded ? { height: 'auto', opacity: 100 } : { height: 0, opacity: 0 }}
          animate={expanded ? { height: 'auto', opacity: 100 } : { height: 0, opacity: 0 }}
        >
          <div className={'col-span-12 grid grid-cols-12'}>
            {childGroups &&
              childGroups.map((child) => (
                <CompleteAppointmentGroupedListGroup
                  layer={layer + 1}
                  {...child}
                  key={child.id}
                  formPath={layer === 0 ? '' : formPath}
                />
              ))}
            {items &&
              items.map((item, index) => (
                <CompleteAppointmentGroupedListItem
                  {...item}
                  key={item.id}
                  layer={layer}
                  formPath={nestedFormPath}
                  bgColor={(index + 1) % 2 == 0 ? evenColor : oddColor}
                />
              ))}
          </div>
        </motion.div>
      </div>
    );
  };
