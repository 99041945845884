import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import { Checkbox, CheckboxUI } from '@/components/Checkbox/Checkbox';
import { Typography } from '@/components/Typography/Typography';
import styles from '@/components/views/person/PersonPage/DepartmentView/styles.module.scss';

import { usePersonPageStoreSelector } from '../../Context/PersonPageStoreContext';

const CHECK_ALL_FIELD_NAME = 'departments_check_all';
export const DepartmentSelectAll: React.FC = () => {
  const { t } = useTranslation('tms', { keyPrefix: 'ui.label' });

  const [isAllSelected, setIsAllSelected] = usePersonPageStoreSelector(
    (store) => store.allDepartmentsSelected
  );
  const [trggerDepartments] = usePersonPageStoreSelector(
    (store) => store.setAllDepartments
  );

  const toggleSelectAll = (checked: boolean) => {
    setIsAllSelected({
      allDepartmentsSelected: checked,
      setAllDepartments: !trggerDepartments
    });
  };

  return (
    <div className='flex flex-row items-center' onClick={() => toggleSelectAll(!isAllSelected)}>
      <CheckboxUI
        name={CHECK_ALL_FIELD_NAME}
        className={clsx(styles.checkBox, 'mr-2')}
        checked={isAllSelected}
      />
      <label className={'cursor-pointer'} htmlFor={CHECK_ALL_FIELD_NAME}>
        <Typography type='16-R' color='Primary'>
          {t('allDepartments')}
        </Typography>
      </label>
    </div>
  );
};
