import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { LoadingStatus } from '@/types/loadingStatus';

export type MobilityFormsContext = 'arrival' | 'departure';

export interface IMobilityItem {
  id: number;
  isWorkingDay: boolean;
  arrival?: string | null;
  departure?: string | null;
  name?: string | null;
}

export interface IMobilityFetchPayload {
  personID: number;
}

export interface IMobilityState {
  mobilityItems: IMobilityItem[] | null;
  mobilityLock: LoadingStatus;
}

const initialState: IMobilityState = {
  mobilityItems: null,
  mobilityLock: LoadingStatus.NEVER,
};

export const mobilitySlice = createSlice({
  name: 'mobility',
  initialState,
  reducers: {
    fetchMobility: (state, action: PayloadAction<IMobilityFetchPayload>) => ({
      ...state,
      payload: action.payload,
    }),
    setMobility: (state, action: PayloadAction<IMobilityItem[] | null>) => ({
      ...state,
      mobilityItems: action.payload,
    }),
    setMobilityLock: (state, action: PayloadAction<LoadingStatus>) => ({
      ...state,
      mobilityLock: action.payload,
    }),
  },
});

export const mobilityReducer = mobilitySlice.reducer;
export const mobilityActions = mobilitySlice.actions;
