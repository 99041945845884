import React from 'react';
import { useFormContext } from 'react-hook-form';

import { CompleteMeasureAppointmentListGroup } from '@/components/Modals/Modals/functions/vocationalTrainingAreaModals/CompleteMeasureModal/CompleteMeasureModalContent/CompleteMeasureAppointmentList/CompleteMeasureAppointmentListGroup';
import { useAppSelector } from '@/core/redux/hooks';
import { vocationalTrainingAreaModalSelectors } from '@/core/redux/slices/modalsSlice/functions/vocationalTrainingArea/selectors';
import {
  IMeasure,
  IRootMeasureGroup,
} from '@/core/redux/slices/modalsSlice/functions/vocationalTrainingArea/slice';

function findMeasure(
  rootGroups: IRootMeasureGroup[] | null,
  groupId: number,
  measureId: number
): IMeasure | undefined {
  if (!rootGroups) {
    return;
  }

  for (const rootGroup of rootGroups) {
    const group = rootGroup.groups.find((_group) => _group.id === groupId);
    if (group) {
      const measure = group.items.find((item) => item.id === measureId);
      if (measure) {
        return measure;
      }
    }
  }
  return undefined;
}

export const CompleteMeasureAppointmentList: React.FC = () => {
  const { measureDetails } = useAppSelector(
    vocationalTrainingAreaModalSelectors.completeMeasureModal
  );

  const { watch } = useFormContext();

  const selectedMeasure = watch('selectedMeasure');
  const matches = selectedMeasure?.match(/groupID=([^&]+)&measureID=([^&]+)/);
  const groupID = Number(matches?.[1]);
  const measureID = Number(matches?.[2]);

  const appointmentList = findMeasure(measureDetails, groupID, measureID);

  return (
    <div>
      {appointmentList &&
        appointmentList.appointments?.map((appointment) => (
          <CompleteMeasureAppointmentListGroup appointment={appointment} key={appointment.id} />
        ))}
    </div>
  );
};
