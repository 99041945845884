import { PayloadAction } from '@reduxjs/toolkit';
import { NormalizeOAS, OASOutput, OASRequestParams } from 'fets';
import { call, put, takeLatest } from 'redux-saga/effects';

import { authAdd, restCall } from '@/core/clients/rest';
import { toCamelCase } from '@/core/utils/commonUtils';
import { toBackendDate, toClientDateInput } from '@/core/utils/dateTimeUtil';
import type oas from '@/services/rest/base/openapi';
import { Camelize } from '@/types/camelize';
import { LoadingStatus } from '@/types/loadingStatus';

import {
  checklistActions,
  IChecklist,
  IChecklistsFetchPayload,
  IMarkCompletePayload,
  IMarkIncompletePayload,
} from './checklistSlice';

// types

type ChecklistsResponse = OASOutput<NormalizeOAS<typeof oas>, '/checklist', 'get', '200'>;
type GroupsResponse = OASOutput<NormalizeOAS<typeof oas>, '/checklist/group', 'get', '200'>;
type MarkCompleteRequest = OASRequestParams<
  NormalizeOAS<typeof oas>,
  '/checklist/checkpoint/mark_complete',
  'post'
>;
type MarkIncompleteRequest = OASRequestParams<
  NormalizeOAS<typeof oas>,
  '/checklist/checkpoint/mark_incomplete',
  'post'
>;
type FillCorrespondingResponse = OASOutput<
  NormalizeOAS<typeof oas>,
  '/checklist/checkpoint/fill_corresponding_document',
  'get',
  '200'
>;
type AidDocumentResponse = OASOutput<
  NormalizeOAS<typeof oas>,
  '/checklist/checkpoint/aid_document',
  'get',
  '200'
>;

const mapChecklists = (checklists: IChecklist[]): IChecklist[] => {
  return checklists.map((checklist) => ({
    ...checklist,
    checkpoints: checklist.checkpoints.map((checkpoint) => ({
      ...checkpoint,
      completeDate: toClientDateInput(checkpoint.completeDate),
    })),
  }));
};

// sagas

function* fetchGroups(): Generator<any, void, GroupsResponse> {
  try {
    const response = yield call(restCall, '/checklist/group', 'get', {
      ...authAdd(),
    });

    const camelizeResponse: Camelize<GroupsResponse> = toCamelCase(response);
    const groups = camelizeResponse.groups;

    yield put(checklistActions.setGroups(groups));
  } catch (error) {
    console.error('Error on groups fetching');
  }
}

function* fetchChecklists(
  action: PayloadAction<IChecklistsFetchPayload>
): Generator<any, void, ChecklistsResponse> {
  const { personID, checklistGroupID } = action.payload;

  try {
    const response = yield call(restCall, '/checklist', 'get', {
      query: {
        person_id: personID,
        checklist_group_id: checklistGroupID,
      },
      ...authAdd(),
    });

    const camelizeResponse: Camelize<ChecklistsResponse> = toCamelCase(response);
    const checklists = mapChecklists(camelizeResponse.checklists);

    yield put(checklistActions.setChecklists(checklists));
  } catch (error) {
    console.error('Error on checklists fetching', error);
  }
}

function* markComplete(action: PayloadAction<IMarkCompletePayload>): Generator<any, void, any> {
  const { checkpointID } = action.payload;

  yield put(checklistActions.setMarkCheckpointLock(LoadingStatus.LOADING));

  try {
    const request: MarkCompleteRequest = {
      query: {
        checkpoint_id: checkpointID,
      },
      ...authAdd(),
    };

    yield call(
      restCall,
      '/checklist/checkpoint/mark_complete',
      'post',
      {
        ...request,
      },
      null,
      true
    );

    yield put(checklistActions.setMarkCheckpointLock(LoadingStatus.LOADED));
  } catch (error) {
    console.error('Error on complete', error);
    yield put(checklistActions.setMarkCheckpointLock(LoadingStatus.ERROR));
  }
}

function* markIncomplete(action: PayloadAction<IMarkIncompletePayload>): Generator<any, void, any> {
  const { checkpointID } = action.payload;

  yield put(checklistActions.setMarkCheckpointLock(LoadingStatus.LOADING));

  try {
    const request: MarkIncompleteRequest = {
      query: {
        checkpoint_id: checkpointID,
      },
      ...authAdd(),
    };

    yield call(
      restCall,
      '/checklist/checkpoint/mark_incomplete',
      'post',
      {
        ...request,
      },
      null,
      true
    );

    yield put(checklistActions.setMarkCheckpointLock(LoadingStatus.LOADED));
  } catch (error) {
    console.error('Error on incomplete', error);
    yield put(checklistActions.setMarkCheckpointLock(LoadingStatus.ERROR));
  }
}

function* fillCorrespondingDocument(
  action: PayloadAction<number>
): Generator<any, void, FillCorrespondingResponse> {
  try {
    yield call(restCall, '/checklist/checkpoint/fill_corresponding_document', 'get', {
      query: {
        checkpoint_id: action.payload,
      },
      ...authAdd(),
    });
  } catch (error) {
    console.error('Error on fill corresponding document', error);
  }
}

function* fetchAidDocument(
  action: PayloadAction<number>
): Generator<any, void, AidDocumentResponse> {
  try {
    yield call(restCall, '/checklist/checkpoint/aid_document', 'get', {
      query: {
        checkpoint_id: action.payload,
      },
      ...authAdd(),
    });
  } catch (error) {
    console.error('Error on get aid document', error);
  }
}

export const checklistSagas = [
  takeLatest(checklistActions.fetchGroups, fetchGroups),
  takeLatest(checklistActions.fetchChecklists, fetchChecklists),
  takeLatest(checklistActions.markComplete, markComplete),
  takeLatest(checklistActions.markIncomplete, markIncomplete),
  takeLatest(checklistActions.fillCorrespondingDocument, fillCorrespondingDocument),
  takeLatest(checklistActions.fetchAidDocument, fetchAidDocument),
];
