import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { FormRadioButtonGroup } from '@/components/FormRadioButtonGroup/FormRadioButtonGroup';
import { IFormRadioButtonGroupItem } from '@/components/FormRadioButtonGroup/FormRadioButtonGroupItem';
import { Typography } from '@/components/Typography/Typography';
import { IAddressType } from '@/core/redux/slices/functions/personGeneralData/addresses/addressesSlice';
import { colorTypes } from '@/styles/types';

export interface IAddressSelectType {
  addressTypes: IAddressType[];
}

export const AddressSelectType: React.FC<IAddressSelectType> = ({ addressTypes }) => {
  const { t: displayAddressModalTranslations } = useTranslation('displayAddressModal');

  const { control } = useFormContext();

  const options: IFormRadioButtonGroupItem[] = addressTypes.map((addressType) => ({
    value: String(addressType.id),
    component: addressType.name,
    className: 'items-center',
  }));

  return (
    <div className='flex flex-col gap-6 pr-3'>
      <Typography type={'H3'} className={`border-b border-b-${colorTypes.Stroke} pb-default`}>
        {displayAddressModalTranslations('types.title.label')}
      </Typography>
      <FormRadioButtonGroup
        options={options}
        control={control.register('addresseeTypeId')}
        containerClassName={'grid grid-cols-3 gap-3 gap-x-7 items-start'}
      />
    </div>
  );
};
