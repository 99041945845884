import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { OrganizationalUnitErrors } from '@/core/enums/functions/organizationalUnits/organizationalUnitErrorsEnum';
import { useDialog } from '@/core/hooks/useDialog';
import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';
import { organizationalUnitsModalsSelectors } from '@/core/redux/slices/modalsSlice/functions/organizationalUnits/selectors';
import { organizationalUnitsModalsActions } from '@/core/redux/slices/modalsSlice/functions/organizationalUnits/slice';

interface IOrganizationalUnitErrorDialog {
  description: string;
  onConfirm: () => void;
}

export function useOrganizationalUnitErrors() {
  const { t: organizationalUnitErrorsTranslations } = useTranslation('organizationalUnits', {
    keyPrefix: 'errors',
  });

  const [activeOrganizationalUnitError, setActiveOrganizationalUnitError] =
    useState<OrganizationalUnitErrors | null>(null);

  const organizationalUnitsErrors = useAppSelector(
    organizationalUnitsModalsSelectors.organizationalUnitErrors
  );

  const dispatch = useAppDispatch();

  const errorDialogs: Record<Partial<OrganizationalUnitErrors>, IOrganizationalUnitErrorDialog> = {
    [OrganizationalUnitErrors.WITHOUT_NOTE]: {
      description: organizationalUnitErrorsTranslations('withOutComment.description.label'),
      onConfirm: () => {
        dispatch(
          organizationalUnitsModalsActions.setOrganizationalErrors({
            [OrganizationalUnitErrors.WITHOUT_NOTE]: false,
          })
        );
      },
    },
    [OrganizationalUnitErrors.INCORRECT_DATE]: {
      description: organizationalUnitErrorsTranslations('incorrectDates.description.label'),
      onConfirm: () => {
        dispatch(
          organizationalUnitsModalsActions.setOrganizationalErrors({
            [OrganizationalUnitErrors.INCORRECT_DATE]: false,
          })
        );
      },
    },
    [OrganizationalUnitErrors.UPDATE_OUT_OF_RANGE]: {
      description: organizationalUnitErrorsTranslations('incorrectPeriod.description.label'),
      onConfirm: () => {
        dispatch(
          organizationalUnitsModalsActions.setOrganizationalErrors({
            [OrganizationalUnitErrors.UPDATE_OUT_OF_RANGE]: false,
          })
        );
      },
    },
    [OrganizationalUnitErrors.CREATE_OUT_OF_RANGE]: {
      description: organizationalUnitErrorsTranslations('incorrectPeriod.description.label'),
      onConfirm: () => {
        dispatch(
          organizationalUnitsModalsActions.setOrganizationalErrors({
            [OrganizationalUnitErrors.CREATE_OUT_OF_RANGE]: false,
          })
        );
      },
    },
    [OrganizationalUnitErrors.WITHOUT_PERSON]: {
      description: organizationalUnitErrorsTranslations('incorrectPeriod.description.label'),
      onConfirm: () => {
        dispatch(
          organizationalUnitsModalsActions.setOrganizationalErrors({
            [OrganizationalUnitErrors.WITHOUT_PERSON]: false,
          })
        );
      },
    },
    [OrganizationalUnitErrors.END_DATE_BEFORE_START_DATE]: {
      description: organizationalUnitErrorsTranslations('startDateAfterEndDate.description.label'),
      onConfirm: () => {
        dispatch(
          organizationalUnitsModalsActions.setOrganizationalErrors({
            [OrganizationalUnitErrors.END_DATE_BEFORE_START_DATE]: false,
          })
        );
      },
    },
  };

  const { renderDialogContainer, openDialog } = useDialog({
    description: activeOrganizationalUnitError
      ? errorDialogs[activeOrganizationalUnitError].description
      : null,
    showConfirmButton: true,
    showCancelButton: false,
    onConfirm: activeOrganizationalUnitError
      ? errorDialogs[activeOrganizationalUnitError].onConfirm
      : undefined,
    isOpened: Boolean(activeOrganizationalUnitError),
  });

  useEffect(() => {
    const activeErrorKey = Object.entries(organizationalUnitsErrors).find(
      ([_, status]) => status
    )?.[0];

    if (activeErrorKey) {
      setActiveOrganizationalUnitError(activeErrorKey as OrganizationalUnitErrors);
      openDialog();
    }
  }, [organizationalUnitsErrors]);

  return { renderDialogContainer };
}
