import React, { useState } from 'react';
import { motion } from 'framer-motion';

import { Button } from '@/components/Button/Button';
import { CircleFilledIcon } from '@/components/Icons/CircleFilledIcon';
import { CircleOutlineIcon } from '@/components/Icons/CircleOutlineIcon';
import { CompleteMeasureGroupedListItem } from '@/components/Modals/Modals/functions/vocationalTrainingAreaModals/CompleteMeasureModal/CompleteMeasureModalContent/CompleteMeasureList/CompleteMeasureGroupedListItem';
import { Typography } from '@/components/Typography/Typography';
import { useAppSelector } from '@/core/redux/hooks';
import { IMeasuresGroup } from '@/core/redux/slices/modalsSlice/functions/vocationalTrainingArea/slice';
import { parametersSelectors } from '@/core/redux/slices/parameters/selectors';
import { twMerge } from '@/core/utils/tailwindUtil';
import { colorTypes } from '@/styles/types';

interface ICompleteAppointmentAreaGroupedListGroup extends IMeasuresGroup {
  layer: number;
  formPath: string;
}

export const DEFAULT_LAYER_PADDING = 40;

export const CompleteMeasureGroup: React.FC<ICompleteAppointmentAreaGroupedListGroup> = ({
  items,
  layer,
  name,
  id,
  formPath,
}) => {
  const [expanded, setExpanded] = useState<boolean>(true);

  const oddColor = useAppSelector(parametersSelectors.selfAssessmentOddBackgroundColor);
  const evenColor = useAppSelector(parametersSelectors.selfAssessmentEvenBackgroundColor);

  const handleExpand = () => {
    setExpanded((prev) => !prev);
  };

  return (
    <div className={'col-span-12 grid grid-cols-12 justify-center items-center'}>
      <Button
        buttonVariant={'Transparent'}
        buttonStyle={'Text'}
        onClick={handleExpand}
        className={`col-span-12 hover:bg-${colorTypes.Hover}`}
      >
        <Typography
          style={{ paddingLeft: DEFAULT_LAYER_PADDING * layer }}
          type={'16-M'}
          className={'flex flex-row items-center gap-2 py-1'}
        >
          {expanded ? (
            <CircleFilledIcon className={`text-${colorTypes.Tiffany} w-[10px]`} />
          ) : (
            <CircleOutlineIcon className={`text-${colorTypes.Tiffany} w-[10px]`} />
          )}
          {name}
        </Typography>
      </Button>
      <motion.div
        className={twMerge(`col-span-12 grid grid-cols-12 w-full overflow-hidden`)}
        initial={expanded ? { height: 'auto', opacity: 100 } : { height: 0, opacity: 0 }}
        animate={expanded ? { height: 'auto', opacity: 100 } : { height: 0, opacity: 0 }}
      >
        <div className={'col-span-12 grid grid-cols-12'}>
          {items &&
            items.map((item, index) => (
              <CompleteMeasureGroupedListItem
                {...item}
                key={item.id}
                groupID={id}
                layer={layer}
                formPath={formPath}
                bgColor={(index + 1) % 2 == 0 ? evenColor : oddColor}
              />
            ))}
        </div>
      </motion.div>
    </div>
  );
};
