import React from 'react';
import { twMerge } from 'tailwind-merge';

import { Button } from '@/components/Button/Button';
import { DocumentViewIcon } from '@/components/Icons/DocumentViewIcon';
import { Typography } from '@/components/Typography/Typography';
import { useAppDispatch } from '@/core/redux/hooks';
import { smartGoalsActions } from '@/core/redux/slices/functions/fundingCycle/smartGoals/smartGoalsSlice';
import { colorTypes } from '@/styles/types';

interface ListItem {
  bgColor?: string;
  isSelected?: boolean;
}

export const ListItem: React.FC<any> = ({
  name,
  id,
  nearestActivity,
  lastActivity,
  type,
  bgColor,
  isSelected,
  hasDocuments,
}) => {
  const dispatch = useAppDispatch();

  const handleSelectMeasure = (id: number) => {
    dispatch(smartGoalsActions.setSelectedMeasure(id));
  };

  return (
    <div
      onClick={() => handleSelectMeasure(id)}
      className={twMerge(
        `w-full col-span-12 grid grid-cols-12 hover:bg-${colorTypes.HoverLight} items-center`,
        isSelected && '!bg-stroke-light'
      )}
      style={{ backgroundColor: bgColor }}
    >
      <Button
        buttonVariant={'Transparent'}
        buttonStyle={'Text'}
        className={`col-span-12 grid grid-cols-12 px-[30px]  py-1`}
      >
        <div className={'col-span-7 flex flex-row gap-5 justify-between items-center'}>
          <Typography className={'whitespace-normal'} type={'16-M'} color={'Secondary'}>
            {name}
          </Typography>
          {hasDocuments && (
            <Button
              buttonStyle={'Icon'}
              buttonVariant={'Icon'}
              className={`text-${colorTypes.Secondary}`}
            >
              <DocumentViewIcon />
            </Button>
          )}
        </div>
        <Typography className={'col-span-2'} type={'16-M'} color={'Secondary'}>
          {nearestActivity}
        </Typography>
        <Typography className={'col-span-1'} type={'16-M'} color={'Secondary'}>
          {type}
        </Typography>
        <Typography className={'col-span-2'} type={'16-M'} color={'Secondary'}>
          {lastActivity}
        </Typography>
      </Button>
    </div>
  );
};
