import React, { useEffect, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Button } from '@/components/Button/Button';
import { Checkbox } from '@/components/Checkbox/Checkbox';
import { Dropdown } from '@/components/Dropdown/Dropdown';
import { IAppointmentDetailsForm } from '@/components/Modals/Modals/functions/activityPlaningModals/appointmentDetailsModals/AppointmentDetailsModal/AppointmentDetails/hooks/useAppointmentDetailsForm';
import { useSchemaContext } from '@/components/SchemaContext/SchemaContext';
import { Typography } from '@/components/Typography/Typography';
import { useAppSelector } from '@/core/redux/hooks';
import { activityPlaningAppointmentDetailsSelectors } from '@/core/redux/slices/functions/activityPlanning/appointmentDetails/selectors';
import { IAppointmentDetails } from '@/core/redux/slices/functions/activityPlanning/appointmentDetails/slice';
import { colorTypes } from '@/styles/types';

interface IImplementationAidsSelect {
  appointmentDetails: IAppointmentDetails | null;
}

const FIELD_NAME = 'implementationAids';

export const ImplementationAidsSelect: React.FC<IImplementationAidsSelect> = () => {
  const { t: appointmentDetailTranslations } = useTranslation('activityPlanning', {
    keyPrefix: 'appointmentDetails',
  });

  const [isAllSelected, setIsAllSelected] = useState<boolean>(false);

  const appointmentDetailMeasureAids = useAppSelector(
    activityPlaningAppointmentDetailsSelectors.appointmentDetailMeasureAids
  );

  const { setValue, control } = useFormContext<IAppointmentDetailsForm>();
  const currentValues = useWatch({ control, name: FIELD_NAME });

  useEffect(() => {
    if (!currentValues) {
      setIsAllSelected(false);
      return;
    }

    const aidsValues = Object.values(currentValues);

    setIsAllSelected(aidsValues.some((value) => value === false));
  }, [currentValues]);

  const getSelectedName = (): string | null => {
    if (!currentValues) {
      return appointmentDetailTranslations('fields.implementationAid.isNotSelected.label');
    }

    const aidsKeys = Object.keys(currentValues);

    const selectedKeys = aidsKeys.reduce<string[]>((accum, key) => {
      if (currentValues[key]) {
        accum.push(key);
      }

      return accum;
    }, []);

    if (selectedKeys.length < 1) {
      return appointmentDetailTranslations('fields.implementationAid.isNotSelected.label');
    }

    if (selectedKeys.length > 1) {
      return appointmentDetailTranslations('fields.implementationAid.isMultiSelected.label');
    }

    const foundElement = appointmentDetailMeasureAids?.find(
      (measureAid) => measureAid.id === Number(selectedKeys[0])
    );

    return foundElement
      ? foundElement.name
      : appointmentDetailTranslations('fields.implementationAid.isNotSelected.label');
  };

  const handleSelectAll = () => {
    if (!currentValues) {
      return;
    }

    const aidsKeys = Object.keys(currentValues);

    const newAidsState = aidsKeys.reduce<Record<string, boolean>>((accum, key) => {
      accum[key] = isAllSelected;
      return accum;
    }, {});

    setValue(FIELD_NAME, newAidsState, { shouldDirty: true });
  };

  const { isRequiredField } = useSchemaContext(FIELD_NAME);

  const dropdownContent = (
    <div className={'bg-white shadow-default p-2 rounded-default flex flex-col gap-1 '}>
      <div className={'flex flex-col max-h-[30vh] overflow-y-auto'}>
        {appointmentDetailMeasureAids &&
          appointmentDetailMeasureAids.map((aid) => (
            <div className={'flex flex-row gap-2'} key={aid.id}>
              <Checkbox name={`implementationAids.${aid.id}`} id={String(aid.id)} />
              <label htmlFor={String(aid.id)}>
                <Typography>{aid.name}</Typography>
              </label>
            </div>
          ))}
      </div>
      <Button onClick={handleSelectAll} className={'w-fit mt-3'}>
        {isAllSelected ? 'select' : 'deselect'}
      </Button>
    </div>
  );

  return (
    <Dropdown
      content={dropdownContent}
      label={appointmentDetailTranslations('fields.implementationAid.label')}
      isRequired={isRequiredField}
    >
      <div className={`w-full bg-${colorTypes.Fields} rounded-default p-4`}>
        <Typography>{getSelectedName()}</Typography>
      </div>
    </Dropdown>
  );
};
