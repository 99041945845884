import { PayloadAction } from '@reduxjs/toolkit';
import { type NormalizeOAS, type OASOutput, OASRequestParams } from 'fets';
import { call, put, takeLatest } from 'redux-saga/effects';

import {
  IDevelopmentReportGoalAndActivity,
  IDevelopmentReportGoalsAndActivityForm,
} from '@/components/functions/DevelopmentReport/DevelopmentReportForms/GoalsAndActivity/helper/goalsAndActivityForm';
import { authAdd, restCall } from '@/core/clients/rest';
import {
  developmentReportGoalsAndActivityActions,
  IDevelopmentReportGoalsAndActivityFetchPayload,
  IDevelopmentReportGoalsAndActivityUpdatePayload,
} from '@/core/redux/slices/functions/developmentReport/developmentReportGoalsAndActivitySlice/slice';
import type oas from '@/services/rest/base/openapi';

type FetchDevelopmentReportGoalsAndActivityRequest = OASRequestParams<
  NormalizeOAS<typeof oas>,
  '/development_report/activity_planning',
  'get'
>;


type DevelopmentReportGoalsAndActivityResponse = OASOutput<
  NormalizeOAS<typeof oas>,
  '/development_report/activity_planning',
  'get',
  '200'
>;

type UpdateDevelopmentReportGoalsAndActivityRequest = OASRequestParams<
  NormalizeOAS<typeof oas>,
  '/development_report/activity_planning',
  'post'
>;

function* fetchDevelopmentReportGoalsAndActivity(action: PayloadAction<IDevelopmentReportGoalsAndActivityFetchPayload>): Generator<any, void, DevelopmentReportGoalsAndActivityResponse> {
  const { developmentReportID } = action.payload;

  try {
    const request: FetchDevelopmentReportGoalsAndActivityRequest = {
      query: {
        development_report_id: developmentReportID
      },
      ...authAdd(),
    };

    const response = yield call(restCall, '/development_report/activity_planning', 'get', request);
    const mappedResponse: IDevelopmentReportGoalsAndActivityForm = {
      goalsAndActivities: response.items.map<IDevelopmentReportGoalAndActivity>((item) => ({
        lfdNr: item.lfd_nr,
        executionPlace: item.execution_place,
        executor: item.executor,
        goal: item.goal,
        until: item.until,
        smartGoalId: item.smart_goal_id,
        requiredActions: item.required_actions
      })),
    }
    //
    yield put(developmentReportGoalsAndActivityActions.setDevelopmentReportGoalsAndActivity(mappedResponse));
  } catch (error) {
    console.log('Error on development report goals and activity fetching', error);
  }
}

function* updateDevelopmentReportGoalsAndActivity(action: PayloadAction<IDevelopmentReportGoalsAndActivityUpdatePayload>): Generator<any, void, any> {
  const { developmentReportID, personID, formValues } = action.payload;

  try {
    const request: UpdateDevelopmentReportGoalsAndActivityRequest = {
      json: {
        person_id: personID,
        development_report_id: developmentReportID,
       items: formValues?.goalsAndActivities.map<UpdateDevelopmentReportGoalsAndActivityRequest['json']['items'][0]>((goalAndActivity) => ({
         lfd_nr: goalAndActivity.lfdNr,
         required_actions: goalAndActivity.requiredActions,
         smart_goal_id: goalAndActivity.smartGoalId,
         execution_place: goalAndActivity.executionPlace,
         executor: goalAndActivity.executor,
         goal: goalAndActivity.goal,
         until: goalAndActivity.until
       }))
      },
      ...authAdd(),
    };

   yield call(restCall, '/development_report/activity_planning', 'post', request);
  } catch (error) {
    console.log('Error on development report goals and activity updating', error);
  }
}

export const developmentReportGoalsAndActivitySagas = [
  takeLatest(developmentReportGoalsAndActivityActions.fetchDevelopmentReportGoalsAndActivity, fetchDevelopmentReportGoalsAndActivity),
  takeLatest(developmentReportGoalsAndActivityActions.updateDevelopmentReportGoalsAndActivity, updateDevelopmentReportGoalsAndActivity)
];
