import { PayloadAction } from '@reduxjs/toolkit';
import { type NormalizeOAS, type OASOutput, OASRequestParams } from 'fets';
import { call, put, takeLatest } from 'redux-saga/effects';

import { authAdd, restCall } from '@/core/clients/rest';
import {
  developmentReportPersonDetailsActions,
  IDevelopmentReportPersonDetailsFetchPayload,
  IDevelopmentReportPersonDetailsUpdatePayload,
} from '@/core/redux/slices/functions/developmentReport/developmentReportPersonDetailsSlice/slice';
import { toCamelCase } from '@/core/utils/commonUtils';
import type oas from '@/services/rest/base/openapi';

type FetchDevelopmentReportPersonDetailsRequest = OASRequestParams<
  NormalizeOAS<typeof oas>,
  '/development_report/personal_details',
  'get'
>;


type DevelopmentReportPersonDetailsResponse = OASOutput<
  NormalizeOAS<typeof oas>,
  '/development_report/personal_details',
  'get',
  '200'
>;

type UpdateDevelopmentReportPersonDetailsRequest = OASRequestParams<
  NormalizeOAS<typeof oas>,
  '/development_report/personal_details',
  'post'
>;

function* fetchDevelopmentReportPersonDetails(action: PayloadAction<IDevelopmentReportPersonDetailsFetchPayload>): Generator<any, void, DevelopmentReportPersonDetailsResponse> {
  const { developmentReportID } = action.payload;

  try {
    const request: FetchDevelopmentReportPersonDetailsRequest = {
      query: {
        development_report_id: developmentReportID
      },
      ...authAdd(),
    };

    const response = yield call(restCall, '/development_report/personal_details', 'get', request);
    const mappedResponse = toCamelCase(response.personal_details)

    yield put(developmentReportPersonDetailsActions.setDevelopmentReportPersonDetails(mappedResponse));
  } catch (error) {
    console.log('Error on development report person details fetching', error);
  }
}

function* updateDevelopmentReportPersonDetails(action: PayloadAction<IDevelopmentReportPersonDetailsUpdatePayload>): Generator<any, void, DevelopmentReportPersonDetailsResponse> {
  const { developmentReportID, personID, formValues } = action.payload;

  try {
    const request: UpdateDevelopmentReportPersonDetailsRequest = {
      json: {
        development_report_id: developmentReportID,
        person_id: personID,
        hbg1: formValues.hbg1 ?? false,
        hbg2: formValues.hbg2 ?? false,
        hgb3: formValues?.hgb3 ?? false,
        legal_guardian_yes_flag: formValues.legalGuardianYesFlag ?? false,
        legal_guardian_no_flag: formValues.legalGuardianNoFlag ?? false,
        is_mobility_oepnv: formValues.isMobilityOepnv ?? false,
        is_mobility_transport_service: formValues.isMobilityTransportService ?? false,
        is_mobility_on_foot: formValues.isMobilityOnFoot ?? false,
        is_mobility_self_drive: formValues.isMobilitySelfDrive ?? false,
        wfbm_name: formValues?.wfbmName,
        wfbm_operating_unit: formValues?.wfbmOperatingUnit,
        wfbm_contact_person: formValues?.wfbmContactPerson,
        legal_guardian_name_address: formValues?.legalGuardianNameAddress,
        working_hours_per_week: formValues?.workingHoursPerWeek,
        working_days_per_week: formValues?.workingDaysPerWeek,
        working_hours_per_day: formValues?.workingHoursPerDay,
        diagnosis: formValues?.diagnosis,
        internships: formValues?.internships,
        communication_note: formValues?.communicationNote,
        goals_wishes: formValues?.goalsWishes
      },
      ...authAdd(),
    };

   yield call(restCall, '/development_report/personal_details', 'post', request);
  } catch (error) {
    console.log('Error on development report person details updating', error);
  }
}

export const developmentReportPersonDetailsSagas = [
  takeLatest(developmentReportPersonDetailsActions.fetchDevelopmentReportPersonDetails, fetchDevelopmentReportPersonDetails),
  takeLatest(developmentReportPersonDetailsActions.updateDevelopmentReportPersonDetails, updateDevelopmentReportPersonDetails)
];
