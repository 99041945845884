import React from 'react';

import { twMerge } from '@/core/utils/tailwindUtil';

interface IFundingCycleSection {
  header: React.ReactNode;
  children: React.ReactNode;
  className?: string;
  contentClassName?: string;
}

export const FundingCycleSection: React.FC<IFundingCycleSection> = ({
  header,
  children,
  className,
  contentClassName,
}) => {
  return (
    <div className={twMerge('flex flex-col overflow-hidden', className)}>
      {header}
      <div className={twMerge('h-full overflow-y-auto', contentClassName)}>{children}</div>
    </div>
  );
};
