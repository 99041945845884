import { createSelector } from '@reduxjs/toolkit';

import { IRootState } from '@/core/redux/types';

const selector = (state: IRootState) => state.functions.checklist;

const checklistGroups = createSelector([selector], (state) => state.groups);
const checklists = createSelector([selector], (state) => state.checklists);
const markCheckpointLock = createSelector([selector], (state) => state.markCheckpointLock);


export const checklistSelectors = {
    checklistGroups,
    checklists,
    markCheckpointLock
};
