import React, { CSSProperties } from 'react';
import { Tooltip } from 'react-tooltip';

import { Button } from '@/components/Button/Button';
import { Typography } from '@/components/Typography/Typography';
import { colorTypes, textTypes } from '@/styles/types';

interface IImplementationAidTableItem {
  aidString: string;
  itemID: number;
}

export const ImplementationAidTableItem: React.FC<IImplementationAidTableItem> = ({
  aidString,
  itemID,
}) => {
  const aids = aidString.split('\n');

  const tooltipID = `aids-tooltip-id-${itemID}`;
  const tooltipStyles: CSSProperties = {
    backgroundColor: 'transparent',
    zIndex: 100,
    padding: 0,
  };

  return (
    <div className={'flex flex-row gap-2 items-center'}>
      {aids[0]}
      {aids.length > 1 && (
        <>
          <Button id={tooltipID} className={`h-fit px-2 py-1 ${textTypes['14-R']}`}>
            {`+${aids.length - 1}`}
          </Button>
          <Tooltip
            anchorSelect={`#${tooltipID}`}
            place={'bottom'}
            positionStrategy={'fixed'}
            className={'bg-blue-300'}
            style={tooltipStyles}
            noArrow={true}
            opacity={1}
          >
            <div
              className={`bg-${colorTypes.HoverLight} rounded-default p-2 flex flex-col max-h-[30vh] overflow-y-auto`}
            >
              {aids.slice(1).map((aid, index) => (
                <Typography key={index}>{aid}</Typography>
              ))}
            </div>
          </Tooltip>
        </>
      )}
    </div>
  );
};
