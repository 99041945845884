import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { CheckboxUI } from '@/components/Checkbox/Checkbox';
import { Typography } from '@/components/Typography/Typography';
import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';
import { smartGoalsSelectors } from '@/core/redux/slices/functions/fundingCycle/smartGoals/selectors';
import { smartGoalsActions } from '@/core/redux/slices/functions/fundingCycle/smartGoals/smartGoalsSlice';
import { parametersSelectors } from '@/core/redux/slices/parameters/selectors';

import { ListHeader } from './ListHeader/ListHeader';
import { ListItem } from './ListItem/ListItem';

interface IMeasureTable {
  className?: string;
}

export const MeasuresTable: React.FC<IMeasureTable> = () => {
  const dispatch = useAppDispatch();

  const data = useAppSelector(smartGoalsSelectors.measures);
  const selectedSmartGoalID = useAppSelector(smartGoalsSelectors.selectedSmartGoal);
  const selectedMeasureID = useAppSelector(smartGoalsSelectors.selectedMeasure);

  const oddColor = useAppSelector(parametersSelectors.vocationalTrainingAreaItemOddColor);
  const evenColor = useAppSelector(parametersSelectors.vocationalTrainingAreaItemEvenColor);

  useEffect(() => {
    if (!selectedSmartGoalID) {
      return;
    }

    dispatch(
      smartGoalsActions.fetchMeasures({
        smartGoalID: selectedSmartGoalID,
      })
    );
  }, [selectedSmartGoalID]);

  useEffect(() => {
    return () => {
      dispatch(smartGoalsActions.setSelectedMeasure(undefined));
    };
  }, []);

  return (
    <div className={'overflow-y-auto flex flex-col'}>
      <ListHeader />
      <div className='overflow-auto grow'>
        {data &&
          data.map((item, index) => (
            <ListItem
              {...item}
              key={item.id}
              bgColor={(index + 1) % 2 == 0 ? evenColor : oddColor}
              isSelected={selectedMeasureID === item.id}
            />
          ))}
      </div>
    </div>
  );
};
