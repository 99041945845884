import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IMobilityForm } from '@/components/Modals/Modals/functions/workingTimeMobilityModals/EditMobilityModal/hooks/useMobilityForm';
import { MobilityFormTypes } from '@/core/enums/functions/workingTimeMobility/mobilityFormTypesEnum';
import { MobilityRefundVariant } from '@/core/enums/functions/workingTimeMobility/mobilityRefundVariantsEnum';
import { MobilityFormsContext } from '@/core/redux/slices/functions/workingTimeMobility/mobility/mobilitySlice';
import { LoadingStatus } from '@/types/loadingStatus';

export interface IMobilityDay {
  id: number;
  name?: string | null;
  isWorkingDay: boolean;
}

export interface IMobilityFormType {
  id: number;
  name?: string | null;
  type: MobilityFormTypes;
}

export interface ITransportTypeService {
  type?: {
    id?: number;
    name?: string | null;
  } | null;
  contacts?: string | null;
  date?: string | null;
}

export interface ISelfDrive {
  refundVariant?: MobilityRefundVariant;
  kilometer?: number | null;
}

export interface IMobilityDetails {
  id: number;
  mobilityType: IMobilityFormType;
  transportService?: ITransportTypeService | null;
  selfDrive?: ISelfDrive | null;
  availableMobilityTypes: IMobilityFormType[];
  availableWorkingDays: IMobilityDay[];
  availableTransportServices: ITransportTypeService[];
}

export interface IModal {
  isRender: boolean;
}

export interface IEditMobilityModalPayload {
  context: MobilityFormsContext;
  mobilityID: number;
  mobilityDay?: IMobilityDay | null;
}

export interface IFetchMobilityDetailsModalPayload {
  context: MobilityFormsContext;
  mobilityID: number;
}

export interface IUpdateMobilityDetailsPayload {
  context: MobilityFormsContext;
  formValues: IMobilityForm;
}

export interface IEditMobilityModal extends IModal {
  payload: IEditMobilityModalPayload | null;
  fetchPayload: IFetchMobilityDetailsModalPayload | null;
  updatePayload: IUpdateMobilityDetailsPayload | null;
  mobilityDetails: IMobilityDetails | null;
  mobilityDetailsLock: LoadingStatus;
  updateMobilityLock: LoadingStatus;
}

export interface IMobilityModals {
  editMobilityModal: IEditMobilityModal;
}

const initialState: IMobilityModals = {
  editMobilityModal: {
    isRender: false,
    payload: null,
    fetchPayload: null,
    updatePayload: null,
    mobilityDetails: null,
    mobilityDetailsLock: LoadingStatus.NEVER,
    updateMobilityLock: LoadingStatus.NEVER,
  },
};

export const mobilityModalsSlice = createSlice({
  name: 'mobilityModals',
  initialState,
  reducers: {
    openEditMobilityModal: (state, action: PayloadAction<IEditMobilityModalPayload>) => ({
      ...state,
      editMobilityModal: {
        ...state.editMobilityModal,
        isRender: true,
        payload: action.payload,
      },
    }),
    closeEditMobilityModal: (state) => ({
      ...state,
      editMobilityModal: {
        isRender: false,
        payload: null,
        fetchPayload: null,
        updatePayload: null,
        mobilityDetails: null,
        mobilityDetailsLock: LoadingStatus.NEVER,
        updateMobilityLock: LoadingStatus.NEVER,
      },
    }),
    fetchMobilityDetails: (state, action: PayloadAction<IEditMobilityModalPayload>) => ({
      ...state,
      editMobilityModal: {
        ...state.editMobilityModal,
        fetchPayload: action.payload,
      },
    }),
    setMobilityDetails: (state, action: PayloadAction<IMobilityDetails | null>) => ({
      ...state,
      editMobilityModal: {
        ...state.editMobilityModal,
        mobilityDetails: action.payload,
      },
    }),
    setMobilityDetailsLock: (state, action: PayloadAction<LoadingStatus>) => ({
      ...state,
      editMobilityModal: {
        ...state.editMobilityModal,
        mobilityDetailsLock: action.payload,
      },
    }),
    updateMobility: (state, action: PayloadAction<IUpdateMobilityDetailsPayload>) => ({
      ...state,
      editMobilityModal: {
        ...state.editMobilityModal,
        updatePayload: action.payload,
      },
    }),
    setUpdateMobilityLock: (state, action: PayloadAction<LoadingStatus>) => ({
      ...state,
      editMobilityModal: {
        ...state.editMobilityModal,
        updateMobilityLock: action.payload,
      },
    }),
  },
});

export const mobilityModalsActions = mobilityModalsSlice.actions;
export const mobilityModalsReducers = mobilityModalsSlice.reducer;
