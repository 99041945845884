import { createSelector } from '@reduxjs/toolkit';

import { IRootState } from '@/core/redux/types';

const selector = (state: IRootState) =>
  state.modals.functions.activityPlaningModals.participantsModals;

const addParticipantsModal = createSelector([selector], (state) => state.addParticipantsModal);

export const activityPlaningParticipantsModalsSelectors = {
  addParticipantsModal,
};
