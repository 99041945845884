import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { LoadingStatus } from '@/types/loadingStatus';

// interfaces

export interface IGoalCheck {
  checkedAtDate: string;
  nextCheckDate: string;
  note?: string | null;
  checkedBy?: string | null;
  id: number;
  achievementLevel: string;
  nextAction: string;
  canBeDeleted?: boolean;
}

export interface IGoalCheckDetails {
  checkedAtDate: string;
  nextCheckDate: string;
  id: number;
  achievementLevel: {
    value?: string | null;
    id: number;
  };
  note?: string | null;
  nextAction: {
    value?: string | null;
    id: number;
  };
}

export interface IGoalCheckData {
  checkedAtDate: string;
  achievementLevel: number;
  note?: string | null;
  nextAction: number;
  nextCheckDate?: string | null;
}

// payloads

export interface IGoalChecksFetchPayload {
  smartGoalID: number;
}

export interface IGoalCheckDetailsFetchPayload {
  goalCheckID: number;
}

export interface IGoalCheckCreatePayload {
  smartGoalID: number;
  goalCheckData: IGoalCheckData;
}

export interface IGoalCheckUpdatePayload {
  smartGoalID: number;
  goalCheckID: number;
  goalCheckData: IGoalCheckData;
}

export interface IGoalCheckDeletePayload {
  goalCheckID: number;
  smartGoalID: number;
}

export interface INextExpectedDateFetchPayload {
  smartGoalID: number;
  currentCheckDate: string;
}

// state

export interface IEvaluateGoalsState {
  goalChecks: IGoalCheck[];
  selectedGoalCheck: number | null;
  goalCheckDetails: IGoalCheckDetails | null;
  nextExpectedCheckDate: string | null | undefined;
  goalCheckDetailsLock: LoadingStatus;
  createGoalCheckLock: LoadingStatus;
  updateGoalCheckLock: LoadingStatus;
  nextExpectedDateLock: LoadingStatus;
  goalCheckNotification: string | null;
}

const initialState: IEvaluateGoalsState = {
  goalChecks: [],
  selectedGoalCheck: null,
  goalCheckDetails: null,
  nextExpectedCheckDate: null,
  // locks
  goalCheckDetailsLock: LoadingStatus.NEVER,
  createGoalCheckLock: LoadingStatus.NEVER,
  updateGoalCheckLock: LoadingStatus.NEVER,
  nextExpectedDateLock: LoadingStatus.NEVER,
  // notification
  goalCheckNotification: null,
};

export const evaluateGoalsSlice = createSlice({
  name: 'evaluateGoals',
  initialState,
  reducers: {
    // goal checks
    fetchGoalChecks: (state, action: PayloadAction<IGoalChecksFetchPayload>) => ({
      ...state,
      payload: action.payload,
    }),
    setGoalChecks: (state, action: PayloadAction<IGoalCheck[]>) => ({
      ...state,
      goalChecks: action.payload,
    }),
    //selected
    setSelectedGoalCheck: (state, action: PayloadAction<number | null>) => ({
      ...state,
      selectedGoalCheck: action.payload,
    }),
    // create
    createGoalCheck: (state, action: PayloadAction<IGoalCheckCreatePayload>) => ({
      ...state,
      payload: action.payload,
    }),
    setCreateGoalCheckLock: (state, action: PayloadAction<LoadingStatus>) => ({
      ...state,
      createGoalCheckLock: action.payload,
    }),
    // update
    updateGoalCheck: (state, action: PayloadAction<IGoalCheckUpdatePayload>) => ({
      ...state,
      payload: action.payload,
    }),
    setUpdateGoalCheckLock: (state, action: PayloadAction<LoadingStatus>) => ({
      ...state,
      updateGoalCheckLock: action.payload,
    }),
    // delete
    deleteGoalCheck: (state, action: PayloadAction<IGoalCheckDeletePayload>) => ({
      ...state,
      payload: action.payload,
    }),
    // details
    fetchGoalCheckDetails: (state, action: PayloadAction<IGoalCheckDetailsFetchPayload>) => ({
      ...state,
      payload: action.payload,
    }),
    setGoalCheckDetails: (state, action: PayloadAction<IGoalCheckDetails | null>) => ({
      ...state,
      goalCheckDetails: action.payload,
    }),
    setGoalCheckDetailsLock: (state, action: PayloadAction<LoadingStatus>) => ({
      ...state,
      goalCheckDetailsLock: action.payload,
    }),
    // next expected date
    fetchNextExpectedDate: (state, action: PayloadAction<INextExpectedDateFetchPayload>) => ({
      ...state,
      payload: action.payload,
    }),
    setNextExpectedDate: (state, action: PayloadAction<string | null | undefined>) => ({
      ...state,
      nextExpectedCheckDate: action.payload,
    }),
    setNextExpectedDateLock: (state, action: PayloadAction<LoadingStatus>) => ({
      ...state,
      nextExpectedDateLock: action.payload,
    }),
    // error handling
    setGoalCheckNotification: (state, action: PayloadAction<string | null>) => ({
      ...state,
      goalCheckNotification: action.payload,
    }),
  },
});

export const evaluateGoalsReducer = evaluateGoalsSlice.reducer;
export const evaluateGoalsActions = evaluateGoalsSlice.actions;
