import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Button } from '@/components/Button/Button';
import {
  GoalsWishes,
  IGoalsWishesFormValues,
} from '@/components/functions/FundingCycle/GoalsWishes/GoalsWishes';
import { Sidebar } from '@/components/Overlays/Sidebar/Sidebar';
import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';
import { participationPlanActions } from '@/core/redux/slices/functions/fundingCycle/participationPlan/participationPlanSlice';
import { participationPlanModalActions } from '@/core/redux/slices/modalsSlice/functions/fundingCycle/participationPlan/participationPlanModalSlice';
import { participationPlanModalsSelectors } from '@/core/redux/slices/modalsSlice/functions/fundingCycle/participationPlan/selectors';

const ModalContent: React.FC = () => {
  const dispatch = useAppDispatch();
  const { t: smartGoalModalTranslations } = useTranslation('displaySmartGoalModal');

  const form = useForm<IGoalsWishesFormValues>();

  // selectors
  const { payload } = useAppSelector(participationPlanModalsSelectors.goalsWishesModal);

  // handle exit
  const handleExit = () => {
    dispatch(participationPlanModalActions.closeGoalsWishesnModal());
  };

  // handle submit

  const onSubmit = (data: IGoalsWishesFormValues) => {
    if (!payload || !payload.participationPlanID) {
      return;
    }

    const goalsWishesData = Object.keys(data).map((key) => ({
      goalsWishesID: Number(key),
      explanationDescription: data[key].explanationDescription,
      methodID: data[key].methodID,
    }));

    dispatch(
      participationPlanActions.updateGoalsWishes({
        goalsWishesData,
        participationPlanID: payload.participationPlanID,
      })
    );

    handleExit();
  };

  const renderFooter = (
    <div className='flex gap-4'>
      <Button type='submit' buttonVariant={'Default'} onClick={form.handleSubmit(onSubmit)}>
        {smartGoalModalTranslations('buttons.submit.label')}
      </Button>
      <Button type='button' buttonVariant='Secondary' onClick={handleExit}>
        {smartGoalModalTranslations('buttons.close.label')}
      </Button>
    </div>
  );

  return (
    <Sidebar onExit={handleExit} className='w-[1000px]' footer={renderFooter} position={'right'}>
      <FormProvider {...form}>
        <GoalsWishes participationPlanID={payload?.participationPlanID} />
      </FormProvider>
    </Sidebar>
  );
};

export const GoalsWishesModal: React.FC = () => {
  const { isRender: isOpened } = useAppSelector(participationPlanModalsSelectors.goalsWishesModal);

  if (isOpened) {
    return <ModalContent />;
  }

  return null;
};
