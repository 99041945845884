import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface IParticipationPlanModalPayload {
  personID: number;
  participationPlanID: number;
}

export interface IModal {
  isRender: boolean;
  payload: IParticipationPlanModalPayload | null;
}

export interface IParticipationPlanModalState {
  displayParticipationPlanModal: IModal;
  goalsWishesModal: IModal;
}

const initialState: IParticipationPlanModalState = {
  displayParticipationPlanModal: {
    isRender: false,
    payload: null,
  },
  goalsWishesModal: {
    isRender: false,
    payload: null,
  },
};

export const participationPlanModalSlice = createSlice({
  name: 'participationPlanModal',
  initialState,
  reducers: {
    // participation plan details modal
    openDisplayParticipationPlannModal: (
      state,
      action: PayloadAction<IParticipationPlanModalPayload>
    ) => ({
      ...state,
      displayParticipationPlanModal: {
        isRender: true,
        payload: action.payload,
      },
    }),
    closeisplayParticipationPlannModal: (state) => ({
      ...state,
      displayParticipationPlanModal: {
        isRender: false,
        payload: null,
      },
    }),
    // goals / wishes
    openGoalsWishesnModal: (state, action: PayloadAction<IParticipationPlanModalPayload>) => ({
      ...state,
      goalsWishesModal: {
        isRender: true,
        payload: action.payload,
      },
    }),
    closeGoalsWishesnModal: (state) => ({
      ...state,
      goalsWishesModal: {
        isRender: false,
        payload: null,
      },
    }),
  },
});

export const participationPlanModalReducer = participationPlanModalSlice.reducer;
export const participationPlanModalActions = participationPlanModalSlice.actions;
