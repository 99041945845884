import React from 'react';

import { AddParticipantsListFilters } from '@/components/Modals/Modals/functions/activityPlaningModals/participantsListModals/AddParticipantsModal/AddParticipantsList/AddParticipantsListFilters';
import { AddParticipantsListHeader } from '@/components/Modals/Modals/functions/activityPlaningModals/participantsListModals/AddParticipantsModal/AddParticipantsList/AddParticipantsListHeader';
import { AddParticipantsListItem } from '@/components/Modals/Modals/functions/activityPlaningModals/participantsListModals/AddParticipantsModal/AddParticipantsList/AddParticipantsListItem';
import { useAppSelector } from '@/core/redux/hooks';
import { IActivityPlanningParticipantsList } from '@/core/redux/slices/modalsSlice/functions/activityPlanning/activityPlanningParticipants/slice';
import { parametersSelectors } from '@/core/redux/slices/parameters/selectors';

interface IAddParticipantsList {
  participantsList: IActivityPlanningParticipantsList | null;
  hasPlaces: boolean;
}

export const AddParticipantsList: React.FC<IAddParticipantsList> = ({
  participantsList,
  hasPlaces,
}) => {
  const oddColor = useAppSelector(parametersSelectors.selfAssessmentOddBackgroundColor);
  const evenColor = useAppSelector(parametersSelectors.selfAssessmentEvenBackgroundColor);

  return (
    <div className={'h-full flex flex-col gap-3'}>
      <AddParticipantsListFilters />
      <AddParticipantsListHeader />
      <div className={'h-full overflow-y-auto'}>
        {participantsList?.participants &&
          participantsList.participants.map((participant, index) => (
            <AddParticipantsListItem
              {...participant}
              key={participant.rowID}
              bgColor={(index + 1) % 2 == 0 ? evenColor : oddColor}
              hasPlaces={hasPlaces}
            />
          ))}
      </div>
    </div>
  );
};
