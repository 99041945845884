import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@/components/Button/Button';
import { VocationalTrainingAreaDocumentDownloadButton } from '@/components/Modals/Modals/functions/vocationalTrainingAreaModals/VocationalTrainingAreaDocumentsModal/VocationalTrainingAreaDocumentsModalContent/VocationalTrainingAreaDocumentDownloadButton';
import { Sidebar } from '@/components/Overlays/Sidebar/Sidebar';
import { Skeleton } from '@/components/Skeleton/Skeleton';
import { ITableColumn, Table } from '@/components/Table/Table';
import { Typography } from '@/components/Typography/Typography';
import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';
import { vocationalTrainingAreaModalSelectors } from '@/core/redux/slices/modalsSlice/functions/vocationalTrainingArea/selectors';
import { vocationalTrainingAreaModalActions } from '@/core/redux/slices/modalsSlice/functions/vocationalTrainingArea/slice';
import { LoadingStatus } from '@/types/loadingStatus';

export const VocationalTrainingAreaDocumentsModalContent: React.FC = () => {
  const { t: vocationalTrainingAreaDocumentsModalTranslations } = useTranslation(
    'vocationalTrainingAreaDocuments'
  );

  const { payload, vocationalTrainingAreaDocuments, vocationalTrainingAreaDocumentsLock } =
    useAppSelector(vocationalTrainingAreaModalSelectors.vocationalTrainingAreaDocumentsModal);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!payload) {
      return;
    }

    dispatch(
      vocationalTrainingAreaModalActions.fetchVocationalTrainingAreaDocuments({
        measureID: payload.measureID,
      })
    );
  }, []);

  const handleExit = () => {
    dispatch(vocationalTrainingAreaModalActions.closeVocationalTrainingAreaDocuments());
  };

  const tableColumns: ITableColumn[] = [
    {
      header: vocationalTrainingAreaDocumentsModalTranslations('table.header.name.label'),
      accessor: 'name',
      className: 'w-full  max-w-[50vw] whitespace-normal',
    },
    {
      header: vocationalTrainingAreaDocumentsModalTranslations('table.header.download.label'),
      accessor: '',
      template: (value, rowData) => (
        <VocationalTrainingAreaDocumentDownloadButton documentID={rowData.id} />
      ),
      className: 'flex justify-center',
    },
  ];

  const renderFooter = () => (
    <Button onClick={handleExit} className={'self-end'}>
      {vocationalTrainingAreaDocumentsModalTranslations('buttons.close.label')}
    </Button>
  );

  return (
    <Sidebar onExit={handleExit} position={'right'} footer={renderFooter()}>
      <div className={'flex flex-col gap-5 min-w-[30vw] items-stretch p-5 pb-0'}>
        <Typography type={'H3'}>
          {vocationalTrainingAreaDocumentsModalTranslations('title.label')}
        </Typography>
        <Skeleton trigger={vocationalTrainingAreaDocumentsLock === LoadingStatus.LOADING}>
          {vocationalTrainingAreaDocuments && (
            <Table
              columns={tableColumns}
              data={vocationalTrainingAreaDocuments}
              className={'max-h-[calc(100vh_-_150px)] h-full'}
              singleSelect={true}
            />
          )}
        </Skeleton>
      </div>
    </Sidebar>
  );
};
