import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { LoadingStatus } from '@/types/loadingStatus';

// interfaces

export interface ICheckpoint {
  id: number;
  name?: string | null;
  isCompleted?: boolean | null;
  completeDate?: string | null;
  note?: string | null;
  hasCorrespondingDocument: boolean;
  hasAidDocument: boolean;
}

export interface IChecklist {
  id: number;
  name?: string | null;
  checkpoints: ICheckpoint[];
}

export interface IGroup {
  id: number;
  name: string;
}

// payloads

export interface IChecklistsFetchPayload {
  personID: number;
  checklistGroupID: number;
}

export interface IMarkCompletePayload {
  checkpointID: number;
}

export interface IMarkIncompletePayload {
  checkpointID: number;
}

// state

export interface IChecklistState {
  checklists: IChecklist[];
  groups: IGroup[];
  markCheckpointLock: LoadingStatus;
}

const initialState: IChecklistState = {
  groups: [],
  checklists: [],
  markCheckpointLock: LoadingStatus.NEVER,
};

export const checklistSlice = createSlice({
  name: 'checklist',
  initialState,
  reducers: {
    // groups
    fetchGroups: () => {},
    setGroups: (state, action: PayloadAction<IGroup[]>) => ({
      ...state,
      groups: action.payload,
    }),
    // checklists
    fetchChecklists: (state, action: PayloadAction<IChecklistsFetchPayload>) => ({
      ...state,
      payload: action.payload,
    }),
    setChecklists: (state, action: PayloadAction<IChecklist[]>) => ({
      ...state,
      checklists: action.payload,
    }),
    // actions
    markComplete: (state, action: PayloadAction<IMarkCompletePayload>) => ({
      ...state,
      payload: action.payload,
    }),
    markIncomplete: (state, action: PayloadAction<IMarkIncompletePayload>) => ({
      ...state,
      payload: action.payload,
    }),
    setMarkCheckpointLock: (state, action: PayloadAction<LoadingStatus>) => ({
      ...state,
      markCheckpointLock: action.payload,
    }),
    // documents
    fillCorrespondingDocument: (state, action: PayloadAction<number>) => ({
      ...state,
      payload: action.payload,
    }),
    fetchAidDocument: (state, action: PayloadAction<number>) => ({
      ...state,
      payload: action.payload,
    }),
  },
});

export const checklistReducer = checklistSlice.reducer;
export const checklistActions = checklistSlice.actions;
