import React, { useState } from 'react';
import { motion } from 'framer-motion';

import { Button } from '@/components/Button/Button';
import { VocationalTrainingAreaGroupedListItem } from '@/components/functions/VocationalTrainingArea/VocationalTrainingAreaTable/VocationalTrainingAreaGroupedList/VocationalTrainingAreaGroupedListItem/VocationalTrainingAreaGroupedListItem';
import { CircleFilledIcon } from '@/components/Icons/CircleFilledIcon';
import { CircleOutlineIcon } from '@/components/Icons/CircleOutlineIcon';
import { Typography } from '@/components/Typography/Typography';
import { useAppSelector } from '@/core/redux/hooks';
import { vocationalTrainingAreaSelectors } from '@/core/redux/slices/functions/vocationalTrainingArea/selector';
import { IVocationalTrainingAreaGroup } from '@/core/redux/slices/functions/vocationalTrainingArea/slice';
import { parametersSelectors } from '@/core/redux/slices/parameters/selectors';
import { twMerge } from '@/core/utils/tailwindUtil';
import { colorTypes } from '@/styles/types';

interface IVocationalTrainingAreaGroupedListGroup extends IVocationalTrainingAreaGroup {
  layer: number;
}

export const DEFAULT_LAYER_PADDING = 40;

export const VocationalTrainingAreaGroupedListGroup: React.FC<IVocationalTrainingAreaGroupedListGroup> =
  ({ items, childGroup, layer, name }) => {
    const [expanded, setExpanded] = useState<boolean>(false);

    const selectedTrainingAreaItems = useAppSelector(
      vocationalTrainingAreaSelectors.selectedVocationalTrainingAreaItems
    );
    const oddColor = useAppSelector(parametersSelectors.vocationalTrainingAreaItemOddColor);
    const evenColor = useAppSelector(parametersSelectors.vocationalTrainingAreaItemEvenColor);

    const handleExpand = () => {
      setExpanded((prev) => !prev);
    };

    return (
      <div className={'col-span-12 grid grid-cols-12'}>
        <Button
          buttonVariant={'Transparent'}
          buttonStyle={'Text'}
          onClick={handleExpand}
          className={`col-span-12 hover:bg-${colorTypes.Hover}`}
        >
          <Typography
            style={{ paddingLeft: DEFAULT_LAYER_PADDING * layer }}
            type={'16-M'}
            className={'flex flex-row items-center gap-2 py-1'}
          >
            {expanded ? (
              <CircleFilledIcon className={`text-${colorTypes.Tiffany} w-[10px]`} />
            ) : (
              <CircleOutlineIcon className={`text-${colorTypes.Tiffany} w-[10px]`} />
            )}
            {name}
          </Typography>
        </Button>
        <motion.div
          className={twMerge(`col-span-12 grid grid-cols-12 w-full overflow-hidden`)}
          initial={expanded ? { height: 'auto', opacity: 100 } : { height: 0, opacity: 0 }}
          animate={expanded ? { height: 'auto', opacity: 100 } : { height: 0, opacity: 0 }}
        >
          <div className={'col-span-12 grid grid-cols-12'}>
            {childGroup &&
              childGroup.map((child) => (
                <VocationalTrainingAreaGroupedListGroup
                  layer={layer + 1}
                  {...child}
                  key={child.id}
                />
              ))}
            {items &&
              items.map((item, index) => {
                const isSelected = Boolean(
                  selectedTrainingAreaItems.find((_item) => _item.id === item.id)
                );

                return (
                  <VocationalTrainingAreaGroupedListItem
                    {...item}
                    key={item.id}
                    layer={layer}
                    isSelected={isSelected}
                    bgColor={(index + 1) % 2 == 0 ? evenColor : oddColor}
                  />
                );
              })}
          </div>
        </motion.div>
      </div>
    );
  };
