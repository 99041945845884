import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IDevelopmentReportReviewSupportForm } from '@/components/functions/DevelopmentReport/DevelopmentReportForms/ReviewSupport/helper/goalsAndActivityForm';

export interface IDevelopmentReportReviewSupportFetchPayload {
  developmentReportID: number;
}

export interface IDevelopmentReportReviewSupportUpdatePayload {
  developmentReportID: number;
  personID: number;
  formValues: IDevelopmentReportReviewSupportForm;
}

export interface IDevelopmentReportReviewSupportState {
  developmentReportReviewSupport: IDevelopmentReportReviewSupportForm | null;
  fetchPayload: IDevelopmentReportReviewSupportFetchPayload | null;
  updatePayload: IDevelopmentReportReviewSupportUpdatePayload | null;
}

const initialState: IDevelopmentReportReviewSupportState = {
  developmentReportReviewSupport: null,
  fetchPayload: null,
  updatePayload: null,
};

const developmentReportReviewSupportSlice = createSlice({
  name: 'developmentReportReviewSupport',
  initialState,
  reducers: {
    fetchDevelopmentReportReviewSupport: (
      state,
      action: PayloadAction<IDevelopmentReportReviewSupportFetchPayload>
    ) => ({
      ...state,
      fetchPayload: action.payload,
    }),
    setDevelopmentReportReviewSupport: (
      state,
      action: PayloadAction<IDevelopmentReportReviewSupportForm | null>
    ) => ({
      ...state,
      developmentReportReviewSupport: action.payload,
    }),
    updateDevelopmentReportReviewSupport: (
      state,
      action: PayloadAction<IDevelopmentReportReviewSupportUpdatePayload>
    ) => ({
      ...state,
      updatePayload: action.payload,
    }),
  },
});

export const developmentReportReviewSupportReducer = developmentReportReviewSupportSlice.reducer;
export const developmentReportReviewSupportActions = developmentReportReviewSupportSlice.actions;
