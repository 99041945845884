import { ObjectSchema } from 'yup';

import { ISmartGoalFormFields } from '@/components/forms/SmartGoal/SmartGoal';
import { yup } from '@/core/utils/commonUtils';

export const SmartGoalFormResolver: ObjectSchema<ISmartGoalFormFields> = yup
  .object()
  .shape({
    name: yup.string().trim().required(),
    agreedOnDate: yup.string().required(),
    firstReviewDate:  yup.string().required(),
  });
