import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';

import { appointmentDetailsSchemaBuilder } from '@/components/Modals/Modals/functions/activityPlaningModals/appointmentDetailsModals/AppointmentDetailsModal/AppointmentDetails/hooks/appointmentDetailsValidator';
import { useSelectedPerson } from '@/core/hooks/useSelectedPerson';
import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';
import { activityPlaningAppointmentDetailsSelectors } from '@/core/redux/slices/functions/activityPlanning/appointmentDetails/selectors';
import {
  activityPlanningAppointmentDetailsActions,
  IAppointmentDetails,
  IAppointmentMeasureAid,
} from '@/core/redux/slices/functions/activityPlanning/appointmentDetails/slice';
import { IAppointmentDetailsModalPayload } from '@/core/redux/slices/modalsSlice/functions/activityPlanning/activityPlaningAppointmentDetails/slice';

export interface IAppointmentDetailsForm {
  appointmentOnlyPlanned?: boolean | null;
  startDate?: string | null;
  endDate?: string | null;
  startTime?: string | null;
  endTime?: string | null;
  measureTitle?: string | null;
  locationID?: number | null;
  locationName?: string | null;
  appointmentTypeID?: number | null;
  implementationAids?: Record<string, boolean> | null;
  appointmentInfo?: string | null;
  places?: number | null;
  responsible?: string | null;
  duration?: number | null;
}

const mapSelectedImplementationAids = (
  aids?: IAppointmentMeasureAid[] | null
): Record<string, boolean> => {
  if (!aids) {
    return {};
  }

  return aids.reduce<Record<string, boolean>>(
    (accum, aid) => ({
      ...accum,
      [aid.id]: true,
    }),
    {}
  );
};

const mapAppointmentDetailsToForm = (
  appointmentDetails: IAppointmentDetails
): IAppointmentDetailsForm => {
  return {
    appointmentInfo: appointmentDetails.appointmentInfo,
    appointmentTypeID: appointmentDetails.appointmentType?.id,
    endDate: appointmentDetails.appointmentEndDate,
    endTime: appointmentDetails.appointmentEndTime,
    places: appointmentDetails.places,
    measureTitle: appointmentDetails.measureTitle,
    responsible: appointmentDetails.responsibleUser,
    startDate: appointmentDetails.appointmentStartDate,
    startTime: appointmentDetails.appointmentStartTime,
    appointmentOnlyPlanned: false, // todo
    locationID: -1,
    locationName: appointmentDetails.location,
    implementationAids: mapSelectedImplementationAids(appointmentDetails.implementationAids),
    duration: appointmentDetails.hours ? parseInt(appointmentDetails.hours) : undefined,
  };
};

export function useAppointmentDetailsForm(payload: IAppointmentDetailsModalPayload | null) {
  const { t: appointmentDetailTranslations } = useTranslation('activityPlanning', {
    keyPrefix: 'appointmentDetails',
  });

  const { selectedPersonID } = useSelectedPerson();

  const dispatch = useAppDispatch();

  const appointmentDetails = useAppSelector(
    activityPlaningAppointmentDetailsSelectors.appointmentDetails
  );

  const formDefaultValues = {
    implementationAids: {},
    startTime: null,
    startDate: null,
    responsible: null,
    places: null,
    endTime: null,
    endDate: null,
    appointmentTypeID: null,
    appointmentOnlyPlanned: null,
    appointmentInfo: null,
    locationID: payload?.personLocation ? payload.personLocation.id : undefined,
    measureTitle: payload?.selectedMeasureName ? payload.selectedMeasureName : undefined,
  };

  const form = useForm<IAppointmentDetailsForm>({
    resolver: yupResolver(appointmentDetailsSchemaBuilder(appointmentDetailTranslations)),
    defaultValues: payload?.appointmentDetailsContext === 'create' ? formDefaultValues : undefined,
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
  });

  const isStartEndSameDate = form.getValues('startDate') === form.getValues('endDate');

  useEffect(() => {
    if (!selectedPersonID || !payload || !payload.appointmentID) {
      dispatch(activityPlanningAppointmentDetailsActions.setAppointmentDetails(null));
      return;
    }

    dispatch(
      activityPlanningAppointmentDetailsActions.fetchAppointmentDetails({
        appointmentID: payload.appointmentID,
        personID: selectedPersonID,
        measureID: payload.measureID,
      })
    );
  }, [selectedPersonID, payload]);

  useEffect(() => {
    dispatch(activityPlanningAppointmentDetailsActions.fetchAppointmentTypeValues());

    return () => {
      dispatch(activityPlanningAppointmentDetailsActions.setAppointmentDetails(null));
    };
  }, []);

  useEffect(() => {
    if (!appointmentDetails || payload?.appointmentDetailsContext === 'create') {
      return;
    }

    const formValues = mapAppointmentDetailsToForm(appointmentDetails);
    form.reset(formValues);
  }, [appointmentDetails, payload]);

  useEffect(() => {
    if (!appointmentDetails || payload?.appointmentDetailsContext === 'create') {
      return;
    }

    const formValues = mapAppointmentDetailsToForm(appointmentDetails);

    form.setValue('duration', formValues.duration, { shouldDirty: false });
    form.setValue('startTime', formValues.startTime, { shouldDirty: false });
    form.setValue('endTime', formValues.endTime, { shouldDirty: false });
  }, [isStartEndSameDate]);

  const handleSubmit = (data: IAppointmentDetailsForm) => {
    if (!selectedPersonID || !payload) {
      return;
    }

    if (payload.appointmentDetailsContext === 'create') {
      dispatch(
        activityPlanningAppointmentDetailsActions.createAppointment({
          formValues: data,
          personID: selectedPersonID,
          measureID: payload.measureID,
        })
      );

      return;
    }

    if (!payload.appointmentID) {
      return;
    }

    dispatch(
      activityPlanningAppointmentDetailsActions.updateAppointment({
        formValues: data,
        personID: selectedPersonID,
        measureID: payload.measureID,
        appointmentID: payload.appointmentID,
      })
    );
    return;
  };

  return {
    form,
    appointmentDetails,
    handleSubmit,
    isStartEndSameDate,
  };
}
