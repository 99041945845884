import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { ITableColumn, Table } from '@/components/Table/Table';
import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';
import { evaluateGoalsActions } from '@/core/redux/slices/functions/fundingCycle/evaluateGoals/evaluateGoalsSlice';
import { evaluateGoalsSelectors } from '@/core/redux/slices/functions/fundingCycle/evaluateGoals/selectors';
import { smartGoalsSelectors } from '@/core/redux/slices/functions/fundingCycle/smartGoals/selectors';

export const GoalCheckSection: React.FC = () => {
  const { t: evaluateGoalsTranslations } = useTranslation('evaluateGoals');

  const dispatch = useAppDispatch();

  const data = useAppSelector(evaluateGoalsSelectors.goalChecks);
  const selectedSmartGoalID = useAppSelector(smartGoalsSelectors.selectedSmartGoal);

  const columns: ITableColumn[] = [
    {
      header: evaluateGoalsTranslations('columns.checkedAtDate.label'),
      accessor: 'checkedAtDate',
    },
    {
      header: evaluateGoalsTranslations('columns.checkedBy.label'),
      accessor: 'checkedBy',
    },
    {
      header: evaluateGoalsTranslations('columns.achievementLevel.label'),
      accessor: 'achievementLevel',
    },
    {
      header: evaluateGoalsTranslations('columns.note.label'),
      accessor: 'note',
    },
    {
      header: evaluateGoalsTranslations('columns.nextAction.label'),
      accessor: 'nextAction',
    },
    {
      header: evaluateGoalsTranslations('columns.nextCheckDate.label'),
      accessor: 'nextCheckDate',
    },
  ];

  useEffect(() => {
    if (!selectedSmartGoalID) {
      return;
    }

    dispatch(
      evaluateGoalsActions.fetchGoalChecks({
        smartGoalID: selectedSmartGoalID,
      })
    );
  }, [selectedSmartGoalID]);

  return (
    <div>
      <Table
        columns={columns}
        data={data}
        singleSelect
        onSingleSelectChange={(selected) =>
          dispatch(evaluateGoalsActions.setSelectedGoalCheck(selected))
        }
      />
    </div>
  );
};
