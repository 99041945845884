import { PayloadAction } from '@reduxjs/toolkit';
import { NormalizeOAS, OASOutput, OASRequestParams } from 'fets';
import { call, debounce, put, takeLatest } from 'redux-saga/effects';

import { authAdd, restCall } from '@/core/clients/rest';
import { activityPlanningAppointmentParticipantsListActions } from '@/core/redux/slices/functions/activityPlanning/appointmentParticipantsList/slice';
import {
  activityPlaningParticipantsModalsActions,
  IActivityPlanningAddParticipantsFetchPayload,
  IActivityPlanningAddParticipantsPayload,
  IActivityPlanningParticipant,
  IActivityPlanningParticipantsList,
} from '@/core/redux/slices/modalsSlice/functions/activityPlanning/activityPlanningParticipants/slice';
import { toClientDateInput } from '@/core/utils/dateTimeUtil';
import oas from '@/services/rest/base/openapi';
import { LoadingStatus } from '@/types/loadingStatus';

type ParticipantsListRequest = OASRequestParams<
  NormalizeOAS<typeof oas>,
  '/activity_planning/measure/appointment/participant/available',
  'get'
>;

type AddParticipantsRequest = OASRequestParams<
  NormalizeOAS<typeof oas>,
  '/activity_planning/measure/appointment/participant',
  'post'
>;

type ParticipantsListResponse = OASOutput<
  NormalizeOAS<typeof oas>,
  '/activity_planning/measure/appointment/participant/available',
  'get',
  '200'
>;

function* fetchActivityPlanningParticipantsList(
  action: PayloadAction<IActivityPlanningAddParticipantsFetchPayload>
): Generator<any, void, any> {
  const {
    measureID,
    appointmentID,
    name,
    departmentID,
    isMeasureAppointmentCompleted,
    isMeasureAssigned,
    isMeasureAssignedHasAppointment,
  } = action.payload;

  yield put(
    activityPlaningParticipantsModalsActions.setParticipantsListLock(LoadingStatus.LOADING)
  );

  try {
    const request: ParticipantsListRequest = {
      query: {
        measure_id: measureID,
        appointment_id: appointmentID,
        department_id: departmentID,
        person_search: name,
        is_measure_appointment_completed: isMeasureAppointmentCompleted,
        is_measure_assigned: isMeasureAssigned,
        is_measure_assigned_has_appointment: isMeasureAssignedHasAppointment,
      },
      ...authAdd(),
    };

    const response: ParticipantsListResponse = yield call(
      restCall,
      '/activity_planning/measure/appointment/participant/available',
      'get',
      request
    );

    const participantsList: IActivityPlanningParticipantsList = {
      participants: response.participants.map<IActivityPlanningParticipant>((participant) => ({
        rowID: participant.row_number,
        id: participant.id,
        name: participant.name,
        surname: participant.surname,
        department: participant.department,
        bbbInInterruption: participant.bbb_in_interruption,
        inMeasure: participant.in_measure,
        appointmentDate: toClientDateInput(participant.appointment_date),
      })),
    };

    yield put(activityPlaningParticipantsModalsActions.setParticipantsList(participantsList));

    yield put(
      activityPlaningParticipantsModalsActions.setParticipantsListLock(LoadingStatus.LOADED)
    );
  } catch (error) {
    console.log('Error on activity planning participants list fetching');
    yield put(
      activityPlaningParticipantsModalsActions.setParticipantsListLock(LoadingStatus.ERROR)
    );
  }
}

function* addParticipants(action: PayloadAction<IActivityPlanningAddParticipantsPayload>) {
  const { participants, appointmentID, measureID } = action.payload;

  try {
    const request: AddParticipantsRequest = {
      json: participants?.map<AddParticipantsRequest['json'][0]>((participant) => ({
        person_id: participant.personID,
        measure_id: participant.measureID,
        appointment_id: participant.appointmentID,
      })),
      ...authAdd(),
    };

    yield call(restCall, '/activity_planning/measure/appointment/participant', 'post', request);

    yield put(
      activityPlanningAppointmentParticipantsListActions.fetchAppointmentParticipantsList({
        measureID: measureID,
        appointmentID: appointmentID,
      })
    );

    yield put(activityPlaningParticipantsModalsActions.closeAddParticipantsModal());
  } catch (error) {
    console.log('Error on participants adding', error);
  }
}

export const activityPlaningParticipantsModalsSagas = [
  debounce(
    500,
    activityPlaningParticipantsModalsActions.fetchParticipantsList,
    fetchActivityPlanningParticipantsList
  ),
  takeLatest(activityPlaningParticipantsModalsActions.addParticipants, addParticipants),
];
