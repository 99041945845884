import { PayloadAction } from '@reduxjs/toolkit';
import type { NormalizeOAS, OASOutput, OASRequestParams } from 'fets';
import { call, put, takeLatest } from 'redux-saga/effects';

import { authAdd, restCall } from '@/core/clients/rest';
import { WorkingTimeType } from '@/core/enums/functions/workingTimeMobility/employmentTypesEnum';
import { WorkingTimePartTimeReason } from '@/core/enums/functions/workingTimeMobility/partTimeReasonsEnum';
import {
  IWorkingDay,
  IWorkingTime,
  IWorkingTimeFetchPayload,
  IWorkingTimeUpdatePayload,
  workingTimeActions,
} from '@/core/redux/slices/functions/workingTimeMobility/workingTime/slice';
import { toBackendDate, toClientDateInput, validateTimeFormat } from '@/core/utils/dateTimeUtil';
import { isEnumValue } from '@/core/utils/enumUtils';
import type oas from '@/services/rest/base/openapi';
import { LoadingStatus } from '@/types/loadingStatus';

type WorkingTimeResponse = OASOutput<NormalizeOAS<typeof oas>, '/working_hours', 'get', '200'>;
type WorkingTimeRequest = OASRequestParams<NormalizeOAS<typeof oas>, '/working_hours', 'get'>;

type WorkingTimeUpdateRequest = OASRequestParams<
  NormalizeOAS<typeof oas>,
  '/working_hours',
  'post'
>;

export const mapWorkingDaysResponse = (
  workingDays: WorkingTimeResponse['working_hours']['working_week']['days']
): Record<string, IWorkingDay> => {
  const daysKeys = Object.keys(workingDays);

  return daysKeys.reduce<Record<string, IWorkingDay>>((accum, daysKey) => {
    return {
      ...accum,
      [daysKey]: {
        fromDateStamp: workingDays[daysKey].from_timestamp?.slice(0, 5),
        toDateStamp: workingDays[daysKey].to_timestamp?.slice(0, 5),
        pauseDuration: workingDays[daysKey].pause_duration,
      },
    };
  }, {});
};

const mapWorkingTimeResponse = (response: WorkingTimeResponse): IWorkingTime => {
  const { working_hours } = response;

  return {
    appliesFromTimeStamp: toClientDateInput(working_hours.applies_from_timestamp) ?? '',
    otherPartTimeReasonText: working_hours.other_part_time_reason_text,
    workingTimeType: isEnumValue(WorkingTimeType, working_hours.working_time_type)
      ? working_hours.working_time_type
      : WorkingTimeType.Default,
    partTimeReasonType: isEnumValue(WorkingTimePartTimeReason, working_hours.part_time_reason)
      ? working_hours.part_time_reason
      : null,
    workingWeek: {
      sumGross: working_hours.working_week.sum_brutto,
      sumNet: working_hours.working_week.sum_netto,
      sumPauseDuration: working_hours.working_week.sum_pauses_durations,
      workingDaysCount: working_hours.working_week.working_days_count,
      workingDays: mapWorkingDaysResponse(working_hours.working_week.days),
    },
  };
};

export const mapWorkingTimeDaysRequest = (
  workingDays?: Record<string, IWorkingDay | null> | null
): WorkingTimeUpdateRequest['json']['days'] => {
  if (!workingDays) {
    return [];
  }

  const daysKeys = Object.keys(workingDays);

  return daysKeys.reduce<WorkingTimeUpdateRequest['json']['days']>((accum, dayKey) => {
    const newDay = {
      from_timestamp: validateTimeFormat(workingDays[dayKey]?.fromDateStamp)
        ? workingDays[dayKey]?.fromDateStamp
        : null,
      to_timestamp: validateTimeFormat(workingDays[dayKey]?.toDateStamp)
        ? workingDays[dayKey]?.toDateStamp
        : null,
      pause_duration: workingDays[dayKey]?.pauseDuration ?? null,
      id: Number(dayKey),
    };

    accum.push(newDay);

    return accum;
  }, []);
};

const mapWorkingTimeRequest = (payload: IWorkingTimeUpdatePayload): WorkingTimeUpdateRequest => {
  const { personID, workingTime } = payload;

  return {
    json: {
      person_id: personID,
      applies_from_timestamp: toBackendDate(workingTime.appliesFromTimeStamp) ?? '',
      working_time_type:
        workingTime.workingTimeType === WorkingTimeType.Default
          ? WorkingTimeType.PartTime
          : workingTime.workingTimeType,
      part_time_reason: workingTime.partTimeReasonType,
      other_part_time_reason_text: workingTime.otherPartTimeReasonText,
      days: mapWorkingTimeDaysRequest(workingTime.workingWeek.workingDays),
    },
    ...authAdd(),
  };
};

function* fetchWorkingTime(
  action: PayloadAction<IWorkingTimeFetchPayload>
): Generator<any, void, WorkingTimeResponse> {
  const { personID } = action.payload;

  try {
    yield put(workingTimeActions.setWorkingTimeLock(LoadingStatus.LOADING));

    const request: WorkingTimeRequest = {
      query: {
        person_id: personID,
      },
      ...authAdd(),
    };

    const response = yield call(restCall, '/working_hours', 'get', request);
    const workingTime = mapWorkingTimeResponse(response);

    yield put(workingTimeActions.setWorkingTimeDaysList(response.working_days));
    yield put(workingTimeActions.setWorkingTime(workingTime));

    yield put(workingTimeActions.setWorkingTimeLock(LoadingStatus.LOADED));
  } catch (error) {
    console.log('Error on fetching working time', error);
    yield put(workingTimeActions.setWorkingTimeLock(LoadingStatus.ERROR));
  }
}

function* updateWorkingTime(action: PayloadAction<IWorkingTimeUpdatePayload>) {
  try {
    yield put(workingTimeActions.setUpdateWorkingTimeLock(LoadingStatus.LOADING));

    const request: WorkingTimeUpdateRequest = mapWorkingTimeRequest(action.payload);

    yield call(restCall, '/working_hours', 'post', request);

    yield put(workingTimeActions.setUpdateWorkingTimeLock(LoadingStatus.LOADED));
  } catch (error) {
    console.log('Error on updating working time', error);
    yield put(workingTimeActions.setUpdateWorkingTimeLock(LoadingStatus.ERROR));
  }
}

export const workingTimeSagas = [
  takeLatest(workingTimeActions.fetchWorkingTime, fetchWorkingTime),
  takeLatest(workingTimeActions.updateWorkingTime, updateWorkingTime),
];
