import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { FetchContext } from '@/components/views/person/PersonPage/Context/PersonPageStoreContext';
import { IDepartment } from '@/types/department';
import { LoadingStatus } from '@/types/loadingStatus';
import { IPerson } from '@/types/person';
import { SortingBy } from '@/types/sortingBy';

interface IDepartmentState {
  value: boolean;
  locations: Record<string, boolean>;
}

export interface IPersonPageState {
  departmentsStatus: LoadingStatus;
  personsStatus: LoadingStatus;
  departments: IDepartment[];
  locationsCheckState: Record<string, IDepartmentState>;
  selectedLocations: string[];
  persons: Record<string, IPerson> | null;
  selectedPersons: IPerson[];
}

export interface IPersonsPayload {
  limit: number;
  search?: string;
  departments?: string[];
  persons?: number[];
  sortBy?: SortingBy;
  context?: FetchContext;
}

const initialState: IPersonPageState = {
  departmentsStatus: LoadingStatus.NEVER,
  personsStatus: LoadingStatus.NEVER,
  departments: [],
  locationsCheckState: {},
  selectedLocations: [],
  persons: null,
  selectedPersons: [],
};

const personPageSlice = createSlice({
  name: 'personPage',
  initialState,
  reducers: {
    fetchPersons: (state, action: PayloadAction<IPersonsPayload>) => ({
      ...state,
      payload: action.payload,
    }),
    setPersonsLock: (state, action: PayloadAction<LoadingStatus>) => ({
      ...state,
      personsStatus: action.payload,
    }),
    // departments
    fetchDepartments: () => {},
    setDepartments: (state, action: PayloadAction<IDepartment[]>) => ({
      ...state,
      departments: action.payload,
    }),
    setDepartmentsLock: (state, action: PayloadAction<LoadingStatus>) => ({
      ...state,
      departmentsStatus: action.payload,
    }),
    // dynamic departments
    fetchDynamicDepartments: () => {},
    setLocationCheckState: (state, action: PayloadAction<Record<string, IDepartmentState>>) => ({
      ...state,
      locationsCheckState: action.payload,
    }),
    setPersons: (state, action: PayloadAction<Record<string, IPerson>>) => {
      return {
        ...state,
        persons: action.payload,
      };
    },
    setSelectedPersons: (state, action: PayloadAction<IPerson[]>) => {
      localStorage.setItem('selectedPersons', JSON.stringify(action.payload));
      return {
        ...state,
        selectedPersons: action.payload,
      };
    },
  },
});

export const personPageReducer = personPageSlice.reducer;
export const personPageActions = personPageSlice.actions;
