import React from 'react';
import { twMerge } from 'tailwind-merge';

import { Button } from '@/components/Button/Button';
import { DEFAULT_LAYER_PADDING } from '@/components/functions/VocationalTrainingArea/VocationalTrainingAreaTable/VocationalTrainingAreaGroupedList/VocationalTrainingAreaGroupedListGroup/VocationalTrainingAreaGroupedListGroup';
import { DocumentViewIcon } from '@/components/Icons/DocumentViewIcon';
import { Typography } from '@/components/Typography/Typography';
import { useAppDispatch } from '@/core/redux/hooks';
import {
  IVocationalTrainingAreaItem,
  vocationalTrainingAreaActions,
} from '@/core/redux/slices/functions/vocationalTrainingArea/slice';
import { vocationalTrainingAreaModalActions } from '@/core/redux/slices/modalsSlice/functions/vocationalTrainingArea/slice';
import { colorTypes } from '@/styles/types';

interface IVocationalTrainingAreaGroupedListItem extends IVocationalTrainingAreaItem {
  layer: number;
  bgColor?: string;
  isSelected?: boolean;
}

export const VocationalTrainingAreaGroupedListItem: React.FC<IVocationalTrainingAreaGroupedListItem> =
  ({ lastActivity, nearestActivity, type, name, id, layer, bgColor, isSelected, hasDocuments }) => {
    const dispatch = useAppDispatch();

    const currentItem = {
      lastActivity,
      nearestActivity,
      type,
      name,
      id,
      hasDocuments,
    };

    const handleOpenDocumentsModal = () => {
      dispatch(
        vocationalTrainingAreaModalActions.openVocationalTrainingAreaDocuments({
          measureID: id,
        })
      );
    };

    const handleSelectTrainingArea = (item: IVocationalTrainingAreaItem) => {
      dispatch(vocationalTrainingAreaActions.setSelectedVocationalTrainingAreaItem(item));
    };

    return (
      <div
        onClick={() => handleSelectTrainingArea(currentItem)}
        className={twMerge(
          `col-span-12 grid grid-cols-12 hover:bg-${colorTypes.HoverLight}`,
          isSelected && '!bg-stroke-light'
        )}
        style={{ backgroundColor: bgColor }}
      >
        <Button
          buttonStyle={'Text'}
          buttonVariant={'Transparent'}
          className={`col-span-12 grid grid-cols-12 py-1 px-[30px]`}
        >
          <div className={'col-span-7 flex flex-row gap-5 justify-between items-center'}>
            <Typography
              className={'whitespace-normal'}
              style={{ paddingLeft: DEFAULT_LAYER_PADDING * (layer + 1) }}
              type={'16-M'}
              color={'Secondary'}
            >
              {name}
            </Typography>
            {hasDocuments && (
              <Button
                buttonVariant={'Icon'}
                buttonStyle={'Icon'}
                onClick={handleOpenDocumentsModal}
                className={`text-${colorTypes.Secondary}`}
              >
                <DocumentViewIcon />
              </Button>
            )}
          </div>
          <Typography className={'col-span-2'} type={'16-M'} color={'Secondary'}>
            {nearestActivity}
          </Typography>
          <Typography className={'col-span-1'} type={'16-M'} color={'Secondary'}>
            {type}
          </Typography>
          <Typography className={'col-span-2'} type={'16-M'} color={'Secondary'}>
            {lastActivity}
          </Typography>
        </Button>
      </div>
    );
  };
