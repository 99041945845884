import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IDevelopmentReportPersonDetailsForm } from '@/components/functions/DevelopmentReport/DevelopmentReportForms/PersonDetails/helper/personDetailsForm';

export interface IDevelopmentReportPersonDetailsFetchPayload {
  developmentReportID: number;
}

export interface IDevelopmentReportPersonDetailsUpdatePayload {
  developmentReportID: number;
  personID: number;
  formValues: IDevelopmentReportPersonDetailsForm;
}

export interface IDevelopmentReportPersonDetailsState {
  developmentReportPersonDetails: IDevelopmentReportPersonDetailsForm | null;
  fetchPayload: IDevelopmentReportPersonDetailsFetchPayload | null;
  updatePayload: IDevelopmentReportPersonDetailsUpdatePayload | null;
}

const initialState: IDevelopmentReportPersonDetailsState = {
  developmentReportPersonDetails: null,
  fetchPayload: null,
  updatePayload: null,
};

const developmentReportPersonDetailsSlice = createSlice({
  name: 'developmentReportPersonDetails',
  initialState,
  reducers: {
    fetchDevelopmentReportPersonDetails: (
      state,
      action: PayloadAction<IDevelopmentReportPersonDetailsFetchPayload>
    ) => ({
      ...state,
      fetchPayload: action.payload,
    }),
    setDevelopmentReportPersonDetails: (
      state,
      action: PayloadAction<IDevelopmentReportPersonDetailsForm | null>
    ) => ({
      ...state,
      developmentReportPersonDetails: action.payload,
    }),
    updateDevelopmentReportPersonDetails: (
      state,
      action: PayloadAction<IDevelopmentReportPersonDetailsUpdatePayload>
    ) => ({
      ...state,
      updatePayload: action.payload,
    }),
  },
});

export const developmentReportPersonDetailsReducer = developmentReportPersonDetailsSlice.reducer;
export const developmentReportPersonDetailsActions = developmentReportPersonDetailsSlice.actions;
