import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';

import { Button } from '@/components/Button/Button';
import { CircleFilledIcon } from '@/components/Icons/CircleFilledIcon';
import { CircleOutlineIcon } from '@/components/Icons/CircleOutlineIcon';
import { IPersonRequirementTimeListForm } from '@/components/Modals/Modals/functions/organizationalUnitsModals/AdditionalPersonRequirementTimeListModal/AdditionalPersonRequirementTimeListModalContent/hooks/usePersonRequirementTimeLIstForm';
import { PersonRequirementTimeListGroupItem } from '@/components/Modals/Modals/functions/organizationalUnitsModals/AdditionalPersonRequirementTimeListModal/AdditionalPersonRequirementTimeListModalContent/PersonRequirementTimeListGroupItem';
import { PersonRequirementTimeListHeader } from '@/components/Modals/Modals/functions/organizationalUnitsModals/AdditionalPersonRequirementTimeListModal/AdditionalPersonRequirementTimeListModalContent/PersonRequirementTimeListHeader';
import { Typography } from '@/components/Typography/Typography';
import { IPersonRequirementTimeGroup } from '@/core/redux/slices/modalsSlice/functions/organizationalUnits/slice';
import { twMerge } from '@/core/utils/tailwindUtil';
import { colorTypes } from '@/styles/types';

interface IPersonRequirementTimeListGroup extends IPersonRequirementTimeGroup {
  groupFormIndex: number;
  setIsExpanded: (id: null | number) => void;
  isExpanded?: boolean;
}

export const PersonRequirementTimeListGroup: React.FC<IPersonRequirementTimeListGroup> = ({
  groupFormIndex,
  id,
  name,
  items,
  isExpanded,
  setIsExpanded,
  maxEntriesCount,
}) => {
  const [currentSum, setCurrentSum] = useState<number>(0);

  const { t: personRequirementTimeListTranslations } = useTranslation(
    'personRequirementTimeListModal'
  );

  const { watch, setValue } = useFormContext<IPersonRequirementTimeListForm>();

  const formItems = watch(`personRequirementTimeListGroups.${groupFormIndex}.items`);
  const currentValues = watch();
  const isOnlyActive = watch('isOnlyActive');

  const expanded = isExpanded || (isOnlyActive && currentSum !== 0);
  const setExpanded = isOnlyActive ? undefined : setIsExpanded;

  useEffect(() => {
    const groupSum = formItems
      ? formItems.reduce<number>((accum, formItem) => {
          return formItem.minutesPerDay ? accum + formItem.minutesPerDay : accum;
        }, 0)
      : 0;

    const formattedSum = parseFloat(groupSum.toFixed(2));

    if (currentSum === formattedSum) {
      return;
    }

    setValue(`personRequirementTimeListGroups.${groupFormIndex}.sum`, formattedSum);
    setCurrentSum(formattedSum);
  }, [currentValues]);

  const handleSelect = (id: number) => {
    if (setExpanded) {
      setExpanded(isExpanded ? null : id);
    }
  };

  return (
    <div>
      <Button
        onClick={() => handleSelect(id)}
        buttonVariant={'Transparent'}
        buttonStyle={'Text'}
        className={'flex flex-row gap-3 items-center'}
      >
        {expanded ? (
          <CircleFilledIcon className={`text-${colorTypes.Tiffany}`} />
        ) : (
          <CircleOutlineIcon className={`text-${colorTypes.Orange}`} />
        )}
        <div>
          <Typography>{name}</Typography>
          {maxEntriesCount && (
            <Typography>
              {personRequirementTimeListTranslations('timeList.timeGroup.requirements.label', {
                count: maxEntriesCount,
              })}
            </Typography>
          )}
          {currentSum !== 0 && (
            <Typography>
              {personRequirementTimeListTranslations('timeList.timeGroup.minutesPerDaySum.label', {
                sum: currentSum,
              })}
            </Typography>
          )}
        </div>
      </Button>
      <motion.div
        className={twMerge(`flex flex-col gap-2 w-full overflow-hidden`)}
        initial={expanded ? { height: 'auto', opacity: 100 } : { height: 0, opacity: 0 }}
        animate={expanded ? { height: 'auto', opacity: 100 } : { height: 0, opacity: 0 }}
      >
        <PersonRequirementTimeListHeader />
        {items.map((item) => {
          const itemFormIndex = items.findIndex((innerItem) => innerItem.id === item.id);

          return (
            <PersonRequirementTimeListGroupItem
              {...item}
              key={item.id}
              id={item.id}
              formIndex={itemFormIndex}
              groupFormIndex={groupFormIndex}
            />
          );
        })}
      </motion.div>
    </div>
  );
};
