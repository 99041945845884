import React from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import { Typography } from '@/components/Typography/Typography';
import { DepartmentList } from '@/components/views/person/PersonPage/DepartmentView/DepartmentList/DepartmentList';
import { DepartmentSearch } from '@/components/views/person/PersonPage/DepartmentView/DepartmentSearch/DepartmentSearch';
import { DepartmentSelectAll } from '@/components/views/person/PersonPage/DepartmentView/DepartmentSelectAll/DepartmentSelectAll';
import { DepartmentSorting } from '@/components/views/person/PersonPage/DepartmentView/DepartmentSorting/DepartmentSorting';

import { DepartmentListExpand } from './DepartmentListExpand/DepartmentListExpand';

import styles from './styles.module.scss';

export const DepartmentView: React.FC = () => {
  const { t } = useTranslation('tms', { keyPrefix: 'ui.title' });  

  return (
    <div className={clsx(styles.departments, 'flex flex-col items-start justify-start')}>
      <Typography type='H2' color='Primary' className={styles.title}>
        {t('departments')}
      </Typography>

      <DepartmentSearch />
      <div className={clsx(styles.departmentContainer, 'w-full flex-grow')}>
          <div
            className={clsx(
              styles.filterContainer,
              'flex flex-row items-center justify-between w-full'
            )}
          >
            <DepartmentSelectAll />
            <div className='flex gap-4'>
              <DepartmentListExpand />
              <DepartmentSorting />
            </div>
          </div>
          <DepartmentList />
      </div>
    </div>
  );
};
