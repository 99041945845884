import React from 'react';
import { useTranslation } from 'react-i18next';

import { Typography } from '@/components/Typography/Typography';
import { colorTypes } from '@/styles/types';

export const MobilityHeader: React.FC = () => {
  const { t: mobilityHeaderTranslations } = useTranslation('mobility', { keyPrefix: 'header' });

  return (
    <div
      className={`grid grid-cols-11 gap-4 items-center border-b border-${colorTypes.Stroke} py-[10px]`}
    >
      <Typography className={'col-span-3'}>
        {mobilityHeaderTranslations('weekday.label')}
      </Typography>
      <Typography className={'col-span-4'}>
        {mobilityHeaderTranslations('arrival.label')}
      </Typography>
      <Typography className={'col-span-4'}>
        {mobilityHeaderTranslations('departure.label')}
      </Typography>
    </div>
  );
};
