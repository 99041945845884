import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { FormRadioButtonGroup } from '@/components/FormRadioButtonGroup/FormRadioButtonGroup';
import { IFormRadioButtonGroupItem } from '@/components/FormRadioButtonGroup/FormRadioButtonGroupItem';
import { Input } from '@/components/Input/Input';
import { IAddParticipantsModalForm } from '@/components/Modals/Modals/functions/activityPlaningModals/participantsListModals/AddParticipantsModal/AddParticipantsModal';
import { Select } from '@/components/Select/Select';
import { ISelectItem } from '@/components/Select/SelectItem/SelectItem';
import { Typography } from '@/components/Typography/Typography';
import { DropdownItemsByFetch } from '@/core/enums/common/DropdownItemsByFetchEnum';
import { AddParticipantsMeasureStateTypes } from '@/core/enums/functions/activityPlanning/addParticipantsMeasureStateTypesEnum';
import { useAppSelector } from '@/core/redux/hooks';
import { dropdownItemsSelectors } from '@/core/redux/slices/dropdownItems/selectors';

export const AddParticipantsListFilters: React.FC = () => {
  const { t: addParticipantsModalTranslations } = useTranslation('addParticipantsModal');

  const { control, setValue } = useFormContext<IAddParticipantsModalForm>();

  const dropdownItems = useAppSelector(dropdownItemsSelectors.dropdownItems);
  const departmentSelectValues = dropdownItems
    ? dropdownItems[DropdownItemsByFetch.MEASURE_LOCATION]?.map<ISelectItem>((dropDownItem) => ({
        id: String(dropDownItem.id),
        component: dropDownItem.value,
      }))
    : [];

  const measureStateOptions: IFormRadioButtonGroupItem[] = [
    {
      id: 0,
      component: (
        <Typography>
          {addParticipantsModalTranslations('table.filters.isMeasureAssigned.label')}
        </Typography>
      ),
      value: AddParticipantsMeasureStateTypes.isMeasureAssigned,
      className: 'items-center gap-2',
    },
    {
      id: 1,
      component: (
        <Typography>
          {addParticipantsModalTranslations('table.filters.isMeasureAssignedHasAppointment.label')}
        </Typography>
      ),
      value: AddParticipantsMeasureStateTypes.isMeasureAssignedHasAppointment,
      className: 'items-center gap-2',
    },
    {
      id: 2,
      component: (
        <Typography>
          {addParticipantsModalTranslations('table.filters.isMeasureAppointmentCompleted.label')}
        </Typography>
      ),
      value: AddParticipantsMeasureStateTypes.isMeasureAppointmentCompleted,
      className: 'items-center gap-2',
    },
  ];

  const handleSelectChange = (id: string) => {
    setValue('filters.departmentID', Number(id));
  };

  const handleSelectReset = () => {
    setValue('filters.departmentID', undefined);
  };

  return (
    <div className={'flex flex-row gap-3 items-center'}>
      <Typography>{addParticipantsModalTranslations('table.filters.name.label')}</Typography>
      <Input control={control.register('filters.name')} />
      <Typography>{addParticipantsModalTranslations('table.filters.department.label')}</Typography>
      <Select
        values={departmentSelectValues}
        onChange={handleSelectChange}
        className={'w-[250px]'}
        isResetable={true}
        onReset={handleSelectReset}
      />
      <FormRadioButtonGroup
        control={control.register('filters.measureState')}
        isResettable={true}
        options={measureStateOptions}
        containerClassName={'flex-row gap-2'}
        className={'items-center'}
      />
    </div>
  );
};
