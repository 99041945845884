import React from 'react';
import { useTranslation } from 'react-i18next';

import { Typography } from '@/components/Typography/Typography';

export const AddParticipantsListHeader: React.FC = () => {
  const { t: addParticipantsModalTranslations } = useTranslation('addParticipantsModal');

  return (
    <div className={'grid grid-cols-12 items-center gap-3 p-2 border-y'}>
      <div />
      <Typography className={'col-span-4'}>
        {addParticipantsModalTranslations('table.header.name.label')}
      </Typography>
      <Typography className={'col-span-4'}>
        {addParticipantsModalTranslations('table.header.department.label')}
      </Typography>
      <Typography className={'col-span-3'}>
        {addParticipantsModalTranslations('table.header.nextDate.label')}
      </Typography>
    </div>
  );
};
