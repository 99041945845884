import { PayloadAction } from '@reduxjs/toolkit';
import { format } from 'date-fns';
import type { NormalizeOAS, OASOutput, OASRequestParams } from 'fets';
import { call, put, select, takeLatest } from 'redux-saga/effects';

import { authAdd, restCall } from '@/core/clients/rest';
import { BACKEND_DATE_FORMAT } from '@/core/constants/dateFormat';
import { OrganizationalUnitsTypes } from '@/core/enums/functions/organizationalUnits/organizationalUnitsTypesEnum';
import { OrganizationUnitBBBActionTypes } from '@/core/enums/functions/organizationalUnits/organizationUnitBBBActionTypesEnum';
import { organizationalUnitsSelectors } from '@/core/redux/slices/functions/organizationalUnits/selectors';
import {
  IAdditionalPersonRequirement,
  IAdditionalPersonRequirementPayload,
  organizationalUnitsActions,
} from '@/core/redux/slices/functions/organizationalUnits/slice';
import { organizationalUnitsModalsSelectors } from '@/core/redux/slices/modalsSlice/functions/organizationalUnits/selectors';
import {
  IAdditionalPersonRequirementAssessmentPrognosisPayload,
  IAdditionalPersonRequirementAssessmentPrognosisUpdatePayload,
  IAdditionalPersonRequirementCreatePayload,
  IAdditionalPersonRequirementModalPayload,
  IAdditionalPersonRequirementUpdatePayload,
  IAvailableActions,
  IAvailableBBBActionsPayload,
  IAvailableOrganizationalUnitsFetchPayload,
  IAvailableOrganizationUnit,
  ICreateOrganizationalUnitPayload,
  IOrganizationUnitBBB,
  IOrganizationUnitBBBAction,
  IPersonRequirementTimeGroup,
  IPersonRequirementTimeItem,
  IPersonRequirementTimeListPayload,
  IPersonRequirementTimeListUpdatePayload,
  IUpdateOrganizationalUnitBBBInterruptionPayload,
  IUpdateOrganizationalUnitBBBPayload,
  organizationalUnitsModalsActions,
} from '@/core/redux/slices/modalsSlice/functions/organizationalUnits/slice';
import { notificationsActions } from '@/core/redux/slices/notifications/notificationsSlice';
import { toBackendDate, toClientDateInput } from '@/core/utils/dateTimeUtil';
import { isEnumValue } from '@/core/utils/enumUtils';
import type oas from '@/services/rest/base/openapi';
import { LoadingStatus } from '@/types/loadingStatus';

type AvailableOrganizationalUnitsResponse = OASOutput<
  NormalizeOAS<typeof oas>,
  '/organization_unit/available',
  'get',
  '200'
>;

type AvailableOrganizationalUnitsRequest = OASRequestParams<
  NormalizeOAS<typeof oas>,
  '/organization_unit/available',
  'get'
>;

type AvailableOrganizationalUnitBBBActionsRequest = OASRequestParams<
  NormalizeOAS<typeof oas>,
  '/organization_unit/bbb/action',
  'get'
>;

type AvailableOrganizationalUnitBBBActionsResponse = OASOutput<
  NormalizeOAS<typeof oas>,
  '/organization_unit/bbb/action',
  'get',
  '200'
>;

type CreateOrganizationalUnitRequest = OASRequestParams<
  NormalizeOAS<typeof oas>,
  '/organization_unit',
  'post'
>;

type UpdateOrganizationalUnitBBBRequest = OASRequestParams<
  NormalizeOAS<typeof oas>,
  '/organization_unit/bbb',
  'put'
>;

type UpdateOrganizationalUnitBBBInterruptionRequest = OASRequestParams<
  NormalizeOAS<typeof oas>,
  '/organization_unit/bbb/interruption',
  'put'
>;

type AvailableAdditionalPersonRequirementAssistanceResponse = OASOutput<
  NormalizeOAS<typeof oas>,
  '/additional_person_requirement/assistance',
  'get'
>;

type AvailableAdditionalPersonRequirementAssistanceRequest = OASRequestParams<
  NormalizeOAS<typeof oas>,
  '/additional_person_requirement/assistance',
  'get'
>;

type CreateAdditionalPersonRequirementRequest = OASRequestParams<
  NormalizeOAS<typeof oas>,
  '/additional_person_requirement',
  'post'
>;

type UpdateAdditionalPersonRequirementRequest = OASRequestParams<
  NormalizeOAS<typeof oas>,
  '/additional_person_requirement',
  'put'
>;

type AdditionalPersonRequirementTimeListResponse = OASOutput<
  NormalizeOAS<typeof oas>,
  '/additional_person_requirement/activity',
  'get'
>;

type AdditionalPersonRequirementTimeListRequest = OASRequestParams<
  NormalizeOAS<typeof oas>,
  '/additional_person_requirement/activity',
  'get'
>;

type AdditionalPersonRequirementTimeListCycleResponse = OASOutput<
  NormalizeOAS<typeof oas>,
  '/additional_person_requirement/activity/cycle',
  'get'
>;

type AdditionalPersonRequirementAssessmentPrognosisRequest = OASRequestParams<
  NormalizeOAS<typeof oas>,
  '/additional_person_requirement/assessment_prognosis',
  'get'
>;

type AdditionalPersonRequirementAssessmentPrognosisResponse = OASOutput<
  NormalizeOAS<typeof oas>,
  '/additional_person_requirement/assessment_prognosis',
  'get'
>;

type AdditionalPersonRequirementAssessmentPrognosisUpdateRequest = OASRequestParams<
  NormalizeOAS<typeof oas>,
  '/additional_person_requirement/assessment_prognosis',
  'put'
>;

type PersonRequirementTimeListRequest = OASRequestParams<
  NormalizeOAS<typeof oas>,
  '/additional_person_requirement/activity',
  'post'
>;

const mapAdditionalPersonRequirementTimeList = (
  timeList: AdditionalPersonRequirementTimeListResponse['activities']
): IPersonRequirementTimeGroup[] => {
  return timeList.map<IPersonRequirementTimeGroup>((timeGroup) => ({
    id: timeGroup.id,
    name: timeGroup.name,
    maxEntriesCount: timeGroup.max_entries_count,
    items: timeGroup.items.map<IPersonRequirementTimeItem>((timeItem) => ({
      id: timeItem.id,
      value: timeItem.value,
      comment: timeItem.comment,
      name: timeItem.name,
      cycleID: timeItem.cycle_id,
      isInGroup: timeItem.in_group,
    })),
  }));
};

function* fetchAvailableOrganizationalUnits(
  action: PayloadAction<IAvailableOrganizationalUnitsFetchPayload>
): Generator<any, void, AvailableOrganizationalUnitsResponse> {
  const { personID } = action.payload;

  try {
    yield put(
      organizationalUnitsModalsActions.setAvailableOrganizationalUnitLock(LoadingStatus.LOADING)
    );

    const request: AvailableOrganizationalUnitsRequest = {
      query: {
        person_id: personID,
      },
      ...authAdd(),
    };

    const response = yield call(restCall, '/organization_unit/available', 'get', request);

    const availableOrganizationalUnits = response.units.map<IAvailableOrganizationUnit>(
      (organizationalUnit) => ({
        unitID: organizationalUnit.id,
        name: organizationalUnit.name ?? '',
        type: isEnumValue(OrganizationalUnitsTypes, organizationalUnit.type)
          ? organizationalUnit.type
          : OrganizationalUnitsTypes.Default,
      })
    );

    yield put(
      organizationalUnitsModalsActions.setAvailableOrganizationalUnit(availableOrganizationalUnits)
    );

    yield put(
      organizationalUnitsModalsActions.setAvailableOrganizationalUnitLock(LoadingStatus.LOADED)
    );
  } catch (error) {
    console.log('Error on fetching available organizational units', error);
    yield put(
      organizationalUnitsModalsActions.setAvailableOrganizationalUnitLock(LoadingStatus.ERROR)
    );
  }
}

function* fetchOrganizationalUnitAvailableBBBActions(
  action: PayloadAction<IAvailableBBBActionsPayload>
): Generator<any, void, AvailableOrganizationalUnitBBBActionsResponse> {
  const { personID } = action.payload;

  try {
    yield put(
      organizationalUnitsModalsActions.setOrganizationalUnitAvailableBBBActionsLock(
        LoadingStatus.LOADING
      )
    );

    const request: AvailableOrganizationalUnitBBBActionsRequest = {
      query: {
        person_id: personID,
      },
      ...authAdd(),
    };

    const response = yield call(restCall, '/organization_unit/bbb/action', 'get', request);

    const availableBBBActions: IAvailableActions = {
      actionsList: response.actions.map<IOrganizationUnitBBBAction>((action) => ({
        id: action.id,
        name: action.name,
        type: isEnumValue(OrganizationUnitBBBActionTypes, action.type)
          ? action.type
          : OrganizationUnitBBBActionTypes.Default,
      })),
      selectedAction: response.previous_action
        ? {
            id: response.previous_action.id,
            name: response.previous_action.name,
            type: isEnumValue(OrganizationUnitBBBActionTypes, response.previous_action.type)
              ? response.previous_action.type
              : OrganizationUnitBBBActionTypes.Default,
          }
        : null,
      actionValue: response.previous_action_days_count,
      adaptationPeriod: response.adaptation_period_days_duration,
      isWithoutAdaptation: response.is_without_adaptation,
      initialDate: toClientDateInput(response.initial_date),
    };

    yield put(
      organizationalUnitsModalsActions.setOrganizationalUnitAvailableBBBActions(availableBBBActions)
    );

    yield put(
      organizationalUnitsModalsActions.setOrganizationalUnitAvailableBBBActionsLock(
        LoadingStatus.LOADED
      )
    );
  } catch (error) {
    console.log('Error on fetching available organizational unit bbb actions', error);
    yield put(
      organizationalUnitsModalsActions.setOrganizationalUnitAvailableBBBActionsLock(
        LoadingStatus.ERROR
      )
    );
  }
}

function* createOrganizationalUnit(action: PayloadAction<ICreateOrganizationalUnitPayload>) {
  const { personID, type, fromTimestamp, toTimestamp, note } = action.payload;

  try {
    const formattedFromTimestamp = toBackendDate(fromTimestamp);

    const request: CreateOrganizationalUnitRequest = {
      json: {
        person_id: personID,
        unit_id: -1,
        type: type,
        from_timestamp: formattedFromTimestamp ?? new Date().toISOString(),
        to_timestamp: toBackendDate(toTimestamp),
        note: note,
      },
      ...authAdd(),
    };
    yield call(restCall, '/organization_unit', 'post', request);

    switch (type) {
      case OrganizationalUnitsTypes.BBB: {
        const organizationalUnitBBB: IOrganizationUnitBBB | null = yield select(
          organizationalUnitsModalsSelectors.organizationalUnitBBB
        );

        if (!organizationalUnitBBB?.formValues) {
          return;
        }

        const updateAction: PayloadAction<IUpdateOrganizationalUnitBBBPayload> = {
          type: organizationalUnitsModalsActions.updateOrganizationalUnitBBB.type,
          payload: {
            personID: personID,
            formValues: organizationalUnitBBB.formValues,
            unitID: -1,
          },
        };

        yield call(updateOrganizationalUnitBBB, updateAction);
        break;
      }
    }

    yield put(organizationalUnitsModalsActions.closeCreateOrganizationalUnitModal());
    yield put(
      organizationalUnitsActions.fetchOrganizationalUnits({
        personID: personID,
      })
    );
  } catch (error) {
    console.log('Error on creating organizational unit', error);
  }
}

function* updateOrganizationalUnitBBB(action: PayloadAction<IUpdateOrganizationalUnitBBBPayload>) {
  const { personID, formValues, unitID } = action.payload;

  try {
    const today = new Date();

    const request: UpdateOrganizationalUnitBBBRequest = {
      json: {
        unit_id: unitID,
        from_timestamp:
          toBackendDate(formValues.startDate) ?? format(today.toISOString(), BACKEND_DATE_FORMAT),
        person_id: personID,
        action:
          formValues?.actionType === OrganizationUnitBBBActionTypes.Default
            ? null
            : formValues.actionType,
        action_value: formValues?.actionValue,
        adaptation_period_days_duration:
          Number(formValues?.adaptationPeriod) > 0 ? formValues?.adaptationPeriod : null,
        is_without_adaptation: formValues?.isWithoutAdaptation,
        note: formValues?.comment,
      },
      ...authAdd(),
    };

    yield put(organizationalUnitsActions.fetchOrganizationalUnits({ personID: personID }));

    yield call(restCall, '/organization_unit/bbb', 'put', request);

    yield put(organizationalUnitsModalsActions.closeUpdateOrganizationalUnitModal());
  } catch (error) {
    console.log('Error on updating organizational unit BBB', error);
    yield put(
      notificationsActions.showNotification({
        notification: {
          type: 'error',
          title: 'Error',
          description: 'Error on updating organizational unit BBB',
        },
      })
    );
  }
}

function* updateOrganizationalUnitBBBInterruption(
  action: PayloadAction<IUpdateOrganizationalUnitBBBInterruptionPayload>
) {
  const { id, personID, fromTimestamp, toTimestamp, note } = action.payload;

  try {
    const today = new Date();

    const request: UpdateOrganizationalUnitBBBInterruptionRequest = {
      json: {
        id: id,
        from_timestamp:
          toBackendDate(fromTimestamp) ?? format(today.toISOString(), BACKEND_DATE_FORMAT),
        to_timestamp: toBackendDate(toTimestamp),
        note: note ?? '',
      },
      ...authAdd(),
    };

    yield call(restCall, '/organization_unit/bbb/interruption', 'put', request);

    yield put(organizationalUnitsModalsActions.closeUpdateOrganizationalUnitModal());
    yield put(organizationalUnitsActions.fetchOrganizationalUnits({ personID: personID }));
  } catch (error) {
    console.log('Error on updating organizational unit BBB interruption', error);
    yield put(
      notificationsActions.showNotification({
        notification: {
          type: 'error',
          title: 'Error',
          description: 'Error on updating organizational unit BBB interruption',
        },
      })
    );
  }
}

function* fetchAdditionalPersonRequirementDetails(
  action: PayloadAction<IAdditionalPersonRequirementModalPayload>
) {
  const { additionalPersonRequirementID } = action.payload;

  const additionalPersonRequirement: IAdditionalPersonRequirement = yield select(
    organizationalUnitsSelectors.additionalPersonRequirement
  );

  try {
    yield put(
      organizationalUnitsModalsActions.setAdditionalPersonRequirementDetailsLock(
        LoadingStatus.LOADING
      )
    );

    const found = additionalPersonRequirement.personRequirements.find(
      (personRequirement) => personRequirement.id === additionalPersonRequirementID
    );

    if (!found) {
      return;
    }

    yield put(organizationalUnitsModalsActions.setAdditionalPersonRequirementDetails(found));

    yield put(
      organizationalUnitsModalsActions.setAdditionalPersonRequirementDetailsLock(
        LoadingStatus.LOADED
      )
    );
  } catch (error) {
    console.log('Error on additional person requirement details fetching', error);
    yield put(
      organizationalUnitsModalsActions.setAdditionalPersonRequirementDetailsLock(
        LoadingStatus.ERROR
      )
    );
  }
}

function* fetchAvailableAssistanceTypes(
  action: PayloadAction<IAdditionalPersonRequirementPayload>
): Generator<any, void, AvailableAdditionalPersonRequirementAssistanceResponse> {
  const { personID } = action.payload;

  try {
    const request: AvailableAdditionalPersonRequirementAssistanceRequest = {
      query: {
        person_id: personID,
      },
      ...authAdd(),
    };

    const response = yield call(
      restCall,
      '/additional_person_requirement/assistance',
      'get',
      request
    );

    yield put(organizationalUnitsModalsActions.setAvailableAssistanceTypes(response.assistance));
  } catch (error) {
    console.log('Error on fetching available additional person requirement assistance');
  }
}

function* createAdditionalPersonRequirement(
  action: PayloadAction<IAdditionalPersonRequirementCreatePayload>
) {
  const { personID, formValues } = action.payload;

  try {
    const request: CreateAdditionalPersonRequirementRequest = {
      json: {
        person_id: personID,
        approved_to_timestamp: toBackendDate(formValues?.approvedTo),
        approved_from_timestamp: toBackendDate(formValues?.approvedFrom),
        applied_timestamp: toBackendDate(formValues?.applicationDate),
        comment: formValues?.comment,
        assistance_id: formValues?.assistanceID,
        group_manager_id: formValues?.responsibleID,
        minutes_per_day: formValues?.minutesPerDay,
      },
      ...authAdd(),
    };

    yield call(restCall, '/additional_person_requirement', 'post', request);
    yield put(
      organizationalUnitsActions.fetchAdditionalPersonRequirementUnits({ personID: personID })
    );
  } catch (error) {
    console.log('Error on additional person requirement');
  }
}

function* updateAdditionalPersonRequirement(
  action: PayloadAction<IAdditionalPersonRequirementUpdatePayload>
) {
  const { personID, additionalPersonRequirementID, formValues } = action.payload;

  try {
    const request: UpdateAdditionalPersonRequirementRequest = {
      json: {
        id: additionalPersonRequirementID,
        approved_to_timestamp: toBackendDate(formValues?.approvedTo),
        approved_from_timestamp: toBackendDate(formValues?.approvedFrom),
        applied_timestamp: toBackendDate(formValues?.applicationDate),
        comment: formValues?.comment,
        assistance_id: formValues?.assistanceID,
        group_manager_id: formValues?.responsibleID,
        minutes_per_day: formValues?.minutesPerDay,
      },
      ...authAdd(),
    };

    yield call(restCall, '/additional_person_requirement', 'put', request);
    yield put(
      organizationalUnitsActions.fetchAdditionalPersonRequirementUnits({ personID: personID })
    );
  } catch (error) {
    console.log('Error on additional person requirement');
  }
}

function* fetchAdditionalPersonRequirementTimeList(
  action: PayloadAction<IPersonRequirementTimeListPayload>
): Generator<any, void, AdditionalPersonRequirementTimeListResponse> {
  const { additionalPersonRequirementID } = action.payload;

  try {
    yield put(
      organizationalUnitsModalsActions.setAdditionalPersonRequirementTimeListLock(
        LoadingStatus.LOADING
      )
    );

    const request: AdditionalPersonRequirementTimeListRequest = {
      query: {
        additional_person_requirement_id: additionalPersonRequirementID,
      },
      ...authAdd(),
    };

    const response = yield call(
      restCall,
      '/additional_person_requirement/activity',
      'get',
      request
    );
    const additionPersonRequirementTimeList = mapAdditionalPersonRequirementTimeList(
      response.activities
    );

    yield put(
      organizationalUnitsModalsActions.setAdditionalPersonRequirementTimeList(
        additionPersonRequirementTimeList
      )
    );

    yield put(
      organizationalUnitsModalsActions.setAdditionalPersonRequirementTimeListLock(
        LoadingStatus.LOADED
      )
    );
  } catch (error) {
    console.log('Error on person requirement time list fetching', error);
    yield put(
      organizationalUnitsModalsActions.setAdditionalPersonRequirementTimeListLock(
        LoadingStatus.ERROR
      )
    );
  }
}

function* fetchAvailablePersonRequirementTimeCycles(): Generator<
  any,
  void,
  AdditionalPersonRequirementTimeListCycleResponse
> {
  try {
    const response = yield call(restCall, '/additional_person_requirement/activity/cycle', 'get', {
      ...authAdd(),
    });

    const availablePersonRequirementTimeCycles = response.cycles;

    yield put(
      organizationalUnitsModalsActions.setAvailablePersonRequirementTimeCycles(
        availablePersonRequirementTimeCycles
      )
    );
  } catch (error) {
    console.log('Error on time list cycles fetch', error);
  }
}

function* fetchAdditionalPersonRequirementAssessmentPrognosis(
  action: PayloadAction<IAdditionalPersonRequirementAssessmentPrognosisPayload>
): Generator<any, void, AdditionalPersonRequirementAssessmentPrognosisResponse> {
  const { additionalPersonRequirementID } = action.payload;

  const request: AdditionalPersonRequirementAssessmentPrognosisRequest = {
    query: { additional_person_requirement_id: additionalPersonRequirementID },
    ...authAdd(),
  };

  try {
    const response = yield call(
      restCall,
      '/additional_person_requirement/assessment_prognosis',
      'get',
      request
    );

    const additionalPersonRequirementAssessmentPrognosis = response.data;

    yield put(
      organizationalUnitsModalsActions.setAdditionalPersonRequirementAssessmentPrognosis(
        additionalPersonRequirementAssessmentPrognosis
      )
    );
  } catch (error) {
    console.log('Error on additional person requirement assessment prognosis fetch', error);
  }
}

function* updateAdditionalPersonRequirementAssessmentPrognosis(
  action: PayloadAction<IAdditionalPersonRequirementAssessmentPrognosisUpdatePayload>
): Generator<any, void, any> {
  const { assessment, prognosis, additionalPersonRequirementID } = action.payload;

  try {
    const request: AdditionalPersonRequirementAssessmentPrognosisUpdateRequest = {
      json: {
        prognosis: prognosis,
        assessment: assessment,
        additional_person_requirement_id: additionalPersonRequirementID,
      },
      ...authAdd(),
    };

    yield call(restCall, '/additional_person_requirement/assessment_prognosis', 'put', request);
  } catch (error) {
    console.log('Error on additional person requirement assessment prognosis update', error);
  }
}

function* updatePersonRequirementTimeList(
  action: PayloadAction<IPersonRequirementTimeListUpdatePayload>
) {
  const { additionalPersonRequirementID, formValues } = action.payload;

  try {
    yield put(
      organizationalUnitsModalsActions.setUpdatePersonRequirementTimeListLock(LoadingStatus.LOADING)
    );

    const request: PersonRequirementTimeListRequest = {
      json: {
        additional_person_requirement_id: additionalPersonRequirementID,
        activities: formValues?.personRequirementTimeListGroups.reduce<
          PersonRequirementTimeListRequest['json']['activities']
        >((accum, timeGroup) => {
          timeGroup.items.forEach((timeItem) => {
            if (!timeItem.value || !timeItem.cycleID) {
              return;
            }
            const item: PersonRequirementTimeListRequest['json']['activities'][0] = {
              in_group: timeItem.isInGroup ?? false,
              cycle_id: timeItem.cycleID,
              comment: timeItem.comment,
              value: timeItem.value,
              activity_id: timeItem.id,
            };
            accum.push(item);
          });
          return accum;
        }, []),
      },
      ...authAdd(),
    };

    yield call(restCall, '/additional_person_requirement/activity', 'post', request);
    yield put(
      organizationalUnitsModalsActions.setUpdatePersonRequirementTimeListLock(LoadingStatus.LOADED)
    );
  } catch (error) {
    console.log('Error on person requirement time list update', error);
    yield put(
      organizationalUnitsModalsActions.setUpdatePersonRequirementTimeListLock(LoadingStatus.ERROR)
    );
  }
}

export const organizationalUnitsModalsSagas = [
  takeLatest(
    organizationalUnitsModalsActions.fetchAvailableOrganizationalUnit,
    fetchAvailableOrganizationalUnits
  ),
  takeLatest(
    organizationalUnitsModalsActions.fetchOrganizationalUnitAvailableBBBActions,
    fetchOrganizationalUnitAvailableBBBActions
  ),
  takeLatest(organizationalUnitsModalsActions.createOrganizationalUnit, createOrganizationalUnit),
  takeLatest(
    organizationalUnitsModalsActions.updateOrganizationalUnitBBB,
    updateOrganizationalUnitBBB
  ),
  takeLatest(
    organizationalUnitsModalsActions.updateOrganizationalUnitBBBInterruption,
    updateOrganizationalUnitBBBInterruption
  ),
  takeLatest(
    organizationalUnitsModalsActions.fetchAdditionalPersonRequirementDetails,
    fetchAdditionalPersonRequirementDetails
  ),
  takeLatest(
    organizationalUnitsModalsActions.fetchAvailableAssistanceTypes,
    fetchAvailableAssistanceTypes
  ),
  takeLatest(
    organizationalUnitsModalsActions.fetchAdditionalPersonRequirementTimeList,
    fetchAdditionalPersonRequirementTimeList
  ),
  takeLatest(
    organizationalUnitsModalsActions.fetchAvailablePersonRequirementTimeCycles,
    fetchAvailablePersonRequirementTimeCycles
  ),
  takeLatest(
    organizationalUnitsModalsActions.createAdditionalPersonRequirement,
    createAdditionalPersonRequirement
  ),
  takeLatest(
    organizationalUnitsModalsActions.updateAdditionalPersonRequirement,
    updateAdditionalPersonRequirement
  ),
  takeLatest(
    organizationalUnitsModalsActions.fetchAdditionalPersonRequirementAssessmentPrognosis,
    fetchAdditionalPersonRequirementAssessmentPrognosis
  ),
  takeLatest(
    organizationalUnitsModalsActions.updateAdditionalPersonRequirementAssessmentPrognosis,
    updateAdditionalPersonRequirementAssessmentPrognosis
  ),
  takeLatest(
    organizationalUnitsModalsActions.updatePersonRequirementTimeList,
    updatePersonRequirementTimeList
  ),
];
