import createStoreContext from '@/components/Context/ReduceModelStoreContext';
import { IPerson } from '@/types/person';
import { SortingBy } from '@/types/sortingBy';

import { ICheckedState } from '../DepartmentView/DepartmentList/DepartmentsTreeSelect/DepartmentsTreeSelect';

export enum FetchContext {
  default = 'default',
  related = 'related',
}

export interface ILocationsChecksState {
  departments_check_all?: boolean | undefined;
  departments?: IDepartmentsState | undefined;
}

export interface IDepartmentsState {
  [p: string]:
    | {
        value?: boolean | undefined;
        intermediate?: boolean | undefined;
        locations?: { [p: string]: boolean | undefined } | undefined;
      }
    | undefined;
}

export interface IPersonState {
  [p: string]: boolean | undefined;
}

export interface IPersonsChecksState {
  persons_check_all?: boolean | undefined;
  persons?: IPersonState | undefined;
}

export interface IPersonPageStoreContext {
  allDepartmentsSelected: boolean;
  setAllDepartments?: boolean;
  allDepartmentsListExpanded: boolean;
  departmentsSortedBy: SortingBy;
  departmentSearchValue: string;
  allPersonsSelected: boolean;
  personsSortedBy: SortingBy;
  personsSearchValue: string;
  selectedLocations: string[];
  locationsChecksState?: ICheckedState;
  personsChecksState?: IPersonsChecksState;
  filteredPersons?: IPerson[];
  fetchContext: FetchContext;
}

export const {
  useStoreSelector: usePersonPageStoreSelector,
  StoreProvider: PersonPageStoreProvider,
} = createStoreContext<IPersonPageStoreContext>();
