import { createSelector } from '@reduxjs/toolkit';

import { IRootState } from '@/core/redux/types';

const selector = (state: IRootState) => state.functions.vocationalTrainingArea;

const vocationalTrainingAreaGroups = createSelector(
  [selector],
  (state) => state.vocationalTrainingAreaGroups
);
const vocationalTrainingAreaList = createSelector(
  [selector],
  (state) => state.vocationalTrainingAreaList
);
const vocationalTrainingAreaGroupsLock = createSelector(
  [selector],
  (state) => state.vocationalTrainingAreaGroupsLock
);
const selectedVocationalTrainingAreaItems = createSelector(
  [selector],
  (state) => state.selectedVocationalTrainingAreaItems
);
const measureCompleteErrors = createSelector([selector], (state) => state.completeMeasuresErrors);

const completeMeasureState = createSelector([selector], (state) => state.completeMeasureState);

export const vocationalTrainingAreaSelectors = {
  vocationalTrainingAreaGroups,
  vocationalTrainingAreaList,
  vocationalTrainingAreaGroupsLock,
  selectedVocationalTrainingAreaItems,
  measureCompleteErrors,
  completeMeasureState,
};
