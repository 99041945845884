import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { LoadingStatus } from '@/types/loadingStatus';

export interface IAppointmentParticipantsListFetchPayload {
  appointmentID: number;
  measureID: number;
}

export interface IDeleteParticipantPayload extends IAppointmentParticipantsListFetchPayload {
  personID: number;
}

export interface IActivityPlanningAppointmentParticipantList {
  list: IActivityPlanningAppointmentParticipant[];
  hasPlaces: boolean;
  placesNum: number;
}

export interface IActivityPlanningAppointmentParticipant {
  rowID: number;
  id: number;
  name: string;
  department?: string | null;
  hasEfficacy?: boolean;
}

export interface IActivityPlanningAppointmentParticipantsList {
  hasPlaces: boolean;
  placesNum: number | null;
  participantsList: IActivityPlanningAppointmentParticipantList | null;
  participantsListLock: LoadingStatus;
}

const initialState: IActivityPlanningAppointmentParticipantsList = {
  hasPlaces: false,
  placesNum: null,
  participantsList: null,
  participantsListLock: LoadingStatus.NEVER,
};

export const activityPlanningAppointmentParticipantsList = createSlice({
  name: 'measuresList',
  initialState,
  reducers: {
    fetchAppointmentParticipantsList: (
      state,
      action: PayloadAction<IAppointmentParticipantsListFetchPayload>
    ) => ({
      ...state,
      payload: action.payload,
    }),
    setAppointmentParticipantsList: (
      state,
      action: PayloadAction<IActivityPlanningAppointmentParticipantList | null>
    ) => ({
      ...state,
      participantsList: action.payload,
    }),
    setAppointmentParticipantsListLock: (state, action: PayloadAction<LoadingStatus>) => ({
      ...state,
      participantsListLock: action.payload,
    }),
    deleteParticipant: (state, action: PayloadAction<IDeleteParticipantPayload>) => ({
      ...state,
      payload: action.payload,
    }),
  },
});

export const activityPlanningAppointmentParticipantsListReducers =
  activityPlanningAppointmentParticipantsList.reducer;
export const activityPlanningAppointmentParticipantsListActions =
  activityPlanningAppointmentParticipantsList.actions;
