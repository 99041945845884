import React from 'react';

import { usePermissions } from '@/components/PermissionsContext/PermissionsContext';
import { RadioButton } from '@/components/RadioButtonGroup/RadioButton/RadioButton';
import { twMerge } from '@/core/utils/tailwindUtil';

export interface IRadioButtonGroupItem {
  value: string;
  element?: React.ReactNode;
  onClick?: (event: React.MouseEvent, value: string) => string;
  preventDefault?: boolean;
  readOnly?: boolean;
}

export interface IRadioButtonGroup {
  options: IRadioButtonGroupItem[];
  name: string;
  className?: string;
  itemClassName?: string;
  layout?: 'vertical' | 'horizontal';
}

export const RadioButtonGroup: React.FC<IRadioButtonGroup> = ({
  options,
  name,
  className,
  itemClassName,
  layout = 'vertical',
}) => {
  const permissions = usePermissions(name);

  return (
    <div className={twMerge('flex gap-2.5', layout === 'vertical' && 'flex-col', className)}>
      {options.map((option) => {
        const { value, readOnly, preventDefault, element, onClick } = option;

        return (
          <div className={twMerge('flex gap-2.5 items-center', itemClassName)} key={name + value}>
            <RadioButton
              value={value}
              name={name}
              id={`${value}_${name}`}
              customClick={onClick}
              preventDefault={preventDefault}
              readOnly={readOnly || permissions.isReadOnly}
            />
            {element && (
              <label htmlFor={`${value}_${name}`} className={'w-full'}>
                {element}
              </label>
            )}
          </div>
        );
      })}
    </div>
  );
};
