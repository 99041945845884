export const fillTime = (value: string): string => {
  const [inputHours, inputMinutes] = value.split(':').map(Number);

  let hours = isNaN(inputHours) ? 1 : inputHours;
  let minutes = isNaN(inputMinutes) ? 0 : inputMinutes;

  hours = Math.min(Math.max(hours, 1), 23);
  minutes = Math.min(Math.max(minutes, 0), 59);

  const formattedHours = hours.toString().padStart(2, '0');
  const formattedMinutes = minutes.toString().padStart(2, '0');

  return `${formattedHours}:${formattedMinutes}`;
};
