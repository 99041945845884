import React, { useEffect, useLayoutEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Checkbox } from '@/components/Checkbox/Checkbox';
import { DatePicker } from '@/components/Datepicker/DatePicker';
import { Input } from '@/components/Input/Input';
import { Select } from '@/components/Select/Select';
import { ISelectItem } from '@/components/Select/SelectItem/SelectItem';
import { TextArea } from '@/components/TextArea/TextArea';
import { Typography } from '@/components/Typography/Typography';
import { OrganizationUnitBBBActionTypes } from '@/core/enums/functions/organizationalUnits/organizationUnitBBBActionTypesEnum';
import { UpdateBBBStatusTypes } from '@/core/enums/functions/organizationalUnits/updateBBBStatusTypesEnum';
import { useDialog } from '@/core/hooks/useDialog';
import { useSelectedPerson } from '@/core/hooks/useSelectedPerson';
import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';
import { organizationalUnitsModalsSelectors } from '@/core/redux/slices/modalsSlice/functions/organizationalUnits/selectors';
import { organizationalUnitsModalsActions } from '@/core/redux/slices/modalsSlice/functions/organizationalUnits/slice';

export interface IOrganizationalUnitBBBForm {
  startDate: string;
  comment?: string;
  actionID?: number;
  actionType?: OrganizationUnitBBBActionTypes;
  actionValue?: number;
  isWithoutAdaptation?: boolean;
  adaptationPeriod?: number;
}

export const OrganizationalUnitBBB: React.FC = () => {
  const { t: organizationalUnitsFormsTranslations } = useTranslation('organizationalUnitsForms', {
    keyPrefix: 'bbb',
  });

  const { updateBBBStatus } = useAppSelector(
    organizationalUnitsModalsSelectors.createOrganizationalUnitModal
  );

  const [selectedActionID, setSelectedActionID] = useState<string | undefined>(undefined);
  const { selectedPersonID } = useSelectedPerson();

  const organizationalUnitBBB = useAppSelector(
    organizationalUnitsModalsSelectors.organizationalUnitBBB
  );

  const dispatch = useAppDispatch();

  const formContext = useFormContext();
  const selectedID = formContext.watch('bbb.actionID');

  const { renderDialogContainer, openDialog } = useDialog({
    showCancelButton: false,
    description: organizationalUnitsFormsTranslations('errors.invalidDate.label'),
  });

  useEffect(() => {
    if (updateBBBStatus === UpdateBBBStatusTypes.InvalidDates) {
      openDialog();
      dispatch(organizationalUnitsModalsActions.setUpdateOrganizationalUnitBBBStatus(null));
    }
  }, [updateBBBStatus]);

  useEffect(() => {
    if (!selectedPersonID) {
      return;
    }

    dispatch(
      organizationalUnitsModalsActions.fetchOrganizationalUnitAvailableBBBActions({
        personID: selectedPersonID,
      })
    );

    return () => {
      dispatch(organizationalUnitsModalsActions.setOrganizationalUnitAvailableBBBActions(null));
      formContext.reset({});
    };
  }, []);

  useLayoutEffect(() => {
    if (!organizationalUnitBBB?.availableActions) {
      return;
    }

    const currentValues = formContext.getValues('bbb');

    const newFormValues: IOrganizationalUnitBBBForm = {
      ...currentValues,
      actionID: organizationalUnitBBB.availableActions.selectedAction?.id,
      actionValue: organizationalUnitBBB.availableActions.actionValue ?? undefined,
      isWithoutAdaptation: organizationalUnitBBB.availableActions.isWithoutAdaptation ?? undefined,
      adaptationPeriod: organizationalUnitBBB.availableActions.adaptationPeriod ?? undefined,
      startDate: organizationalUnitBBB.availableActions.initialDate,
    };

    setSelectedActionID(String(organizationalUnitBBB.availableActions.selectedAction?.id));

    formContext.setValue('bbb', newFormValues);
  }, [organizationalUnitBBB]);

  const selectValues = organizationalUnitBBB?.availableActions?.actionsList?.map<ISelectItem>(
    (action) => ({
      id: action.id.toString(),
      component: action.name,
    })
  );

  const handleActionSelect = (id: string) => {
    const selectedAction = organizationalUnitBBB?.availableActions?.actionsList?.find(
      (action) => action.id === Number(id)
    );

    if (!selectedAction) {
      return;
    }

    formContext.setValue('bbb.actionID', id);
    formContext.setValue('bbb.actionType', selectedAction.type);
  };

  return (
    <div className={'flex flex-col gap-3'}>
      <Typography className={'mt-7'}>
        {organizationalUnitsFormsTranslations('dateFrom.label')}
      </Typography>
      <DatePicker control={formContext.register('bbb.startDate')} />
      <div className={'flex flex-row gap-5'}>
        <div className={'flex flex-col gap-3 w-full'}>
          <Typography>{organizationalUnitsFormsTranslations('reduction.label')}</Typography>
          <Select
            values={selectValues}
            className={'w-full'}
            onChange={handleActionSelect}
            selectedExternal={selectedActionID}
            setSelectedExternal={setSelectedActionID}
            initialValueID={selectedID}
          />
        </div>
        <div className={'flex flex-col gap-3'}>
          <Typography>{organizationalUnitsFormsTranslations('day.label')}</Typography>
          <Input control={formContext.register('bbb.actionValue')} type={'number'} />
        </div>
      </div>
      <div className={'flex flex-row gap-5 items-center'}>
        <div className={'flex flex-col gap-3 items-center'}>
          <Typography className={'self-start'}>
            {organizationalUnitsFormsTranslations('shorten.label')}{' '}
            {organizationalUnitsFormsTranslations('day.label')}
          </Typography>
          <div className={'flex flex-row gap-5 items-center'}>
            <Input control={formContext.register('bbb.adaptationPeriod')} type={'number'} />
            <Typography className={'shrink-0'}>
              {organizationalUnitsFormsTranslations('or.label')}
            </Typography>
            <div className={'flex flex-row gap-1 items-center'}>
              <Typography>
                {organizationalUnitsFormsTranslations('withoutAdaptation.label')}
              </Typography>
              <Checkbox name={'bbb.isWithoutAdaptation'} />
            </div>
          </div>
        </div>
      </div>
      <Typography>{organizationalUnitsFormsTranslations('comment.label')}</Typography>
      <TextArea control={formContext.register('bbb.comment')} />
      {renderDialogContainer()}
    </div>
  );
};
