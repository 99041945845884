import { TFunction } from 'i18next';
import { ObjectSchema } from 'yup';

import { IDevelopmentReportVisionAndGoalsForm } from '@/components/functions/DevelopmentReport/DevelopmentReportForms/VisionAndGoals/helper/visionAndGoalsForm';
import { yup } from '@/core/utils/commonUtils';

export const visionAndGoalsValidatorBuilder = (
  translations: TFunction<'developmentReportVisionAndGoals', undefined>
): ObjectSchema<IDevelopmentReportVisionAndGoalsForm> =>
  yup.object().shape({
    visionAndGoals: yup
      .array()
      .of(
        yup.object().shape({
          lfdNr: yup.number().required(),
          isOwn: yup.boolean().required(),
          isRepresentative: yup.boolean().required(),
          ownView: yup.string().nullable(),
          representativeView: yup.string().nullable(),
        })
      )
      .required(),
  });

export const visionAndGoalsValidator: ObjectSchema<IDevelopmentReportVisionAndGoalsForm> = yup
  .object()
  .shape({
    visionAndGoals: yup
      .array()
      .of(
        yup.object().shape({
          lfdNr: yup.number().required(),
          isOwn: yup.boolean().required(),
          isRepresentative: yup.boolean().required(),
          ownView: yup.string().nullable(),
          representativeView: yup.string().nullable(),
        })
      )
      .required(),
  });
