import React from 'react';

import { QuestionnaireQuestionsGroup } from '@/components/functions/PerformanceProfile/Questionnaire/QuestionnaireQuestionsGroup/QuestionnaireQuestionsGroup';
import { TableHeader } from '@/components/functions/PerformanceProfile/Questionnaire/QuestionnaireQuestionsList/TableHeader/TableHeader';
import { IQuestionnaireQuestionsGroup } from '@/core/redux/slices/functions/performanceProfile/slice';
import { twMerge } from '@/core/utils/tailwindUtil';

import styles from './styles.module.scss';

interface IQuestionnaireQuestionsList {
  questionsGroups: IQuestionnaireQuestionsGroup[];
}

export const QuestionnaireQuestionsList: React.FC<IQuestionnaireQuestionsList> = ({
  questionsGroups,
}) => {
  return (
    <div className={'flex flex-col flex-grow overflow-hidden'}>
      <TableHeader />
      <div className={twMerge('overflow-y-auto', styles.scrollbar)}>
        {questionsGroups.map((questionGroup, index) => (
          <QuestionnaireQuestionsGroup
            name={questionGroup.name}
            index={index + 1}
            questionsList={questionGroup.questionsList}
            id={questionGroup.id}
            key={questionGroup.id}
          />
        ))}
      </div>
    </div>
  );
};
