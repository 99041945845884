import { useTranslation } from 'react-i18next';

import { Button } from '@/components/Button/Button';
import { FundingCycleSection } from '@/components/functions/FundingCycle/FundingCycleSection/FundingCycleSection';
import { FundingCycleSectionHeader } from '@/components/functions/FundingCycle/FundingCycleSectionHeader/FundingCycleSectionHeader';
import { useSelectedPerson } from '@/core/hooks/useSelectedPerson';
import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';
import { smartGoalsSelectors } from '@/core/redux/slices/functions/fundingCycle/smartGoals/selectors';
import { smartGoalsActions } from '@/core/redux/slices/functions/fundingCycle/smartGoals/smartGoalsSlice';
import { smartGoalsModalActions } from '@/core/redux/slices/modalsSlice/functions/fundingCycle/smartGoals/smartGoalsModalSlice';

import { GoalsSection } from './GoalsSection/GoalsSection';
import { MeasuresSection } from './MeasuresSection/MeasuresSection';

export const SmartGoals: React.FC = () => {
  const { t: smartGoalsTranslations } = useTranslation('smartGoals');

  const dispatch = useAppDispatch();
  const { selectedPersonID } = useSelectedPerson();

  const selectedSmartGoalID = useAppSelector(smartGoalsSelectors.selectedSmartGoal);
  const selectedMeasureID = useAppSelector(smartGoalsSelectors.selectedMeasure);

  const handleDeleteMeasure = (id: number) => {
    if (!selectedSmartGoalID) {
      return;
    }

    dispatch(
      smartGoalsActions.deleteMeasure({
        measureID: id,
        smartGoalID: selectedSmartGoalID,
      })
    );
  };

  const openDisplaySmartGoalsModal = (id: number) => {
    if (!selectedPersonID) {
      return;
    }

    dispatch(
      smartGoalsModalActions.openDisplaySmartGoalModal({
        smartGoalID: id,
        personID: selectedPersonID,
      })
    );
  };

  const goalsControls = (
    <Button onClick={() => openDisplaySmartGoalsModal(0)}>
      {smartGoalsTranslations('buttons.addGoal.label')}
    </Button>
  );

  const measureControls = selectedMeasureID ? (
    <Button onClick={() => handleDeleteMeasure(selectedMeasureID)}>
      {smartGoalsTranslations('buttons.deleteMeasure.label')}
    </Button>
  ) : null;

  return (
    <div className='h-full flex flex-col'>
      <FundingCycleSection
        className={'max-h-[50%]'}
        header={
          <FundingCycleSectionHeader
            title={smartGoalsTranslations('smartGoals.label')}
            controls={goalsControls}
          />
        }
      >
        <GoalsSection />
      </FundingCycleSection>
      <FundingCycleSection
        header={
          <FundingCycleSectionHeader
            title={smartGoalsTranslations('measures.label')}
            controls={measureControls}
          />
        }
      >
        {selectedSmartGoalID && <MeasuresSection />}
      </FundingCycleSection>
    </div>
  );
};
