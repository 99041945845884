import { PayloadAction } from '@reduxjs/toolkit';
import { NormalizeOAS, OASOutput, OASRequestParams } from 'fets';
import { call, put, takeLatest } from 'redux-saga/effects';

import { authAdd, restCall } from '@/core/clients/rest';
import { toCamelCase } from '@/core/utils/commonUtils';
import { toBackendDate, toClientDateInput } from '@/core/utils/dateTimeUtil';
import type oas from '@/services/rest/base/openapi';
import { Camelize } from '@/types/camelize';
import { LoadingStatus } from '@/types/loadingStatus';

import {
  evaluateGoalsActions,
  IGoalCheckCreatePayload,
  IGoalCheckDeletePayload,
  IGoalCheckDetailsFetchPayload,
  IGoalChecksFetchPayload,
  IGoalCheckUpdatePayload,
  INextExpectedDateFetchPayload,
} from './evaluateGoalsSlice';

// types

type GoalChecksResponse = OASOutput<
  NormalizeOAS<typeof oas>,
  '/funding_cycle/smart_goal/check',
  'get',
  '200'
>;
type GoalCheckDetailsResponse = OASOutput<
  NormalizeOAS<typeof oas>,
  '/funding_cycle/smart_goal/check/details',
  'get',
  '200'
>;
type NextExpectedDateResponse = OASOutput<
  NormalizeOAS<typeof oas>,
  '/funding_cycle/smart_goal/check/next_expected_date',
  'get',
  '200'
>;
type GoalCheckCreateRequest = OASRequestParams<
  NormalizeOAS<typeof oas>,
  '/funding_cycle/smart_goal/check',
  'post'
>;
type GoalCheckUpdateRequest = OASRequestParams<
  NormalizeOAS<typeof oas>,
  '/funding_cycle/smart_goal/check',
  'put'
>;
type GoalCheckDeleteRequest = OASRequestParams<
  NormalizeOAS<typeof oas>,
  '/funding_cycle/smart_goal/check',
  'delete'
>;

// sagas

function* fetchGoalChecks(
  action: PayloadAction<IGoalChecksFetchPayload>
): Generator<any, void, GoalChecksResponse> {
  const { smartGoalID } = action.payload;

  try {
    const response = yield call(restCall, '/funding_cycle/smart_goal/check', 'get', {
      query: {
        smart_goal_id: smartGoalID,
      },
      ...authAdd(),
    });

    const camelizeResponse: Camelize<GoalChecksResponse> = toCamelCase(response);
    const checkGoals = camelizeResponse.goalChecks.map((item) => ({
      ...item,
      checkedAtDate: toClientDateInput(item.checkedAtDate) ?? '',
      nextCheckDate: toClientDateInput(item.nextCheckDate) ?? '',
    }));

    yield put(evaluateGoalsActions.setGoalChecks(checkGoals));
  } catch (error) {
    console.error('Error on goal checks fetching');
  }
}

function* fetchGoalCheckDetails(
  action: PayloadAction<IGoalCheckDetailsFetchPayload>
): Generator<any, void, GoalCheckDetailsResponse> {
  const { goalCheckID } = action.payload;

  yield put(evaluateGoalsActions.setGoalCheckDetailsLock(LoadingStatus.LOADING));

  try {
    const response = yield call(restCall, '/funding_cycle/smart_goal/check/details', 'get', {
      query: {
        goal_check_id: goalCheckID,
      },
      ...authAdd(),
    });

    const camelizeResponse: Camelize<GoalCheckDetailsResponse> = toCamelCase(response);
    const goalCheckDetails = {
      ...camelizeResponse.details,
      checkedAtDate: toClientDateInput(camelizeResponse.details.checkedAtDate) ?? '',
      nextCheckDate: toClientDateInput(camelizeResponse.details.nextCheckDate) ?? '',
    };

    yield put(evaluateGoalsActions.setGoalCheckDetails(goalCheckDetails));

    yield put(evaluateGoalsActions.setGoalCheckDetailsLock(LoadingStatus.LOADED));
  } catch (error) {
    console.error('Error on goal check details fetching');
    yield put(evaluateGoalsActions.setGoalCheckDetailsLock(LoadingStatus.ERROR));
  }
}

function* fetchNextExpectedDate(
  action: PayloadAction<INextExpectedDateFetchPayload>
): Generator<any, void, NextExpectedDateResponse> {
  const { smartGoalID, currentCheckDate } = action.payload;

  try {
    const response = yield call(
      restCall,
      '/funding_cycle/smart_goal/check/next_expected_date',
      'get',
      {
        query: {
          smart_goal_id: smartGoalID,
          current_check_date: toBackendDate(currentCheckDate) ?? '',
        },
        ...authAdd(),
      }
    );

    const camelizeResponse: Camelize<NextExpectedDateResponse> = toCamelCase(response);
    const nextExpectedCheckDate = toClientDateInput(camelizeResponse.nextExpectedCheckDate);

    yield put(evaluateGoalsActions.setNextExpectedDate(nextExpectedCheckDate));
  } catch (error) {
    console.error('Error on next expected date fetching');
  }
}

function* createGoalCheck(
  action: PayloadAction<IGoalCheckCreatePayload>
): Generator<any, void, any> {
  const { smartGoalID, goalCheckData } = action.payload;
  const { checkedAtDate, achievementLevel, note, nextAction, nextCheckDate } = goalCheckData;

  yield put(evaluateGoalsActions.setCreateGoalCheckLock(LoadingStatus.LOADING));

  try {
    const request: GoalCheckCreateRequest = {
      json: {
        smart_goal_id: smartGoalID,
        checked_at_date: toBackendDate(checkedAtDate) ?? '',
        achievement_level_id: achievementLevel,
        note: note,
        next_action_id: nextAction,
        next_check_date: toBackendDate(nextCheckDate),
      },
      ...authAdd(),
    };

    yield call(
      restCall,
      '/funding_cycle/smart_goal/check',
      'post',
      {
        ...request,
      },
      null,
      true
    );

    yield put(evaluateGoalsActions.setCreateGoalCheckLock(LoadingStatus.LOADED));

    yield put(
      evaluateGoalsActions.fetchGoalChecks({
        smartGoalID: smartGoalID,
      })
    );
  } catch (error) {
    if (error instanceof Error) {
      console.error('Error on create goal check', error.message);
      yield put(evaluateGoalsActions.setCreateGoalCheckLock(LoadingStatus.ERROR));
      yield put(evaluateGoalsActions.setGoalCheckNotification(error.message));
    }
  }
}

function* updateGoalCheck(
  action: PayloadAction<IGoalCheckUpdatePayload>
): Generator<any, void, any> {
  const { smartGoalID, goalCheckID, goalCheckData } = action.payload;
  const { checkedAtDate, achievementLevel, note, nextAction, nextCheckDate } = goalCheckData;

  yield put(evaluateGoalsActions.setUpdateGoalCheckLock(LoadingStatus.LOADING));

  try {
    const request: GoalCheckUpdateRequest = {
      json: {
        id: goalCheckID,
        checked_at_date: toBackendDate(checkedAtDate) ?? '',
        achievement_level_id: achievementLevel,
        note: note,
        next_action_id: nextAction,
        next_check_date: toBackendDate(nextCheckDate),
      },
      ...authAdd(),
    };

    yield call(
      restCall,
      '/funding_cycle/smart_goal/check',
      'put',
      {
        ...request,
      },
      null,
      true
    );

    yield put(evaluateGoalsActions.setUpdateGoalCheckLock(LoadingStatus.LOADED));

    yield put(
      evaluateGoalsActions.fetchGoalChecks({
        smartGoalID: smartGoalID,
      })
    );
  } catch (error) {
    if (error instanceof Error) {
      console.error('Error on update goal check', error.message);
      yield put(evaluateGoalsActions.setCreateGoalCheckLock(LoadingStatus.ERROR));
      yield put(evaluateGoalsActions.setGoalCheckNotification(error.message));
    }
  }
}

function* deleteGoalCheck(
  action: PayloadAction<IGoalCheckDeletePayload>
): Generator<any, void, any> {
  const { smartGoalID, goalCheckID } = action.payload;

  try {
    const request: GoalCheckDeleteRequest = {
      query: {
        goal_check_id: goalCheckID,
      },
      ...authAdd(),
    };

    yield call(
      restCall,
      '/funding_cycle/smart_goal/check',
      'delete',
      {
        ...request,
      },
      null,
      true
    );
    
    yield put(
      evaluateGoalsActions.fetchGoalChecks({
        smartGoalID: smartGoalID,
      })
    );

    yield put(evaluateGoalsActions.setSelectedGoalCheck(null));
  } catch (error) {
    console.error('Error on delete goal check', error);
  }
}

export const evauluateGoalsSagas = [
  takeLatest(evaluateGoalsActions.fetchGoalChecks, fetchGoalChecks),
  takeLatest(evaluateGoalsActions.fetchGoalCheckDetails, fetchGoalCheckDetails),
  takeLatest(evaluateGoalsActions.fetchNextExpectedDate, fetchNextExpectedDate),
  takeLatest(evaluateGoalsActions.createGoalCheck, createGoalCheck),
  takeLatest(evaluateGoalsActions.updateGoalCheck, updateGoalCheck),
  takeLatest(evaluateGoalsActions.deleteGoalCheck, deleteGoalCheck),
];
