import { useMemo } from 'react';
import { isArray } from 'lodash';
import fp from 'lodash/fp';

import { usePersonPageStoreSelector } from '@/components/views/person/PersonPage/Context/PersonPageStoreContext';

export function useSelectedLocations() {
  const [locationsChecksState] = usePersonPageStoreSelector((store) => store.locationsChecksState);

  const locationsChecksStateSelected = useMemo(
    () => locationsChecksState?.checked,
    [locationsChecksState]
  );

  return useMemo(() => {
    if (fp.isEmpty(locationsChecksStateSelected)) {
      return null;
    }
    if (locationsChecksState && isArray(locationsChecksStateSelected)) {
      return locationsChecksStateSelected
        .map((location) => String(location)?.split('_')[1])
        .filter((location) => !fp.isEmpty(location));
    }
    return null;
  }, [locationsChecksStateSelected]);
}
