import React, { useEffect, useState } from 'react';
import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import fp from 'lodash/fp';

import { DevelopmentReportGoalsAndActivity } from '@/components/functions/DevelopmentReport/DevelopmentReportForms/GoalsAndActivity/GoalsAndActivity';
import { DevelopmentReportPersonDetails } from '@/components/functions/DevelopmentReport/DevelopmentReportForms/PersonDetails/PersonDetails';
import { DevelopmentReportReviewSupport } from '@/components/functions/DevelopmentReport/DevelopmentReportForms/ReviewSupport/ReviewSupport';
import { DevelopmentReportVisionAndGoals } from '@/components/functions/DevelopmentReport/DevelopmentReportForms/VisionAndGoals/VisionAndGoals';
import { DevelopmentReportNavigation } from '@/components/functions/DevelopmentReport/DevelopmentReportNavigation/DevelopmentReportNavigation';
import { useDevelopmentReportForm } from '@/components/functions/DevelopmentReport/hooks/useDevelopmentReportForm';
import { FunctionContent } from '@/components/wrapper/FunctionWrapper/FunctionContent/FunctionContent';
import { FunctionWrapper } from '@/components/wrapper/FunctionWrapper/FunctionWrapper';
import { DevelopmentReportForms } from '@/core/enums/functions/developmentReport/developmentReportFormsEnum';
import { useSelectedPerson } from '@/core/hooks/useSelectedPerson';
import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';
import { developmentReportSelectors } from '@/core/redux/slices/functions/developmentReport/selectors';
import { developmentReportActions } from '@/core/redux/slices/functions/developmentReport/slice';

export interface IDevelopmentReportTab {
  form: React.ReactNode;
  title: string;
  type: DevelopmentReportForms;
  shortcut?: string | null;
}

export const DevelopmentReport: React.FC = () => {
  const { t: developmentReportTranslations } = useTranslation('developmentReport');

  const [activeForm, setActiveForm] = useState<DevelopmentReportForms>(
    DevelopmentReportForms.PersonDetails
  );

  const developmentReport = useAppSelector(developmentReportSelectors.developmentReport);

  const { form } = useDevelopmentReportForm();
  const { selectedPersonID } = useSelectedPerson();

  const dispatch = useAppDispatch();

  const handleSwitchTab = (selectedTab: DevelopmentReportForms) => {
    const { isDirty } = form.getFieldState(activeForm);

    if (isDirty) {
      form.trigger(activeForm).then((isValid) => {
        if (isValid && selectedPersonID && developmentReport?.id) {
          const currentValues = fp.cloneDeep(form.watch());

          dispatch(
            developmentReportActions.updateDevelopmentReportForm({
              type: activeForm,
              personID: selectedPersonID,
              form: currentValues,
              developmentReportID: developmentReport.id,
            })
          );

          setActiveForm(selectedTab);
        }
      });

      return;
    }

    setActiveForm(selectedTab);
  };

  const developmentReportTabs: Record<DevelopmentReportForms, IDevelopmentReportTab> = {
    [DevelopmentReportForms.PersonDetails]: {
      form: <DevelopmentReportPersonDetails />,
      title: developmentReportTranslations('tabs.personDetails.label'),
      type: DevelopmentReportForms.PersonDetails,
      shortcut: '',
    },
    [DevelopmentReportForms.VisionAndGoals]: {
      form: <DevelopmentReportVisionAndGoals />,
      title: developmentReportTranslations('tabs.visionGoals.label'),
      type: DevelopmentReportForms.VisionAndGoals,
      shortcut: '',
    },
    [DevelopmentReportForms.Review]: {
      form: <DevelopmentReportReviewSupport />,
      title: developmentReportTranslations('tabs.review.label'),
      type: DevelopmentReportForms.Review,
      shortcut: '',
    },
    [DevelopmentReportForms.GoalsAndActivityPlaning]: {
      form: <DevelopmentReportGoalsAndActivity />,
      title: developmentReportTranslations('tabs.activityPlaning.label'),
      type: DevelopmentReportForms.GoalsAndActivityPlaning,
      shortcut: '',
    },
  };

  useEffect(() => {
    if (!selectedPersonID) {
      return;
    }

    dispatch(
      developmentReportActions.fetchDevelopmentReport({
        personID: selectedPersonID,
      })
    );
  }, [selectedPersonID]);

  return (
    <FunctionWrapper>
      <FunctionContent className={'flex flex-col px-4 overflow-hidden'}>
        <DevelopmentReportNavigation
          developmentReportTabs={developmentReportTabs}
          onTabSwitch={handleSwitchTab}
          activeTab={activeForm}
        />
        <div className={'h-full overflow-y-auto'}>
          <FormProvider {...form}>{developmentReportTabs[activeForm].form}</FormProvider>
        </div>
      </FunctionContent>
    </FunctionWrapper>
  );
};
