import React from 'react';
import { useTranslation } from 'react-i18next';

import { Typography } from '@/components/Typography/Typography';
import { colorTypes } from '@/styles/types';

export const ListHeader: React.FC = () => {
  const { t: smartGoalsTranslations } = useTranslation('smartGoals', {
    keyPrefix: 'table.header',
  });

  return (
    <div
      className={`grid grid-cols-12 gap-2 items-start border-y border-${colorTypes.Stroke} px-[30px] py-3`}
    >
      <Typography color={'Secondary'} className={'col-span-7'}>
        {smartGoalsTranslations('name.label')}
      </Typography>
      <div className='col-span-5 grid grid-cols-12'>
        <Typography color={'Secondary'} className={'col-span-4'}>
          {smartGoalsTranslations('nearestActivity.label')}
        </Typography>
        <Typography color={'Secondary'} className={'col-span-4'}>
          {smartGoalsTranslations('type.label')}
        </Typography>
        <Typography color={'Secondary'} className={'col-span-4'}>
          {smartGoalsTranslations('lastActivity.label')}
        </Typography>
      </div>
    </div>
  );
};
