import React, { useEffect } from 'react';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { debounce } from 'lodash';
import fp from 'lodash/fp';

import { Button } from '@/components/Button/Button';
import { AddParticipantsList } from '@/components/Modals/Modals/functions/activityPlaningModals/participantsListModals/AddParticipantsModal/AddParticipantsList/AddParticipantsList';
import { Sidebar } from '@/components/Overlays/Sidebar/Sidebar';
import { DropdownItemsByFetch } from '@/core/enums/common/DropdownItemsByFetchEnum';
import { AddParticipantsMeasureStateTypes } from '@/core/enums/functions/activityPlanning/addParticipantsMeasureStateTypesEnum';
import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';
import { dropdownItemsActions } from '@/core/redux/slices/dropdownItems/dropdownItems.slice';
import { activityPlaningParticipantsModalsSelectors } from '@/core/redux/slices/modalsSlice/functions/activityPlanning/activityPlanningParticipants/selectors';
import {
  activityPlaningParticipantsModalsActions,
  IActivityPlanningAddedParticipant,
} from '@/core/redux/slices/modalsSlice/functions/activityPlanning/activityPlanningParticipants/slice';

export interface IAddParticipantsModalForm {
  participants: Record<string, boolean>;
  filters?: {
    name?: string;
    departmentID?: number;
    measureState: AddParticipantsMeasureStateTypes;
  };
}

export const AddParticipantsModal: React.FC = () => {
  const { isRender } = useAppSelector(
    activityPlaningParticipantsModalsSelectors.addParticipantsModal
  );

  return isRender ? <AddParticipantsModalContent /> : null;
};

const AddParticipantsModalContent: React.FC = () => {
  const { t: addParticipantsModalTranslations } = useTranslation('addParticipantsModal');

  const { payload, participantsList } = useAppSelector(
    activityPlaningParticipantsModalsSelectors.addParticipantsModal
  );

  const form = useForm<IAddParticipantsModalForm>();
  const filters = useWatch({ control: form.control, name: 'filters' });
  const currentValues = form.getValues('participants');

  const dispatch = useAppDispatch();

  const values = fp.values(currentValues);
  const filteredValues = values.filter((value) => Boolean(value));

  const places = payload?.placesCount || 0;
  const hasPlaces = filteredValues.length < places;

  const handleExit = () => {
    dispatch(activityPlaningParticipantsModalsActions.closeAddParticipantsModal());
  };

  const handleSave = () => {
    const currentValues = form.getValues('participants');

    if (!currentValues || !payload?.appointmentID || !payload.measureID) {
      return;
    }

    const participantsKeys = Object.keys(currentValues);

    const mappedParticipants: IActivityPlanningAddedParticipant[] = participantsKeys.reduce<
      IActivityPlanningAddedParticipant[]
    >((accum, participantKey) => {
      if (!currentValues[participantKey]) {
        return accum;
      }

      const participant: IActivityPlanningAddedParticipant = {
        personID: Number(participantKey.split('=')[1]),
        appointmentID: payload?.appointmentID,
        measureID: payload?.measureID,
      };

      accum.push(participant);

      return accum;
    }, []);

    dispatch(
      activityPlaningParticipantsModalsActions.addParticipants({
        participants: mappedParticipants,
        measureID: payload.measureID,
        appointmentID: payload.appointmentID,
      })
    );
  };

  const handleCancel = () => {
    form.formState.isDirty ? form.reset() : handleExit();
  };

  useEffect(() => {
    if (!payload) {
      return;
    }

    const debouncedSearch = debounce(() => {
      dispatch(
        activityPlaningParticipantsModalsActions.fetchParticipantsList({
          appointmentID: payload.appointmentID,
          measureID: payload.measureID,
          name: filters?.name,
          departmentID: filters?.departmentID,
          isMeasureAssignedHasAppointment:
            filters?.measureState ===
            AddParticipantsMeasureStateTypes.isMeasureAssignedHasAppointment,
          isMeasureAssigned:
            filters?.measureState === AddParticipantsMeasureStateTypes.isMeasureAssigned,
          isMeasureAppointmentCompleted:
            filters?.measureState ===
            AddParticipantsMeasureStateTypes.isMeasureAppointmentCompleted,
        })
      );
    });

    debouncedSearch();

    return () => {
      debouncedSearch.cancel();
    };
  }, [payload?.appointmentID, payload?.measureID, filters]);

  useEffect(() => {
    dispatch(
      dropdownItemsActions.fetchDropdownItems({
        dropdownTable: DropdownItemsByFetch.MEASURE_LOCATION,
      })
    );
  }, []);

  const footer = (
    <div className={'flex gap-2 justify-end'}>
      <Button onClick={handleSave}>{addParticipantsModalTranslations('buttons.save.label')}</Button>
      <Button buttonVariant={'Secondary'} onClick={handleCancel}>
        {addParticipantsModalTranslations('buttons.cancel.label')}
      </Button>
    </div>
  );

  return (
    <Sidebar
      onExit={handleExit}
      position={'right'}
      footer={footer}
      title={addParticipantsModalTranslations('title.label', {
        measureName: payload?.measureName || '',
      })}
      className={'max-w-[75vw]'}
    >
      <div className={'p-3 h-full'}>
        <FormProvider {...form}>
          <AddParticipantsList participantsList={participantsList} hasPlaces={hasPlaces} />
        </FormProvider>
      </div>
    </Sidebar>
  );
};
