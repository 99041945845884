import React from 'react';
import { useFormContext } from 'react-hook-form';

import { Button } from '@/components/Button/Button';
import { Checkbox } from '@/components/Checkbox/Checkbox';
import { ICompleteMeasureFormGroupItem } from '@/components/Modals/Modals/functions/vocationalTrainingAreaModals/CompleteMeasureModal/CompleteMeasureModalContent/hooks/useCompleteMeasureModalForm';
import { TextArea } from '@/components/TextArea/TextArea';
import { Typography } from '@/components/Typography/Typography';
import { IAppointment } from '@/core/redux/slices/modalsSlice/functions/vocationalTrainingArea/slice';
import { twMerge } from '@/core/utils/tailwindUtil';
import { colorTypes } from '@/styles/types';

export const CompleteMeasureAppointmentListItem: React.FC<IAppointment> = ({
  text,
  type,
  note,
  id,
}) => {
  const { watch, register } = useFormContext();

  const currentValues: ICompleteMeasureFormGroupItem[] = watch('appointments');

  const foundIndex = currentValues.findIndex((appointment) => appointment.id === id);

  return (
    <Button
      buttonStyle={'Text'}
      buttonVariant={'Transparent'}
      className={twMerge(
        `col-span-12 grid grid-cols-12 items-center justify-center hover:bg-${colorTypes.HoverLight} py-1 px-[30px] border-l`
      )}
    >
      <Typography className={'col-span-1 whitespace-normal'} type={'16-M'} color={'Secondary'}>
        {type}
      </Typography>
      <Typography className={'col-span-4 whitespace-normal'} type={'16-M'} color={'Secondary'}>
        {text}
      </Typography>
      <Checkbox
        name={`appointments.${foundIndex}.isParticipated`}
        preventDefault={true}
        className={'justify-self-center'}
      />
      <Checkbox
        name={`appointments.${foundIndex}.isUnderDirection`}
        preventDefault={true}
        className={'justify-self-center'}
      />
      <Checkbox
        name={`appointments.${foundIndex}.isAlmostIndependent`}
        preventDefault={true}
        className={'justify-self-center'}
      />
      <Checkbox
        name={`appointments.${foundIndex}.isIndependent`}
        preventDefault={true}
        className={'justify-self-center'}
      />
      <TextArea
        control={register(`appointments.${foundIndex}.comment`)}
        containerClassName={'col-span-3 w-full'}
        readOnly={true}
      >
        {note}
      </TextArea>
    </Button>
  );
};
