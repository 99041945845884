import { IChecklist } from '@/core/redux/slices/functions/checklist/checklistSlice';

import { TableGroup } from './TableGroup';
import { TableHeader } from './TableHeader';

interface ICheckListTable {
  checklists: IChecklist[];
}

export const CheckListTable: React.FC<ICheckListTable> = ({ checklists }) => {
  return (
    <div className={'flex flex-col flex-grow overflow-hidden'}>
      <TableHeader />
      <div className='overflow-y-auto'>
        {checklists.map((checklist, index) => (
          <TableGroup
            name={checklist.name}
            index={index + 1}
            checkpoints={checklist.checkpoints}
            id={checklist.id}
            key={checklist.id}
          />
        ))}
      </div>
    </div>
  );
};
