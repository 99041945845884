import fp from 'lodash/fp';

import { IDepartment } from '@/types/department';
import { ILocation } from '@/types/location';
import { SortingBy } from '@/types/sortingBy';

export function searchByName(departments: IDepartment[], searchValue: string): IDepartment[] {
  if (fp.isEmpty(searchValue)) {
    return departments;
  }

  const search = searchValue.toLowerCase();
  const filteredArray = departments.map((department) => {
    if (department.name.toLowerCase().includes(search)) {
      return department;
    }

    const filteredLocations = department.departments.filter((department) =>
      department.name.toLowerCase().includes(search)
    );

    return { ...department, locations: filteredLocations };
  });

  return filteredArray.filter((department) => department.departments.length > 0);
}

export function sortLocations(department: IDepartment, sortBy: SortingBy) {
  const copiedLocations = [...department.departments];
  const sortedLocations = copiedLocations.sort((a, b) => sortByName(a, b, sortBy));

  return {
    ...department,
    departments: sortedLocations,
  };
}

export function sortByName(
  a: IDepartment | ILocation,
  b: IDepartment | ILocation,
  sortBy: SortingBy
) {
  const nameA = a.name.toLowerCase();
  const nameB = b.name.toLowerCase();

  return sortBy === SortingBy.nameAscend ? nameA.localeCompare(nameB) : nameB.localeCompare(nameA);
}

export const filterDepartments = (
  departments: IDepartment[],
  searchValue: string
): IDepartment[] => {
  return departments
    .map((department) => {
      if (department.departments) {
        const filteredDepartments = department.departments
          ? filterDepartments(department.departments, searchValue)
          : [];
        const filteredChildGroups = department.childGroups
          ? filterDepartments(department.childGroups, searchValue)
          : [];

        if (
          department.name.toLowerCase().includes(searchValue.toLowerCase()) ||
          filteredDepartments.length > 0 ||
          filteredChildGroups.length > 0
        ) {
          return {
            ...department,
            departments: filteredDepartments,
            childGroups: filteredChildGroups,
          };
        }
      } else {
        if (department.name.toLowerCase().includes(searchValue.toLowerCase())) {
          return department;
        }
      }

      return null;
    })
    .filter((department) => department !== null) as IDepartment[];
};

export const sortDepartments = (departments: IDepartment[], sortBy: SortingBy): IDepartment[] => {
  return departments
    .sort((a, b) => {
      const nameA = a.name.toLowerCase();
      const nameB = b.name.toLowerCase();

      if (sortBy === SortingBy.nameAscend) {
        return nameA.localeCompare(nameB);
      } else {
        return nameB.localeCompare(nameA);
      }
    })
    .map((department) => {
      if (department.departments) {
        // Recursively sort child groups and departments
        return {
          ...department,
          departments: department.departments
            ? sortDepartments(department.departments, sortBy)
            : [],
          childGroups: department.childGroups
            ? sortDepartments(department.childGroups, sortBy)
            : [],
        };
      } else {
        return department;
      }
    });
};
