import { createSelector } from '@reduxjs/toolkit';

import { IRootState } from '@/core/redux/types';

const selector = (state: IRootState) => state.functions.workingTimeMobility.mobility;

const mobilityItems = createSelector([selector], (state) => state.mobilityItems);
const mobilityLock = createSelector([selector], (state) => state.mobilityLock);

export const mobilitySelectors = {
  mobilityItems,
  mobilityLock,
};
