import { PayloadAction } from '@reduxjs/toolkit';
import { NormalizeOAS, OASOutput, OASRequestParams } from 'fets';
import { call, put, takeLatest } from 'redux-saga/effects';

import { authAdd, restCall } from '@/core/clients/rest';
import {
  IAppointment,
  ICloseMeasurePayload,
  IMeasure,
  IMeasuresGroup,
  IRootMeasureGroup,
  IVocationalTrainingAreaDocumentDownloadPayload,
  IVocationalTrainingAreaDocumentsModalFetchPayload,
  vocationalTrainingAreaModalActions,
} from '@/core/redux/slices/modalsSlice/functions/vocationalTrainingArea/slice';
import { notificationsActions } from '@/core/redux/slices/notifications/notificationsSlice';
import { toClientDateInput } from '@/core/utils/dateTimeUtil';
import { readStreamAsBlob } from '@/core/utils/fileUtils';
import oas from '@/services/rest/base/openapi';
import { LoadingStatus } from '@/types/loadingStatus';

type VocationalTrainingAreaDocumentsResponse = OASOutput<
  NormalizeOAS<typeof oas>,
  '/vocational_training_area/measure/document',
  'get',
  '200'
>;

type VocationalTrainingAreaDocumentsRequest = OASRequestParams<
  NormalizeOAS<typeof oas>,
  '/vocational_training_area/measure/document',
  'get'
>;

type VocationalTrainingAreaDocumentDownloadRequest = OASRequestParams<
  NormalizeOAS<typeof oas>,
  '/vocational_training_areameasure/document/download',
  'get'
>;

type CompleteMeasureModalDetailsResponse = OASOutput<
  NormalizeOAS<typeof oas>,
  '/funding_cycle/measure/close',
  'get',
  '200'
>;

type CompleteMeasureModalDetailsRequest = OASRequestParams<
  NormalizeOAS<typeof oas>,
  '/funding_cycle/measure/close',
  'get'
>;

type RemoveMeasureFromPersonRequest = OASRequestParams<
  NormalizeOAS<typeof oas>,
  '/funding_cycle/measure/delete',
  'delete'
>;

type CloseMeasureRequest = OASRequestParams<
  NormalizeOAS<typeof oas>,
  '/funding_cycle/measure/close',
  'post'
>;

function mapCompleteMeasureModalDetailsResponse(
  data: CompleteMeasureModalDetailsResponse
): IRootMeasureGroup[] {
  return data.groups.map(mapRootMeasureGroup);
}

function mapRootMeasureGroup(
  rootGroup: CompleteMeasureModalDetailsResponse['groups'][0]
): IRootMeasureGroup {
  return {
    id: rootGroup.id,
    groups: rootGroup.groups.map(mapMeasureGroup),
    name: rootGroup.name,
    surname: rootGroup.surname,
  };
}

function mapMeasureGroup(
  measureGroup: CompleteMeasureModalDetailsResponse['groups'][0]['groups'][0]
): IMeasuresGroup {
  return {
    id: measureGroup.id,
    items: measureGroup.items.map(mapMeasure),
    name: measureGroup.name,
  };
}

function mapMeasure(
  measure: CompleteMeasureModalDetailsResponse['groups'][0]['groups'][0]['items'][0]
): IMeasure {
  return {
    id: measure.id,
    type: measure.type,
    isParticipated: measure.is_participated,
    isUnderDirection: measure.is_under_direction,
    isAlmostIndependent: measure.is_almost_independent,
    isIndependent: measure.is_independent,
    appointments: measure.appointments.map(mapAppointment),
    text: measure.text,
    explanation: measure.explanation,
    note: measure.note,
  };
}

function mapAppointment(
  appointment: CompleteMeasureModalDetailsResponse['groups'][0]['groups'][0]['items'][0]['appointments'][0]
): IAppointment {
  return {
    id: appointment.id,
    appointmentDate: toClientDateInput(appointment.appointment_date),
    type: appointment.type,
    isParticipated: appointment.is_participated,
    isUnderDirection: appointment.is_under_direction,
    isAlmostIndependent: appointment.is_almost_independent,
    isIndependent: appointment.is_independent,
    text: appointment.text,
    explanation: appointment.explanation,
    note: appointment.note,
  };
}

function* fetchVocationalTrainingAreaDocuments(
  action: PayloadAction<IVocationalTrainingAreaDocumentsModalFetchPayload>
): Generator<any, void, VocationalTrainingAreaDocumentsResponse> {
  const { measureID } = action.payload;

  yield put(
    vocationalTrainingAreaModalActions.setVocationalTrainingAreaDocumentsLock(LoadingStatus.LOADING)
  );

  try {
    const request: VocationalTrainingAreaDocumentsRequest = {
      query: {
        measure_id: measureID,
      },
      ...authAdd(),
    };

    const response = yield call(
      restCall,
      '/vocational_training_area/measure/document',
      'get',
      request
    );

    yield put(
      vocationalTrainingAreaModalActions.setVocationalTrainingAreaDocuments(response.documents)
    );

    yield put(
      vocationalTrainingAreaModalActions.setVocationalTrainingAreaDocumentsLock(
        LoadingStatus.LOADED
      )
    );
  } catch (error) {
    yield put(
      vocationalTrainingAreaModalActions.setVocationalTrainingAreaDocumentsLock(LoadingStatus.ERROR)
    );
  }
}

function* downloadVocationalTrainingAreaDocument(
  action: PayloadAction<IVocationalTrainingAreaDocumentDownloadPayload>
): Generator<any, void, any> {
  const { documentID } = action.payload;

  try {
    const request: VocationalTrainingAreaDocumentDownloadRequest = {
      query: {
        document_id: documentID,
      },
      ...authAdd(),
    };

    yield put(
      vocationalTrainingAreaModalActions.setDownloadVocationalTrainingAreaDocumentLock({
        documentID: documentID,
        status: LoadingStatus.LOADING,
      })
    );

    const response = yield call(
      restCall,
      '/vocational_training_areameasure/document/download',
      'get',
      request,
      undefined,
      true
    );

    if (response.body) {
      const blob = yield readStreamAsBlob(response.body);
      const contentDisposition = response.headers.get('content-disposition');
      const fileName = contentDisposition.split("filename*=UTF-8''")[1];

      const blobUrl = URL.createObjectURL(blob);
      const a = document.createElement('a');
      document.body.appendChild(a);
      a.href = blobUrl;
      a.download = fileName;
      a.click();
      URL.revokeObjectURL(blobUrl);
      document.body.removeChild(a);
    }

    yield put(
      vocationalTrainingAreaModalActions.setDownloadVocationalTrainingAreaDocumentLock({
        documentID: documentID,
        status: LoadingStatus.LOADED,
      })
    );
  } catch (error) {
    console.log('Error on vocational training document download', error);
    yield put(
      vocationalTrainingAreaModalActions.setDownloadVocationalTrainingAreaDocumentLock({
        documentID: documentID,
        status: LoadingStatus.ERROR,
      })
    );
    yield put(
      notificationsActions.showNotification({
        notification: {
          type: 'error',
          title: 'Error',
          description: 'File not found',
        },
      })
    );
  }
}

function* fetchCompleteMeasureModalDetails(
  action: PayloadAction<number[]>
): Generator<any, void, CompleteMeasureModalDetailsResponse> {
  try {
    yield put(
      vocationalTrainingAreaModalActions.setCompleteMeasureModalDetailsLock(LoadingStatus.LOADING)
    );

    const request: CompleteMeasureModalDetailsRequest = {
      query: {
        measure_to_person_ids: action.payload,
      },
      ...authAdd(),
    };

    const response = yield call(restCall, '/funding_cycle/measure/close', 'get', request);
    const completeMeasureModalDetails: IRootMeasureGroup[] =
      mapCompleteMeasureModalDetailsResponse(response);

    yield put(
      vocationalTrainingAreaModalActions.setCompleteMeasureModalDetails(completeMeasureModalDetails)
    );
    yield put(
      vocationalTrainingAreaModalActions.setCompleteMeasureModalDetailsLock(LoadingStatus.LOADED)
    );
  } catch (error) {
    console.log('Error on complete measure modal details fetch', error);
    yield put(
      vocationalTrainingAreaModalActions.setCompleteMeasureModalDetailsLock(LoadingStatus.ERROR)
    );
  }
}

function* removeMeasureFromPerson(
  action: PayloadAction<number[]>
): Generator<any, void, VocationalTrainingAreaDocumentsResponse> {
  try {
    const request: RemoveMeasureFromPersonRequest = {
      query: {
        measure_to_person_ids: action.payload,
      },
      ...authAdd(),
    };

    yield call(restCall, '/funding_cycle/measure/delete', 'delete', request);
  } catch (error) {
    console.log('Error on measure removing', error);
  }
}

function* closeMeasure(
  action: PayloadAction<ICloseMeasurePayload>
): Generator<any, void, VocationalTrainingAreaDocumentsResponse> {
  const { measureItems } = action.payload;

  try {
    const request: CloseMeasureRequest = {
      json: measureItems.map<CloseMeasureRequest['json'][0]>((measureItem) => ({
        measure_to_person_id: measureItem.id,
        note: measureItem.note,
        is_almost_independent: measureItem.isAlmostIndependent,
        is_independent: measureItem.isIndependent,
        is_participated: measureItem.isParticipated,
        is_under_direction: measureItem.isUnderDirection,
        level: measureItem.level,
      })),
      ...authAdd(),
    };

    yield call(restCall, '/funding_cycle/measure/close', 'post', request);

    yield put(vocationalTrainingAreaModalActions.closeCompleteMeasureModal());
  } catch (error) {
    console.log('Error on measure closing', error);
  }
}

export const vocationalTrainingAreaModalsSagas = [
  takeLatest(
    vocationalTrainingAreaModalActions.fetchVocationalTrainingAreaDocuments,
    fetchVocationalTrainingAreaDocuments
  ),
  takeLatest(
    vocationalTrainingAreaModalActions.downloadVocationalTrainingAreaDocument,
    downloadVocationalTrainingAreaDocument
  ),
  takeLatest(
    vocationalTrainingAreaModalActions.fetchCompleteMeasureModalDetails,
    fetchCompleteMeasureModalDetails
  ),
  takeLatest(vocationalTrainingAreaModalActions.removeMeasureFromPerson, removeMeasureFromPerson),
  takeLatest(vocationalTrainingAreaModalActions.closeMeasure, closeMeasure),
];
