import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { CheckboxUI } from '@/components/Checkbox/Checkbox';
import { Typography } from '@/components/Typography/Typography';
import { useSelectedPerson } from '@/core/hooks/useSelectedPerson';
import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';
import { efficacyAssessmentActions } from '@/core/redux/slices/efficacyAssessment/efficacyAssessmentSlice';
import { efficacyAssessmentSelectors } from '@/core/redux/slices/efficacyAssessment/selectors';
import { efficacyAssessmentModalSelectors } from '@/core/redux/slices/modalsSlice/efficacyAssessment/selectors';

import { ListGroup } from './ListGroup/ListGroup';
import { ListHeader } from './ListHeader/ListHeader';

export const EfficacyAssessmentTable: React.FC = () => {
  const dispatch = useAppDispatch();

  const { t: efficacyAssessmentModalTranslations } = useTranslation('efficacyAssessmentModal');

  const { selectedPersonID } = useSelectedPerson();

  const [isShowAll, setIsShowAll] = useState(false);

  const data = useAppSelector(efficacyAssessmentSelectors.efficacyAssessment);
  const { payload } = useAppSelector(efficacyAssessmentModalSelectors.efficacyAssessmentModal);

  useEffect(() => {
    if (!selectedPersonID || !payload) {
      return;
    }

    dispatch(
      efficacyAssessmentActions.fetchEfficacyAssessment({
        personID: selectedPersonID,
        measureID: !isShowAll ? payload.measureID : undefined,
      })
    );
  }, [isShowAll]);

  return (
    <div className={'overflow-y-auto flex flex-col'}>
      <label className='w-fit flex gap-2 cursor-pointer p-5'>
        <CheckboxUI
          checked={isShowAll}
          onChange={(value) => setIsShowAll(value)}
          className='pointer-events-none'
        />
        <Typography>{efficacyAssessmentModalTranslations('filter.isShowAll.label')}</Typography>
      </label>

      <ListHeader />
      <div className='overflow-auto grow'>
        {data && data.id !== -1 && (
          <ListGroup
            id={data.id}
            items={data.items}
            name={data.name}
            childGroups={data.childGroups}
            layer={0}
          />
        )}
      </div>
    </div>
  );
};
