import { ObjectSchema } from 'yup';

import { goalsAndActivityValidator } from '@/components/functions/DevelopmentReport/DevelopmentReportForms/GoalsAndActivity/helper/goalsAndActivityValidator';
import { developmentReportPersonDetailsValidator } from '@/components/functions/DevelopmentReport/DevelopmentReportForms/PersonDetails/helper/developmentReportPersonDetailsValidator';
import { visionAndGoalsValidator } from '@/components/functions/DevelopmentReport/DevelopmentReportForms/VisionAndGoals/helper/visionAndGoalsValidator';
import { IDevelopmentReportForm } from '@/components/functions/DevelopmentReport/hooks/useDevelopmentReportForm';
import { DevelopmentReportForms } from '@/core/enums/functions/developmentReport/developmentReportFormsEnum';
import { yup } from '@/core/utils/commonUtils';

export const developmentReportValidator: ObjectSchema<IDevelopmentReportForm> = yup.object().shape({
  [DevelopmentReportForms.PersonDetails]: developmentReportPersonDetailsValidator,
  [DevelopmentReportForms.GoalsAndActivityPlaning]: goalsAndActivityValidator,
  [DevelopmentReportForms.Review]: yup.object(),
  [DevelopmentReportForms.VisionAndGoals]: visionAndGoalsValidator,
});
