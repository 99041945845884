import { TFunction } from 'i18next';
import { ObjectSchema } from 'yup';

import { IDevelopmentReportGoalsAndActivityForm } from '@/components/functions/DevelopmentReport/DevelopmentReportForms/GoalsAndActivity/helper/goalsAndActivityForm';
import { yup } from '@/core/utils/commonUtils';

export const goalsAndActivityValidatorBuilder = (
  translations: TFunction<'developmentReportGoalsAndActivity', undefined>
): ObjectSchema<any> => yup.object().shape({});

export const goalsAndActivityValidator: ObjectSchema<IDevelopmentReportGoalsAndActivityForm> = yup
  .object()
  .shape({
    goalsAndActivities: yup
      .array()
      .of(
        yup.object().shape({
          lfdNr: yup.number().required(),
          goal: yup.string().nullable(),
          requiredActions: yup.string().nullable(),
          until: yup.string().nullable(),
          executor: yup.string().nullable(),
          executionPlace: yup.string().nullable(),
          smartGoalId: yup.number().nullable(),
        })
      )
      .required(),
  });
