import React from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@/components/Button/Button';
import { CheckedCircleIcon } from '@/components/Icons/CheckedCircleIcon';
import { DocumentViewIcon } from '@/components/Icons/DocumentViewIcon';
import { InfoIcon } from '@/components/Icons/InfoIcon';
import { MinusCircleIcon } from '@/components/Icons/MinusCircleIcon';
import { Tag } from '@/components/Tag/Tag';
import { Typography } from '@/components/Typography/Typography';
import { useAppDispatch } from '@/core/redux/hooks';
import { checklistActions } from '@/core/redux/slices/functions/checklist/checklistSlice';
import { colorTypes } from '@/styles/types';

interface ITableItem {
  id: number;
  bgColor?: string;
  hasCorrespondingDocument: boolean;
  hasAidDocument: boolean;
  name?: string | null;
  isCompleted?: boolean | null;
  completeDate?: string | null;
}

export const TableItem: React.FC<ITableItem> = ({
  id,
  hasCorrespondingDocument,
  hasAidDocument,
  name,
  completeDate,
  isCompleted,
  bgColor,
}) => {
  const dispatch = useAppDispatch();
  const { t: checklistTranslations } = useTranslation('checklist');

  const handleMarkComplete = (id: number) => {
    dispatch(
      checklistActions.markComplete({
        checkpointID: id,
      })
    );
  };

  const handleMarkIncomplete = (id: number) => {
    dispatch(
      checklistActions.markIncomplete({
        checkpointID: id,
      })
    );
  };

  const handleFillCorrespondingDocument = (id: number) => {
    dispatch(checklistActions.fillCorrespondingDocument(id));
  };

  const handleFetchAidDoucment = (id: number) => {
    dispatch(checklistActions.fetchAidDocument(id));
  };

  return (
    <div
      style={{ backgroundColor: bgColor }}
      className={`w-full py-2 grid grid-cols-12 items-start px-[30px]`}
    >
      <div className={'col-span-5 grid grid-cols-8 items-center'}>
        <div className={'col-span-8 flex flex-row justify-between gap-2 items-center px-[34px]'}>
          <div className={'flex flex-row gap-2 items-center'}>
            <Typography>{name}</Typography>
          </div>
        </div>
      </div>
      <div className={'col-span-2'}>
        <Typography>{completeDate ?? '--'}</Typography>
      </div>
      <div className={'col-span-3'}>
        {isCompleted === true && (
          <Tag
            background={colorTypes.Blue}
            label={checklistTranslations('status.complete.label')}
          />
        )}
        {isCompleted === false && (
          <Tag
            background={colorTypes.Orange}
            label={checklistTranslations('status.incomplete.label')}
          />
        )}
        {isCompleted === null && (
          <Tag
            background={colorTypes.Grey}
            label={checklistTranslations('status.notRecorded.label')}
          />
        )}
      </div>
      <div className={'col-span-2'}>
        <div className='grid grid-cols-4 gap-1 items-center'>
          {hasCorrespondingDocument ? (
            <Button
              buttonStyle={'Icon'}
              buttonVariant={'Icon'}
              className={`text-${colorTypes.Secondary}`}
              onClick={() => handleFillCorrespondingDocument(id)}
            >
              <CheckedCircleIcon />
            </Button>
          ) : (
            <div />
          )}
          <Button
            buttonStyle={'Icon'}
            buttonVariant={'Icon'}
            className={`text-${colorTypes.Secondary}`}
            onClick={() => handleMarkComplete(id)}
          >
            <MinusCircleIcon />
          </Button>
          <Button
            buttonStyle={'Icon'}
            buttonVariant={'Icon'}
            className={`text-${colorTypes.Secondary}`}
            onClick={() => handleMarkIncomplete(id)}
          >
            <DocumentViewIcon />
          </Button>
          {hasAidDocument ? (
            <Button
              buttonStyle={'Icon'}
              buttonVariant={'Icon'}
              className={`text-${colorTypes.Secondary}`}
              onClick={() => handleFetchAidDoucment(id)}
            >
              <InfoIcon />
            </Button>
          ) : (
            <div />
          )}
        </div>
      </div>
    </div>
  );
};
