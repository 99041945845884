import React from 'react';

import { IToggleGroupState } from '@/components/ToggleGroup/ToggleGroup';
import { twMerge } from '@/core/utils/tailwindUtil';
import { colorTypes } from '@/styles/types';

export interface IToggleGroupItem extends IToggleGroupState {
  value: any;
  id?: number | string;
  component?: React.ReactNode;
  onChange?: (value: any) => void;
  isSelected?: boolean;
  readOnly?: boolean;
  className?: string;
}

export const ToggleGroupItem: React.FC<IToggleGroupItem> = ({
  id,
  value,
  component,
  isSelected,
  className,
  readOnly,
  onChange,
}) => {
  const baseStyles = 'px-4 py-[10px] rounded-default border border-transparent';
  const defaultStyles = `bg-${colorTypes.White} border-${colorTypes.Stroke} text-${colorTypes.Primary}`;
  const selectedStyles = `bg-${colorTypes.Dark} border-${colorTypes.Dark} text-${colorTypes.White}`;
  const readOnlyStyles = `bg-${colorTypes.Pressed} border-${colorTypes.Pressed} text-${colorTypes.Disabled}`;

  const handleChange = () => {
    if (readOnly) {
      return;
    }

    onChange?.(id);
  };

  return (
    <div
      className={twMerge(
        `flex flex-row gap-2 ${readOnly ? 'cursor-default' : 'cursor-pointer'}`,
        className
      )}
    >
      {component ? (
        component
      ) : (
        <div
          onClick={handleChange}
          className={twMerge(
            baseStyles,
            isSelected ? selectedStyles : readOnly ? readOnlyStyles : defaultStyles
          )}
        >
          {value}
        </div>
      )}
    </div>
  );
};
