import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { LoadingStatus } from '@/types/loadingStatus';

export interface IMedication {
  administeredOnTimestamp?: string | null;
  daytime?: string | null;
  administrationRemark?: string | null;
  administrationNote?: string | null;
  id: number;
  personName: string;
  medicationName?: string | null;
  isNotGiven: boolean;
  isGiven: boolean;
  dosage?: string | null;
  isDemanded?: boolean | null;
  isRemarkedOnGiving?: boolean | null;
  dosageForm?: string | null;
}

export interface IDepartment {
  id: string;
  name: string;
}

export interface IMedicationDepartment {
  id: string;
  name: string;
}

export interface IUnadministratedMedicationsFetchPayload {
  departmentID?: number;
  date?: string | null;
  isOnlyOnDemand: boolean;
  search?: string;
}

export interface IAdministratedMedicationsFetchPayload {
  departmentID?: number;
  administerID?: number;
  date?: string | null;
  isOnlyOnDemand: boolean;
  search?: string;
}

export interface IMarkAdministratedMedicationPayload {
  medicationID: number;
  isGiven: boolean;
  administrationRemark: string;
  fetchPayload?: IAdministratedMedicationsFetchPayload;
}

export interface IMarkUnadministratedMedicationPayload {
  medicationID: number;
  administrationRemark: string;
  date?: string | null;
}

export interface IMedicationAdministrationState {
  administratedMedications: IMedication[];
  unadministratedMedications: IMedication[];
  departments: IMedicationDepartment[];
  unadministratedMedicationsLock: LoadingStatus;
  administratedMedicationsLock: LoadingStatus;
  departmentsLock: LoadingStatus;
  markAdministratedLock: LoadingStatus;
  markUnadministratedLock: LoadingStatus;
  isOnlyOnDemand: boolean;
}

const initialState: IMedicationAdministrationState = {
  //medications
  administratedMedications: [],
  unadministratedMedications: [],
  unadministratedMedicationsLock: LoadingStatus.NEVER,
  administratedMedicationsLock: LoadingStatus.NEVER,
  //departments
  departments: [],
  departmentsLock: LoadingStatus.NEVER,
  //mark
  markAdministratedLock: LoadingStatus.NEVER,
  markUnadministratedLock: LoadingStatus.NEVER,
  isOnlyOnDemand: false,
};

export const medicationAdministartionSlice = createSlice({
  name: 'medicationAdministration',
  initialState,
  reducers: {
    //unadministrated medications
    fetchUnadministratedMedications: (
      state,
      action: PayloadAction<IUnadministratedMedicationsFetchPayload>
    ) => ({
      ...state,
      payload: action.payload,
    }),
    setUnadministratedMedications: (state, action: PayloadAction<IMedication[]>) => ({
      ...state,
      unadministratedMedications: action.payload ?? [],
    }),
    setUnadministratedMedicationsLock: (state, action: PayloadAction<LoadingStatus>) => ({
      ...state,
      unadministratedMedicationsLock: action.payload,
    }),

    //administrated medications
    fetchAdministratedMedications: (
      state,
      action: PayloadAction<IAdministratedMedicationsFetchPayload>
    ) => ({
      ...state,
      payload: action.payload,
    }),
    setAdministratedMedications: (state, action: PayloadAction<IMedication[]>) => ({
      ...state,
      administratedMedications: action.payload ?? [],
    }),
    setAdministratedMedicationsLock: (state, action: PayloadAction<LoadingStatus>) => ({
      ...state,
      administratedMedicationsLock: action.payload,
    }),

    //mark
    markAdministrated: (state, action: PayloadAction<IMarkAdministratedMedicationPayload>) => ({
      ...state,
      payload: action.payload,
    }),
    setMarkAdministratedLock: (state, action: PayloadAction<LoadingStatus>) => ({
      ...state,
      markAdministratedLock: action.payload,
    }),

    markUnadministrated: (state, action: PayloadAction<IMarkUnadministratedMedicationPayload>) => ({
      ...state,
      payload: action.payload,
    }),
    setMarkUnadministratedLock: (state, action: PayloadAction<LoadingStatus>) => ({
      ...state,
      markUnadministratedLock: action.payload,
    }),

    //departments
    fetchDepartments: () => {},
    setDepartmentsLock: (state, action: PayloadAction<LoadingStatus>) => ({
      ...state,
      addressDataLock: action.payload,
    }),
    setDepartments: (state, action: PayloadAction<IMedicationDepartment[]>) => ({
      ...state,
      departments: action.payload ?? [],
    }),
    // isOnlyOnDemand
    setIsOnlyOnDemand: (state, action: PayloadAction<boolean>) => ({
      ...state,
      isOnlyOnDemand: action.payload,
    }),
  },
});

export const medicationAdministrationReducer = medicationAdministartionSlice.reducer;
export const medicationAdministrationActions = medicationAdministartionSlice.actions;
