import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import fp from 'lodash/fp';

import { Button } from '@/components/Button/Button';
import { useOrganizationalUnitErrors } from '@/components/Modals/Modals/functions/organizationalUnitsModals/hooks/useOrganizationalUnitErrors';
import {
  IOrganizationalUnitBBBForm,
  OrganizationalUnitBBB,
} from '@/components/Modals/Modals/functions/organizationalUnitsModals/OrganizationalUnitForms/BBB/OrganizationalUnitBBB';
import {
  IOrganizationalUnitBBBInterruptionForm,
  OrganizationalUnitBBBInterruption,
} from '@/components/Modals/Modals/functions/organizationalUnitsModals/OrganizationalUnitForms/BBBInterruption/OrganizationalUnitBBBInterruption';
import { OrganizationUnitDefault } from '@/components/Modals/Modals/functions/organizationalUnitsModals/OrganizationalUnitForms/Default/OrganizationUnitDefault';
import { Sidebar } from '@/components/Overlays/Sidebar/Sidebar';
import { Typography } from '@/components/Typography/Typography';
import { OrganizationalUnitsTypes } from '@/core/enums/functions/organizationalUnits/organizationalUnitsTypesEnum';
import { useAppDispatch } from '@/core/redux/hooks';
import { IOrganizationalUnit } from '@/core/redux/slices/functions/organizationalUnits/slice';
import { organizationalUnitsModalsActions } from '@/core/redux/slices/modalsSlice/functions/organizationalUnits/slice';

interface IUpdateOrganizationalUnitForm {
  bbb?: IOrganizationalUnitBBBForm;
  bbbInterruption?: IOrganizationalUnitBBBInterruptionForm;
}

interface IUpdateOrganizationalUnitsModalContent {
  personID: number;
  organizationalUnit: IOrganizationalUnit;
  handleExit: () => void;
  unitID: number;
}

export const UpdateOrganizationalUnitModalContent: React.FC<IUpdateOrganizationalUnitsModalContent> =
  ({ personID, organizationalUnit, handleExit, unitID }) => {
    const { t: updateOrganizationalUnitTranslations } = useTranslation(
      'updateOrganizationalUnitModal'
    );

    const dispatch = useAppDispatch();

    const form = useForm<IUpdateOrganizationalUnitForm>({
      defaultValues: {
        bbb: {
          startDate: organizationalUnit.fromTimestamp ?? '',
          comment: organizationalUnit.comment ?? '',
        },
        bbbInterruption: {
          note: organizationalUnit.comment,
          fromTimestamp: organizationalUnit.fromTimestamp ?? '',
          toTimestamp: organizationalUnit.toTimestamp,
        },
      },
    });

    const { renderDialogContainer } = useOrganizationalUnitErrors();

    const updateOrganizationalUnitForms: Record<OrganizationalUnitsTypes, React.ReactNode> = {
      [OrganizationalUnitsTypes.BBB]: <OrganizationalUnitBBB />,
      [OrganizationalUnitsTypes.BBBInterruption]: <OrganizationalUnitBBBInterruption />,
      [OrganizationalUnitsTypes.Default]: <OrganizationUnitDefault />,
    };

    const handleUpdate = () => {
      switch (organizationalUnit.type) {
        case OrganizationalUnitsTypes.BBB: {
          const formValues = fp.cloneDeep(form.getValues('bbb'));

          if (!formValues) {
            return;
          }

          dispatch(
            organizationalUnitsModalsActions.updateOrganizationalUnitBBB({
              unitID: unitID,
              personID: personID,
              formValues: formValues,
            })
          );

          dispatch(organizationalUnitsModalsActions.closeUpdateOrganizationalUnitModal());
          break;
        }
        case OrganizationalUnitsTypes.BBBInterruption: {
          const formValues = fp.cloneDeep(form.getValues('bbbInterruption'));

          if (!formValues) {
            return;
          }

          dispatch(
            organizationalUnitsModalsActions.updateOrganizationalUnitBBBInterruption({
              personID: personID,
              id: organizationalUnit.id,
              fromTimestamp: formValues.fromTimestamp,
              toTimestamp: formValues.toTimestamp,
              note: formValues.note,
            })
          );

          break;
        }
        case OrganizationalUnitsTypes.Default: {
          break;
        }
      }
    };

    const footer = (
      <div className={'flex flex-row gap-3'}>
        <Button onClick={handleUpdate}>
          {updateOrganizationalUnitTranslations('buttons.update.label')}
        </Button>
        <Button buttonVariant={'Secondary'} onClick={handleExit}>
          {updateOrganizationalUnitTranslations('buttons.cancel.label')}
        </Button>
      </div>
    );

    return (
      <Sidebar onExit={handleExit} position={'right'} footer={footer} className={'min-w-[40vw]'}>
        <div className={'flex flex-col gap-3 p-4'}>
          <Typography type={'H3'}>{updateOrganizationalUnitTranslations('title.label')}</Typography>
          <div className={'flex flex-row gap-2'}>
            <Typography>
              {updateOrganizationalUnitTranslations('fields.organizationalUnit.label')}
            </Typography>
            <Typography type={'16-M'}>
              {organizationalUnit.type === OrganizationalUnitsTypes.BBB
                ? updateOrganizationalUnitTranslations('bbbTitle.label')
                : organizationalUnit.name}
            </Typography>
          </div>
          <FormProvider {...form}>
            <div>{updateOrganizationalUnitForms[organizationalUnit.type]}</div>
          </FormProvider>
        </div>
        {renderDialogContainer()}
      </Sidebar>
    );
  };
