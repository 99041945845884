import { createSelector } from '@reduxjs/toolkit';

import { IRootState } from '@/core/redux/types';

const selector = (state: IRootState) => state.functions.fundingCycle.participationPlan;

const participantPlans = createSelector([selector], (state) => state.participantPlans);
const selectedParticipantPlan = createSelector(
  [selector],
  (state) => state.selectedParticipantPlan
);
const participationPlanDetails = createSelector(
  [selector],
  (state) => state.participationPlanDetails
);

const participationPlanDetailsLock = createSelector(
  [selector],
  (state) => state.participationPlanDetailsLock
);
const createParticipationPlanLock = createSelector(
  [selector],
  (state) => state.createParticipationPlanLock
);
const updateParticipationPlanLock = createSelector(
  [selector],
  (state) => state.updateParticipationPlanLock
);

const participationPlanNotification = createSelector(
  [selector],
  (state) => state.participationPlanNotification
);

const goalsWishes = createSelector([selector], (state) => state.goalsWishes);
const goalsWishesLock = createSelector([selector], (state) => state.goalWishesLock);

export const participationPlanSelectors = {
  participantPlans,
  participationPlanDetails,
  selectedParticipantPlan,
  goalsWishes,
  goalsWishesLock,
  participationPlanDetailsLock,
  createParticipationPlanLock,
  updateParticipationPlanLock,

  participationPlanNotification,
};
