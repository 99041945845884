import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { IAppointmentDetailsForm } from '@/components/Modals/Modals/functions/activityPlaningModals/appointmentDetailsModals/AppointmentDetailsModal/AppointmentDetails/hooks/useAppointmentDetailsForm';
import { useSchemaContext } from '@/components/SchemaContext/SchemaContext';
import { SelectForm } from '@/components/Select/form/SelectForm';
import { ISelectItem } from '@/components/Select/SelectItem/SelectItem';
import { DropdownItemsByFetch } from '@/core/enums/common/DropdownItemsByFetchEnum';
import { useAppSelector } from '@/core/redux/hooks';
import { dropdownItemsSelectors } from '@/core/redux/slices/dropdownItems/selectors';

const FIELD_NAME = 'appointmentTypeID';

export const AppointmentDetailsTypeSelect: React.FC = () => {
  const { t: appointmentDetailTranslations } = useTranslation('activityPlanning', {
    keyPrefix: 'appointmentDetails',
  });

  const { setValue, control } = useFormContext<IAppointmentDetailsForm>();

  const dropdownItems = useAppSelector(dropdownItemsSelectors.dropdownItems);

  const appointmentDetailsTypeValues: ISelectItem[] = [];

  if (dropdownItems && dropdownItems[DropdownItemsByFetch.APPOINTMENT_TYPE]) {
    dropdownItems[DropdownItemsByFetch.APPOINTMENT_TYPE].forEach((appointmentType) => {
      const newItem: ISelectItem = {
        id: String(appointmentType.id),
        component: appointmentType.value,
      };

      appointmentDetailsTypeValues.push(newItem);
    });
  }

  const { getValues } = useFormContext<IAppointmentDetailsForm>();

  const initialValue = String(getValues(FIELD_NAME));

  const handleChange = (id: string) => {
    if (!dropdownItems || !dropdownItems[DropdownItemsByFetch.APPOINTMENT_TYPE]) {
      return;
    }

    const foundElement = dropdownItems[DropdownItemsByFetch.APPOINTMENT_TYPE].find(
      (detailsType) => detailsType.id === Number(id)
    );

    if (!foundElement) {
      return;
    }

    setValue(FIELD_NAME, foundElement.id, { shouldDirty: true });
  };

  const { isRequiredField } = useSchemaContext(FIELD_NAME);

  return (
    <SelectForm
      label={appointmentDetailTranslations('fields.appointmentType.label')}
      isRequired={isRequiredField}
      values={appointmentDetailsTypeValues}
      initialValueID={initialValue}
      onChange={handleChange}
      control={control.register('appointmentTypeID')}
    />
  );
};
