import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { LoadingStatus } from '@/types/loadingStatus';

export interface IModal {
  isRender: boolean;
}

export interface IActivityPlanningAddParticipantsModalPayload {
  measureID: number;
  appointmentID: number;
  measureName?: string | null;
  placesCount?: number;
}

export interface IActivityPlanningAddedParticipant {
  personID: number;
  measureID: number;
  appointmentID: number;
}

export interface IActivityPlanningAddParticipantsPayload {
  participants: IActivityPlanningAddedParticipant[];
  measureID: number;
  appointmentID: number;
}

export interface IActivityPlanningAddParticipantsFetchPayload {
  measureID: number;
  appointmentID: number;
  name?: string;
  departmentID?: number;
  isMeasureAssigned?: boolean;
  isMeasureAssignedHasAppointment?: boolean;
  isMeasureAppointmentCompleted?: boolean;
}

export interface IActivityPlanningParticipant {
  rowID: number;
  id: number;
  name?: string | null;
  surname?: string | null;
  department?: string | null;
  appointmentDate?: string | null;
  bbbInInterruption: boolean;
  inMeasure: boolean;
}

export interface IActivityPlanningParticipantsList {
  participants: IActivityPlanningParticipant[] | null;
}

export interface IActivityPlanningAddParticipantModal extends IModal {
  payload: IActivityPlanningAddParticipantsModalPayload | null;
  fetchPayload: IActivityPlanningAddParticipantsFetchPayload | null;
  addParticipantPayload: IActivityPlanningAddParticipantsPayload | null;
  participantsList: IActivityPlanningParticipantsList | null;
  participantsListLock: LoadingStatus;
}

export interface IActivityPlaningParticipantsModalsState {
  addParticipantsModal: IActivityPlanningAddParticipantModal;
}

const initialState: IActivityPlaningParticipantsModalsState = {
  addParticipantsModal: {
    isRender: false,
    payload: null,
    fetchPayload: null,
    addParticipantPayload: null,
    participantsList: null,
    participantsListLock: LoadingStatus.NEVER,
  },
};

const activityPlaningParticipantsModalsSlice = createSlice({
  name: 'activityPlaningParticipantsModals',
  initialState,
  reducers: {
    openAddParticipantsModal: (
      state,
      action: PayloadAction<IActivityPlanningAddParticipantsModalPayload>
    ) => ({
      ...state,
      addParticipantsModal: {
        ...state.addParticipantsModal,
        isRender: true,
        payload: action.payload,
      },
    }),
    closeAddParticipantsModal: (state) => ({
      ...state,
      addParticipantsModal: {
        isRender: false,
        payload: null,
        fetchPayload: null,
        addParticipantPayload: null,
        participantsList: null,
        participantsListLock: LoadingStatus.NEVER,
      },
    }),
    fetchParticipantsList: (
      state,
      action: PayloadAction<IActivityPlanningAddParticipantsFetchPayload>
    ) => ({
      ...state,
      addParticipantsModal: {
        ...state.addParticipantsModal,
        fetchPayload: action.payload,
      },
    }),
    setParticipantsList: (
      state,
      action: PayloadAction<IActivityPlanningParticipantsList | null>
    ) => ({
      ...state,
      addParticipantsModal: {
        ...state.addParticipantsModal,
        participantsList: action.payload,
      },
    }),
    setParticipantsListLock: (state, action: PayloadAction<LoadingStatus>) => ({
      ...state,
      addParticipantsModal: {
        ...state.addParticipantsModal,
        participantsListLock: action.payload,
      },
    }),
    addParticipants: (state, action: PayloadAction<IActivityPlanningAddParticipantsPayload>) => ({
      ...state,
      addParticipantsModal: {
        ...state.addParticipantsModal,
        addParticipantPayload: action.payload,
      },
    }),
  },
});

export const activityPlaningParticipantsModalsReducer =
  activityPlaningParticipantsModalsSlice.reducer;
export const activityPlaningParticipantsModalsActions =
  activityPlaningParticipantsModalsSlice.actions;
