import { useEffect } from 'react';

import { useSelectedPerson } from '@/core/hooks/useSelectedPerson';
import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';
import { checklistActions } from '@/core/redux/slices/functions/checklist/checklistSlice';
import { checklistSelectors } from '@/core/redux/slices/functions/checklist/selectros';
import { LoadingStatus } from '@/types/loadingStatus';

import { CheckListTable } from '../CheckListTable/CheckListTable';

interface ICheckList {
  id: number;
}

export const CheckList: React.FC<ICheckList> = ({ id }) => {
  const dispatch = useAppDispatch();
  const { selectedPersonID } = useSelectedPerson();

  const checklists = useAppSelector(checklistSelectors.checklists);
  const markCheckpointLock = useAppSelector(checklistSelectors.markCheckpointLock);

  useEffect(() => {
    if (!selectedPersonID) {
      return;
    }

    dispatch(
      checklistActions.fetchChecklists({
        personID: selectedPersonID,
        checklistGroupID: id,
      })
    );
  }, [id]);

  useEffect(() => {
    if (!selectedPersonID) {
      return;
    }

    if (markCheckpointLock === LoadingStatus.LOADED) {
      dispatch(
        checklistActions.fetchChecklists({
          personID: selectedPersonID,
          checklistGroupID: id,
        })
      );
    }
  }, [markCheckpointLock]);

  useEffect(() => {
    return () => {
      dispatch(checklistActions.setMarkCheckpointLock(LoadingStatus.NEVER));
      dispatch(checklistActions.setChecklists([]));
    };
  }, []);

  return (
    <div>
      <CheckListTable checklists={checklists} />
    </div>
  );
};
