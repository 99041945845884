import React, { useState } from 'react';

import { Accordion } from '@/components/Accordion/Accordion';
import { useAppSelector } from '@/core/redux/hooks';
import { ICheckpoint } from '@/core/redux/slices/functions/checklist/checklistSlice';
import { parametersSelectors } from '@/core/redux/slices/parameters/selectors';
import { colorTypes } from '@/styles/types';

import { TableItem } from './TableItem';

interface ITableGroup {
  id: number;
  name?: string | null;
  index: number;
  checkpoints: ICheckpoint[];
}

export const TableGroup: React.FC<ITableGroup> = ({ name, checkpoints }) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(false);

  const oddColor = useAppSelector(parametersSelectors.selfAssessmentOddBackgroundColor);
  const evenColor = useAppSelector(parametersSelectors.selfAssessmentEvenBackgroundColor);

  return (
    <Accordion
      title={name}
      className={'w-full'}
      isExpanded={isExpanded}
      setIsExpanded={setIsExpanded}
      headerClassName={`bg-${colorTypes.Fields} px-[30px] py-3`}
    >
      {checkpoints.map((checkpoint, index) => (
        <TableItem
          bgColor={(index + 1) % 2 == 0 ? evenColor : oddColor}
          id={checkpoint.id}
          name={checkpoint.name}
          key={checkpoint.id}
          completeDate={checkpoint.completeDate}
          isCompleted={checkpoint.isCompleted}
          hasCorrespondingDocument={checkpoint.hasCorrespondingDocument}
          hasAidDocument={checkpoint.hasAidDocument}
        />
      ))}
    </Accordion>
  );
};
