import { FieldError, FieldErrors } from 'react-hook-form';

type GenericFormValues<T> = T extends Record<string, any> ? FieldErrors<T> : never;

export function getFirstFormError<T extends Record<string, any>>(
  errors: FieldErrors<T>
): string | undefined {
  for (const key in errors) {
    if (errors.hasOwnProperty(key)) {
      const error = errors[key as keyof FieldErrors<T>];
      if (isFieldError(error)) {
        return error.message;
      }
      if (typeof error === 'object' && error) {
        const nestedError = getFirstFormError(error as FieldErrors<any>);
        if (nestedError) {
          return nestedError;
        }
      }
    }
  }
  return undefined;
}

function isFieldError(error: any): error is FieldError {
  return error && typeof error.message === 'string';
}
