import { useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Button } from '@/components/Button/Button';
import { DatePicker } from '@/components/Datepicker/DatePicker';
import { Sidebar } from '@/components/Overlays/Sidebar/Sidebar';
import { SelectForm } from '@/components/Select/form/SelectForm';
import { ITableColumn, Table } from '@/components/Table/Table';
import { TextArea } from '@/components/TextArea/TextArea';
import { Typography } from '@/components/Typography/Typography';
import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';
import { functionPageSelectors } from '@/core/redux/slices/functionPage/selectors';
import { logbookActions } from '@/core/redux/slices/logbook/logbookSlice';
import { logbookSelectors } from '@/core/redux/slices/logbook/selectors';
import { functionsPageModalsActions } from '@/core/redux/slices/modalsSlice/functionsPage/functionsPageModalsSlice';
import { logbookModalsActions } from '@/core/redux/slices/modalsSlice/logbook/logbookModalSlice';
import { logbookModalsSelectors } from '@/core/redux/slices/modalsSlice/logbook/selectors';
import { LoadingStatus } from '@/types/loadingStatus';

import { useFecthedCategories } from '../hooks/useFetchLogbookCategories';

interface ILogbookEditForm {
  categoryID: string | null;
  createdTimestamp: string | null;
  note: string | null;
}

const ModalContent: React.FC = () => {
  const { t: logbookEditModalTranslations } = useTranslation('logbookEditModal');
  const form = useForm<ILogbookEditForm>({});

  const personID = useAppSelector(functionPageSelectors.personID);
  const logbook = useAppSelector(logbookSelectors.logbook);
  const updateLogbookItemLock = useAppSelector(logbookSelectors.updateLogbookItemLock);

  const dispatch = useAppDispatch();

  const { options: categoryOptions } = useFecthedCategories();

  const [selectedLogbookItem, setSelectedLogbookItem] = useState<number | null>(null);

  const currenLogbookSummary = useMemo(
    () => logbook.find((item) => item.id === selectedLogbookItem),
    [logbook, selectedLogbookItem]
  );

  useEffect(() => {
    currenLogbookSummary
      ? form.reset({
          categoryID: String(currenLogbookSummary.category.id),
          createdTimestamp: currenLogbookSummary.createdTimestamp,
          note: currenLogbookSummary.note,
        })
      : form.reset({ categoryID: null, createdTimestamp: null, note: null });
  }, [currenLogbookSummary]);

  const columns: ITableColumn[] = [
    {
      header: logbookEditModalTranslations('columns.createdTimestamp.label'),
      accessor: 'createdTimestamp',
    },
    {
      header: logbookEditModalTranslations('columns.createdBy.label'),
      accessor: 'createdBy',
    },
    {
      header: logbookEditModalTranslations('columns.category.label'),
      accessor: 'category.name',
    },
    {
      header: logbookEditModalTranslations('columns.note.label'),
      accessor: 'note',
      className: 'whitespace-normal',
    },
  ];

  const handleExit = () => {
    dispatch(functionsPageModalsActions.closePersonInfoModal());
    dispatch(logbookModalsActions.closeLogbookEditModal());
  };

  const onSubmit = (data: ILogbookEditForm) => {
    if (!selectedLogbookItem || !data) {
      return;
    }

    dispatch(
      logbookActions.updateLogbookItem({
        logbookItemID: selectedLogbookItem,
        categoryID: Number(data.categoryID),
        note: data.note || '',
        createdTimestamp: data.createdTimestamp || '',
      })
    );
  };

  useEffect(() => {
    if (!personID) {
      return;
    }
    dispatch(
      logbookActions.fetchLogbookPerson({
        personID: personID,
      })
    );
  }, []);

  useEffect(() => {
    if (!personID) {
      return;
    }

    if (updateLogbookItemLock === LoadingStatus.LOADED) {
      dispatch(
        logbookActions.fetchLogbookPerson({
          personID: personID,
        })
      );
      dispatch(
        logbookActions.fetchLogbookSummary({
          personID: personID,
        })
      );
    }
  }, [updateLogbookItemLock]);

  useEffect(() => {
    return () => {
      dispatch(logbookActions.setUpdateLogbookItemLock(LoadingStatus.NEVER));
    };
  }, []);

  const renderFooter = (
    <div className='flex gap-2.5'>
      <Button type='submit' onClick={form.handleSubmit(onSubmit)} disabled={!selectedLogbookItem}>
        {logbookEditModalTranslations('buttons.update.label')}
      </Button>
      <Button
        type='button'
        buttonVariant='Secondary'
        onClick={() => dispatch(logbookModalsActions.closeLogbookEditModal())}
      >
        {logbookEditModalTranslations('buttons.close.label')}
      </Button>
    </div>
  );
  return (
    <Sidebar
      onExit={handleExit}
      footer={renderFooter}
      position={'right'}
      isNested
      layer={4}
      onBackTo={() => dispatch(logbookModalsActions.closeLogbookEditModal())}
      className='w-[716px]'
      title={logbookEditModalTranslations('title.label')}
    >
      <div className='min-h-[400px]'>
        <Table
          columns={columns}
          data={logbook}
          isDeselectAllowed={false}
          className='h-[400px]'
          singleSelect
          onSingleSelectChange={(selected) => setSelectedLogbookItem(selected)}
        />
      </div>

      <div className='p-8'>
        <div className='mb-4'>
          <Typography type='H3'>{logbookEditModalTranslations('info.label')}</Typography>
        </div>

        <FormProvider {...form}>
          <form className='flex flex-col gap-4'>
            <div className='flex gap-4'>
              <div className='flex flex-col gap-2'>
                <Typography as='label' className='truncate h-6'>
                  {logbookEditModalTranslations('fields.categoryID.label')}
                </Typography>
                <SelectForm
                  control={form.control.register('categoryID')}
                  className='w-[180px]'
                  values={categoryOptions}
                  readOnly={!selectedLogbookItem}
                />
              </div>
              <div className='flex flex-col gap-2'>
                <Typography as='label' className='truncate h-6'>
                  {logbookEditModalTranslations('fields.createdTimestamp.label')}
                </Typography>
                <DatePicker
                  control={form.control.register('createdTimestamp')}
                  readOnly={!selectedLogbookItem}
                />
              </div>
            </div>
            <TextArea control={form.control.register('note')} readOnly={!selectedLogbookItem} />
          </form>
        </FormProvider>
      </div>
    </Sidebar>
  );
};

export const LogbookEditModal: React.FC = () => {
  const isOpened = useAppSelector(logbookModalsSelectors.logbookEditModal);

  if (isOpened) {
    return <ModalContent />;
  }

  return null;
};
