import React, { useState } from 'react';

import { PersonRequirementTimeListGroup } from '@/components/Modals/Modals/functions/organizationalUnitsModals/AdditionalPersonRequirementTimeListModal/AdditionalPersonRequirementTimeListModalContent/PersonRequirementTimeListGroup';
import { IPersonRequirementTimeGroup } from '@/core/redux/slices/modalsSlice/functions/organizationalUnits/slice';

interface IPersonRequirementTimeList {
  personRequirementTimeGroups: IPersonRequirementTimeGroup[];
}

export const PersonRequirementTimeList: React.FC<IPersonRequirementTimeList> = ({
  personRequirementTimeGroups,
}) => {
  const [expandedID, setExpandedID] = useState<number | null>(null);

  return (
    <div>
      <div className={'flex flex-col h-[calc(100vh_-_220px)] overflow-y-auto'}>
        {personRequirementTimeGroups.map((personRequirementTimeGroup) => {
          const groupFormIndex = personRequirementTimeGroups.findIndex(
            (timeGroup) => timeGroup.id === personRequirementTimeGroup.id
          );

          return (
            <PersonRequirementTimeListGroup
              setIsExpanded={setExpandedID}
              id={personRequirementTimeGroup.id}
              name={personRequirementTimeGroup.name}
              items={personRequirementTimeGroup.items}
              isExpanded={expandedID === personRequirementTimeGroup.id}
              groupFormIndex={groupFormIndex}
              maxEntriesCount={personRequirementTimeGroup.maxEntriesCount}
              key={personRequirementTimeGroup.id}
            />
          );
        })}
      </div>
    </div>
  );
};
