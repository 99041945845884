import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import { Button } from '@/components/Button/Button';
import { FunctionFooter } from '@/components/wrapper/FunctionWrapper/FunctionFooter/FunctionFooter';
import { PerformanceProfileViews } from '@/core/enums/functions/performanceProfile/performanceProfileViewEnum';
import { QuestionnaireStatus } from '@/core/enums/functions/performanceProfile/questionnaires/questionnaireStatusEnum';
import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';
import { functionPageSelectors } from '@/core/redux/slices/functionPage/selectors';
import { performanceProfileSelectors } from '@/core/redux/slices/functions/performanceProfile/selectors';
import { performanceProfileActions } from '@/core/redux/slices/functions/performanceProfile/slice';
import { performanceProfileModalsActions } from '@/core/redux/slices/modalsSlice/functions/performanceProfile/performancePrfoileModalSlice';

interface IQuestionnaireHistoryControl {
  onViewChange: (view: PerformanceProfileViews) => void;
}

export const QuestionnaireHistoryControl: React.FC<IQuestionnaireHistoryControl> = ({
  onViewChange,
}) => {
  const [selectedQuestionnaires, setSelectedQuestionnaires] = useState<number[] | null>(null);

  const history = useAppSelector(performanceProfileSelectors.history);
  const targetCatalogTypes = useAppSelector(performanceProfileSelectors.targetCatalogTypes);
  const selectedPerson = useAppSelector(functionPageSelectors.selectedPerson);
  const selectedPersonID = selectedPerson?.id;

  const detailsIDs = useAppSelector(performanceProfileSelectors.detailsIDs);

  const dispatch = useAppDispatch();

  const { t: questionnaireHistoryTranslations } = useTranslation('performanceProfile', {
    keyPrefix: 'questionnaireHistory',
  });

  const handleOpenSelectModal = () => {
    dispatch(performanceProfileModalsActions.openSelectTargetCatalogModal(null));
  };

  const handleUnlock = () => {
    if (!selectedPersonID || !detailsIDs) {
      return;
    }

    const competenceAnalysisID = detailsIDs[selectedPersonID][0];

    dispatch(
      performanceProfileActions.unlockQuestionnaire({
        competenceAnalysisID,
        personID: selectedPersonID,
      })
    );
  };

  useEffect(() => {
    if (!selectedPersonID || !detailsIDs) {
      return;
    }

    if (selectedPersonID in detailsIDs) {
      setSelectedQuestionnaires(detailsIDs[selectedPersonID]);
    }
  }, [selectedPersonID, detailsIDs]);

  const selectedQuestionnaire = useMemo(() => {
    if (!selectedQuestionnaires) {
      return;
    }
    return history?.filter((item) => item.id === selectedQuestionnaires[0])[0];
  }, [history, selectedQuestionnaires]);

  const isQuestionnaireStarted = selectedQuestionnaire?.status === QuestionnaireStatus.Started;
  const isStartNewDisabled = targetCatalogTypes.length === 0;

  useEffect(() => {
    if (!selectedPersonID) {
      return;
    }

    dispatch(
      performanceProfileActions.fetchTargetCatalogTypes({
        personID: selectedPersonID,
      })
    );
  }, []);

  const isUnlockable =
    selectedQuestionnaire?.status === QuestionnaireStatus.Release &&
    selectedQuestionnaire.id === history[0].id;

  return (
    <FunctionFooter>
      <div className='flex justify-between gap-2.5'>
        <Button onClick={handleOpenSelectModal} className={clsx(isStartNewDisabled && 'hidden')}>
          {questionnaireHistoryTranslations('buttons.newPerformanceProfile.label')}
        </Button>
        <div>
          {selectedQuestionnaires && selectedQuestionnaires.length > 1 && (
            <Button
              onClick={() => onViewChange(PerformanceProfileViews.Charts)}
              className={'self-center'}
              buttonVariant='Outlined'
            >
              {questionnaireHistoryTranslations('buttons.comparePerformanceProfiles.label')}
            </Button>
          )}
          {selectedQuestionnaires?.length === 1 && (
            <div className='flex gap-2.5'>
              <Button
                onClick={() => onViewChange(PerformanceProfileViews.Questionnaire)}
                disabled={selectedQuestionnaires?.length !== 1}
                buttonVariant='Outlined'
              >
                {isQuestionnaireStarted
                  ? questionnaireHistoryTranslations('buttons.editPerformanceProfile.label')
                  : questionnaireHistoryTranslations('buttons.viewPerformanceProfile.label')}
              </Button>
              {isUnlockable && (
                <Button buttonVariant='Outlined' onClick={handleUnlock}>
                  {questionnaireHistoryTranslations('buttons.unlockPerformanceProfile.label')}
                </Button>
              )}
            </div>
          )}
        </div>
      </div>
    </FunctionFooter>
  );
};
