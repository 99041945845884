import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import fp from 'lodash/fp';

import FormRender from '@/components/FormRender/FormRender';
import {
  personDetailsDefaultValues,
  personDetailsForm,
} from '@/components/functions/DevelopmentReport/DevelopmentReportForms/PersonDetails/helper/personDetailsForm';
import { useDevelopmentReport } from '@/components/functions/DevelopmentReport/hooks/useDevelopmentReport';
import { IDevelopmentReportForm } from '@/components/functions/DevelopmentReport/hooks/useDevelopmentReportForm';
import { DevelopmentReportForms } from '@/core/enums/functions/developmentReport/developmentReportFormsEnum';
import { useAppDispatch } from '@/core/redux/hooks';
import { developmentReportPersonDetailsActions } from '@/core/redux/slices/functions/developmentReport/developmentReportPersonDetailsSlice/slice';

export const DevelopmentReportPersonDetails: React.FC = () => {
  const { t: developmentReportPersonDetailsTranslations } = useTranslation(
    'developmentReportPersonDetails'
  );

  const { developmentReportID } = useDevelopmentReport();

  const { reset, watch } = useFormContext<IDevelopmentReportForm>();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!developmentReportID) {
      return;
    }

    dispatch(
      developmentReportPersonDetailsActions.fetchDevelopmentReportPersonDetails({
        developmentReportID: developmentReportID,
      })
    );

    return () => {
      const currentValues = fp.cloneDeep(watch());
      reset({
        ...currentValues,
        [DevelopmentReportForms.PersonDetails]: personDetailsDefaultValues,
      });
    };
  }, [developmentReportID]);

  return (
    <FormRender<IDevelopmentReportForm>
      list={personDetailsForm}
      translationContext={developmentReportPersonDetailsTranslations}
      className={'py-4 flex flex-col gap-2'}
    />
  );
};
