import React, { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Button } from '@/components/Button/Button';
import { DatePicker } from '@/components/Datepicker/DatePicker';
import FormRender from '@/components/FormRender/FormRender';
import { ServiceProvidersSelect } from '@/components/functions/PersonGeneralData/ParticipantData/ServiceProvidersSelect/ServiceProvidersSelect';
import { Input } from '@/components/Input/Input';
import { ExternalSelectForm } from '@/components/Select/form/ExternalSelectForm';
import { Typography } from '@/components/Typography/Typography';
import { DropdownItemsByFetch } from '@/core/enums/common/DropdownItemsByFetchEnum';
import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';
import {
  IPersonData as FormValues,
  personDataActions,
} from '@/core/redux/slices/functions/personGeneralData/personData/personDataSlice';
import { personDataSelectors } from '@/core/redux/slices/functions/personGeneralData/personData/selectors';
import { notificationsActions } from '@/core/redux/slices/notifications/notificationsSlice';
import { colorTypes } from '@/styles/types';
import { LoadingStatus } from '@/types/loadingStatus';

import { generalDataFirstColumn, generalDataSecondColumn } from './helpers/formTemplates';

interface IParticipantData {
  personID?: number;
}

export const ParticipantData: React.FC<IParticipantData> = ({ personID }) => {
  const form = useForm<FormValues>();
  const { t: personGeneralDataTranslations } = useTranslation('personGeneralData');

  const personData = useAppSelector(personDataSelectors.personData);
  const updatePersonDataLock = useAppSelector(personDataSelectors.updatePersonDataLock);

  const dispatch = useAppDispatch();

  const onSubmit = (data: FormValues) => {
    if (!personID) {
      return;
    }

    dispatch(
      personDataActions.updatePersonData({
        personID: personID,
        personData: data,
      })
    );
  };

  useEffect(() => {
    if (!personID) {
      return;
    }

    dispatch(
      personDataActions.fetchPersonData({
        personID: personID,
      })
    );
  }, [dispatch, personID]);

  useEffect(() => {
    if (!personData) {
      return;
    }

    form.reset(personData);
  }, [personData]);

  useEffect(() => {
    if (updatePersonDataLock === LoadingStatus.LOADED) {
      dispatch(
        notificationsActions.showNotification({
          notification: {
            type: 'success',
            title: personGeneralDataTranslations(
              'notification.updatePersonData.success.title.label'
            ),
            description: personGeneralDataTranslations(
              'notification.updatePersonData.success.description.label'
            ),
          },
        })
      );
    }

    if (updatePersonDataLock === LoadingStatus.ERROR) {
      dispatch(
        notificationsActions.showNotification({
          notification: {
            type: 'error',
            title: personGeneralDataTranslations('notification.updatePersonData.error.title.label'),
            description: personGeneralDataTranslations(
              'notification.updatePersonData.error.description.label'
            ),
          },
        })
      );
    }
  }, [updatePersonDataLock]);

  useEffect(() => {
    return () => {
      dispatch(personDataActions.setPersonData(null));
      dispatch(personDataActions.setPersonDataLock(LoadingStatus.NEVER));
      dispatch(personDataActions.setUpdatePersonDataLock(LoadingStatus.NEVER));
    };
  }, []);

  return (
    <FormProvider {...form}>
      <div className='w-full flex flex-col gap-4 p-5 h-[calc(100vh_-_300px)] overflow-auto'>
        <form>
          <div className={`mb-5 border-b border-b-${colorTypes.StrokeLight} pb-2.5`}>
            <Typography type='H3'>{personGeneralDataTranslations('title.label')}</Typography>
          </div>
          <div>
            <div className='w-full'>
              <div className='flex gap-4 w-full'>
                <div className='flex gap-10 grow'>
                  <FormRender
                    list={generalDataFirstColumn}
                    translationContext={personGeneralDataTranslations}
                    className='w-[450px]'
                  />
                  <FormRender
                    list={generalDataSecondColumn}
                    translationContext={personGeneralDataTranslations}
                    className='w-[450px]'
                  />
                </div>
              </div>
              <div className='mt-10 flex flex-col gap-5'>
                <Typography type={'H3'} className={`border-b border-${colorTypes.Border} pb-2.5`}>
                  {personGeneralDataTranslations('fields.educationalSupporter.label')}
                </Typography>
                <div className={'grid grid-cols-2 gap-4 gap-x-10'}>
                  <div className={'flex flex-col gap-2'}>
                    <Typography type='16-Sb'>
                      {personGeneralDataTranslations('support.label')}
                    </Typography>
                    <ServiceProvidersSelect />
                  </div>
                  <div className={'flex flex-col gap-2'}>
                    <Typography>
                      {personGeneralDataTranslations('fields.costBearer.label')}
                    </Typography>
                    <Input control={form.register('costBearer')} />
                  </div>
                  <div className={'flex flex-col gap-2'}>
                    <Typography>
                      {personGeneralDataTranslations('fields.foreignLegalStatus.label')}
                    </Typography>
                    <ExternalSelectForm
                      control={form.register('foreignLegalStatus')}
                      tableName={DropdownItemsByFetch.FOREIGN_LEGAL_STATUS}
                    />
                  </div>
                  <div className={'flex flex-col gap-2'}>
                    <Typography>
                      {personGeneralDataTranslations('fields.inactiveTimestamp.label')}
                    </Typography>
                    <DatePicker control={form.register('inactiveTimestamp')} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>

      <div
        className={'flex flex-row px-7 py-default justify-start items-center gap-3 shadow-footer'}
      >
        <Button type='submit' onClick={form.handleSubmit(onSubmit)}>
          {personGeneralDataTranslations('buttons.submit.label')}
        </Button>
      </div>
    </FormProvider>
  );
};
