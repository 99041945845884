import React, { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Button } from '@/components/Button/Button';
import { FundingCycleSectionHeader } from '@/components/functions/FundingCycle/FundingCycleSectionHeader/FundingCycleSectionHeader';
import { SpinnerIcon } from '@/components/Icons/SpinnerIcon';
import { ExternalSelectForm } from '@/components/Select/form/ExternalSelectForm';
import { TextArea } from '@/components/TextArea/TextArea';
import { Typography } from '@/components/Typography/Typography';
import { DropdownItemsByFetch } from '@/core/enums/common/DropdownItemsByFetchEnum';
import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';
import { participationPlanActions } from '@/core/redux/slices/functions/fundingCycle/participationPlan/participationPlanSlice';
import { participationPlanSelectors } from '@/core/redux/slices/functions/fundingCycle/participationPlan/selectors';
import { colorTypes } from '@/styles/types';
import { LoadingStatus } from '@/types/loadingStatus';

import { FundingCycleSection } from '../FundingCycleSection/FundingCycleSection';

export interface IGoalsWishesFormValues {
  [key: string]: {
    explanationDescription?: string | null;
    methodID?: number | null;
  };
}

interface IGoalsWishes {
  participationPlanID?: number | null;
}

export const GoalsWishes: React.FC<IGoalsWishes> = ({ participationPlanID }) => {
  const dispatch = useAppDispatch();

  const goalsWishes = useAppSelector(participationPlanSelectors.goalsWishes);
  const goalsWishesLock = useAppSelector(participationPlanSelectors.goalsWishesLock);

  const { t: goalsWishesTranslations } = useTranslation('goalsWishes');
  const form = useForm<IGoalsWishesFormValues>();

  const onSubmit = (data: IGoalsWishesFormValues) => {
    if (!participationPlanID) {
      return;
    }

    const goalsWishesData = Object.keys(data).map((key) => ({
      goalsWishesID: Number(key),
      explanationDescription: data[key].explanationDescription,
      methodID: data[key].methodID,
    }));

    dispatch(
      participationPlanActions.updateGoalsWishes({
        goalsWishesData,
        participationPlanID,
      })
    );
  };

  useEffect(() => {
    if (!goalsWishes) {
      return;
    }

    const formValues = goalsWishes.reduce((acc, item) => {
      acc[item.id] = {
        explanationDescription: item.explanationDescription,
        methodID: item.method,
      };
      return acc;
    }, {} as IGoalsWishesFormValues);

    form.reset(formValues, { keepDirty: false });
  }, [goalsWishes]);

  useEffect(() => {
    if (!participationPlanID) {
      return;
    }

    dispatch(
      participationPlanActions.fetchGoalsWishes({
        participationPlanID: participationPlanID,
      })
    );
  }, [participationPlanID]);

  useEffect(() => {
    return () => {
      dispatch(participationPlanActions.setGoalsWishes([]));
    };
  }, []);

  const GoalsWishesControls = form.formState.isDirty ? (
    <Button type={'submit'} className={'w-fit'}>
      {goalsWishesLock === LoadingStatus.LOADING ? (
        <SpinnerIcon className={`text-${colorTypes.White} w-[16px] h-[16px]`} />
      ) : (
        goalsWishesTranslations('buttons.save.label')
      )}
    </Button>
  ) : null;

  return (
    <FundingCycleSection header={<FundingCycleSectionHeader controls={GoalsWishesControls} />}>
      <FormProvider {...form}>
        {!participationPlanID ? (
          <div className='p-5'>
            <Typography>{goalsWishesTranslations('emptyParticipantPlan.label')}</Typography>
          </div>
        ) : (
          <form onSubmit={form.handleSubmit(onSubmit)} className='p-5 h-full flex flex-col'>
            <div className='mb-4'>
              <div className='grid grid-cols-12 gap-4'>
                <div className='col-span-2'>{goalsWishesTranslations('columns.topic.label')}</div>
                <div className='col-span-6'>
                  {goalsWishesTranslations('columns.explanationDescription.label')}
                </div>
                <div className='col-span-4'>
                  {goalsWishesTranslations('columns.methodID.label')}
                </div>
              </div>
            </div>
            <div className='flex-grow flex flex-col gap-4 justify-between'>
              {goalsWishes.map((item) => (
                <div key={item.id} className='grid grid-cols-12 gap-4'>
                  <div className='col-span-2'>
                    <h3>{item.topic}</h3>
                  </div>
                  <div className='col-span-6'>
                    <TextArea
                      control={form.control.register(`${item.id}.explanationDescription`)}
                      maxLength={
                        item.maxCharacters && item.maxCharacters !== 0
                          ? item.maxCharacters
                          : undefined
                      }
                    />
                  </div>
                  <div className='col-span-4'>
                    <ExternalSelectForm
                      tableName={DropdownItemsByFetch.DETERMINATION_METHOD}
                      control={form.control.register(`${item.id}.methodID`)}
                      isFlexible
                    />
                  </div>
                </div>
              ))}
            </div>
          </form>
        )}
      </FormProvider>
    </FundingCycleSection>
  );
};
