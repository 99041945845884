import { createSelector } from '@reduxjs/toolkit';

import { IRootState } from '@/core/redux/types';

const selector = (state: IRootState) => state.functions.fundingCycle.smartGoals;

const smartGoals = createSelector([selector], (state) => state.smartGoals);
const smartGoalDetails =  createSelector([selector], (state) => state.smartGoalDetails);
const measures = createSelector([selector], (state) => state.measures);

const selectedSmartGoal = createSelector([selector], (state) => state.selectedSmartGoal);
const selectedMeasure = createSelector([selector], (state) => state.selectedMeasure);

const smartGoalDetailsLock = createSelector([selector], (state) => state.smartGoalDetailsLock);
const createSmartGoalLock = createSelector([selector], (state) => state.createSmartGoalLock);
const updateSmartGoalLock = createSelector([selector], (state) => state.updateSmartGoalLock);

export const smartGoalsSelectors = {
  smartGoals,
  selectedSmartGoal,
  smartGoalDetails,
  smartGoalDetailsLock,
  createSmartGoalLock,
  updateSmartGoalLock,
  measures,
  selectedMeasure
};
