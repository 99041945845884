import { PayloadAction } from '@reduxjs/toolkit';
import { NormalizeOAS, OASOutput, OASRequestParams } from 'fets';
import { call, put, takeLatest } from 'redux-saga/effects';

import { authAdd, restCall } from '@/core/clients/rest';
import { toCamelCase } from '@/core/utils/commonUtils';
import { toBackendDate, toClientDateInput } from '@/core/utils/dateTimeUtil';
import type oas from '@/services/rest/base/openapi';
import { Camelize } from '@/types/camelize';
import { LoadingStatus } from '@/types/loadingStatus';

import {
  IGoalsWishesFetchPayload,
  IGoalsWishesUpdatePayload,
  IParticipantPlansFetchPayload,
  IParticipationPlanCreatePayload,
  IParticipationPlanDeletePayload,
  IParticipationPlanDetailsFetchPayload,
  IParticipationPlanUpdatePayload,
  IUpdateGoalWhish,
  participationPlanActions,
} from './participationPlanSlice';

type ParticipantPlansResponse = OASOutput<
  NormalizeOAS<typeof oas>,
  '/funding_cycle/participation_plan',
  'get',
  '200'
>;
type ParticipantPlanDetailsResponse = OASOutput<
  NormalizeOAS<typeof oas>,
  '/funding_cycle/participation_plan/details',
  'get',
  '200'
>;
type ParticipantPlanCreateRequest = OASRequestParams<
  NormalizeOAS<typeof oas>,
  '/funding_cycle/participation_plan',
  'post'
>;
type ParticipantPlanUpdateRequest = OASRequestParams<
  NormalizeOAS<typeof oas>,
  '/funding_cycle/participation_plan',
  'put'
>;
type ParticipantPlanDeleteRequest = OASRequestParams<
  NormalizeOAS<typeof oas>,
  '/funding_cycle/participation_plan',
  'delete'
>;

type GoalsWishesResponse = OASOutput<
  NormalizeOAS<typeof oas>,
  '/funding_cycle/participation_plan/goals_wishes',
  'get',
  '200'
>;
type GoalsWishesUpdateRequest = OASRequestParams<
  NormalizeOAS<typeof oas>,
  '/funding_cycle/participation_plan/goals_wishes',
  'put'
>;

// helpers

const mapUpdatedGoalWhishes = (items: IUpdateGoalWhish[]) =>
  items.map((item) => ({
    goals_wishes_id: item.goalsWishesID,
    explanation_description: item.explanationDescription,
    method_id: item.methodID,
  }));

// participation plans

function* fetchParticipantPlans(
  action: PayloadAction<IParticipantPlansFetchPayload>
): Generator<any, void, ParticipantPlansResponse> {
  const { personID } = action.payload;

  try {
    const response = yield call(restCall, '/funding_cycle/participation_plan', 'get', {
      query: {
        person_id: personID,
      },
      ...authAdd(),
    });

    const camelizeResponse: Camelize<ParticipantPlansResponse> = toCamelCase(response);
    const participantPlans = camelizeResponse.participantPlans.map((item) => ({
      ...item,
      startDate: toClientDateInput(item.startDate) ?? '',
    }));

    yield put(participationPlanActions.setParticipanPlans(participantPlans));
  } catch (error) {
    console.error('Error on participant plans fetching');
  }
}

function* fetchParticipationPlanDetails(
  action: PayloadAction<IParticipationPlanDetailsFetchPayload>
): Generator<any, void, ParticipantPlanDetailsResponse> {
  const { participationPlanID } = action.payload;

  yield put(participationPlanActions.setParticipationPlanDetailsLock(LoadingStatus.LOADING));

  try {
    const response = yield call(restCall, '/funding_cycle/participation_plan/details', 'get', {
      query: {
        participation_plan_id: participationPlanID,
      },
      ...authAdd(),
    });

    const camelizeResponse: Camelize<ParticipantPlanDetailsResponse> = toCamelCase(response);
    const participationPlanDetails = {
      ...camelizeResponse.participationPlan,
      startDate: toClientDateInput(camelizeResponse.participationPlan.startDate),
    };

    yield put(participationPlanActions.setParticipationPlanDetails(participationPlanDetails));

    yield put(participationPlanActions.setParticipationPlanDetailsLock(LoadingStatus.LOADED));
  } catch (error) {
    console.error('Error on participant plan details fetching');
    yield put(participationPlanActions.setParticipationPlanDetailsLock(LoadingStatus.ERROR));
  }
}

function* createParticipationPlan(
  action: PayloadAction<IParticipationPlanCreatePayload>
): Generator<any, void, any> {
  const { personID, participationPlanData } = action.payload;
  const { name, groupManager, socialServiceWorker, startDate } = participationPlanData;

  yield put(participationPlanActions.setCreateParticipationPlanLock(LoadingStatus.LOADING));

  try {
    const request: ParticipantPlanCreateRequest = {
      json: {
        person_id: personID,
        name: name,
        group_manager_id: groupManager,
        social_service_worker_id: socialServiceWorker,
        start_date: toBackendDate(startDate) ?? '',
      },
      ...authAdd(),
    };

    yield call(
      restCall,
      '/funding_cycle/participation_plan',
      'post',
      {
        ...request,
      },
      null,
      true
    );

    yield put(participationPlanActions.setCreateParticipationPlanLock(LoadingStatus.LOADED));

    yield put(
      participationPlanActions.fetchParticipantPlans({
        personID: personID,
      })
    );
  } catch (error) {
    if (error instanceof Error) {
      console.error('Error on create particiption plan', error.message);
      yield put(participationPlanActions.setParticipationPlanNotification(error.message));
      yield put(participationPlanActions.setCreateParticipationPlanLock(LoadingStatus.ERROR));
    }
  }
}

function* updateParticipationPlan(
  action: PayloadAction<IParticipationPlanUpdatePayload>
): Generator<any, void, any> {
  const { personID, participationPlanID, participationPlanData } = action.payload;
  const { name, groupManager, socialServiceWorker, startDate } = participationPlanData;

  yield put(participationPlanActions.setUpdateParticipationPlanLock(LoadingStatus.LOADING));

  try {
    const request: ParticipantPlanUpdateRequest = {
      json: {
        id: participationPlanID,
        name: name,
        group_manager_id: groupManager,
        social_service_worker_id: socialServiceWorker,
        start_date: toBackendDate(startDate) ?? '',
      },
      ...authAdd(),
    };

    yield call(
      restCall,
      '/funding_cycle/participation_plan',
      'put',
      {
        ...request,
      },
      null,
      true
    );

    yield put(participationPlanActions.setUpdateParticipationPlanLock(LoadingStatus.LOADED));

    yield put(
      participationPlanActions.fetchParticipantPlans({
        personID: personID,
      })
    );
  } catch (error) {
    if (error instanceof Error) {
      console.error('Error on update particiption plan', error.message);
      yield put(participationPlanActions.setParticipationPlanNotification(error.message));
      yield put(participationPlanActions.setUpdateParticipationPlanLock(LoadingStatus.ERROR));
    }
  }
}

function* deleteParticipationPlan(
  action: PayloadAction<IParticipationPlanDeletePayload>
): Generator<any, void, any> {
  const { participationPlanID, personID } = action.payload;

  try {
    const request: ParticipantPlanDeleteRequest = {
      query: {
        participation_plan_id: participationPlanID,
      },
      ...authAdd(),
    };

    yield call(
      restCall,
      '/funding_cycle/participation_plan',
      'delete',
      {
        ...request,
      },
      null,
      true
    );

    yield put(
      participationPlanActions.fetchParticipantPlans({
        personID: personID,
      })
    );
  } catch (error) {
    console.error('Error on delete particiption plan', error);
  }
}

// goals / wishes

function* fetchGoalsWishes(
  action: PayloadAction<IGoalsWishesFetchPayload>
): Generator<any, void, GoalsWishesResponse> {
  const { participationPlanID } = action.payload;

  try {
    const response = yield call(restCall, '/funding_cycle/participation_plan/goals_wishes', 'get', {
      query: {
        participation_plan_id: participationPlanID,
      },
      ...authAdd(),
    });

    const camelizeResponse: Camelize<GoalsWishesResponse> = toCamelCase(response);
    const goalsWishes = camelizeResponse.goalsWishes.map((item) => ({
      ...item,
      method: item.method?.id,
    }));

    yield put(participationPlanActions.setGoalsWishes(goalsWishes));
  } catch (error) {
    console.error('Error on goals and wishes fetching');
  }
}

function* updateGoalsWishes(
  action: PayloadAction<IGoalsWishesUpdatePayload>
): Generator<any, void, any> {
  const { goalsWishesData, participationPlanID } = action.payload;

  try {
    yield put(participationPlanActions.setGoalsWishesLock(LoadingStatus.LOADING));

    const request: GoalsWishesUpdateRequest = {
      json: mapUpdatedGoalWhishes(goalsWishesData),
      ...authAdd(),
    };

    yield call(
      restCall,
      '/funding_cycle/participation_plan/goals_wishes',
      'put',
      {
        ...request,
      },
      null,
      true
    );

    yield put(participationPlanActions.setGoalsWishesLock(LoadingStatus.LOADED));
    yield put(
      participationPlanActions.fetchGoalsWishes({ participationPlanID: participationPlanID })
    );
  } catch (error) {
    console.error('Error on goal wishes data updating', error);
    yield put(participationPlanActions.setGoalsWishesLock(LoadingStatus.ERROR));
  }
}

export const participationPlanSagas = [
  takeLatest(participationPlanActions.fetchParticipantPlans, fetchParticipantPlans),
  takeLatest(participationPlanActions.createParticipationPlan, createParticipationPlan),
  takeLatest(participationPlanActions.updateParticipationPlan, updateParticipationPlan),
  takeLatest(participationPlanActions.deleteParticipationPlan, deleteParticipationPlan),

  takeLatest(participationPlanActions.fetchParticipationPlanDetails, fetchParticipationPlanDetails),

  takeLatest(participationPlanActions.fetchGoalsWishes, fetchGoalsWishes),
  takeLatest(participationPlanActions.updateGoalsWishes, updateGoalsWishes),
];
