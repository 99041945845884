import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface ISmartGoalsModalPayload {
  personID: number;
  smartGoalID: number;
}

export interface IModal {
  isRender: boolean;
  payload: ISmartGoalsModalPayload | null;
}

export interface ISmartGoalsModalState {
  displaySmartGoalModal: IModal;
}

const initialState: ISmartGoalsModalState = {
  displaySmartGoalModal: {
    isRender: false,
    payload: null,
  },
};

export const smartGoalsModalSlice = createSlice({
  name: 'smartGoalsModal',
  initialState,
  reducers: {
    openDisplaySmartGoalModal: (state, action: PayloadAction<ISmartGoalsModalPayload>) => ({
      ...state,
      displaySmartGoalModal: {
        isRender: true,
        payload: action.payload,
      },
    }),
    closeDisplaySmartGoalModal: (state) => ({
      ...state,
      displaySmartGoalModal: {
        isRender: false,
        payload: null,
      },
    }),
  },
});

export const smartGoalsModalReducer = smartGoalsModalSlice.reducer;
export const smartGoalsModalActions = smartGoalsModalSlice.actions;
