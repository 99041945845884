import { ObjectSchema } from 'yup';

import { IMedicationFormFields } from '@/components/forms/Medication/Medication';
import { UploadFileFormField } from '@/components/UploadFileZone/UploadFileZone';
import { yup } from '@/core/utils/commonUtils';

export const MedicationFormResolver: ObjectSchema<IMedicationFormFields> = yup.object().shape({
  id: yup.number().optional(),
  name: yup.string().trim().required('Please provide a medication name'),
  isNotDispensed: yup.boolean().required('Please specify if the medication is not dispensed'),
  prescriptionFile: yup
    .mixed<UploadFileFormField>()
    .test('file-validation', 'Prescription file is required', (value) => {
      return value !== undefined && value.fileName !== undefined && value.fileName !== null;
    })
    .required('Prescription file is required'),
  isRemarkedOnGiving: yup.boolean().nullable(),
  dosageFormId: yup.string().nullable(),
  individualDosage: yup.string().nullable(),
  maxDailyDosage: yup.string().nullable(),
  indicationID: yup.string().nullable(),
  orderedBy: yup.string().nullable().required(),
  orderedFromTimestamp: yup.string().nullable().required(),
  orderedToTimestamp: yup.string().nullable(),
  dosages: yup
    .array()
    .of(
      yup.object({
        id: yup.number().required(),
        dosage: yup.string().nullable(),
        inWorkshop: yup.boolean().nullable(),
      })
    )
    .required()
    .when('isDemanded', {
      is: false,
      then: () =>
        yup
          .array()
          .of(
            yup.object({
              id: yup.number().required(),
              dosage: yup.string().nullable(),
              inWorkshop: yup.boolean().nullable(),
            })
          )
          .required()
          .test('required', 'At least one dosage must be provided', (value) => {
            return value.some((item) => item.dosage);
          }),
      otherwise: () =>
        yup
          .array()
          .of(
            yup.object({
              id: yup.number().required(),
              dosage: yup.string().nullable(),
              inWorkshop: yup.boolean().nullable(),
            })
          )
          .required()
    }),

  isDemanded: yup.boolean().nullable(),
  note: yup.string().nullable(),
});
