import { UseFormReturn } from 'react-hook-form';
import fp from 'lodash/fp';

import { ICreateOrganizationalUnitForm } from '@/components/Modals/Modals/functions/organizationalUnitsModals/CreateOrganizationalUnitModal/CreateOrganizationalUnitModalContent/CreateOrganizationalUnitModalContent';
import { OrganizationalUnitsTypes } from '@/core/enums/functions/organizationalUnits/organizationalUnitsTypesEnum';
import { useAppDispatch } from '@/core/redux/hooks';
import { organizationalUnitsModalsActions } from '@/core/redux/slices/modalsSlice/functions/organizationalUnits/slice';

export function useBBBUpdate(
  form: UseFormReturn<ICreateOrganizationalUnitForm, any, undefined>,
  personID: number,
  type?: OrganizationalUnitsTypes | null
) {
  const dispatch = useAppDispatch();

  const handleUpdateBBB = () => {
    form.trigger('bbb').then((isValid) => {
      if (!isValid) {
        return;
      }

      const bbbFormValues = fp.cloneDeep(form.watch('bbb'));

      if (!bbbFormValues || !type) {
        return;
      }

      dispatch(organizationalUnitsModalsActions.setOrganizationalUnitBBBFormValues(bbbFormValues));

      dispatch(
        organizationalUnitsModalsActions.createOrganizationalUnit({
          personID: personID,
          type: type,
          fromTimestamp: bbbFormValues.startDate,
        })
      );
    });
  };

  return { handleUpdateBBB };
}
