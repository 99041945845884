import { Typography } from '../Typography/Typography';

export interface ITag {
  background: string;
  label: string;
}

export const Tag: React.FC<ITag> = ({ background, label }) => {
  return (
    <div className={`px-3 py-1.5 rounded-3xl w-fit bg-${background}`}>
      <Typography color={'White'} type={'14-M'}>
        {label}
      </Typography>
    </div>
  );
};
