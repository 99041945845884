import React, { useEffect, useMemo, useState } from 'react';
import fp from 'lodash/fp';

import { Skeleton } from '@/components/Skeleton/Skeleton';
import useFetchedDepartments from '@/components/views/person/PersonPage/DepartmentView/DepartmentList/hooks/useFetchedDepartments';
import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';
import { userSettingsSelectors } from '@/core/redux/slices/userSettings/selectors';
import { userSettingsActions } from '@/core/redux/slices/userSettings/userSettingsSlice';

import { usePersonPageStoreSelector } from '../../Context/PersonPageStoreContext';

import {
  DepartmentsTreeSelect,
  ICheckedState,
} from './DepartmentsTreeSelect/DepartmentsTreeSelect';

export const DepartmentList: React.FC = () => {
  const [locationsCheckState, setLocationCheckState] = usePersonPageStoreSelector(
    (store) => store.locationsChecksState
  );

  const { departments, isDepartmentsInProgress } = useFetchedDepartments();

  const dispatch = useAppDispatch();

  const [defaultSelected, setDefaultSelected] = useState<ICheckedState>();

  const userSelectedDepartments = useAppSelector(userSettingsSelectors.userSelectedDepartments);

  const defaultSelectedDepartments = useMemo(
    () => (userSelectedDepartments ? JSON.parse(userSelectedDepartments) : {}),
    [userSelectedDepartments]
  );

  useEffect(() => {
    if (fp.isEmpty(locationsCheckState) && defaultSelectedDepartments) {
      setLocationCheckState({
        locationsChecksState: defaultSelectedDepartments,
      });
      setDefaultSelected(defaultSelectedDepartments);
    } else {
      setDefaultSelected(locationsCheckState);
    }
  }, [defaultSelectedDepartments]);


  return (
    <Skeleton trigger={isDepartmentsInProgress} rows={4} className={'p-[30px]'}>
      {departments && (
        <DepartmentsTreeSelect
          departments={departments}
          defaultSelected={defaultSelected}
          onSelectedChange={(selected: ICheckedState) => {
            setLocationCheckState({ locationsChecksState: selected });
            dispatch(userSettingsActions.updateUserSelectedDepartments(selected));
          }}
        />
      )}
    </Skeleton>
  );
};
