import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Tooltip } from 'react-tooltip';

import { AttentionIcon } from '@/components/Icons/AttentionIcon';
import { CheckedIcon } from '@/components/Icons/CheckedIcon';
import { CrossIcon } from '@/components/Icons/CrossIcon';
import { TableFolderIcon } from '@/components/Icons/TableFolderIcon';
import { TableRefreshIcon } from '@/components/Icons/TableRefreshIcon';
import { ITableColumn, Table } from '@/components/Table/Table';
import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';
import {
  IMedication,
  medicationAdministrationActions,
} from '@/core/redux/slices/medicationAdministration/medicationAdministrationSlice';
import { medicationAdministrationSelectors } from '@/core/redux/slices/medicationAdministration/selectors';
import { medicationAdministrationModalsActions } from '@/core/redux/slices/modalsSlice/medicationAdministration/medicationAdministrationModalSlice';
import { notificationsActions } from '@/core/redux/slices/notifications/notificationsSlice';
import { LoadingStatus } from '@/types/loadingStatus';

export const MedicationAdministratedList: React.FC = () => {
  const { t: medicationAdministrationTranslations } = useTranslation('medicationAdministration');

  const medications = useAppSelector(medicationAdministrationSelectors.administratedMedications);
  const markUnadministratedLock = useAppSelector(
    medicationAdministrationSelectors.markUnadministratedLock
  );

  const dispatch = useAppDispatch();

  const handleMarkUnadministrated = (id: number) => {
    dispatch(
      medicationAdministrationModalsActions.openConfirmUnmarknModal({
        medicationID: id,
      })
    );
  };

  const handleOpenHistory = (medication: IMedication) => {
    dispatch(
      medicationAdministrationModalsActions.openHistorynModal({
        medication: medication,
      })
    );
  };

  const columns: ITableColumn[] = [
    {
      header: medicationAdministrationTranslations('columns.personName.label'),
      accessor: 'personName',
    },
    {
      header: medicationAdministrationTranslations('columns.medicationName.label'),
      accessor: 'medicationName',
      className: 'px-1 max-w-[300px] whitespace-normal',
      headerClassName: 'px-1',
      template: (value: string, data: IMedication) => {
        const { id, administrationNote } = data;
        return (
          <div className='flex gap-5 items-center'>
            {administrationNote && (
              <div data-tooltip-id={String(id)} data-tooltip-content={administrationNote}>
                <AttentionIcon />
              </div>
            )}
            {value}
            <Tooltip id={String(id)} style={{ zIndex: 100000 }} />
          </div>
        );
      },
    },
    {
      header: medicationAdministrationTranslations('columns.dosageForm.label'),
      accessor: 'dosageForm',
      className: 'px-1',
      headerClassName: 'px-1',
    },
    {
      header: medicationAdministrationTranslations('columns.daytime.label'),
      accessor: 'daytime',
      className: 'px-1',
      headerClassName: 'px-1',
    },
    {
      header: medicationAdministrationTranslations('columns.dosage.label'),
      accessor: 'dosage',
      className: 'px-1',
      headerClassName: 'px-1',
    },
    {
      header: medicationAdministrationTranslations('columns.isNotGiven.label'),
      headerClassName: 'bg-orange text-white px-1',
      accessor: 'isNotGiven',
      className: 'bg-orange bg-opacity-20 group text-center align-middle px-1',
      template: (value: boolean) => {
        return (
          <div className='flex items-center justify-center text-white'>
            {value && <CrossIcon className='w-5 h-5' />}
          </div>
        );
      },
    },
    {
      header: medicationAdministrationTranslations('columns.isGiven.label'),
      headerClassName: 'bg-blue text-white px-1',
      accessor: 'isGiven',
      className: 'bg-blue bg-opacity-20 text-center align-middle px-1',
      template: (value: boolean) => {
        return (
          <div className='flex items-center justify-center text-white'>
            {value && <CheckedIcon className='w-5 h-5' />}
          </div>
        );
      },
    },
    {
      header: medicationAdministrationTranslations('columns.administeredOnTimestamp.label'),
      accessor: 'administeredOnTimestamp',
    },
    {
      header: medicationAdministrationTranslations('columns.administeredBy.label'),
      accessor: 'administeredBy',
    },
    {
      header: '',
      accessor: '',
      isSortable: false,
      template: (value, data: IMedication) => {
        const { id, administrationRemark } = data;

        return (
          <div className='flex gap-2.5'>
            <div
              className='p-2 hover:bg-hover rounded-default'
              onClick={() => handleOpenHistory(data)}
            >
              <TableFolderIcon />
            </div>
            <div
              className='p-2 hover:bg-hover rounded-default'
              onClick={() => handleMarkUnadministrated(id)}
            >
              <TableRefreshIcon />
            </div>
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    if (markUnadministratedLock === LoadingStatus.LOADED) {
      dispatch(
        notificationsActions.showNotification({
          notification: {
            type: 'success',
            title: medicationAdministrationTranslations(
              'notification.markUnadministrated.title.label'
            ),
            description: medicationAdministrationTranslations(
              'notification.markUnadministrated.description.label'
            ),
          },
        })
      );
    }
  }, [markUnadministratedLock]);

  useEffect(() => {
    return () => {
      dispatch(medicationAdministrationActions.setAdministratedMedications([]));
      dispatch(
        medicationAdministrationActions.setAdministratedMedicationsLock(LoadingStatus.NEVER)
      );
      dispatch(medicationAdministrationActions.setMarkAdministratedLock(LoadingStatus.NEVER));
    };
  }, []);

  return (
    <Table
      data={medications}
      columns={columns}
      singleSelect
      defaultSortConfig={{
        key: 'personName',
        direction: 'asc',
      }}
    />
  );
};
