import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import fp from 'lodash/fp';

import { reviewSupportDefaultValues } from '@/components/functions/DevelopmentReport/DevelopmentReportForms/ReviewSupport/helper/goalsAndActivityForm';
import { useDevelopmentReport } from '@/components/functions/DevelopmentReport/hooks/useDevelopmentReport';
import { IDevelopmentReportForm } from '@/components/functions/DevelopmentReport/hooks/useDevelopmentReportForm';
import { DevelopmentReportForms } from '@/core/enums/functions/developmentReport/developmentReportFormsEnum';
import { useAppDispatch } from '@/core/redux/hooks';
import { developmentReportReviewSupportActions } from '@/core/redux/slices/functions/developmentReport/developmentReportReviewSupportSlice/slice';

export const DevelopmentReportReviewSupport: React.FC = () => {
  const { t: reviewSupportTranslations } = useTranslation('developmentReportReviewSupport');

  const { developmentReportID } = useDevelopmentReport();

  const { reset, watch } = useFormContext<IDevelopmentReportForm>();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!developmentReportID) {
      return;
    }

    dispatch(
      developmentReportReviewSupportActions.fetchDevelopmentReportReviewSupport({
        developmentReportID: developmentReportID,
      })
    );

    return () => {
      const currentValues = fp.cloneDeep(watch());
      reset({
        ...currentValues,
        [DevelopmentReportForms.Review]: reviewSupportDefaultValues,
      });
    };
  }, [developmentReportID]);

  return <div></div>;
};
