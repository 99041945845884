import { OrganizationalUnitErrors } from '@/core/enums/functions/organizationalUnits/organizationalUnitErrorsEnum';
import { organizationalUnitsModalsActions } from '@/core/redux/slices/modalsSlice/functions/organizationalUnits/slice';
import { store } from '@/core/redux/store';

export const organizationalUnitErrorHandler: Record<number, () => void> = {
  500: () => {
    console.log('Incorrect Date');
    store.dispatch(
      organizationalUnitsModalsActions.setOrganizationalErrors({
        [OrganizationalUnitErrors.INCORRECT_DATE]: true,
      })
    );
  },
  501: () => {
    console.log('Person null');
    store.dispatch(
      organizationalUnitsModalsActions.setOrganizationalErrors({
        [OrganizationalUnitErrors.WITHOUT_PERSON]: true,
      })
    );
  },
  502: () => {
    console.log('End date before start date');
    store.dispatch(
      organizationalUnitsModalsActions.setOrganizationalErrors({
        [OrganizationalUnitErrors.END_DATE_BEFORE_START_DATE]: true,
      })
    );
  },
  503: () => {
    console.log('Comment required');
    store.dispatch(
      organizationalUnitsModalsActions.setOrganizationalErrors({
        [OrganizationalUnitErrors.WITHOUT_NOTE]: true,
      })
    );
  },
  504: () => {
    console.log('Incorrect period');
    store.dispatch(
      organizationalUnitsModalsActions.setOrganizationalErrors({
        [OrganizationalUnitErrors.CREATE_OUT_OF_RANGE]: true,
      })
    );
  },
  505: () => {
    console.log('Incorrect update period');
    store.dispatch(
      organizationalUnitsModalsActions.setOrganizationalErrors({
        [OrganizationalUnitErrors.UPDATE_OUT_OF_RANGE]: true,
      })
    );
  },
};
