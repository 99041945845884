import { ObjectSchema } from 'yup';

import { IDevelopmentReportPersonDetailsForm } from '@/components/functions/DevelopmentReport/DevelopmentReportForms/PersonDetails/helper/personDetailsForm';
import { yup } from '@/core/utils/commonUtils';

export function developmentReportPersonDetailsValidatorBuilder(
  translations: any
): ObjectSchema<IDevelopmentReportPersonDetailsForm> {
  return yup.object().shape({
    wfbmName: yup.string().nullable().optional(),
    wfbmOperatingUnit: yup.string().nullable().optional(),
    wfbmContactPerson: yup.string().nullable().optional(),
    hbg1: yup.boolean().required(),
    hbg2: yup.boolean().required(),
    hgb3: yup.boolean().required(),
    legalGuardianYesFlag: yup.boolean().required(),
    legalGuardianNoFlag: yup.boolean().required(),
    legalGuardianNameAddress: yup.string().nullable().optional(),
    isMobilityOepnv: yup.boolean().required(),
    isMobilityTransportService: yup.boolean().required(),
    isMobilityOnFoot: yup.boolean().required(),
    isMobilitySelfDrive: yup.boolean().required(),
    workingHoursPerWeek: yup.number().nullable().optional(),
    workingDaysPerWeek: yup.number().nullable().optional(),
    workingHoursPerDay: yup.number().nullable().optional(),
    diagnosis: yup.string().nullable().optional(),
    internships: yup.string().nullable().optional(),
    communicationNote: yup.string().nullable().optional(),
    goalsWishes: yup.string().nullable().optional(),
    defaultLegalGuardianNameAddress: yup.string().nullable().optional(),
  });
}

export const developmentReportPersonDetailsValidator =
  developmentReportPersonDetailsValidatorBuilder('s');
