import React, { DetailedHTMLProps, HTMLAttributes, useEffect, useRef } from 'react';
import { useController, UseControllerProps } from 'react-hook-form';

import { CrossIcon } from '@/components/Icons/CrossIcon';
import { usePermissions } from '@/components/PermissionsContext/PermissionsContext';
import {
  getTextAreaVariant,
  TextAreaVariants,
} from '@/components/TextArea/helpers/textAreaVariants';
import { Typography } from '@/components/Typography/Typography';
import { twMerge } from '@/core/utils/tailwindUtil';
import { colorTypes } from '@/styles/types';

import { useSchemaContext } from '../SchemaContext/SchemaContext';

const INTERNAL_PADDING_COMPENSATION = 7;

interface ITextArea
  extends DetailedHTMLProps<HTMLAttributes<HTMLTextAreaElement>, HTMLTextAreaElement> {
  control: UseControllerProps;
  label?: string | React.ReactNode;
  resetButton?: boolean;
  readOnly?: boolean;
  handleChange?: (value: string) => string;
  textAreaVariant?: TextAreaVariants;
  className?: string;
  containerClassName?: string;
  wrapperClassName?: string;
  errorMessage?: string;
  maxLength?: number;
}

export const TextArea: React.FC<ITextArea> = ({
  control,
  handleChange,
  label,
  errorMessage,
  containerClassName,
  className,
  wrapperClassName,
  textAreaVariant = 'Default',
  resetButton = true,
  readOnly,
  maxLength,
  ...rest
}) => {
  const {
    field: { onChange: controllerOnChange, ...field },
    fieldState,
  } = useController(control);

  const permissions = usePermissions(field.name);

  const textAreaRef = useRef<HTMLTextAreaElement | null>(null);
  const containerRef = useRef<HTMLDivElement | null>(null);

  const onChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    if (readOnly || permissions.isReadOnly) {
      return;
    }

    if (handleChange) {
      controllerOnChange(handleChange(event.target.value));
      return;
    }
    controllerOnChange(event.target.value);
  };

  const handleFieldReset = () => {
    controllerOnChange('');
  };

  const { isRequiredField } = useSchemaContext(field.name);

  useEffect(() => {
    if (field && field.ref) {
      field.ref(textAreaRef.current);
      if (textAreaRef.current && containerRef.current) {
        textAreaRef.current.style.height = 'auto';
        const containerHeight = containerRef.current.clientHeight;
        const currentHeight = textAreaRef.current.scrollHeight;
        textAreaRef.current.style.height = `${Math.max(
          containerHeight - INTERNAL_PADDING_COMPENSATION,
          currentHeight
        )}px`;
      }
    }
  }, [field.value, textAreaRef]);

  return (
    <div className={twMerge('flex flex-col gap-3', containerClassName)} ref={containerRef}>
      {label &&
        (typeof label === 'string' ? (
          <Typography>
            {label} {isRequiredField && '*'}
          </Typography>
        ) : (
          <>
            {label} {isRequiredField && '*'}
          </>
        ))}
      <div className={twMerge('relative', wrapperClassName)}>
        <textarea
          onChange={onChange}
          readOnly={readOnly || permissions.isReadOnly}
          maxLength={maxLength}
          className={twMerge(
            'overflow-hidden',
            getTextAreaVariant(textAreaVariant),
            fieldState.error && `border-${colorTypes.Error} focus:border-${colorTypes.Error}`,
            resetButton && 'pe-[40px]',
            className
          )}
          {...field}
          {...rest}
          ref={textAreaRef}
          value={field.value || ''}
        />
        {resetButton &&
          field.value !== '' &&
          !readOnly &&
          !permissions.isReadOnly &&
          !field.disabled && (
            <CrossIcon
              className={`absolute right-3 top-4 transform text-${colorTypes.Disabled} cursor-pointer`}
              onClick={handleFieldReset}
            />
          )}
      </div>
      {fieldState.error && (
        <Typography type={'14-R'} color={'Error'} className={'mt-1'}>
          {fieldState.error.message}
        </Typography>
      )}
      {!fieldState.error && errorMessage && (
        <Typography type={'14-R'} color={'Disabled'} className={'mt-1'}>
          {errorMessage}
        </Typography>
      )}
    </div>
  );
};
