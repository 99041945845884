import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useActivityPlaningSelector } from '@/components/functions/ActivityPlanning/FullFunction/ActivityPlanningContext/ActivityPlanningContext';
import { MeasuresListHeader } from '@/components/functions/ActivityPlanning/FullFunction/MeasuresList/MeasureListHeader/MeasuresListHeader';
import { Skeleton } from '@/components/Skeleton/Skeleton';
import { ITableColumn, Table } from '@/components/Table/Table';
import { useSelectedPerson } from '@/core/hooks/useSelectedPerson';
import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';
import { activityPlaningMeasuresListSelectors } from '@/core/redux/slices/functions/activityPlanning/measuresList/selectors';
import { activityPlaningMeasuresListActions } from '@/core/redux/slices/functions/activityPlanning/measuresList/slice';
import { LoadingStatus } from '@/types/loadingStatus';

export const MeasuresList: React.FC = () => {
  const { t: measuresListTranslations } = useTranslation('activityPlanning', {
    keyPrefix: 'measuresList',
  });

  const [selectedTableID, setSelectedTableID] = useState<number | null>(null);

  const [, setSelectedMeasureID] = useActivityPlaningSelector((store) => store.selectedMeasureID);
  const [, setSelectedMeasureName] = useActivityPlaningSelector(
    (store) => store.selectedMeasureName
  );
  const [isActionsBlocked] = useActivityPlaningSelector((store) => store.isActionsBlocked);

  const measuresList = useAppSelector(activityPlaningMeasuresListSelectors.measuresList);
  const measuresListLock = useAppSelector(activityPlaningMeasuresListSelectors.measuresListLock);

  const { selectedPersonID } = useSelectedPerson();
  const dispatch = useAppDispatch();

  const columns: ITableColumn[] = [
    {
      accessor: 'name',
      header: measuresListTranslations('list.tableHeader.name.label'),
      className: 'whitespace-normal',
    },
    { accessor: 'measureType', header: measuresListTranslations('list.tableHeader.type.label') },
  ];

  useEffect(() => {
    if (!selectedPersonID) {
      return;
    }

    dispatch(
      activityPlaningMeasuresListActions.fetchMeasuresList({
        personID: selectedPersonID,
      })
    );
  }, [selectedPersonID]);

  useEffect(() => {
    if (!measuresList || !measuresList[0]) {
      return;
    }

    setSelectedTableID(measuresList[0].id);
    setSelectedMeasureID({ selectedMeasureID: measuresList[0].measureID });
  }, [measuresList]);

  const handleSelect = (id: number) => {
    const foundElement = measuresList.find((measure) => measure.id === id);

    if (!foundElement || isActionsBlocked) {
      return;
    }

    setSelectedTableID(id);
    setSelectedMeasureID({
      selectedMeasureID: foundElement.measureID,
    });
    setSelectedMeasureName({ selectedMeasureName: foundElement.name });
  };

  return (
    <div className={'border p-2 flex flex-col gap-2'}>
      <MeasuresListHeader />
      <Skeleton trigger={measuresListLock === LoadingStatus.LOADING} rows={8}>
        <Table
          preventDefault={isActionsBlocked}
          isDeselectAllowed={false}
          data={measuresList}
          columns={columns}
          singleSelect={true}
          initialSelectedState={[selectedTableID]}
          className={'overflow-auto'}
          onSingleSelectChange={handleSelect}
        />
      </Skeleton>
    </div>
  );
};
