import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import fp from 'lodash/fp';

import FormRender, { FormGroup, FormItem } from '@/components/FormRender/FormRender';
import { goalsAndActivityDefaultValues } from '@/components/functions/DevelopmentReport/DevelopmentReportForms/GoalsAndActivity/helper/goalsAndActivityForm';
import { useDevelopmentReport } from '@/components/functions/DevelopmentReport/hooks/useDevelopmentReport';
import { IDevelopmentReportForm } from '@/components/functions/DevelopmentReport/hooks/useDevelopmentReportForm';
import { DevelopmentReportForms } from '@/core/enums/functions/developmentReport/developmentReportFormsEnum';
import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';
import { developmentReportGoalsAndActivitySelectors } from '@/core/redux/slices/functions/developmentReport/developmentReportGoalsAndActivitySlice/selectors';
import { developmentReportGoalsAndActivityActions } from '@/core/redux/slices/functions/developmentReport/developmentReportGoalsAndActivitySlice/slice';

export const DevelopmentReportGoalsAndActivity: React.FC = () => {
  const { t: reviewSupportTranslations } = useTranslation('developmentReportGoalsAndActivity');

  const { developmentReportID } = useDevelopmentReport();

  const { reset, watch } = useFormContext<IDevelopmentReportForm>();
  const dispatch = useAppDispatch();

  const developmentReportGoalsAndActivity = useAppSelector(
    developmentReportGoalsAndActivitySelectors.developmentReportGoalsAndActivity
  );

  useEffect(() => {
    if (!developmentReportID) {
      return;
    }

    dispatch(
      developmentReportGoalsAndActivityActions.fetchDevelopmentReportGoalsAndActivity({
        developmentReportID: developmentReportID,
      })
    );

    return () => {
      const currentValues = fp.cloneDeep(watch());
      reset({
        ...currentValues,
        [DevelopmentReportForms.GoalsAndActivityPlaning]: goalsAndActivityDefaultValues,
      });
    };
  }, [developmentReportID]);

  const developmentReportGoalsAndActivityForm: (
    | FormItem<IDevelopmentReportForm>
    | FormGroup<IDevelopmentReportForm>
  )[] = developmentReportGoalsAndActivity
    ? developmentReportGoalsAndActivity.goalsAndActivities.map<
        FormItem<IDevelopmentReportForm> | FormGroup<IDevelopmentReportForm>
      >((goalAndActivity, index) => ({
        fieldsContainerClassName: 'grid grid-cols-2 gap-5',
        fields: [
          {
            fieldsContainerClassName: 'flex flex-col gap-2',
            fields: [
              {
                name: `${DevelopmentReportForms.GoalsAndActivityPlaning}.goalsAndActivities.${index}.goal`,
                type: 'input',
                label: 'objective.label',
                labelParams: { objectiveNum: goalAndActivity.lfdNr },
              },
              {
                name: `${DevelopmentReportForms.GoalsAndActivityPlaning}.goalsAndActivities.${index}.requiredActions`,
                type: 'textarea',
                label: 'taskDetails.label',
              },
            ],
          },
          {
            fieldsContainerClassName: 'flex flex-col gap-2',
            fields: [
              {
                name: `${DevelopmentReportForms.GoalsAndActivityPlaning}.goalsAndActivities.${index}.until`,
                type: 'input',
                label: 'deadline.label',
              },
              {
                name: `${DevelopmentReportForms.GoalsAndActivityPlaning}.goalsAndActivities.${index}.executor`,
                type: 'textarea',
                label: 'responsible.label',
              },
              {
                name: `${DevelopmentReportForms.GoalsAndActivityPlaning}.goalsAndActivities.${index}.executionPlace`,
                type: 'textarea',
                label: 'location.label',
              },
            ],
          },
        ],
      }))
    : [];

  return (
    <FormRender<IDevelopmentReportForm>
      list={developmentReportGoalsAndActivityForm}
      translationContext={reviewSupportTranslations}
      className={'py-4 w-full flex flex-col gap-2'}
    />
  );
};
