import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import { ITableColumn, Table } from '@/components/Table/Table';
import { Tag } from '@/components/Tag/Tag';
import { Typography } from '@/components/Typography/Typography';
import { PerformanceProfileViews } from '@/core/enums/functions/performanceProfile/performanceProfileViewEnum';
import { QuestionnaireStatus } from '@/core/enums/functions/performanceProfile/questionnaires/questionnaireStatusEnum';
import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';
import { functionPageSelectors } from '@/core/redux/slices/functionPage/selectors';
import { performanceProfileSelectors } from '@/core/redux/slices/functions/performanceProfile/selectors';
import { performanceProfileActions } from '@/core/redux/slices/functions/performanceProfile/slice';
import { colorTypes } from '@/styles/types';

import { QuestionnaireHistoryControl } from './QuestionnaireHistoryControl/QuestionnaireHistoryControl';

interface IQuestionnaireHistory {
  onViewChange: (view: PerformanceProfileViews) => void;
}

export const QuestionnaireHistory: React.FC<IQuestionnaireHistory> = ({ onViewChange }) => {
  const { t: questionnaireHistoryTranslations } = useTranslation('performanceProfile', {
    keyPrefix: 'questionnaireHistory',
  });
  const { t: questionnaireTranslations } = useTranslation('questionnaire', { keyPrefix: 'status' });

  const [selectedCompetenceAnalysisId, setSelectedCompetenceAnalysisId] = useState<number[]>([]);

  const statusBackground: Record<QuestionnaireStatus, string> = {
    [QuestionnaireStatus.Release]: colorTypes.Blue,
    [QuestionnaireStatus.ForApproval]: colorTypes.Tiffany,
    [QuestionnaireStatus.Started]: colorTypes.Orange,
  };

  const statusLabels: Record<QuestionnaireStatus, string> = {
    [QuestionnaireStatus.Started]: questionnaireTranslations('questionnaireStatus.started.label'),
    [QuestionnaireStatus.ForApproval]: questionnaireTranslations(
      'questionnaireStatus.forApproval.label'
    ),
    [QuestionnaireStatus.Release]: questionnaireTranslations('questionnaireStatus.release.label'),
  };

  const columns: ITableColumn[] = [
    {
      header: questionnaireHistoryTranslations('table.header.catalogType.label'),
      accessor: 'targetCatalogType',
    },
    {
      header: questionnaireHistoryTranslations('table.header.status.label'),
      accessor: 'statusText',
      template: (status: QuestionnaireStatus) => (
        <Tag background={statusBackground[status]} label={statusLabels[status]} />
      ),
    },
    {
      header: questionnaireHistoryTranslations('table.header.createdBy.label'),
      accessor: 'createdBy',
    },
    {
      header: questionnaireHistoryTranslations('table.header.createdDate.label'),
      accessor: 'createdTimestamp',
      template: (date: string) => moment(date).format('DD-MM-YYYY'),
    },
    {
      header: questionnaireHistoryTranslations('table.header.completedBy.label'),
      accessor: 'closedBy',
    },
    {
      header: questionnaireHistoryTranslations('table.header.completedDate.label'),
      accessor: 'closedTimestamp',
      template: (date: string) => moment(date).format('DD-MM-YYYY'),
    },
  ];

  const history = useAppSelector(performanceProfileSelectors.history);
  const selectedPerson = useAppSelector(functionPageSelectors.selectedPerson);
  const detailIDs = useAppSelector(performanceProfileSelectors.detailsIDs);

  const selectedPersonId = selectedPerson?.id;

  const dispatch = useAppDispatch();

  const handleQuestionnaireSelect = (selected: number[]) => {
    if (!selectedPersonId) {
      return;
    }

    dispatch(
      performanceProfileActions.setViewDetailsIDs({
        personID: selectedPersonId,
        competenceAnalysisIDs: selected,
      })
    );
  };

  useEffect(() => {
    if (!selectedPersonId) {
      return;
    }

    dispatch(performanceProfileActions.fetchHistory({ personID: selectedPersonId }));
  }, [selectedPersonId]);

  useEffect(() => {
    if (!detailIDs || !selectedPerson) {
      return;
    }

    if (selectedPerson.id in detailIDs) {
      const competenceAnalysisID = detailIDs[selectedPerson.id];
      setSelectedCompetenceAnalysisId(competenceAnalysisID);
      return;
    }
  }, [detailIDs, selectedPersonId]);

  return (
    <div className='flex flex-col justify-between h-full'>
      <Table
        data={history}
        columns={columns}
        onMultiSelectChange={handleQuestionnaireSelect}
        initialSelectedState={selectedCompetenceAnalysisId}
      />
      <QuestionnaireHistoryControl onViewChange={onViewChange} />
    </div>
  );
};
