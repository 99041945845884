import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { twMerge } from 'tailwind-merge';

import { DatePicker } from '@/components/Datepicker/DatePicker';
import { Input } from '@/components/Input/Input';
import { ExternalSelectForm } from '@/components/Select/form/ExternalSelectForm';
import { DropdownItemsByFetch } from '@/core/enums/common/DropdownItemsByFetchEnum';

export interface IParticipationPlanFormFields {
  name: string;
  groupManager: number;
  socialServiceWorker: number;
  startDate: string;
}

interface IParticipationPlanForm {
  className?: string;
}

export const ParticipationPlanForm: React.FC<IParticipationPlanForm> = ({ className }) => {
  const { t: participationPlanModalTranslations } = useTranslation('displayParticipationPlanModal');

  const form = useFormContext<IParticipationPlanFormFields>();
  const { control } = form;

  return (
    <form className={twMerge('flex flex-col gap-4', className)}>
      <Input
        control={control.register('name')}
        label={participationPlanModalTranslations('fields.name.label')}
      />
      <ExternalSelectForm
        tableName={DropdownItemsByFetch.GROUP_MANAGER}
        control={control.register('groupManager')}
        label={participationPlanModalTranslations('fields.groupManager.label')}
        isFlexible
      />
      <ExternalSelectForm
        tableName={DropdownItemsByFetch.SOCIAL_SERVICE_WORKER}
        control={control.register('socialServiceWorker')}
        label={participationPlanModalTranslations('fields.socialServiceWorker.label')}
        isFlexible
      />
      <DatePicker
        control={control.register('startDate')}
        label={participationPlanModalTranslations('fields.startDate.label')}
      />
    </form>
  );
};
