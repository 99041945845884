import React, { useEffect } from 'react';
import {
  ControllerFieldState,
  FieldValues,
  useController,
  UseControllerProps,
  UseFormStateReturn,
} from 'react-hook-form';

import {
  FormRadioButtonGroupItem,
  IFormRadioButtonGroupItem,
} from '@/components/FormRadioButtonGroup/FormRadioButtonGroupItem';
import { Typography } from '@/components/Typography/Typography';
import { twMerge } from '@/core/utils/tailwindUtil';

export interface IRadioButtonGroupState {
  formState?: UseFormStateReturn<FieldValues>;
  fieldState?: ControllerFieldState;
}

interface IRadioButtonGroupChangeOptions {
  preventDefault?: boolean;
  onAfterChange?: boolean;
  onBeforeChange?: boolean;
}

export interface IRadioButtonGroupChangeEvent extends IRadioButtonGroupState {
  preventDefault: () => void;
  onBeforeChange: (callback: (value: any) => void) => void;
  onAfterChange: (callback: (value: any) => void) => void;
}

interface IFormRadioButtonGroup extends IRadioButtonGroupState {
  control: UseControllerProps<FieldValues, string>;
  options?: IFormRadioButtonGroupItem[];
  onChange?: (value?: any, event?: IRadioButtonGroupChangeEvent) => void;
  initialValue?: any;
  isResettable?: boolean;
  label?: string;
  customContainer?: React.FC<IFormRadioButtonGroup>;
  customItem?: React.FC<IFormRadioButtonGroupItem>;
  customHeader?: React.FC<IRadioButtonGroupState>;
  customErrorMessage?: React.FC<IRadioButtonGroupState>;
  containerClassName?: string;
  headerClassName?: string;
  errorClassName?: string;
  className?: string;
}

export const FormRadioButtonGroup: React.FC<IFormRadioButtonGroup> = React.memo(
  ({
    control,
    options,
    onChange,
    initialValue,
    isResettable,
    label,
    containerClassName,
    headerClassName,
    errorClassName,
    className,
    customHeader: CustomHeader,
    customContainer: CustomContainer,
    customItem: CustomItem,
    customErrorMessage: CustomErrorMessage,
  }) => {
    const {
      field: { value, onChange: onControllerChange },
      fieldState,
      formState,
    } = useController(control);

    useEffect(() => {
      if (!initialValue) {
        return;
      }

      handleChange(initialValue);
    }, [initialValue]);

    const handleChange = (newValue?: any) => {
      const defaultChangeOptions: IRadioButtonGroupChangeOptions = {
        preventDefault: false,
        onAfterChange: false,
        onBeforeChange: false,
      };

      let beforeChangeCallback: (value: any) => void = () => {};
      let afterChangeCallback: (value: any) => void = () => {};

      const event: IRadioButtonGroupChangeEvent = {
        preventDefault: () => {
          defaultChangeOptions.preventDefault = true;
        },
        onBeforeChange: (callback) => {
          defaultChangeOptions.onBeforeChange = true;
          beforeChangeCallback = callback;
        },
        onAfterChange: (callback) => {
          defaultChangeOptions.onAfterChange = true;
          afterChangeCallback = callback;
        },
        formState: formState,
        fieldState: fieldState,
      };

      const _value = isResettable && newValue === value ? undefined : newValue;
      onChange?.(_value, event);

      if (defaultChangeOptions.onBeforeChange) {
        if (beforeChangeCallback) beforeChangeCallback(_value);
      }

      if (!defaultChangeOptions.preventDefault) {
        onControllerChange(_value);
      }

      if (defaultChangeOptions.onAfterChange) {
        afterChangeCallback(_value);
      }
    };

    const renderHeader = (props: IRadioButtonGroupState) => {
      if (CustomHeader) {
        return <CustomHeader fieldState={props.fieldState} formState={props.formState} />;
      }

      return label ? (
        <Typography className={twMerge('', headerClassName)}>{label}</Typography>
      ) : null;
    };

    const renderItem = (
      item: IFormRadioButtonGroupItem,
      onChange: (value: any) => void,
      key: string | number
    ) => {
      if (CustomItem) {
        return (
          <CustomItem
            key={key}
            onChange={onChange}
            value={item.value}
            id={item.id}
            readOnly={item.readOnly}
            isSelected={item.value === value}
            formState={item.formState}
            fieldState={item.fieldState}
          />
        );
      }

      return (
        <FormRadioButtonGroupItem
          key={key}
          onChange={onChange}
          value={item.value}
          id={item.id ?? item.value}
          component={item.component}
          readOnly={item.readOnly}
          isSelected={item.value === value}
          formState={item.formState}
          fieldState={item.fieldState}
          className={item.className}
        />
      );
    };

    const renderContainer = (props: IFormRadioButtonGroup) => {
      if (CustomContainer) {
        return (
          <CustomContainer
            control={props.control}
            onChange={props.onChange}
            options={props.options}
            label={props.label}
            customItem={props.customItem}
          />
        );
      }

      return (
        <div className={twMerge('flex flex-col gap-1', containerClassName)}>
          {options?.map((option) => renderItem(option, handleChange, option.id || option.value))}
        </div>
      );
    };

    const renderErrorMessage = (props: IRadioButtonGroupState) => {
      if (CustomErrorMessage) {
        return <CustomErrorMessage fieldState={props.fieldState} formState={props.formState} />;
      }

      const errorMessage = fieldState.error?.message;

      return (
        errorMessage && (
          <Typography color={'Error'} className={errorClassName}>
            {errorMessage}
          </Typography>
        )
      );
    };

    return (
      <div className={twMerge('flex flex-col gap-3', className)}>
        {renderHeader({
          fieldState: fieldState,
          formState: formState,
        })}
        {renderContainer({
          label: label,
          options: options,
          onChange: onChange,
          control: control,
          customItem: CustomItem,
          customContainer: CustomContainer,
          formState: formState,
          fieldState: fieldState,
        })}
        {renderErrorMessage({
          formState: formState,
          fieldState: fieldState,
        })}
      </div>
    );
  }
);
