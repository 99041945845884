import React from 'react';
import { useTranslation } from 'react-i18next';

import { Typography } from '@/components/Typography/Typography';
import { twMerge } from '@/core/utils/tailwindUtil';
import { colorTypes } from '@/styles/types';

interface IWeekendButton {
  className?: string;
}

export const WeekendButton: React.FC<IWeekendButton> = ({ className }) => {
  const { t: mobilityTranslations } = useTranslation('mobility', { keyPrefix: 'placeholders' });

  return (
    <Typography
      className={twMerge(
        `rounded-default border-2 border-${colorTypes.Stroke} py-[14px] px-[20px]`,
        className
      )}
    >
      {mobilityTranslations('weekend.label')}
    </Typography>
  );
};
