import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@/components/Button/Button';
import { useActivityPlaningSelector } from '@/components/functions/ActivityPlanning/FullFunction/ActivityPlanningContext/ActivityPlanningContext';
import { CheckedIcon } from '@/components/Icons/CheckedIcon';
import { Skeleton } from '@/components/Skeleton/Skeleton';
import { ITableColumn, Table } from '@/components/Table/Table';
import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';
import { activityPlaningAppointmentParticipantsListSelectors } from '@/core/redux/slices/functions/activityPlanning/appointmentParticipantsList/selectors';
import { activityPlanningAppointmentParticipantsListActions } from '@/core/redux/slices/functions/activityPlanning/appointmentParticipantsList/slice';
import { activityPlaningParticipantsModalsActions } from '@/core/redux/slices/modalsSlice/functions/activityPlanning/activityPlanningParticipants/slice';
import { LoadingStatus } from '@/types/loadingStatus';

export const ParticipantsList: React.FC = () => {
  const { t: appointmentParticipantsListTranslations } = useTranslation('activityPlanning', {
    keyPrefix: 'appointmentParticipantsList',
  });

  const [selectedParticipant, setSelectedParticipant] = useState<string | null>(null);

  const [selectedAppointmentID] = useActivityPlaningSelector(
    (store) => store.selectedAppointmentID
  );
  const [selectedMeasureID] = useActivityPlaningSelector((store) => store.selectedMeasureID);
  const [selectedMeasureName] = useActivityPlaningSelector((store) => store.selectedMeasureName);

  const appointmentParticipants = useAppSelector(
    activityPlaningAppointmentParticipantsListSelectors.appointmentParticipantsList
  );
  const loadingStatus = useAppSelector(
    activityPlaningAppointmentParticipantsListSelectors.appointmentParticipantsListLock
  );

  const dispatch = useAppDispatch();

  const columns: ITableColumn[] = [
    {
      accessor: 'hasEfficacy',
      header: appointmentParticipantsListTranslations('list.tableHeader.w.label'),
      template: (value) => (value ? <CheckedIcon /> : null),
    },
    {
      accessor: 'name',
      header: appointmentParticipantsListTranslations('list.tableHeader.participant.label'),
    },
    {
      accessor: 'department',
      header: appointmentParticipantsListTranslations('list.tableHeader.department.label'),
    },
  ];

  const openAddParticipantsModal = () => {
    if (!selectedAppointmentID || !selectedMeasureID) {
      return;
    }

    dispatch(
      activityPlaningParticipantsModalsActions.openAddParticipantsModal({
        appointmentID: selectedAppointmentID,
        measureID: selectedMeasureID,
        measureName: selectedMeasureName,
        placesCount: appointmentParticipants?.placesNum,
      })
    );
  };

  const handleChange = (id: string) => {
    setSelectedParticipant(id);
  };

  const handleDeleteParticipant = () => {
    if (!selectedMeasureID || !selectedAppointmentID) {
      return;
    }

    dispatch(
      activityPlanningAppointmentParticipantsListActions.deleteParticipant({
        personID: Number(selectedParticipant),
        measureID: selectedMeasureID,
        appointmentID: selectedAppointmentID,
      })
    );

    setSelectedParticipant(null);
  };

  useEffect(() => {
    if (!selectedAppointmentID || !selectedMeasureID) {
      return;
    }

    dispatch(
      activityPlanningAppointmentParticipantsListActions.fetchAppointmentParticipantsList({
        appointmentID: selectedAppointmentID,
        measureID: selectedMeasureID,
      })
    );
  }, [selectedAppointmentID]);

  return (
    <div className={'border flex flex-col gap-2 p-2'}>
      <Skeleton trigger={loadingStatus === LoadingStatus.LOADING}>
        <Table
          rowIDAccessor={'rowID'}
          columns={columns}
          data={
            appointmentParticipants?.list && selectedAppointmentID
              ? appointmentParticipants?.list
              : []
          }
          singleSelect={true}
          onSingleSelectChange={handleChange}
        />
      </Skeleton>
      <div className={'flex flex-row gap-3 justify-between'}>
        {appointmentParticipants?.hasPlaces && (
          <Button
            onClick={openAddParticipantsModal}
            disabled={!selectedAppointmentID || !selectedMeasureID}
          >
            {appointmentParticipantsListTranslations(
              'footer.buttons.openAddParticipantsListModal.label'
            )}
          </Button>
        )}
        {selectedParticipant && (
          <Button
            onClick={handleDeleteParticipant}
            disabled={!selectedAppointmentID || !selectedMeasureID || !selectedParticipant}
          >
            {appointmentParticipantsListTranslations('footer.buttons.removeParticipant.label')}
          </Button>
        )}
      </div>
    </div>
  );
};
