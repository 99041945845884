import { useController, UseControllerProps } from 'react-hook-form';

import { useSchemaContext } from '@/components/SchemaContext/SchemaContext';
import { Typography } from '@/components/Typography/Typography';
import { twMerge } from '@/core/utils/tailwindUtil';
import { colorTypes } from '@/styles/types';

import { ISelect, Select } from '../Select';

export interface ISelectForm extends ISelect {
  control: UseControllerProps;
}

export const SelectForm: React.FC<ISelectForm> = (props) => {
  const { control, label, onChange: handleChange } = props;

  const {
    field: { value, name, onChange: controllerOnChange },
    fieldState,
  } = useController(control);
  const { isRequiredField } = useSchemaContext(name);

  const onChange = (id: string) => {
    controllerOnChange(id);
    handleChange?.(id);
  };

  const onReset = () => {
    controllerOnChange(undefined);
  };

  return (
    <>
      <Select
        {...props}
        onChange={onChange}
        onReset={onReset}
        initialValueID={value}
        label={label}
        isRequired={isRequiredField}
        selectedContainerClassName={twMerge(
          fieldState.error && `border-${colorTypes.Error} focus:border-${colorTypes.Error}`
        )}
      />
      {fieldState.error && (
        <Typography type={'14-R'} color={'Error'} className={'mt-1'}>
          {fieldState.error.message}
        </Typography>
      )}
    </>
  );
};
