import { PayloadAction } from '@reduxjs/toolkit';
import { format } from 'date-fns';
import type { NormalizeOAS, OASOutput } from 'fets';
import { OASRequestParams } from 'fets/typings';
import { call, put, takeLatest } from 'redux-saga/effects';

import { authAdd, restCall } from '@/core/clients/rest';
import { BACKEND_DATE_FORMAT } from '@/core/constants/dateFormat';
import { DropdownItemsByFetch } from '@/core/enums/common/DropdownItemsByFetchEnum';
import { dropdownItemsActions } from '@/core/redux/slices/dropdownItems/dropdownItems.slice';
import {
  activityPlanningAppointmentDetailsActions,
  IActivityPlanningAppointmentDetailsFetchPayload,
  IActivityPlanningAppointmentDetailsValuesFetchPayload,
  IAppointmentDetails,
  ICreateActivityPlanningAppointmentPayload,
  IUpdateActivityPlanningAppointmentPayload,
} from '@/core/redux/slices/functions/activityPlanning/appointmentDetails/slice';
import { activityPlanningAppointmentsMeasuresListActions } from '@/core/redux/slices/functions/activityPlanning/measuresAppointmentsList/slice';
import { activityPlaningAppointmentDetailsModalsActions } from '@/core/redux/slices/modalsSlice/functions/activityPlanning/activityPlaningAppointmentDetails/slice';
import { parseTime, toBackendDate, toClientDateInput } from '@/core/utils/dateTimeUtil';
import type oas from '@/services/rest/base/openapi';
import { LoadingStatus } from '@/types/loadingStatus';

type AppointmentDetailsResponse = OASOutput<
  NormalizeOAS<typeof oas>,
  '/activity_planning/measure/appointment/details',
  'get',
  '200'
>;

type AppointmentDetailsRequest = OASRequestParams<
  NormalizeOAS<typeof oas>,
  '/activity_planning/measure/appointment/details',
  'get'
>;

type MeasureAidsValuesResponse = OASOutput<
  NormalizeOAS<typeof oas>,
  '/vocational_training_area/measure/document',
  'get',
  '200'
>;

type MeasureAidsValuesRequest = OASRequestParams<
  NormalizeOAS<typeof oas>,
  '/vocational_training_area/measure/document',
  'get'
>;

type CreateAppointmentRequest = OASRequestParams<
  NormalizeOAS<typeof oas>,
  '/activity_planning/measure/appointment',
  'post'
>;

type UpdateAppointmentRequest = OASRequestParams<
  NormalizeOAS<typeof oas>,
  '/activity_planning/measure/appointment',
  'put'
>;

const mapImplementationIDs = (implementationAids?: Record<string, boolean> | null): number[] => {
  if (!implementationAids) {
    return [];
  }

  const implementationAidsKeys = Object.keys(implementationAids);

  return implementationAidsKeys.reduce<number[]>((accum, aidKey) => {
    if (implementationAids[aidKey]) {
      accum.push(Number(aidKey));
    }

    return accum;
  }, []);
};

function* fetchAppointmentDetails(
  action: PayloadAction<IActivityPlanningAppointmentDetailsFetchPayload>
): Generator<any, void, any> {
  const { personID, appointmentID, measureID } = action.payload;

  try {
    yield put(
      activityPlanningAppointmentDetailsActions.setAppointmentDetailsLock(LoadingStatus.LOADING)
    );

    const request: AppointmentDetailsRequest = {
      query: {
        person_id: personID,
        appointment_id: appointmentID,
      },
      ...authAdd(),
    };

    const response: AppointmentDetailsResponse = yield call(
      restCall,
      '/activity_planning/measure/appointment/details',
      'get',
      request
    );

    const { appointment } = response;

    const appointmentDetails: IAppointmentDetails = {
      id: appointment.id,
      appointmentType: appointment.appointment_type,
      responsibleUser: appointment.responsible_user,
      appointmentEndTime: parseTime(appointment.appointment_end_time),
      appointmentStartTime: parseTime(appointment.appointment_start_time),
      appointmentInfo: appointment.appointment_info,
      hours: appointment.hours?.toFixed(2),
      location: appointment.location,
      measureTitle: appointment.measure_title,
      places: appointment.places,
      appointmentStartDate: toClientDateInput(appointment.appointment_start_date),
      appointmentEndDate: toClientDateInput(appointment.appointment_end_date),
      implementationAids: appointment.implementation_aids,
    };

    yield put(activityPlanningAppointmentDetailsActions.setAppointmentDetails(appointmentDetails));

    yield put(
      activityPlanningAppointmentDetailsActions.fetchAppointmentDetailsFields({
        measureID: measureID,
      })
    );

    yield put(
      activityPlanningAppointmentDetailsActions.setAppointmentDetailsLock(LoadingStatus.LOADED)
    );
  } catch (error) {
    console.log('Error on activity planing measures list fetch', error);
    yield put(
      activityPlanningAppointmentDetailsActions.setAppointmentDetailsLock(LoadingStatus.ERROR)
    );
  }
}

function* fetchAppointmentDetailsFields(
  action: PayloadAction<IActivityPlanningAppointmentDetailsValuesFetchPayload>
): Generator<any, void, any> {
  const { measureID } = action.payload;

  try {
    yield put(
      dropdownItemsActions.fetchDropdownItems({
        dropdownTable: DropdownItemsByFetch.MEASURE_LOCATION,
      })
    );

    const measureAidsValuesRequest: MeasureAidsValuesRequest = {
      query: {
        measure_id: measureID,
      },
      ...authAdd(),
    };

    const measureAidsResponse: MeasureAidsValuesResponse = yield call(
      restCall,
      '/vocational_training_area/measure/document',
      'get',
      measureAidsValuesRequest
    );

    yield put(
      activityPlanningAppointmentDetailsActions.setMeasureAidsValues(measureAidsResponse.documents)
    );

    yield put(
      dropdownItemsActions.fetchDropdownItems({
        dropdownTable: DropdownItemsByFetch.APPOINTMENT_TYPE,
      })
    );
  } catch (error) {
    console.log('Error on appointment details values fetching', error);
  }
}

function* createAppointment(
  action: PayloadAction<ICreateActivityPlanningAppointmentPayload>
): Generator<any, void, any> {
  const { formValues, measureID, personID } = action.payload;

  if (!formValues) {
    return;
  }

  const today = format(new Date(), BACKEND_DATE_FORMAT);

  try {
    const request: CreateAppointmentRequest = {
      json: {
        measure_title: formValues.measureTitle ?? '',
        appointment_info: formValues.appointmentInfo ?? '',
        appointment_start_time: formValues.startTime,
        appointment_end_time: formValues.endTime,
        appointment_end_date: toBackendDate(formValues.endDate) ?? today,
        appointment_start_date: toBackendDate(formValues.startDate) ?? today,
        location: formValues.locationName ?? '',
        hours: formValues.duration,
        appointment_type_id: formValues.appointmentTypeID ?? -1,
        measure_id: measureID,
        places: formValues.places ?? 5,
        person_id: personID,
        implementation_aids_ids: mapImplementationIDs(formValues.implementationAids),
      },
      ...authAdd(),
    };

    yield call(restCall, '/activity_planning/measure/appointment', 'post', request);

    yield put(
      activityPlanningAppointmentsMeasuresListActions.fetchMeasuresAppointmentsList({
        measureID: measureID,
        personID: personID,
      })
    );

    yield put(activityPlaningAppointmentDetailsModalsActions.closeAppointmentDetailsModal());
  } catch (error) {
    console.log('Error on activity planning appointment creating', error);
  }
}

function* updateAppointment(
  action: PayloadAction<IUpdateActivityPlanningAppointmentPayload>
): Generator<any, void, any> {
  const { formValues, measureID, personID, appointmentID } = action.payload;

  if (!formValues) {
    return;
  }

  const today = format(new Date(), BACKEND_DATE_FORMAT);

  try {
    const request: UpdateAppointmentRequest = {
      json: {
        id: appointmentID,
        measure_title: formValues.measureTitle ?? '',
        appointment_info: formValues.appointmentInfo ?? '',
        appointment_start_time: formValues.startTime ?? null,
        appointment_end_time: formValues.endTime ?? null,
        appointment_end_date: toBackendDate(formValues.endDate) ?? today,
        appointment_start_date: toBackendDate(formValues.startDate) ?? today,
        location: formValues.locationName ?? '',
        hours: formValues.duration,
        appointment_type_id: formValues.appointmentTypeID ?? -1,
        places: formValues.places,
        implementation_aids_ids: mapImplementationIDs(formValues.implementationAids),
      },
      ...authAdd(),
    };

    yield call(restCall, '/activity_planning/measure/appointment', 'put', request);

    yield put(
      activityPlanningAppointmentsMeasuresListActions.fetchMeasuresAppointmentsList({
        measureID: measureID,
        personID: personID,
      })
    );

    yield put(activityPlaningAppointmentDetailsModalsActions.closeAppointmentDetailsModal());
  } catch (error) {
    console.log('Error on activity planning appointment creating', error);
  }
}

export const activityPlaningAppointmentDetailsSagas = [
  takeLatest(
    activityPlanningAppointmentDetailsActions.fetchAppointmentDetails,
    fetchAppointmentDetails
  ),
  takeLatest(activityPlanningAppointmentDetailsActions.createAppointment, createAppointment),
  takeLatest(activityPlanningAppointmentDetailsActions.updateAppointment, updateAppointment),
  takeLatest(
    activityPlanningAppointmentDetailsActions.fetchAppointmentDetailsFields,
    fetchAppointmentDetailsFields
  ),
];
