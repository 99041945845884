import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Button } from '@/components/Button/Button';
import {
  IPersonState,
  usePersonPageStoreSelector,
} from '@/components/views/person/PersonPage/Context/PersonPageStoreContext';
import { FUNCTIONS_URL } from '@/core/constants/urls';
import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';
import { dashboardActions } from '@/core/redux/slices/dashboard/dashboardSlice';
import { dashboardSelectors } from '@/core/redux/slices/dashboard/selectors';
import { userSettingsSelectors } from '@/core/redux/slices/userSettings/selectors';
import { IPerson } from '@/types/person';

export const FavoritePersonWidgetHeader: React.FC = () => {
  const { t: favoritePersonsWidgetTranslations } = useTranslation('favoritePersonWidget');

  const personsGroup = useAppSelector(dashboardSelectors.favoritePersonsGroups);
  const favoritePersons = useAppSelector(userSettingsSelectors.userFavoritePersons);

  const [personsChecksState, setPersonsChecksState] = usePersonPageStoreSelector(
    (store) => store.personsChecksState?.persons
  );

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleToFunctions = () => {
    const navigatedPersons = personsGroup.reduce<Record<string, IPerson>>((accum, personGroup) => {
      personGroup.persons.forEach((person) => {
        const id = person.id;

        if (!favoritePersons.includes(id)) {
          return accum;
        }

        accum[id] = {
          id: person.id,
          name: person.name ?? '',
          surname: person.surname ?? '',
          last_modified: person.lastModified ?? '',
          functions: person.functions,
        };
      });
      return accum;
    }, {});

    dispatch(dashboardActions.setNavigatedActivityPersons(navigatedPersons));

    const favoritePersonsCheckState: IPersonState = Object.keys(navigatedPersons).reduce(
      (accum, key) => ({
        ...accum,
        [`person_id=${key}`]: true,
      }),
      {}
    );

    setPersonsChecksState({
      personsChecksState: {
        persons_check_all: false,
        persons: {
          ...personsChecksState,
          ...favoritePersonsCheckState,
        },
      },
    });

    navigate(FUNCTIONS_URL, { replace: true });
  };

  return (
    favoritePersons &&
    favoritePersons.length > 0 && (
      <div className={'flex justify-end items-center px-4'}>
        <Button onClick={handleToFunctions} className={'h-[40px]'}>
          {favoritePersonsWidgetTranslations('buttons.toFunctions.label')}
        </Button>
      </div>
    )
  );
};
