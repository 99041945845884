import {
  addMonths,
  addWeeks,
  endOfDay,
  endOfMonth,
  endOfQuarter,
  endOfWeek,
  format,
  Locale,
  parse,
  parseISO,
  startOfDay,
  startOfMonth,
  startOfQuarter,
  startOfWeek,
} from 'date-fns';
import * as Locales from 'date-fns/locale';
import moment, { Moment, MomentInput } from 'moment';
import bigM from 'moment/moment';

import {
  BACKEND_DATE_FORMAT,
  FRONTEND_DATE_FORMAT,
  FRONTEND_DATE_TIME_FORMAT,
  FRONTEND_TIME_FORMAT,
} from '@/core/constants/dateFormat';
import { DateRangeType } from '@/core/enums/dateRangeTypeEnum';
import { IDateRange } from '@/core/redux/slices/dashboard/dashboardSlice';

import { BACKEND_DATE_TIME_FORMAT } from '../constants/dateTimeFormat';

export const momentDates = (src?: string | null | Moment | bigM.Moment | number) => {
  const lang = 'de';
  const lm = moment.utc(src);

  lm.locale(lang);
  lm.local();

  return {
    defaultDate: lm.format('D.MM.YYYY'),
    hoursMinutes: lm.format('HH:mm'),
    duration: lm.format('HH:mm'),
    weekdays: moment.weekdaysShort(true),
    roundedTime: moment(lm).add(5 - (lm.minute() % 5), 'm'),
    durationIn: (date2: MomentInput) => moment(lm.diff(moment.utc(date2))).utc(),
    durationObject: moment.duration(src as any).locale(lang),
    self: lm,
  };
};

export function createDateRangeObject(rangeType: DateRangeType, range?: IDateRange): IDateRange {
  let fromDate: Date;
  let toDate: Date;

  switch (rangeType) {
    case DateRangeType.THIS_WEEK:
      fromDate = startOfWeek(new Date());
      toDate = endOfWeek(new Date());
      break;
    case DateRangeType.THIS_MONTH:
      fromDate = startOfMonth(new Date());
      toDate = endOfMonth(new Date());
      break;
    case DateRangeType.THIS_QUARTER:
      fromDate = startOfQuarter(new Date());
      toDate = endOfQuarter(new Date());
      break;
    case DateRangeType.NEXT_WEEK:
      fromDate = startOfWeek(addWeeks(new Date(), 1));
      toDate = endOfWeek(addWeeks(new Date(), 1));
      break;
    case DateRangeType.NEXT_MONTH:
      fromDate = startOfMonth(addMonths(new Date(), 1));
      toDate = endOfMonth(addMonths(new Date(), 1));
      break;
    case DateRangeType.NEXT_QUARTER:
      fromDate = startOfQuarter(addMonths(new Date(), 3));
      toDate = endOfQuarter(addMonths(new Date(), 3));
      break;
    case DateRangeType.CHOSEN_DAY:
      if (!range) {
        fromDate = new Date();
        toDate = new Date();
        break;
      }

      const parsedDate = parse(range?.fromDate, FRONTEND_DATE_FORMAT, new Date());
      fromDate = startOfDay(parsedDate);
      toDate = endOfDay(parsedDate);
      break;
    case DateRangeType.CHOSEN_PERIOD:
      if (!range) {
        fromDate = new Date();
        toDate = new Date();
        break;
      }

      const parsedFromDate = parse(range?.fromDate, FRONTEND_DATE_FORMAT, new Date());
      const parsedToDate = parse(range?.toDate, FRONTEND_DATE_FORMAT, new Date());

      fromDate = startOfDay(parsedFromDate);
      toDate = endOfDay(parsedToDate);
      break;
    default:
      fromDate = new Date();
      toDate = new Date();
      break;
  }

  return {
    fromDate: fromDate.toISOString(),
    toDate: toDate.toISOString(),
  };
}

// Analogue of eachDayOfInterval from date-fns
export const getDaysArray = function (start: Date, end: Date): Date[] {
  const startDate = new Date(start);
  const endDate = new Date(end);
  const daysArray: Date[] = [];

  for (let date = startDate; date <= endDate; date.setDate(date.getDate() + 1)) {
    daysArray.push(new Date(date));
  }

  return daysArray;
};

export const isValidDate = (d: Date) => {
  return d instanceof Date && !isNaN(d.getTime());
};

export const getDateFnsLocale = ({ locale }: { locale: string }): Locale =>
  //@ts-expect-error: typescript handling locale issue
  Locales[locale.substring(0, 2)] ?? Locales.enUS;

export function toClientDateInput(dateString?: string | null): string | null {
  if (!dateString) {
    return null;
  }

  const date = new Date(dateString);
  return format(date, FRONTEND_DATE_FORMAT);
}

export function currentClientDate() {
  const date = new Date();

  return format(date, FRONTEND_DATE_FORMAT);
}

export function currentClientDateTime() {
  const date = new Date();

  return format(date, FRONTEND_DATE_TIME_FORMAT);
}

export function toClientDateTimeInput(dateString?: string | null): string | null {
  if (!dateString) {
    return null;
  }

  const date = new Date(dateString);
  return format(date, FRONTEND_DATE_TIME_FORMAT);
}

export function toBackendDate(
  dateString?: string | null,
  toISODateString: boolean = false
): string | null {
  if (!dateString) {
    return null;
  }

  const date = parse(dateString, FRONTEND_DATE_FORMAT, new Date());

  return toISODateString ? date.toISOString() : format(date, BACKEND_DATE_FORMAT);
}

export function toBackendDateTime(dateString: string): string {
  const date = parse(dateString, FRONTEND_DATE_FORMAT, new Date());
  return format(date, BACKEND_DATE_TIME_FORMAT);
}

export function parseDateAndTime(
  dateString?: string | null
): { date: string; time: string } | null {
  if (!dateString) {
    return null;
  }

  const parsedISO = parseISO(dateString);
  const date = format(parsedISO, 'dd-MM-yyyy');
  const time = format(parsedISO, 'HH:mm');

  return { date, time };
}

export function parseTime(dateString?: string | null): string | null {
  if (!dateString) {
    return null;
  }

  const parsedTime = parse(dateString, 'HH:mm:ss', new Date());
  return format(parsedTime, FRONTEND_TIME_FORMAT);
}

export function validateTimeFormat(timeString?: string | null): boolean {
  if (!timeString) {
    return false;
  }

  const timeRegex = /^([01]\d|2[0-3]):([0-5]\d)$/;
  return timeRegex.test(timeString);
}

export function toBackendTime(timeString?: string | null): string | null {
  if (!timeString) {
    return null;
  }
  const now = new Date();
  const parsedTime = parse(timeString, 'HH:mm', now);

  const timezoneOffset = now.getTimezoneOffset();
  const timezoneOffsetInMilliseconds = timezoneOffset * 60 * 1000;

  const localTime = new Date(parsedTime.getTime() - timezoneOffsetInMilliseconds);

  return format(localTime, "HH:mm:ss.SSS'Z'");
}
