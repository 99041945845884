import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { CheckboxUI } from '@/components/Checkbox/Checkbox';
import { Typography } from '@/components/Typography/Typography';
import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';
import { medicationAdministrationActions } from '@/core/redux/slices/medicationAdministration/medicationAdministrationSlice';
import { medicationAdministrationSelectors } from '@/core/redux/slices/medicationAdministration/selectors';

import { MedicationAdministratedList } from './MedicationAdministratedList/MedicationAdministratedList';
import { MedicationHeader } from './MedicationHeader/MedicationHeader';
import { MedicationUnadministratedList } from './MedicationUnadministratedList/MedicationUnadministratedList';

export const MedicationAdministration: React.FC = () => {
  const dispatch = useAppDispatch();
  const isOnlyOnDemand = useAppSelector(medicationAdministrationSelectors.isOnlyOnDemand);

  const [isAdministrated, setIsAdministrated] = useState<boolean>(false);
  const { t: medicationAdministrationTranslations } = useTranslation('medicationAdministration');

  return (
    <div className={'h-full'}>
      <div className='flex gap-4'>
        <label className='w-fit flex gap-4 cursor-pointer'>
          <CheckboxUI
            className='pointer-events-none'
            checked={isOnlyOnDemand}
            onChange={(value: boolean) => {
              dispatch(medicationAdministrationActions.setIsOnlyOnDemand(value));
            }}
          />
          <Typography>
            {medicationAdministrationTranslations('filter.onlyOnDemand.label')}
          </Typography>
        </label>

        <label className='w-fit flex gap-4 cursor-pointer'>
          <CheckboxUI
            className='pointer-events-none'
            checked={isAdministrated}
            onChange={(value) => setIsAdministrated(value)}
          />
          <Typography>{medicationAdministrationTranslations('isAdministrated.label')}</Typography>
        </label>
      </div>

      <div className='py-5'>
        <MedicationHeader isAdministered={isAdministrated} />
      </div>
      <div className={'h-full overflow-auto'}>
        {isAdministrated ? <MedicationAdministratedList /> : <MedicationUnadministratedList />}
      </div>
    </div>
  );
};
