import { isAfter, isEqual, parse } from 'date-fns';
import { TFunction } from 'i18next';
import { ObjectSchema } from 'yup';

import { IAppointmentDetailsForm } from '@/components/Modals/Modals/functions/activityPlaningModals/appointmentDetailsModals/AppointmentDetailsModal/AppointmentDetails/hooks/useAppointmentDetailsForm';
import { yup } from '@/core/utils/commonUtils';

export const appointmentDetailsSchemaBuilder = (
  translations: TFunction<'activityPlanning', 'appointmentDetails'>
): ObjectSchema<IAppointmentDetailsForm> => {
  return yup
    .object({
      appointmentOnlyPlanned: yup.boolean().nullable(),
      startDate: yup.string().required(translations('fields.startDate.errors.required.label')),
      endDate: yup.string().required(translations('fields.endDate.errors.required.label')),
      startTime: yup
        .string()
        .nullable()
        .matches(
          /^([01]\d|2[0-3]):([0-5]\d)$/,
          translations('fields.startTime.errors.invalidFormat.label')
        ),
      endTime: yup
        .string()
        .nullable()
        .matches(
          /^([01]\d|2[0-3]):([0-5]\d)$/,
          translations('fields.endTime.errors.invalidFormat.label')
        ),
      measureTitle: yup.string().required(),
      locationID: yup.number().nullable(),
      locationName: yup.string().nullable(),
      appointmentTypeID: yup
        .number()
        .required(translations('fields.appointmentType.errors.required.label')),
      implementationAids: yup.object().nullable(),
      appointmentInfo: yup.string().nullable(),
      places: yup.number().nullable(),
      responsible: yup.string().nullable(),
      duration: yup.number().nullable(),
    })
    .test(
      'required',
      'startTime and endTime are required when startDate and endDate are different',
      function (value) {
        return !(value.startDate === value.endDate && (!value.startTime || !value.endTime));
      }
    )
    .test(
      'required',
      'startTime is required when startDate and endDate are different',
      function (value) {
        if (value.startDate === value.endDate && !value.startTime) {
          return this.createError({
            path: 'startTime',
            message: translations('fields.startTime.errors.required.label'),
          });
        }
        return true;
      }
    )
    .test(
      'required',
      'endTime is required when startDate and endDate are different',
      function (value) {
        if (value.startDate === value.endDate && !value.endTime) {
          return this.createError({
            path: 'endTime',
            message: translations('fields.endTime.errors.required.label'),
          });
        }
        return true;
      }
    )
    .test(
      'startTime-before-endTime',
      translations('fields.startTime.errors.afterEndTime.label'),
      function (value) {
        if (value.startTime && value.endTime) {
          const startTime = parse(value.startTime, 'HH:mm', new Date());
          const endTime = parse(value.endTime, 'HH:mm', new Date());
          if (isAfter(startTime, endTime) || isEqual(startTime, endTime)) {
            return this.createError({
              path: 'startTime',
              message: translations('fields.startTime.errors.afterEndTime.label'),
            });
          }
        }
        return true;
      }
    )
    .test(
      'required',
      'duration is required when startDate and endDate are different',
      function (value) {
        if (value.startDate !== value.endDate && !value.duration) {
          return this.createError({
            path: 'duration',
            message: translations('fields.hours.errors.required.label'),
          });
        }
        return true;
      }
    );
};
