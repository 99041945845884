import React from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import clsx from 'clsx';

import { Button } from '@/components/Button/Button';
import { TMSIcon } from '@/components/Icons/TMSIcon';
import { LeftSidebar } from '@/components/LeftSidebar/LeftSidebar';
import { ModalWrapper } from '@/components/Modals/ModalWrapper/ModalWrapper';
import {
  FetchContext,
  IPersonPageStoreContext,
  PersonPageStoreProvider,
} from '@/components/views/person/PersonPage/Context/PersonPageStoreContext';
import { UserCard } from '@/components/wrapper/MainDashWrapper/UserCard/UserCard';
import { DASHBOARD_URL } from '@/core/constants/urls';
import { SortingBy } from '@/types/sortingBy';

import styles from './styles.module.scss';

export const MainDashWrapper: React.FC = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(DASHBOARD_URL);
  };

  const initialState: IPersonPageStoreContext = {
    allDepartmentsListExpanded: false,
    allDepartmentsSelected: false,
    setAllDepartments: undefined,
    departmentsSortedBy: SortingBy.nameAscend,
    departmentSearchValue: '',
    personsSortedBy: SortingBy.nameAscend,
    allPersonsSelected: false,
    personsSearchValue: '',
    selectedLocations: [],
    fetchContext: FetchContext.default,
  };

  return (
    <div className='flex flex-col h-full w-screen'>
      <div className={clsx(styles.container, 'flex justify-between')}>
        <Button buttonVariant={'Icon'} buttonStyle='Icon' onClick={handleClick}>
          <TMSIcon />
        </Button>
        <div>
          <UserCard />
        </div>
      </div>
      <div className={clsx(styles.wrapper, 'flex flex-1')}>
        <LeftSidebar />
        <div className={clsx(styles.content, 'flex-1 h-full flex')}>
          <PersonPageStoreProvider state={initialState}>
            <Outlet />
            <ModalWrapper />
          </PersonPageStoreProvider>
        </div>
      </div>
    </div>
  );
};
