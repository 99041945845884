import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';

import { Button } from '@/components/Button/Button';
import { createOrganizationalUnitValidatorBuilder } from '@/components/Modals/Modals/functions/organizationalUnitsModals/CreateOrganizationalUnitModal/CreateOrganizationalUnitModalContent/helper/createOrganizationalUnitValidator';
import { useOrganizationalUnitErrors } from '@/components/Modals/Modals/functions/organizationalUnitsModals/hooks/useOrganizationalUnitErrors';
import { useBBBUpdate } from '@/components/Modals/Modals/functions/organizationalUnitsModals/OrganizationalUnitForms/BBB/helper/useBBBUpdate';
import {
  IOrganizationalUnitBBBForm,
  OrganizationalUnitBBB,
} from '@/components/Modals/Modals/functions/organizationalUnitsModals/OrganizationalUnitForms/BBB/OrganizationalUnitBBB';
import { useBBBInterruptionUpdate } from '@/components/Modals/Modals/functions/organizationalUnitsModals/OrganizationalUnitForms/BBBInterruption/helper/useBBBInterruptionUpdate';
import {
  IOrganizationalUnitBBBInterruptionForm,
  OrganizationalUnitBBBInterruption,
} from '@/components/Modals/Modals/functions/organizationalUnitsModals/OrganizationalUnitForms/BBBInterruption/OrganizationalUnitBBBInterruption';
import { OrganizationUnitDefault } from '@/components/Modals/Modals/functions/organizationalUnitsModals/OrganizationalUnitForms/Default/OrganizationUnitDefault';
import { Sidebar } from '@/components/Overlays/Sidebar/Sidebar';
import { SchemaProvider } from '@/components/SchemaContext/SchemaContext';
import { Select } from '@/components/Select/Select';
import { ISelectItem } from '@/components/Select/SelectItem/SelectItem';
import { Skeleton } from '@/components/Skeleton/Skeleton';
import { Typography } from '@/components/Typography/Typography';
import { OrganizationalUnitsTypes } from '@/core/enums/functions/organizationalUnits/organizationalUnitsTypesEnum';
import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';
import { organizationalUnitsModalsSelectors } from '@/core/redux/slices/modalsSlice/functions/organizationalUnits/selectors';
import {
  IAvailableOrganizationUnit,
  organizationalUnitsModalsActions,
} from '@/core/redux/slices/modalsSlice/functions/organizationalUnits/slice';
import { LoadingStatus } from '@/types/loadingStatus';

interface ICreateOrganizationalUnitModalContent {
  personID: number;
  handleExit: () => void;
}

export interface ICreateOrganizationalUnitForm {
  bbb?: IOrganizationalUnitBBBForm;
  bbbInterruption?: IOrganizationalUnitBBBInterruptionForm;
}

export const CreateOrganizationalUnitModalContent: React.FC<ICreateOrganizationalUnitModalContent> =
  ({ personID, handleExit }) => {
    const { t: createOrganizationalUnitModalTranslations } = useTranslation(
      'createOrganizationalUnitModal'
    );
    const { t: organizationalUnitFormsTranslations } = useTranslation('organizationalUnitsForms');

    const [selectedOrganizationalUnit, setSelectedOrganizationalUnit] =
      useState<IAvailableOrganizationUnit | null>(null);

    const { availableOrganizationUnits, availableOrganizationUnitsLock } = useAppSelector(
      organizationalUnitsModalsSelectors.createOrganizationalUnitModal
    );

    const dispatch = useAppDispatch();

    const form = useForm<ICreateOrganizationalUnitForm>({
      resolver: yupResolver(
        createOrganizationalUnitValidatorBuilder(organizationalUnitFormsTranslations)
      ),
    });

    const { handleUpdateBBB } = useBBBUpdate(form, personID, selectedOrganizationalUnit?.type);

    const { handleUpdateBBBInterruption } = useBBBInterruptionUpdate(
      form,
      personID,
      selectedOrganizationalUnit?.type
    );

    const { renderDialogContainer } = useOrganizationalUnitErrors();

    useEffect(() => {
      dispatch(
        organizationalUnitsModalsActions.fetchAvailableOrganizationalUnit({
          personID: personID,
        })
      );
    }, [personID]);

    useEffect(() => {
      if (!availableOrganizationUnits) {
        return;
      }

      setSelectedOrganizationalUnit(availableOrganizationUnits[0]);
    }, [availableOrganizationUnits]);

    const selectValues = availableOrganizationUnits?.map<ISelectItem>((organizationUnit) => ({
      id: organizationUnit.unitID.toString(),
      component: organizationUnit.name,
    }));

    const organizationalUnitForms: Record<OrganizationalUnitsTypes, React.ReactNode> = {
      [OrganizationalUnitsTypes.BBB]: <OrganizationalUnitBBB />,
      [OrganizationalUnitsTypes.Default]: <OrganizationUnitDefault />,
      [OrganizationalUnitsTypes.BBBInterruption]: <OrganizationalUnitBBBInterruption />,
    };

    const handleSelect = (id: string) => {
      const foundElement = availableOrganizationUnits?.find(
        (organizationalUnit) => organizationalUnit.unitID === Number(id)
      );

      if (!foundElement) {
        return;
      }

      setSelectedOrganizationalUnit(foundElement);
    };

    const handleCreate = () => {
      switch (selectedOrganizationalUnit?.type) {
        case OrganizationalUnitsTypes.BBB: {
          handleUpdateBBB();
          break;
        }
        case OrganizationalUnitsTypes.BBBInterruption: {
          handleUpdateBBBInterruption();
          break;
        }
        case OrganizationalUnitsTypes.Default: {
          break;
        }
      }
    };

    const footer = (
      <div className={'flex flex-row gap-3'}>
        <Button onClick={handleCreate}>
          {createOrganizationalUnitModalTranslations('buttons.save.label')}
        </Button>
        <Button buttonVariant={'Secondary'} onClick={handleExit}>
          {createOrganizationalUnitModalTranslations('buttons.cancel.label')}
        </Button>
      </div>
    );

    return (
      <Sidebar position={'right'} onExit={handleExit} className={'min-w-[40vw]'} footer={footer}>
        <div className={'p-4'}>
          <Typography type={'H3'}>
            {createOrganizationalUnitModalTranslations('title.label')}
          </Typography>
          <SchemaProvider
            schema={createOrganizationalUnitValidatorBuilder(organizationalUnitFormsTranslations)}
          >
            <FormProvider {...form}>
              <div className={'flex flex-col gap-3 mt-3'}>
                <Typography>
                  {createOrganizationalUnitModalTranslations('fields.organizationalUnit.label')}
                </Typography>
                <Select
                  values={selectValues}
                  isFlexible={true}
                  onChange={handleSelect}
                  initialValueID={availableOrganizationUnits?.[0].unitID.toString()}
                />
                <Skeleton trigger={availableOrganizationUnitsLock === LoadingStatus.LOADING}>
                  {selectedOrganizationalUnit &&
                    organizationalUnitForms[selectedOrganizationalUnit.type]}
                </Skeleton>
              </div>
            </FormProvider>
          </SchemaProvider>
          {renderDialogContainer()}
        </div>
      </Sidebar>
    );
  };
