import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IDevelopmentReportGoalsAndActivityForm } from '@/components/functions/DevelopmentReport/DevelopmentReportForms/GoalsAndActivity/helper/goalsAndActivityForm';

export interface IDevelopmentReportGoalsAndActivityFetchPayload {
  developmentReportID: number;
}

export interface IDevelopmentReportGoalsAndActivityUpdatePayload {
  developmentReportID: number;
  personID: number;
  formValues: IDevelopmentReportGoalsAndActivityForm;
}

export interface IDevelopmentReportGoalsAndActivityState {
  developmentReportGoalsAndActivity: IDevelopmentReportGoalsAndActivityForm | null;
  fetchPayload: IDevelopmentReportGoalsAndActivityFetchPayload | null;
  updatePayload: IDevelopmentReportGoalsAndActivityUpdatePayload | null;
}

const initialState: IDevelopmentReportGoalsAndActivityState = {
  developmentReportGoalsAndActivity: null,
  fetchPayload: null,
  updatePayload: null,
};

const developmentReportGoalsAndActivitySlice = createSlice({
  name: 'developmentReportGoalsAndActivity',
  initialState,
  reducers: {
    fetchDevelopmentReportGoalsAndActivity: (
      state,
      action: PayloadAction<IDevelopmentReportGoalsAndActivityFetchPayload>
    ) => ({
      ...state,
      fetchPayload: action.payload,
    }),
    setDevelopmentReportGoalsAndActivity: (
      state,
      action: PayloadAction<IDevelopmentReportGoalsAndActivityForm | null>
    ) => ({
      ...state,
      developmentReportGoalsAndActivity: action.payload,
    }),
    updateDevelopmentReportGoalsAndActivity: (
      state,
      action: PayloadAction<IDevelopmentReportGoalsAndActivityUpdatePayload>
    ) => ({
      ...state,
      updatePayload: action.payload,
    }),
  },
});

export const developmentReportGoalsAndActivityReducer =
  developmentReportGoalsAndActivitySlice.reducer;
export const developmentReportGoalsAndActivityActions =
  developmentReportGoalsAndActivitySlice.actions;
