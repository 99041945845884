import React from 'react';

import { Typography } from '@/components/Typography/Typography';
import { colorTypes } from '@/styles/types';

interface IFundingCycleSectionHeader {
  title?: string;
  controls?: React.ReactNode;
}

export const FundingCycleSectionHeader: React.FC<IFundingCycleSectionHeader> = ({
  title,
  controls,
}) => {
  return (
    <div
      className={`flex flex-row justify-between gap-3 items-center min-h-[56px] px-[20px] border-y border-${colorTypes.Fields} bg-${colorTypes.HoverLight}`}
    >
      {title ? <Typography type={'H3'}>{title}</Typography> : <div />}
      {controls && controls}
    </div>
  );
};
