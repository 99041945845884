import { PayloadAction } from '@reduxjs/toolkit';
import { NormalizeOAS, OASOutput, OASRequestParams } from 'fets';
import { call, put, takeLatest } from 'redux-saga/effects';

import { authAdd, restCall } from '@/core/clients/rest';
import { toCamelCase } from '@/core/utils/commonUtils';
import { toBackendDate, toClientDateInput } from '@/core/utils/dateTimeUtil';
import type oas from '@/services/rest/base/openapi';
import { Camelize } from '@/types/camelize';
import { LoadingStatus } from '@/types/loadingStatus';

import {
  efficacyAssessmentActions,
  IAppointmentsFetchPayload,
  IEfficacyAssessment,
  IEfficacyAssessmentCreatePayload,
  IEfficacyAssessmentFetchPayload,
  IMeasureAppointmentsFetchPayload,
} from './efficacyAssessmentSlice';

type AppointmentsResponse = OASOutput<
  NormalizeOAS<typeof oas>,
  '/efficacy_assessment/appointment',
  'get',
  '200'
>;

type MeasureAppointmentsResponse = OASOutput<
  NormalizeOAS<typeof oas>,
  '/efficacy_assessment/measure/appointment',
  'get',
  '200'
>;

type EfficacyAssessmentCreateRequest = OASRequestParams<
  NormalizeOAS<typeof oas>,
  '/efficacy_assessment',
  'post'
>;

function* fetchEfficacyAssessment(
  action: PayloadAction<IEfficacyAssessmentFetchPayload>
): Generator<any, void, any> {
  yield put(efficacyAssessmentActions.setEfficacyAssessmentaLock(LoadingStatus.LOADING));

  const { personID, measureID } = action.payload;

  try {
    const response = yield call(restCall, '/efficacy_assessment', 'get', {
      query: { person_id: personID, measure_id: measureID },
      ...authAdd(),
    });

    const efficacyAssessment: IEfficacyAssessment = toCamelCase(response.group);

    yield put(efficacyAssessmentActions.setEfficacyAssessment(efficacyAssessment));

    yield put(efficacyAssessmentActions.setEfficacyAssessmentaLock(LoadingStatus.LOADED));
  } catch (error) {
    console.log('Error on efficacy assessment fetching');
    yield put(efficacyAssessmentActions.setEfficacyAssessmentaLock(LoadingStatus.ERROR));
  }
}

function* fetchAppointments(
  action: PayloadAction<IAppointmentsFetchPayload>
): Generator<any, void, AppointmentsResponse> {
  const { personID, fromDate, toDate } = action.payload;

  try {
    const response = yield call(restCall, '/efficacy_assessment/appointment', 'get', {
      query: {
        person_id: personID,
        from_date: toBackendDate(fromDate),
        to_date: toBackendDate(toDate),
      },
      ...authAdd(),
    });

    const camelizeResponse: Camelize<AppointmentsResponse> = toCamelCase(response);

    const appointments = camelizeResponse.appointments;

    yield put(efficacyAssessmentActions.setAppointments(appointments));
  } catch (error) {
    console.log('Error on appointments fetching');
  }
}

function* fetchMeasureAppointments(
  action: PayloadAction<IMeasureAppointmentsFetchPayload>
): Generator<any, void, MeasureAppointmentsResponse> {
  const { personID, measureID } = action.payload;

  try {
    const response = yield call(restCall, '/efficacy_assessment/measure/appointment', 'get', {
      query: { person_id: personID, measure_id: measureID },
      ...authAdd(),
    });

    const camelizeResponse: Camelize<MeasureAppointmentsResponse> = toCamelCase(response);

    const appointments = camelizeResponse.appointments.map((item) => ({
      ...item,
      fromTimestamp: toClientDateInput(item.fromTimestamp) ?? '',
      toTimestamp: toClientDateInput(item.toTimestamp) ?? '',
    }));

    yield put(efficacyAssessmentActions.setMeasureAppointments(appointments));
  } catch (error) {
    console.log('Error on appointments fetching');
  }
}

function* createEfficacyAssessment(
  action: PayloadAction<IEfficacyAssessmentCreatePayload>
): Generator<any, void, any> {
  const {
    personID,
    appointmentID,
    measureID,
    level,
    measureTitle,
    isParticipated,
    isUnderDirection,
    isAlmostIndependent,
    isIndependent,
    location,
    fromTimestamp,
    toTimestamp,
    hours,
    note,
  } = action.payload;

  try {
    const request: EfficacyAssessmentCreateRequest = {
      json: {
        appointment_id: appointmentID,
        person_id: personID,
        measure_id: measureID,
        measure_title: measureTitle,
        is_participated: isParticipated,
        is_under_direction: isUnderDirection,
        is_almost_independent: isAlmostIndependent,
        is_independent: isIndependent,
        location: location,
        from_timestamp: toBackendDate(fromTimestamp) ?? '',
        hours: hours,
        level: level,
        to_timestamp: toBackendDate(toTimestamp) ?? '',
        note: note,
      },
      ...authAdd(),
    };

    yield call(restCall, '/efficacy_assessment', 'post', request);
  } catch (error) {
    console.log('Error on medication update', error);
  }
}
export const efficacyAssessmentSagas = [
  takeLatest(efficacyAssessmentActions.fetchEfficacyAssessment, fetchEfficacyAssessment),
  takeLatest(efficacyAssessmentActions.fetchAppointments, fetchAppointments),
  takeLatest(efficacyAssessmentActions.fetchMeasureAppointments, fetchMeasureAppointments),
  takeLatest(efficacyAssessmentActions.createEfficacyAssessment, createEfficacyAssessment),
];
