import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface IEvaluateGoalsModalPayload {
  smartGoalID: number;
  goalCheckID: number;
}

export interface IModal {
  isRender: boolean;
  payload: IEvaluateGoalsModalPayload | null;
}

export interface IEvaluateGoalsModalState {
  displayGoalCheckModal: IModal;
}

const initialState: IEvaluateGoalsModalState = {
  displayGoalCheckModal: {
    isRender: false,
    payload: null,
  },
};

export const evaluateGoalsModalSlice = createSlice({
  name: 'evaluateGoalsModal',
  initialState,
  reducers: {
    openDisplayGoalCheckModal: (state, action: PayloadAction<IEvaluateGoalsModalPayload>) => ({
      ...state,
      displayGoalCheckModal: {
        isRender: true,
        payload: action.payload,
      },
    }),
    closeDisplayGoalCheckModal: (state) => ({
      ...state,
      displayGoalCheckModal: {
        isRender: false,
        payload: null,
      },
    }),
  },
});

export const evaluateGoalsModalReducer = evaluateGoalsModalSlice.reducer;
export const evaluateGoalsModalActions = evaluateGoalsModalSlice.actions;
