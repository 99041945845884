import React from 'react';
import { useFormContext } from 'react-hook-form';
import clsx from 'clsx';

import {
  CheckedCheckboxIcon,
  IntermediateCheckboxIcon,
  UnCheckedCheckboxIcon,
} from '@/components/Icons/CheckboxIcons';
import { usePermissions } from '@/components/PermissionsContext/PermissionsContext';
import { ICheckBox } from '@/types/checkBox';

import styles from './styles.module.scss';

export interface ICheckBoxUI {
  checked: boolean;
  intermediate?: boolean;
  name?: string;
  className?: string;
  id?: string;
  onChange?: (checked: boolean) => void;
  preventDefault?: boolean;
  readOnly?: boolean;
}

export const Checkbox: React.FC<ICheckBox> = ({
  name,
  className,
  id,
  onClick,
  disabled,
  shouldSetDirty = true,
  preventDefault = false,
  intermediate = false,
}) => {
  const formContext = useFormContext();
  const reg = formContext.register(name);
  const checked = formContext.watch(name);

  const permissions = usePermissions(name);

  const onChange = (isChecked: boolean) => {
    if (permissions.isReadOnly) {
      return;
    }

    if (onClick) {
      onClick(isChecked);
      return;
    }

    if (preventDefault) {
      return;
    }

    formContext.setValue(name, !checked, { shouldDirty: shouldSetDirty });
  };

  return (
    <CheckboxUI
      checked={checked}
      id={id}
      onChange={onChange}
      name={reg.name}
      intermediate={intermediate}
      className={className}
      readOnly={disabled || permissions?.isReadOnly}
    />
  );
};

export const CheckboxUI: React.FC<ICheckBoxUI> = ({
  checked,
  onChange,
  id,
  name,
  intermediate,
  className,
  readOnly,
}) => {
  const handleClick = (event: React.MouseEvent) => {
    if (readOnly) {
      return;
    }

    onChange?.(!checked);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') {
      onChange?.(!checked);
    }
  };

  return (
    <div
      className={clsx(
        styles.container,
        className,
        'relative flex items-center cursor-pointer',
        readOnly && 'pointer-events-none'
      )}
      onClick={handleClick}
      tabIndex={0}
      role='checkbox'
      aria-checked={checked}
      onKeyDown={handleKeyDown}
    >
      <input type='checkbox' id={id} name={name} />
      <div
        className={clsx(
          styles.iconContainer,
          'absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-10',
          { [styles.iconActive]: checked || intermediate }
        )}
      >
        {checked && !intermediate && <CheckedCheckboxIcon />}
        {!checked && !intermediate && <UnCheckedCheckboxIcon />}
        {intermediate && <IntermediateCheckboxIcon />}
      </div>
    </div>
  );
};
